import React, { ReactNode } from "react";
import { PiX } from "react-icons/pi";

export class Slideout extends React.Component<
    {
        //Properties go here
        children: ReactNode, title: string, closeClicked: () => void
    },
    {
        active: boolean
        //State goes here
    }
> {

    ;

    constructor(props: any) {
        super(props);
        this.state = {
            active: false
            //Initialize ALL state variables here
        }
    }

    static defaultProps = {
        //Set any properties that are optional when creating the component.
    }


    componentDidMount(): void {
        setTimeout(()=>{this.setState({active: true})},1);
        //Put stuff here that you want to run the first time the component is loaded.
    }

    //This is where our external functions and event handlers go.
   

    render() {
        //THis is where we return our HTML for the UI

        return (
            <>
                <div className="transparentWrapper" onClick={() => { this.props.closeClicked(); }}>
                <div className={"slideoutContainer " + (this.state.active ? "active": "")}>
                        <div className="slideoutTopbar">
                            <div className="slideoutTitle">
                                <h1>{this.props.title}</h1>
                            </div>
                            <div className="closeButton" onClick={() => { this.props.closeClicked(); }}>
                                <PiX className="buttonIcon"/>
                            </div>
                        </div>
                        <div className="slideoutMainbar">
                            <div className="slideoutContent">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
