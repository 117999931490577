import React from 'react';
import { DataHelper } from '../Helpers/DataHelper';


class MultiFileBrowser extends React.PureComponent<
{ controllerName: string, methodName: string, performUpload: boolean, dataTransfer: DataHelper, singleFileInput:boolean, progressEvent: (arg0: ProgressEvent)=>void , onUploadComplete: (dataOut: any) => void, onUploadFailed: (status: number, errorText: string, statusText: string) => void  }
> {

  fileUploadInput: React.RefObject<HTMLInputElement>;
  constructor(props: any) {
    
    super(props);
    this.fileUploadInput = React.createRef();
    this.state = {
      
    };
  }
  static defaultProps = {
    progressEvent: null,
    singleFileInput: false
  }

  openDialog = () => {
    let fileInput = (this.fileUploadInput.current as HTMLInputElement);

    if (fileInput) {

      fileInput.click();
    }
  }

  fileName: string = "";


  onInputChange = (e: any) => {
    let fileInput = (this.fileUploadInput.current as HTMLInputElement);
    if (fileInput) {
      e.preventDefault();

            
            if (this.props.performUpload)
            {
              let formData = new FormData();
              for (let index = 0; index < e.target.files.length; index++) {
                  const element = e.target.files[index];
                  formData.append('files', element);
              }
            let DataRec = this.props.dataTransfer
            return DataRec.CallDataServiceFiles(this.props.controllerName, this.props.methodName, formData, (dataOut: any)=>
            {
                
                console.log("Files Uploaded successfully");
                this.props.onUploadComplete(dataOut);
            },  (status: number, errorText: string, statusText: string) =>{ 
              if (this.props.onUploadFailed)
              this.props.onUploadFailed(status, errorText, statusText);
              else
              console.log("Error on files upload. " +errorText);
            } , this.props.progressEvent);
           
          }else
          {
            let FilesSelected = [];
            //If not performing an upload then just return the files.
            for (let index = 0; index < e.target.files.length; index++) {
              const element = e.target.files[index];
              FilesSelected.push(element);

        }
        
        
        this.props.onUploadComplete(FilesSelected)

      }
      
        


    }

  }

  render() {
    return (
      <>
        <form>
          <input ref={this.fileUploadInput} name="files" type='file' style={{ display: "none" }} onChange={this.onInputChange} multiple={!this.props.singleFileInput}>
          </input>
        </form>
      </>
    );
  }


}

export default MultiFileBrowser;