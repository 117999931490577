import React, { RefObject } from "react";
import { StudyModel } from "../Models/StudyModel";
import { DataHelper } from "../Helpers/DataHelper";
import { MeasurementTypesPerStudy } from "../Dashboards/MeasurementTypesPerStudy";
import { UploadsPerUser } from "../Dashboards/UploadsPerUser";
import { LabelsPerStudy } from "../Dashboards/LabelsPerStudy";
import { FlowValues } from "../Dashboards/FlowValues";

export class SelectedHomeWindow extends React.Component<
    {
        selectedStudy: StudyModel, dataClient: DataHelper
    },
    {

    }
> {

    measurementTypesPerStudyWindow: RefObject<MeasurementTypesPerStudy>;
    uploadsPerUserWindow: RefObject<UploadsPerUser>;
    labelsPerStudyWindow: RefObject<LabelsPerStudy>;
    flowValuesWindow: RefObject<FlowValues>;

    constructor(props: any) {

        super(props);
        this.measurementTypesPerStudyWindow = React.createRef();
        this.uploadsPerUserWindow = React.createRef();
        this.labelsPerStudyWindow = React.createRef();
        this.flowValuesWindow = React.createRef();
        this.state = {

        }
    }

    getMainData = (dataIn: StudyModel): void => {
        this.measurementTypesPerStudyWindow.current?.getData(dataIn.id as string);
        this.uploadsPerUserWindow.current?.getData(dataIn.id as string);
        this.labelsPerStudyWindow.current?.getData(dataIn.id as string, this.props.selectedStudy.labelTypes?.at(0));
        this.flowValuesWindow.current?.getData(dataIn.id as string, this.props.selectedStudy.labelTypes?.at(0));
    }

    
    componentDidMount(): void {

    }


    render() {


        return (
            <>
                <div className="dashboardHeader">
                    <h1>Overview of {this.props.selectedStudy.name}</h1>
                </div>
                <div className="dashboardBody">
                    <div className="topContent">
                        <div className="chartContainer">
                        <MeasurementTypesPerStudy ref={this.measurementTypesPerStudyWindow} dataClient={this.props.dataClient} studyId={this.props.selectedStudy.id as string}></MeasurementTypesPerStudy>
                        </div>
                        <div className="chartContainer">
                        <UploadsPerUser ref={this.uploadsPerUserWindow} dataClient={this.props.dataClient} studyId={this.props.selectedStudy.id as string}></UploadsPerUser>
                        </div>
                    </div>
                    <div className="bottomContent">
                        <div className="chartContainer">
                        <LabelsPerStudy ref={this.labelsPerStudyWindow} dataClient={this.props.dataClient} studyId={this.props.selectedStudy.id as string} studySelected={this.props.selectedStudy}></LabelsPerStudy>
                        </div>
                        <div className="chartContainer">
                        <FlowValues ref={this.flowValuesWindow} dataClient={this.props.dataClient} studyId={this.props.selectedStudy.id as string} studySelected={this.props.selectedStudy}></FlowValues>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
