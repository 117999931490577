import React, { RefObject }  from "react";
import { RadialBarChart, RadialBar, Legend, PolarAngleAxis } from "recharts";
import { ChartDataModel } from "../Models/ChartDataModel";
import { DataHelper } from "../Helpers/DataHelper";
import { LoadingWidget } from "../Components/LoadingWidget";
import { ErrorWindow } from "../Components/ErrorWindow";

export class StudyProgress extends React.Component<
    {
        dataClient: DataHelper
    },
    {
        progressData: ChartDataModel[],
        isBusy: boolean
    }
> {

    //Data structure:
    // data = [
    //     {
    //       name: 'category',
    //       value: number,
    //       fill: '#color',
    //     }
    // ];

    style = {
        top: 0,
        left: 350,
        lineHeight: "24px"
    };

    errorWindow:  RefObject<ErrorWindow>;
    constructor(props: any) {
        super(props);
        this.errorWindow = React.createRef();
        this.state = {
            progressData: [],
            isBusy: false,
        }
    }

    componentDidMount(): void {
        this.setState({ isBusy: true })
        this.props.dataClient.CallDashboardService("GetStudyProgress", {}, {}, (result: string) => {
            let studyProgress = JSON.parse(result);
            const colors = ['#3F51B5', '#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57', '#ffc658', '#F58723', '#F54B23', '#D4021B', '#9D0303'];
            for (let index = 0; index < studyProgress.length; index++) {
                const element = studyProgress[index];
                element.fill = colors[index % colors.length];
            }
            this.setState({ progressData: studyProgress, isBusy: false })
        }, (stautus:number, errorMessage:string)=>{
            (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage)}, "GET", false)}

    render() {

        return (
            <>
            <ErrorWindow ref={this.errorWindow}/>
            <h3>Study progress</h3>
            <div className="chartHolder">
                
                <RadialBarChart
                    width={880}
                    height={340}
                    cx={170}
                    cy={170}
                    innerRadius={40}
                    outerRadius={170}
                    barSize={20}
                    data={this.state.progressData}
                    >

                    <PolarAngleAxis
                        type="number"
                        domain={[0, 100]}
                        dataKey={"value1"}
                        angleAxisId={0}
                        tick={false}
                    />
                    <RadialBar
                        
                        background={{ fill: "#ececec" }}
                        label={{ position: "insideStart", fill: "#4A4A4A", formatter: (value1: number) => `${value1.toFixed(0)}%`}}
                        dataKey="value1"
                        angleAxisId={0}
                    />
                    <Legend
                        iconSize={10}
                        width={180}
                        height={140}
                        layout="vertical"
                        wrapperStyle={this.style}
                        align={"left"}
                    />
                </RadialBarChart>
                <LoadingWidget visible={this.state.isBusy} zIndexLayer={0}></LoadingWidget>
            </div>
            </>
        );
    }
}
