import React, { ReactNode } from "react";

export class DropdownButton extends React.Component<
  {  dropdownValue: string, children: ReactNode  },
  {
    showSelect: boolean
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
        showSelect: false
    }
  }


  render() {
    return (
      <>
      
      <span className="dropdown filter">
                        
                        <span
                            className="large-label dropdown-opener"
                            onClick={() => {
                            this.setState({
                                showSelect: !this.state.showSelect
                            })
                        }}>
                              <span className="small-label serials-label">
                               {this.props.dropdownValue}</span>
                            <span className="dropdownArrow">▼</span>
                        </span>
                         <div
                            className={"dropdown-content " + (this.state.showSelect
                            ? "open"
                            : "closed")} onClick={() => {
                              this.setState({
                                  showSelect: !this.state.showSelect
                              })
                          }}>
                          <div className="dropdownBody">
                            {this.props.children}
                          </div>
                         
                        <div className="BottomButtons">
                            <button className="button-main close" 
                                onClick={() => {
                                    this.setState({
                                        showSelect: !this.state.showSelect
                                    })
                                }}>Close</button>
                        </div>
                    </div>
                    </span>
         
      </>
    );
  }
}
