import React from "react";

export class TermsConditions extends React.Component<
    {
        confirmClicked: (accepted: boolean) => void
    },
    {
        //State goes here
    }
> {


    render() {

      return (
        <>
          <div className="termsContainer">
            <p>Medistim CaseCloud is a service for anonymized analysis of clinical data. Uploading data requires consent from the patients to use data for research purposes.</p>
            <p>All data is handled according to Medistim's personal data protection policy:</p>
            <p><a target="_blank" rel="noopener noreferrer" title="personal data protection policy" href="https://medistim.com/about-us/personal-data-protection-policy/">Personal Data Protection Policy</a></p>
            <p className="spaceTop">Contact: <a href="mailto:casecloud@medistim.com" title="Contact Medistim CaseCloud">casecloud@medistim.com</a></p>
          
            <div className="termsAccept">
              <p className="spaceTop">I have read and accept the above.</p>
              <button className="primaryButton confirmButton" onClick={()=>{this.props.confirmClicked(true);}}>Confirm</button>
            </div>
          </div>
        </>
        );
    }
}



