import * as React from 'react';
import ProbeIndicator from './ProbeIndicator';
import { IndicatorState, Tabs, TargetDetails } from './TriggerClasses';

class ConduitVisualize extends React.Component < {
    topConduit: string,
    bottomConduit: string,
    leftConduit: string,
    rightConduit: string,
    topIndicator: IndicatorState,
    bottomIndicator: IndicatorState,
    leftIndicator: IndicatorState,
    rightIndicator: IndicatorState,
    mode: Tabs
}, {
},
any> 
{

    constructor(props : any) {
        super(props);
        this.state = {
    
        };
    }
    static defaultProps={
        topConduit: null, 
        bottomConduit: null, 
        leftConduit: null, 
        rightConduit: null,
        topIndicator: IndicatorState.none,
        bottomIndicator: IndicatorState.none,
        leftIndicator: IndicatorState.none,
        rightIndicator: IndicatorState.none,
        mode: Tabs.TTFM,
       
    };

    render() {

        return ( 
        <> 
        <div className="visual-conduit-cross">
            <div className="visual-conduit-cross-top" style={{backgroundColor: this.props.topConduit ? TargetDetails.getTargetDetail(this.props.topConduit).color : "none" }}>
          
            </div>
            {
                    (this.props.topIndicator !== IndicatorState.none && this.props.topConduit ?

                    <ProbeIndicator mode={this.props.mode} classToUse="visual-conduit-cross-top" offsetX={-10} offsetY={-5} rotation={90} indicator={this.props.topIndicator}></ProbeIndicator>
                    : null)
                }
            <div className="visual-conduit-cross-bottom" style={{backgroundColor: this.props.bottomConduit ? TargetDetails.getTargetDetail(this.props.bottomConduit).color : "none" }}>
                
            </div>
            {
                    (this.props.bottomIndicator !== IndicatorState.none && this.props.bottomConduit ?

                    <ProbeIndicator mode={this.props.mode} classToUse="visual-conduit-cross-bottom" offsetX={10} offsetY={-10} rotation={90} indicator={this.props.bottomIndicator}></ProbeIndicator>
                    : null)
                }
            <div className="visual-conduit-cross-left" style={{backgroundColor: this.props.leftConduit ? TargetDetails.getTargetDetail(this.props.leftConduit).color : "none" }}>
            
            </div>
            {
                    (this.props.leftIndicator !== IndicatorState.none && this.props.leftConduit ?

                    <ProbeIndicator mode={this.props.mode} classToUse="visual-conduit-cross-left" offsetX={-5} offsetY={-30} rotation={0} indicator={this.props.leftIndicator}></ProbeIndicator>
                    : null)
                }
            <div className="visual-conduit-cross-right" style={{backgroundColor: this.props.rightConduit ? TargetDetails.getTargetDetail(this.props.rightConduit).color : "none" }}>
            
            </div>
            {
                    (this.props.rightIndicator !== IndicatorState.none && this.props.rightConduit ?

                    <ProbeIndicator mode={this.props.mode} classToUse="visual-conduit-cross-right" offsetX={-10} offsetY={5} rotation={0} indicator={this.props.rightIndicator}></ProbeIndicator>
                    : null)
                }
        </div> 
        </>     
    )
  }
}


export default ConduitVisualize;