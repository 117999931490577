import { DataGridRow } from "../Helpers/SharedClasses";

export class UserModel extends DataGridRow
{
    id: string = "";
    name: string = "";
    userType: string = "";
    email: string = "";
    studies: string[] = [];
    lastAccessed?: Date;
    userHospital:string="";
}