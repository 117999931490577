import React, {RefObject} from "react";
import DataGrid from "../Components/DataGrid";
import { DataHelper } from "../Helpers/DataHelper";
import { ErrorWindow } from "../Components/ErrorWindow";
import { StudyModel } from "../Models/StudyModel";
import { InfoBox } from "../Components/InfoBox";
import { ImportHistoryModel } from "../Models/ImportHistoryModel";
import { DataGridColumn } from "../Helpers/SharedClasses";

export class ImportHistory extends React.Component<
    {
        
        dataClient: DataHelper,
        allStudies: StudyModel[]

        

        //Properties go here
    },
    {
        importHistoryLog:ImportHistoryModel[],
       
        //State goes here
    }
> {

    ;

    errorWindow:  RefObject<ErrorWindow>;
    infoBox: RefObject<InfoBox>;
    
    constructor(props: any) {
        super(props);
        this.errorWindow = React.createRef();
        this.infoBox = React.createRef();
        this.state={
            
            importHistoryLog:[],
            
        }

    }


    componentDidMount(): void {
        this.refreshImports();
    //     this.props.dataClient.CallMetadataService("GetUsers", {}, {}, (resultOut: string)=>
    //     {
    //         let allUsers: UserModel[] = JSON.parse(resultOut);
    //         this.setState({
    //             allUsers: allUsers
                
    //         })
    //     //Put stuff here that you want to run the first time the component is loaded.
    // });        
    }


    refreshImports():void{
        this.props.dataClient.CallMetadataService("GetImportHistory", {}, {}, (resultOut: string)=>
            {
                let usageHistoryLog: ImportHistoryModel[] = JSON.parse(resultOut);
               
                this.setState({
                    importHistoryLog: usageHistoryLog
                })
            },(stautus:number, errorMessage:string)=>{
                (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage);
            });
    }
   

   
    render() {
       
        let columns: DataGridColumn[]= [
            //{Name: "id", Caption: "Id"},
           
            {Name: "email", Caption: "Email"},
            {Name: "importDate", Caption: "Upload date", Type: "date"},
            {Name: "studyId", Caption: "Study", RenderTemplate: (item, rowItem, parentRow)=>{
                let studyName = item;
                for (let index = 0; index < this.props.allStudies.length; index++) {
                    const element = this.props.allStudies[index];
                    if (element.id === item)
                    {
                        studyName = element.name;
                    }
                }
                return <>{studyName}</>;
            } },
            {Name: "hospitalName", Caption: "Hospital name"},
            {Name: "importLabel", Caption: "Import name"},
            {Name: "totalProcedures", Caption: "Procedures"},
            {Name: "totalMeasurements", Caption: "Measurements"}
            ];
          
        return (
            <>
            <ErrorWindow ref={this.errorWindow}/>
            <InfoBox ref={this.infoBox} title="Confirmation" type="confirmation"></InfoBox> 
            <div className="userWindowWrapper">
                <div className="userWindowBody">
                    <div className="usersGridHolder">
                        <DataGrid columns={columns}

                            rows={this.state.importHistoryLog}
                            
                        ></DataGrid>

                    </div>
                </div>
                
            </div>
            </>
        );
    }
}

