import React from "react";

export class InputItem extends React.Component<
    { title: string, chosenOptions:string[], minIntervalVal?:string, maxIntervalVal?:string, disableButton?:boolean, type: string, name: string, onChange?: (name: string, value: any, intervalVal?:any, dropdownSelection?:any) => void, deleteOption?:(value:string,row?:any)=>void, value?: any, options?: string[] },
    {
        minInterval:string,
        maxInterval:string,
        validationMessage: string
        disableButton:boolean
    }
> {
    constructor(props: any) {
        super(props);
        this.state={
            minInterval:"-99999",
            maxInterval:'99999',
            validationMessage: "",
            disableButton:true
            
        }
    }
    static defaultProps = {
        minIntervalVal:'-99999',
        maxIntervalVal:'99999',
        chosenOptions: [],
        
    }

validateInputValue = (value: any) : boolean=> {
    var code, i, len;
    if (typeof value === 'string' || value instanceof String)
    {
        for (i = 0, len = value.length; i < len; i++) {
        code = value.charCodeAt(i);
        if ((code !== 32 && code !== 45 && code !== 46 && code !== 40 && code !== 41) &&
            !(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)) { // lower alpha (a-z)
            return false;
        }
        }
    }
    return true;
}
    stringToColor(str: string): string {
        let hash = 0;
        if (!str || str.length === 0) {
        return 'red';
        }

        for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        const hue = (hash*19) % 360;
        return `hsl(${hue}, 50%, 80%)`; // Here, we set the saturation to 100% and lightness to 75%
    }

    internalOnChange = (e: any, minInterval?:any) => {

        let val=''
        if(e===this.props.maxIntervalVal || e===this.props.minIntervalVal){
            val=e;
        }else
        {
            val=e.currentTarget.value
        }
        
        
     
        //validate the value for invalid characters and show error if there are. 
        if ( (this.props.type === "text" || this.props.type==="longtext") && !this.validateInputValue(val))
        {
            this.setState({
                validationMessage: "Only alphanumeric characters and limited punctuation characters are supported."
            })
        }else
        {
        if(minInterval===true && this.props.onChange){
            this.props.onChange(this.props.name, val, this.state.maxInterval);
        }
        else if(minInterval===false && this.props.onChange){
            this.props.onChange(this.props.name, this.state.minInterval, val);
        }
        else if (this.props.type === "addableTextField") {
            if (e.type === "click") {
                // Handle add button click
                if (this.props.onChange && this.props.value) {
                    this.props.onChange(this.props.name, this.props.value, true);
                    if (e.currentTarget) {
                        e.currentTarget.previousElementSibling.value = "";
                    }
                }
            } else {
                // Handle text input change
                if (this.props.onChange) {
                    this.props.onChange(this.props.name, e.currentTarget.value, false);
                }
            }
        }
        else if (this.props.onChange){this.props.onChange(this.props.name, val, this.props.chosenOptions);}
        this.setState({
            validationMessage: ""
        });
        }
    }

    internalCheckboxChange = (e: any) => {

        if(this.props.onChange)this.props.onChange(this.props.name, e.currentTarget.checked);

    }


    handleDeleteOption = (index:number, value:any) => {

        if(this.props.deleteOption)this.props.deleteOption(value)
    }

    

    render() {
        return (
            <>
                <div className="inputContainer">
                    <div className="inputLabel"><label htmlFor="inputLabel">{this.props.title}</label></div>
                    <div>
               
                    {this.props.type === "longtext" ? <textarea className="longtextbox" value={this.props.value} onChange={this.internalOnChange as any} />
                        : this.props.type === "dropdown" ? (
                             <select
                               className="dropdown"
                               defaultValue={this.props.value}
                               onChange={(e) => this.internalOnChange(e)}
                             >
                               
                               {this.props.options?.map((option) => (
                                 <option key={this.props.title+option} value={option}>{option}</option>
                                 
                               ))}
                            </select>
                        ):this.props.type==="boolean"?(
                            <select
                               className="boolean"
                               defaultValue={this.props.value}
                               onChange={(e) => this.internalOnChange(e)}
                             >
                                <option value='--Select--'>--Select--</option>
                                <option value='true'>Yes</option> 
                                <option value='false'>No</option> 
                            </select>

                        ):this.props.type==="gender"?(
                            <select
                               className="boolean"
                               defaultValue={this.props.value}
                               onChange={(e) => this.internalOnChange(e)}
                             >
                                     <option value='--Select--'>--Select--</option>
                                <option value='Male'>M</option> 
                                <option value='Female'>F</option> 
                           
                            </select>

                        ): this.props.type === "interval" || this.props.type === "int" || this.props.type==="star" ?(
                            <div className="intervalHolder">
                                <input className="intervalBox" type="number" placeholder="From" name="quantityMin" onChange={(e)=>
                                    {
                                    if(e.currentTarget.value===''){
                                        if(this.state.maxInterval==='99999'){
                                            if(this.props.deleteOption){this.props.deleteOption('-1')}
                                        }else{
                                        this.setState({
                                        minInterval:(this.props.minIntervalVal as string)})
                                        this.internalOnChange((this.props.minIntervalVal as string), true)
                                        }
                                    }else{
                                        this.setState({
                                        minInterval:e.currentTarget.value})
                                        this.internalOnChange(e, true)
                                    }
                                    }}/>
                                <input className="intervalBox" type="number" placeholder="To" name="quantityMax" onChange={(e)=>
                                
                                    {
                                    if(e.currentTarget.value===''){
                                        if(this.state.minInterval==='-99999'){
                                            if(this.props.deleteOption){this.props.deleteOption('-1')}
                                        }else{
                                        this.setState({
                                        maxInterval:(this.props.maxIntervalVal as string)})
                                        this.internalOnChange((this.props.maxIntervalVal as string), false)
                                        }
                                    }else{
                                        this.setState({
                                        maxInterval:e.currentTarget.value})
                                        this.internalOnChange(e, false)
                                    }
                                    
                                    }}/>
                            </div>
                        ): this.props.type==="multipleSelect"?(
                            <div>
                                {this.props.chosenOptions && this.props.chosenOptions.length > 0 && this.props.chosenOptions[0]!==''? (
                                    <div className="multipleSelectLabelContainer">
                                        {this.props.chosenOptions.map((chosenOption, index)=>(
                                            <div key={index}>
                                                <span className="multipleSelectLabel" style={{backgroundColor: this.stringToColor(chosenOption)}}>{chosenOption}</span>
                                                <button className="multipleSelectButton" onClick={(e)=> this.handleDeleteOption(index, chosenOption)}>X</button>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ):  this.props.type==="dropdownMultipleSelect"?(
                            <div>

                                <select
                                    className="dropdownMultipleSelect"
                                    defaultValue={this.props.value}
                                    onChange={(e) => {this.internalOnChange(e)
                                        }}
                                    >
                                    
                                    {["--Select--",...this.props.options||[]].map((option, index) => (
                                        <option key={"Select_" + option} value={option} >
                                            {option}
                                        </option>
                                        
                                    ))}
                                </select>
                                
                                {this.props.chosenOptions && this.props.chosenOptions.length > 0 && (
                                    <div>
                                    {this.props.chosenOptions?.map((chosenOption, index)=>(
                                        <div key={index}>
                                            <span>{chosenOption}</span>
                                            <button onClick={(e)=> this.handleDeleteOption(index, chosenOption)}>X</button>
                                        </div>
                                    ))}

                                    </div>
                            )}
                            </div>
                            ): this.props.type === "addableTextField"?
                            (
                            <div className="addableTextField">
                                <input 
                                    type="text" 
                                    value={this.props.value}

                                    onChange={this.internalOnChange}
                                    placeholder="Enter text"
                                />
                                <button 
                                    disabled={this.props.disableButton}
                                    onClick={this.internalOnChange}
                                    className="addButton"
                                >
                                    <span className="plusSign">+</span>
                                </button>
                            </div>
                            ): this.props.type === "checkbox" ? 
                            (<input className="checkbox" id="checkbox" type={this.props.type} name={this.props.name} checked={this.props.value} onChange={this.internalCheckboxChange as any} />)
                        
                            : <input className="textbox" id="lt" type={this.props.type} name={this.props.name} value={this.props.value} onChange={this.internalOnChange as any} />}


{
                                this.state.validationMessage ?
                                <div className="validationerror">
                                    {this.state.validationMessage}
                                </div>
                                : <></>

                            }
                            </div>
                </div>

            </>

        );
    }
}