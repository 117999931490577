import React from "react";

export class ShowMore extends React.Component<
    {
        text:string
        maxLength:number
    },
    {
        visible:boolean
    }
> {

    ;

    constructor(props: any) {
        super(props);
        this.state = {
            visible:false
            //Initialize ALL state variables here
        }
    }

    static defaultProps = {
        //Set any properties that are optional when creating the component.
      }


    showMore=()=>{
        this.setState({
            visible:true
        });
    }

    showLess=()=>{
        this.setState({
            visible:false
        });
    }



    render() {
        //THis is where we return our HTML for the UI

        return (
            <>
            
            <div>
                {this.state.visible?(
                    <>
                    {this.props.text}
                    <button className="show-less-button" onClick={this.showLess}>Show less</button>
                    </>
                ) : (
                    <>
                    {this.props.text.slice(0, this.props.maxLength)}
                    {this.props.text.length > this.props.maxLength && <button className="show-more-button" onClick={this.showMore}>Show more</button>}
                    </>
                )}
                </div>
                
            </>
        );
    }
}


export default ShowMore;