import React from "react";
import { PreviewProcedureModel } from "../Models/PreviewProcedureModel";
import { MeasurmentThumbnail } from "../Components/MeasurementThumbnail";
import { DataHelper } from "../Helpers/DataHelper";
import { StudyModel } from "../Models/StudyModel";
import { PiPrinter } from "react-icons/pi";
import { LabelModel } from "../Models/LabelModel";
import { Graft, TargetLocation } from "../Models/Trigger/TriggerClasses";
import SubwayMap from "../Models/Trigger/SubwayMap";
import HeartDrawingOverlay from "../Models/Trigger/HeartDrawingOverlay";


export class ReportingWindow extends React.Component<
    {
        //Properties go here
        procedure: PreviewProcedureModel | undefined,
        dataHelper: DataHelper,
        studySelected: StudyModel
    },
    {
        //State goes here
    }
> {

    static defaultProps = {
        //Set any properties that are optional when creating the component.
    }


    componentDidMount(): void {
        //Put stuff here that you want to run the first time the component is loaded.
    }

    //This is where our external functions and event handlers go.
    externalMethod = (parameter1: string): string => {
        return "";

    }

    divOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        // alert(localVariable);
    }
    divOnClickVariable = (variableValue: string): void => {
        alert(variableValue);
    }

    render() {
        let subwayMaps: React.ReactElement[] = []; 
       
        let FullIllustration = <></>;
        if (this.props.procedure?.measurements)
        {
            let suggestedLabelsLabels: LabelModel[][] = [];
            for (let index = 0; index < this.props.procedure?.measurements.length; index++) {
                let suggestedLabels: LabelModel[] = [];
                const measurement = this.props.procedure?.measurements[index];
                if (measurement.labels)
                {
                    // for (let index2 = 0; index2 < measurement.labels.length; index2++) {
                    //     const label = measurement.labels[index2];
                        
                    // }
                   //Remove label duplicates
           let newLabelList = [];
           let labelsAdded = [];
        for (let index = 0; index < measurement.labels.length; index++) {
          const element = measurement.labels[index];
          if (labelsAdded.indexOf(element.id) === -1)
          {
            labelsAdded.push(element.id);
            newLabelList.push(element);
          }
        
        }
                    for (let index = 0; index < newLabelList.length; index++) {
                        const element = newLabelList[index];

                        if ((element.suggestedLabels as LabelModel[]))
                        {
                        for (let index2 = 0; index2 < (element.suggestedLabels as LabelModel[]).length; index2++) {
                            const element2 = (element.suggestedLabels as LabelModel[])[index2];
                            if (suggestedLabels.filter((pred) =>pred.name === element2.name).length > 0)
                            {
                                
                            }else
                            {

                                suggestedLabels.push(element2);
                            }
                            
                        }
                    }

                        // suggestedLabels = suggestedLabels.concat(element.suggestedLabels as LabelModel[]);


                    }
                    
                }
                suggestedLabelsLabels.push(suggestedLabels);
            }

           
           
            if (suggestedLabelsLabels.length > 0 )
            {
                let HeartPic = <></>;
                let graftsout : Graft[] =  Graft.GenerateGrafts(suggestedLabelsLabels);
                //Here we need to split to Show other diagrams like vascular etc.
                if (graftsout.length > 0)
                {
                HeartPic = <div className="alternative-grafts-container " >
                    <HeartDrawingOverlay Context="TTFM" drawDebugLine={false} selectedProcedure={{name: "CABG", title: "", type: "Cardiac"}} heartxOffset={0 } heartyOffset={-30} GraftsLoaded={graftsout} customGraftLocation={new TargetLocation()} heartTargetsShow={true} heartOutlineShow={true} editGraftClicked={()=>{}} graftSelected={new Graft()}></HeartDrawingOverlay>
                    </div>;
           
                for (let index = 0; index < graftsout.length; index++) {
                    const element = graftsout[index];
                    subwayMaps.push(<SubwayMap CurrentItem={element} Context={"TTFM"} customGraftText={""} index={0} commentToggled={function (e: boolean): void {
                        throw new Error("Function not implemented.");
                    } } measurementSelectionOpen={false} measurementSelectionSelected={function (e: boolean): void {
                        throw new Error("Function not implemented.");
                    } } tileColorOn={false}></SubwayMap>)
                }

                FullIllustration = <div className="holderIllustrationContainer"> 
                <div className="illustrationContainer">{HeartPic}</div>
                <div className="illustrationWorklist">{subwayMaps}</div>
                </div>;
                 }
            
            }
            
    }
        return (
            <>
                <div id="report-window">
                    <div className="subtitleAction">
                        <h2>Print preview:</h2>
                        
                        <button className="primaryButton actionButton" onClick={() => {
                           (window as any).print();
                           }}><PiPrinter></PiPrinter><span> Print</span>
                        </button>
                    </div>
                
                    <div id="section-to-print">
                        <div className="header-details">
                            <table className="row-item">
                                <tbody className="wide-column top-row">
                                    <tr>
                                        <td className="header">
                                            Procedure Outcome
                                        </td>
                                        <td className="header-icon">
                                            <span className="reportNumber"></span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="166.518" height="24" viewBox="0 0 166.518 24">
                                                <path id="medistimlogo" data-name="medistimlogo" d="M79.013,16.866V6.408a1.716,1.716,0,0,0-1.679-1.689H73.573V0h3.761a6.411,6.411,0,0,1,6.413,6.408V16.835a2.431,2.431,0,0,0,2.428,2.431h15.057a2.453,2.453,0,0,0,.008-4.906h-7.3A7.183,7.183,0,0,1,93.851,0h10.2V4.744h-10.1a2.449,2.449,0,0,0,0,4.9h7.327a7.179,7.179,0,0,1,0,14.358H86.143A7.146,7.146,0,0,1,79.013,16.866ZM161.544,24V6.994h-.067L155.527,24h-4.114L145.439,7.169h-.059V24h-5V0h7.519l5.672,16.512h.075L159.006,0h7.511V24Zm-29.879,0V0h4.84V24Zm-16.187,0V4.734h-8.017V0h21.3V4.734h-8.017V24ZM51.306,24V0H61.689a10.666,10.666,0,0,1,7.8,2.953c2.036,2.062,3.118,5.148,3.118,8.915a13.3,13.3,0,0,1-2.7,8.677,9.135,9.135,0,0,1-3.412,2.541,11.987,11.987,0,0,1-4.8.914Zm5.3-4.429H61.32a5.69,5.69,0,0,0,4.154-1.605,7.724,7.724,0,0,0,1.843-5.585c0-2.605-.49-4.5-1.494-5.781C64.689,5.148,62.9,4.447,60.371,4.447H56.605ZM30.013,24V0H48.137V4.447H35.343V9.585H47.082v4.552H35.343v5.433H48.4V24Zm-8.876,0V6.994h-.059L15.124,24H11.006L5.06,7.169H4.993V24H0V0H7.5l5.679,16.512h.071L18.627,0h7.511V24Z" fill="#F56200" />
                                            </svg>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="main-details">

                            <table className="patientTable">
                                <tbody>
                                    <tr className="infoRow">
                                        <td className="infoRowLeft" colSpan={2}>
                                        <table className="blockTable idTable">
                                                <tbody>
                                                    <tr className="detail-item">
                                                        <td className="item-label">Id:</td>
                                                        <td className="item-value">{this.props.procedure?.id}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr className="vertical-seperator"><td colSpan={2}></td></tr>
                                    <tr className="infoRow">
                                        <td className="infoRowLeft">
                                            <table className="blockTable">
                                                <tbody>
                                                    <tr className="detail-item">
                                                        <td className="item-label">Hospital name:</td>
                                                        <td className="item-value">{this.props.procedure?.hospitalName}</td>
                                                    </tr>
                                                    <tr className="detail-item">
                                                        <td className="item-label">Procedure date:</td>
                                                        <td className="item-value">{new Date(this.props.procedure?.createdDate as Date).toLocaleString()}</td>
                                                    </tr>
                                                    <tr className="detail-item">
                                                        <td className="item-label">Imported date:</td>
                                                        <td className="item-value">{new Date(this.props.procedure?.importDate as Date).toLocaleString()}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td className="infoRowRight">
                                            <table className="blockTable">
                                                <tbody>
                                                    <tr className="detail-item">
                                                        <td className="item-label">-</td>
                                                        <td className="item-value"></td>
                                                    </tr>
                                                    <tr className="detail-item">
                                                        <td className="item-label">Generated by:</td>
                                                        <td className="item-value">{this.props.procedure?.userId}</td>
                                                    </tr>
                                                    <tr className="detail-item">
                                                        <td className="item-label">Generated at:</td>
                                                        <td className="item-value">{new Date(Date.now()).toLocaleString()}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr className="vertical-seperator"><td colSpan={2}></td></tr>

                                    <tr className="infoRow">
                                        <td className="infoRowLeft">
                                            <table className="blockTable mainDataTable">
                                                <tbody>
                                                    <tr className="detail-item">
                                                        <td className="item-label">Flow measurements:</td>
                                                        <td className="item-value">{this.props.procedure?.flowMeasurements}</td>
                                                    </tr>
                                                    <tr className="detail-item">
                                                        <td className="item-label">Imaging measurements:</td>
                                                        <td className="item-value">{this.props.procedure?.imagingMeasurements}</td>
                                                    </tr>
                                                    <tr className="detail-item">
                                                        <td className="item-label">PW measurements:</td>
                                                        <td className="item-value">{this.props.procedure?.pWMeasurements}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td className="infoRowRight">
                                            <table className="blockTable">
                                                <tbody>
                                                    <tr className="detail-item">
                                                        <td className="item-label">Comments:</td>
                                                        <td className="item-value comment-value">{this.props.procedure?.comments}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr className="vertical-seperator"><td colSpan={2}></td></tr>

                                    <tr className="infoRow infoRowFull">
                                        <td colSpan={2}>
                                            <table className="blockTable">
                                                <tbody>
                                                    <tr className="detail-item">
                                                        {/* <td className="item-label">Labels:</td>
                                    <td className="item-value">{this.props.procedure?.anatomicalLocations}</td>  */}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        {FullIllustration}
                        <div className="thumbnails-details">

                            <h3 className="sub-header">Measurements</h3>

                            <table className="row-item thumbnail-container">
                                
                                    {
                                        this.props.procedure ?
                                            this.props.procedure.measurements.map((item) => {
                                                let subwayMap: React.ReactElement[] =[];
                                                if (item.labels)
                                                {
                                                    let suggestedLabels: LabelModel[] = [];
                                                    for (let index = 0; index < item.labels.length; index++) {
                                                        const element = item.labels[index];


                                                        if ((element.suggestedLabels as LabelModel[]))
                                                        {
                                                        for (let index2 = 0; index2 < (element.suggestedLabels as LabelModel[]).length; index2++) {
                                                            const element2 = (element.suggestedLabels as LabelModel[])[index2];
                                                            if (suggestedLabels.filter((pred) =>pred.name === element2.name).length > 0)
                                                            {
                                                                
                                                            }else
                                                            {

                                                                suggestedLabels.push(element2);
                                                            }
                                                            
                                                        }
                                                    }

                                                        // suggestedLabels = suggestedLabels.concat(element.suggestedLabels as LabelModel[]);


                                                    }   
                                                    
                                                    let graftOut = Graft.GenerateGrafts([suggestedLabels]);

                                                    if (graftOut.length > 0 && !graftOut[0].BlankNewItem)
                                                    {
                                                        
                                                        subwayMap = [<SubwayMap CurrentItem={graftOut[0]} Context={"TTFM"} customGraftText={""} index={0} commentToggled={function (e: boolean): void {
                                                            throw new Error("Function not implemented.");
                                                        } } measurementSelectionOpen={false} measurementSelectionSelected={function (e: boolean): void {
                                                            throw new Error("Function not implemented.");
                                                        } } tileColorOn={false}></SubwayMap>];
                                                    }
                                                  
                                                }
                                            

                                                let meanValue= Math.abs((item.mean as number *1000));
                                                let lowerValue = this.props.studySelected.lowerReferenceValue as number;
                                                let upperValue = this.props.studySelected.upperReferenceValue as number;
                                                let meanflowClassname = ( meanValue< lowerValue? "meanflowThumbnailRed": meanValue > upperValue? "meanflowThumbnailGreen": "meanflowThumbnailYellow")
                          

                                                return <tbody className="report-measurement">
                                                    
                                                        {
                                                            item.measurementType === "Flow" &&
                                                            <tr className="thumbnail-item flow infoRow">
                                                            <td className="infoRowLeft">
                                                                <table className="blockTable">
                                                                    <tbody>
                                                                        <tr className="detail-item bigRow">
                                                                            <td colSpan={2} className="item-value">{new Date(item.recordDate as Date)?.toLocaleString()}</td>
                                                                        </tr>
                                                                        <tr className="detail-item">
                                                                            <td className="item-label">Location:</td>
                                                                            <td className="item-value">{item.anatomicalLocations}</td>
                                                                        </tr>
                                                                        <tr className="detail-item">
                                                                            <td className="item-label">Subway:</td>
                                                                            <td className="item-value">{subwayMap}</td>
                                                                        </tr>
                                                                        <tr className={"detail-item " + meanflowClassname}>
                                                                            <td className="item-label">Flow:</td>
                                                                            <td className="item-value">{(item.mean ? item.mean *1000 : 0.0).toFixed(2)} {"mL/min"}</td>
                                                                        </tr>
                                                                        <tr className="detail-item">
                                                                            <td className="item-label">PI:</td>
                                                                            <td className="item-value">{item.pI} {""}</td>
                                                                        </tr>
                                                                        <tr className="detail-item">
                                                                            <td className="item-label">DF:</td>
                                                                            <td className="item-value">{item.dF} {""}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="infoRowRight"> <MeasurmentThumbnail procedureId={this.props.procedure?.id as string} dataHelper={this.props.dataHelper} rowData={item} studySelected={this.props.studySelected} detailed={true} context="ReportWindow" ></MeasurmentThumbnail></td>
                                                        </tr>
                                                        }
                                                        {
                                                            item.measurementType === "Imaging" &&
                                                            <tr className="thumbnail-item imaging infoRow">
                                                                <td className="infoRowLeft">
                                                                    <table className="blockTable">
                                                                        <tbody>
                                                                            <tr className="detail-item bigRow">
                                                                                <td colSpan={2} className="item-value">{new Date(item.recordDate as Date)?.toLocaleString()}</td>
                                                                            </tr>
                                                                            <tr className="detail-item">
                                                                                <td className="item-label">Location:</td>
                                                                                <td className="item-value">{item.anatomicalLocations}</td>
                                                                            </tr>
                                                                            <tr className="detail-item">
                                                                            <td className="item-label">Subway:</td>
                                                                            <td className="item-value">{subwayMap}</td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td className="infoRowRight"> <MeasurmentThumbnail procedureId={this.props.procedure?.id as string} dataHelper={this.props.dataHelper} rowData={item} studySelected={this.props.studySelected} detailed={true} context="ReportWindow" ></MeasurmentThumbnail></td>
                                                            </tr>
                                                        }
                                                        {
                                                            item.measurementType === "Ecg" &&
                                                            <tr className="thumbnail-item ecg infoRow">
                                                            <td className="infoRowLeft">
                                                                <table className="blockTable">
                                                                    <tbody>
                                                                        <tr className="detail-item bigRow">
                                                                            <td colSpan={2} className="item-value">{new Date(item.recordDate as Date)?.toLocaleString()}</td>
                                                                        </tr>
                                                                        <tr className="detail-item">
                                                                            <td className="item-label">Location:</td>
                                                                            <td className="item-value">{item.anatomicalLocations}</td>
                                                                        </tr>
                                                                        <tr className="detail-item">
                                                                            <td className="item-label">BPM max:</td>
                                                                            <td className="item-value">{item.bPMMax} {""}</td>
                                                                        </tr>
                                                                        <tr className="detail-item">
                                                                            <td className="item-label">BPM min:</td>
                                                                            <td className="item-value">{item.bPMMin} {""}</td>
                                                                        </tr>
                                                                        <tr className="detail-item">
                                                                            <td className="item-label">BPM mean:</td>
                                                                            <td className="item-value">{item.bPMMean} {""}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="infoRowRight"> <MeasurmentThumbnail procedureId={this.props.procedure?.id as string} dataHelper={this.props.dataHelper} rowData={item} studySelected={this.props.studySelected} detailed={true} context="ReportWindow" ></MeasurmentThumbnail></td>
                                                            </tr>
                                                        }

                                                        
                                                    
                                                    
                                                </tbody>

                                            }) : <></>}
                                
                            </table>

                        </div>
                        <div className="report-measurements">



                        </div>
                    </div>
                </div>
                

                
            </>
        );
    }
}
