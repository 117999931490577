import React, { RefObject } from "react";
import DataGrid from "../Components/DataGrid";
import { DataHelper } from "../Helpers/DataHelper";
import { PreviewProcedureModel } from "../Models/PreviewProcedureModel";
import { AppMode, DataGridColumn, DataGridRow } from "../Helpers/SharedClasses";
import { PreviewMeasurement } from "../Models/PreviewMeasurement";
import { ErrorWindow } from "../Components/ErrorWindow";
import { StudyModel } from "../Models/StudyModel";
import { MeasurmentThumbnail } from "../Components/MeasurementThumbnail";
import { InfoBox } from "../Components/InfoBox";
import { PiFilePdfDuotone, PiTrash } from "react-icons/pi";
import { InputItem } from "../Components/InputItem";
import { StarPreview } from "../Components/StarPreview";
import { LabelModel } from "../Models/LabelModel";
import { Graft } from "../Models/Trigger/TriggerClasses";
import SubwayMap from "../Models/Trigger/SubwayMap";
import { ExportModel } from "../Models/ExportModel";


export class SearchWindow extends React.Component<
  { procedures: PreviewProcedureModel[], studySelected: StudyModel, dataHelper: DataHelper, showOtherWindow: (windowType: AppMode, additionalData: any) => void, toggleFilter: boolean, editMode: boolean, exportMode: boolean, procedureItemUpdated: (procId: string, columnChanged: string, value: string) => void },
  {

    options: string[],
    includeMeasurements: boolean
    

  }
> {

  ;

  errorWindow:  RefObject<ErrorWindow>;
  searchGrid: RefObject<DataGrid>;
  infoBox: RefObject<InfoBox>;
  constructor(props: any) {
      super(props);
    this.errorWindow = React.createRef();
    this.searchGrid = React.createRef();
    this.infoBox = React.createRef();
    let procParse: PreviewProcedureModel[] = this.props.procedures;
    let options: string[] = []
    procParse.map((procedure, index) => procedure.anatomicalLocations.forEach(location => {
      if (!options.includes(location) && /^[a-zA-Z]+$/.test(location)) {
        options.push(location)
      }
    }
    ))
    this.state = {
      options: options,
      includeMeasurements: true
    }
  }


  static defaultProps = {
    //Set any properties that are optional when creating the component.
    exportMode: false
}

  componentDidMount(){
    if(this.props.studySelected.name==="")
    {(this.errorWindow.current as ErrorWindow).show(400, "")}
  }

  stringToColor(str: string): string {
    let hash = 0;
    if (!str || str.length === 0) {
      return 'red';
    }

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = (hash*19) % 360;
    return `hsl(${hue}, 50%, 80%)`; // Here, we set the saturation to 100% and lightness to 75%
  }

  
  //Put these in a helper class somewhere common to avoid redefinition.
  renderKeyValueInGrid = (item: any) => {
    if (item as number) {
      return <div>{(item as number).toFixed(2)}</div>
    } else {
      return <div>--</div>
    }
  }
  renderSizeValueInGrid = (item: any) => {
    if (item as number) {
      return <div>{(item as number).toFixed(1)}</div>
    } else {
      return <div>--</div>
    }
  }
  renderDepthValueInGrid = (item: any) => {
    if (item as number) {
      return <div>{(item as number).toFixed(3)}</div>
    } else {
      return <div>--</div>
    }
  }

  
  //Put these in a helper class somewhere common to avoid redefinition.
  renderMeanValueInGrid = (item: any) => {

    let meanValue = Math.abs((item as number * 1000));
    let lowerValue = this.props.studySelected.lowerReferenceValue as number;
    let upperValue = this.props.studySelected.upperReferenceValue as number;
    let meanflowClassname = (meanValue < lowerValue ? "meanflowThumbnailRed" : meanValue > upperValue ? "meanflowThumbnailGreen" : "meanflowThumbnailYellow")

    if (item as number) {
      return <div className={meanflowClassname}>{(item as number * 1000).toFixed(2)}</div>
    } else {
      return <div>--</div>
    }
  }

  renderListInGrid = (items: string[]) => {
    let valToShow = "";

    let itemsAdded: string[] = [];

    if (items) {
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        //No repeats!
        if (itemsAdded.indexOf(element) === -1)
        {
          itemsAdded.push(element);
          valToShow += element;
          if (index < items.length - 1)
            valToShow += " - ";
        }
      }
      return <div className="data-grid-cell">{valToShow}</div>;
    }
  }
  renderToButton = (item: any, rowItem: any) => {

    return <div className="createReport">
      <button className="primaryButton" onClick={() => {
        this.props.showOtherWindow(AppMode.Report, rowItem);


        // this.props.dataHelper.CallMetadataService("CreateReport", {}, {studyid: this.props.studySelected.id, procedureid: rowItem.id}, (dataout: string)=>
        // {
        //   (this.infoBox as InfoBox).show("PDF-report successfully generated: " + dataout);
        // });
      }}><PiFilePdfDuotone /></button>
    </div>
  }
  
  averagingTemplate = (rows: DataGridRow[], columnName: string, columnValueFunc?: (row: DataGridRow)=>number)=>
  {
    let runningTotal = 0;
    let totalRows = 0;
    
    for (let index = 0; index < rows.length; index++) {
      const element = rows[index];
      if ((element as any)[columnName] > 0)
      {
        totalRows++;
        if(columnValueFunc)
        {
          let valueIn = columnValueFunc(element);
          if (valueIn > 0)
          {
            runningTotal = runningTotal + valueIn;
          }else
          {
            totalRows--;
          }
        }else
          runningTotal = runningTotal + (element as any)[columnName];
      }
      
    }
    let averageNum = 0;
    if (totalRows > 0 )
      averageNum = (runningTotal/totalRows);
    return <>Avg: {averageNum > 0 ? averageNum.toFixed(1) : "--"}</>;
  }

  handleErrorBox=(error:string)=>{(this.errorWindow.current as ErrorWindow).show(700,error)}
  handleInfoBox=(info:string)=>{(this.infoBox.current as InfoBox).show(info)}

  exportData = () => {
    let exportData: ExportModel = {includeMeasurements: this.state.includeMeasurements, procedures: [], studyId: (this.props.studySelected.id as string) }
    
    if (this.props.toggleFilter){
      let filteredData: PreviewProcedureModel[] = [...(this.searchGrid.current as DataGrid).getFilteredData(this.state.includeMeasurements) as PreviewProcedureModel[]] ;
      exportData.procedures = filteredData.filter(data => data.selected);
    }else
    {
      exportData.procedures = this.props.procedures;
    }
    this.props.dataHelper.CallMetadataService("ExportData", exportData,{}, (resultOut: string) => {
      let filename = JSON.parse(resultOut);
       this.props.dataHelper.downloadFile("StudyMetadata", "GetFile", {fileid: filename})
    },(status:number, errorMessage:string)=>{ (this.errorWindow.current as ErrorWindow).show(status, errorMessage)},"POST");
  }

  render() {
    let steps = ["Select"].concat(this.props.studySelected.protocolSteps as string[]);
    let labelOptions = this.props.studySelected.suggestedLabels?.map((item)=>{
      return item.name;
    });
    let hospitalsList: string [] = ["--Select--"];
    for (let index = 0; index < this.props.procedures.length; index++) {
      const element = this.props.procedures[index];
      if (hospitalsList.indexOf(element.hospitalName) === -1)
        hospitalsList.push(element.hospitalName);
      
    }

    let mainGridColumns: DataGridColumn[] = [
      { Name: "qualified", Caption: "Qualified" , Type:"boolean", SummaryTemplate: (rows: DataGridRow[]) =>
      {
        let totalQualifiedMeasurements =  rows.length;
        for (let index = 0; index < rows.length; index++) {
          const element = rows[index] as PreviewProcedureModel;
          //totalRows += element.measurements.length;
          for (let measindex = 0; measindex < element.measurements.length; measindex++) {
            const measurementIn = element.measurements[measindex];
            if (measurementIn.qualified)
            {
              totalQualifiedMeasurements++;
            }
          }
        }
 
        return <div className="subTotalDisplay">
                <span className="subTotalDisplayItem">Total: {totalQualifiedMeasurements}</span>
            </div>;
            
      }
      
    },
      { Name: "createdDate", Caption: "Date", Type: this.props.studySelected.allowPersonalData ? "date" : "datenoday" },
      { Name: "patientId", Caption: "Patient Id", Type: "encryptable" },
      {
        Name: "starRating", Caption: "Rating", Type: "star", RenderTemplate: (starRating, item: PreviewProcedureModel) => {

          // let ratings = new Map<string,number>();
          let ratings: string = "Total rating: " + item.starRating?.toFixed(2) as string + ", ";
          let attributes = ["Labels", "Missing data", "Protocol steps", "Imaging", "Saves", "ECG", "Comment"];
          let ratingsData = item.specificRatings as number[];
          for (let i = 0; i < attributes.length; i++) {
            // let num = "";
            // if (ratingsData[i] === 0 ) {num = "0"}
            // else{num= }

            ratings += (attributes[i] + ": " + ratingsData[i] + ", ");
          }
          ratings.trimEnd();

          if (this.props.studySelected.idFieldForStudy)
          {
            if (!((item as any)[this.props.studySelected.idFieldForStudy]))
            {
              //if the study has an explicit id field and the value of that field is blank then give it a zero rating.
              item.starRating = 0;
            }
          }
          return <div title={ratings} className="datagridStars"><StarPreview value={item.starRating as number}></StarPreview></div>;
        },
      },
      { Name: "comments", Caption: "Comments", Editable: this.props.editMode },
      { Name: "height", Caption: "Height", Type: "int", ignoreChildren: true, SummaryTemplate: (rows)=>
      {
        return this.averagingTemplate(rows, "height");
      }},
      { Name: "weight", Caption: "Weight", Type: "int", ignoreChildren: true, SummaryTemplate: (rows)=>
        {
          return this.averagingTemplate(rows, "weight");
        }},
      { Name: "gender", Caption: "Gender", Type: "gender", ignoreChildren: true},
      { Name: "age", Caption: "Age", Type: "interval", ignoreChildren: true, RenderTemplate: (item: PreviewMeasurement[], rowItem: PreviewProcedureModel, parentRow)=>
      {
        let yearOut = 0;
        if (rowItem.createdDate)
        {
          try{

        
            let dateParsed: Date = new Date(rowItem.createdDate);
            yearOut = dateParsed.getFullYear();
          }catch(ex1)
          {}
        }
        if (rowItem.birthYear as number && rowItem.birthYear > 0 && yearOut && yearOut > rowItem.birthYear) {
          return <div>{yearOut -  rowItem.birthYear}</div>
        } else {
          return <div>--</div>
        }

      }, SummaryTemplate: (rows) =>
      {
      return this.averagingTemplate(rows, "birthYear", (row: DataGridRow)=>{
        let rowItem = row as PreviewProcedureModel;
        let yearOut = 0;
        if (rowItem.createdDate)
        {
        
          try{

        
            let dateParsed: Date = new Date(rowItem.createdDate);
            yearOut = dateParsed.getFullYear();
          }catch(ex1)
          {}
        }
        if (rowItem.birthYear as number && rowItem.birthYear > 0 && yearOut && yearOut > rowItem.birthYear) {
          return yearOut -  rowItem.birthYear;
        }
        return 0;
      })
      }
       },
      // { Name: "anatomicalLocations", Type: "dropdownMultipleSelect", FilterOptions: this.state.options, Caption: "Anatomical Locations", RenderTemplate: this.renderListInGrid },
      {Name: "labels", Type:"dropdownMultipleSelect", FilterOptions:labelOptions, Caption: "Labels", RenderTemplate: (item: PreviewMeasurement[], rowItem: PreviewProcedureModel, parentRow)=>{
        
        let suggestedLabels: string[] = [];
        if (rowItem.measurements)
        {
        rowItem.measurements.forEach(element => {
          if (element.labels) {
            element.labels.forEach(label => {
              label.suggestedLabels?.forEach(sug=>{
                if (suggestedLabels.indexOf(sug.name) === -1)
                suggestedLabels.push(sug.name);
              })
            });
          }
        });
      }
        let uniqueLabels = Array.from(new Set(suggestedLabels));

        if (uniqueLabels && uniqueLabels.length > 0) {
          return (
            <div className="tabColoring">
              {uniqueLabels.map((label, index) => (
                <div key={index} className={"labelInDataGrid"} style={{backgroundColor: this.stringToColor(label)}}>
                  {label}
                </div>
              ))}
            </div>
          );
        }else
        {
          let suggestedLabels: string = "";
          let isAdded: string[]  = [];
          if (rowItem.measurements)
        {
        rowItem.measurements.forEach(element => {
            if (element.labels) {
              element.labels.forEach(label => {
                if (isAdded.indexOf(label.name) === -1)
                {
                  if (suggestedLabels)
                  suggestedLabels +=" - ";
                  suggestedLabels += (label.name);
                  isAdded.push(label.name);
                }
              });
            }
          });

        }

            return <div>{suggestedLabels? suggestedLabels : Array.from(new Set(rowItem.anatomicalLocations))}</div>;
        }

      }, AdditionalFilter: (item: any) => {
        /*
        item.forEach(element => {
          if(element.measurementType=="Flow" || )
        });*/
        return (
          <> <InputItem title="Probe Size" type="interval" name="measurements" deleteOption={(value) => { (this.searchGrid.current as DataGrid).removeFilter("probeSize", value) }} onChange={(name, value, value2) => {
            (this.searchGrid.current as DataGrid).handleSort("Measurement",name, value, value2, "probeSize")
          }} />

        <InputItem title="Image depth" type="interval" name="measurements" deleteOption={(value) => { (this.searchGrid.current as DataGrid).removeFilter("imageDepth", value) }} onChange={(name, value, value2) => {
            (this.searchGrid.current as DataGrid).handleSort("Measurement",name, value, value2, "imageDepth")
          }} />
  <InputItem title="ID"  type="text" name="id" onChange={(name, value, value2) => {
            (this.searchGrid.current as DataGrid).handleSort("Measurement","measurements", value, null, "id")
          }} />
           <InputItem title="Phasicity"  type="dropdown" name="phascityPreview" options={["--Select--","Unknown", "Monophasic", "Biphasic", "Triphasic"]}  deleteOption={(value) => { (this.searchGrid.current as DataGrid).removeFilter("phasicityPreview", value) }} onChange={(name, value, value2) => {
            (this.searchGrid.current as DataGrid).handleSort("Measurement","measurements", value, null, "phasicityPreview")
          }} />
          <InputItem title="Mode"  type="dropdown" name="operatingMode" options={["--Select--","0", "1", "2", "3"]}  deleteOption={(value) => { (this.searchGrid.current as DataGrid).removeFilter("operatingMode", value) }} onChange={(name, value, value2) => {
            (this.searchGrid.current as DataGrid).handleSort("Measurement","measurements", value, null, "operatingMode")
          }} />
          </>)
      }
      , SummaryTemplate: (rows: DataGridRow[]) =>
        {
          let totalProbeSize = 0;
          let totalRows = 0;
          let totalProbeSizeRows = 0;

          let totalMap = 0;
          let totalMapRows = 0;

          for (let index = 0; index < rows.length; index++) {
            const element = rows[index] as PreviewProcedureModel;
            totalRows += element.measurements.length;
            for (let measindex = 0; measindex < element.measurements.length; measindex++) {
              const measurementIn = element.measurements[measindex];
              if (measurementIn.measurementType === "Flow")
              {
              if (!Number.isNaN(measurementIn.probeSize) && measurementIn.probeSize as number > 0)
              {
                totalProbeSize += (measurementIn.probeSize as number);
                totalProbeSizeRows++;
              }

              if (!Number.isNaN(measurementIn.mAPMean) && measurementIn.mAPMean as number > 0)
              {
                totalMap += (measurementIn.mAPMean as number);
                totalMapRows++;
              }

            }
             
            }
         
          }
            return <div className="subTotalDisplay">
              {
                totalRows > 0   ?
                <>
                <span className="subTotalDisplayItem">Avg probe size: {totalProbeSizeRows > 0 ? (totalProbeSize/totalProbeSizeRows).toFixed(1) : "--"}</span>
                <span className="subTotalDisplayItem">Avg MAP: {totalMapRows > 0 ? (totalMap/totalMapRows).toFixed(1) : "--"}</span>
                </>
             :
                <></>
              }
              
            </div>;
        }
      },
      {
        Name: "flowMeasurements", Caption: "Flow", Type: "interval", FilterTemplate: (item: DataGridColumn) => {
          return (<InputItem title="" type="interval" name="flowMeasurements" onChange={(name, value, value2) => {
            (this.searchGrid.current as DataGrid).handleSort(name, value, value2)
          }} />)

        }
      },
      {
        Name: "imagingMeasurements", Type: "interval", Caption: "Imaging", FilterTemplate: (item: DataGridColumn) => {
          return (<InputItem title="" type="interval" name="imagingMeasurements" onChange={(name, value, value2) => {
            (this.searchGrid.current as DataGrid).handleSort(name, value, value2)
          }} />)

        }
      },
      { Name: "reports", Caption: "Reports",Type: "interval" },
      { Name: "missingData", Caption: "Missing Data", Type: "boolean" },
      { Name: "measurements", Caption: "Measurement", Type: "interval", RenderTemplate: (item: PreviewMeasurement[], rowItem) => {
          let itemsOut = [];
          let index = 0;
          let labelsAdded: string[] = [];
          item.sort((a: PreviewMeasurement, b: PreviewMeasurement) => ((a.recordDate ? new Date(a.recordDate)?.getTime() : 0) - (b.recordDate ? new Date(b.recordDate)?.getTime() : 0)))
          let indexesAdded: number[] = [];
          while (itemsOut.length < 4 && index < item.length) {
            const element = item[index];
            let fullAnatomicalLocation = element.anatomicalLocations?.join("-") as string;
            if (!fullAnatomicalLocation)
              fullAnatomicalLocation = element.vessels?.join("-") as string;
            if (labelsAdded.indexOf(fullAnatomicalLocation) < 0 && (element.measurementType === "Imaging" || element.measurementType === "Flow" )) {
              indexesAdded.push(index);
              labelsAdded.push(fullAnatomicalLocation);
              itemsOut.push(<MeasurmentThumbnail key={"measurements" +  element.id} studySelected={this.props.studySelected} procedureId={rowItem.id} dataHelper={this.props.dataHelper} rowData={element as PreviewMeasurement} detailed={false} ></MeasurmentThumbnail>);
            }

            index++;
          }
          index = 0;
          while (itemsOut.length < 4 && index < item.length) {
            //If there are still less than the magic number four then just add the remaining without worrying about label repeats
            const element = item[index];
            let fullAnatomicalLocation = element.anatomicalLocations?.join("-") as string;
            if (!fullAnatomicalLocation)
              fullAnatomicalLocation = element.vessels?.join("-") as string;
            if (indexesAdded.indexOf(index) < 0 && (element.measurementType === "Imaging" || element.measurementType === "Flow" || element.measurementType === "Ecg" || element.measurementType === "PWDopplers")) {
              labelsAdded.push(fullAnatomicalLocation);
              itemsOut.push(<MeasurmentThumbnail  key={"measurements" +  element.id} studySelected={this.props.studySelected} procedureId={rowItem.id} dataHelper={this.props.dataHelper} rowData={element as PreviewMeasurement} detailed={false} ></MeasurmentThumbnail>);
            }
            index++;
          }

          return <div className="thumbnails-container" >{itemsOut}</div>;
        }, FilterTemplate: (item: any) => {
          /*
          item.forEach(element => {
            if(element.measurementType=="Flow" || )
          });*/
          return (
            <div className="messurementFilterContainer">
      <InputItem title="Type" type="dropdown" name="measurementType" options={["--Select--","Flow", "Imaging", "Ecg", "PWDopplers"]} deleteOption={(value) => { (this.searchGrid.current as DataGrid).removeFilter("measurementType", value) }} onChange={(name, value, value2) => {
                (this.searchGrid.current as DataGrid).handleSort("Measurement","measurements", value, null, "measurementType")
              }} />
              <InputItem title="MF" type="interval" name="measurements" deleteOption={(value) => { (this.searchGrid.current as DataGrid).removeFilter("mean", value) }} onChange={(name, value, value2) => {
                (this.searchGrid.current as DataGrid).handleSort("Measurement",name, value, value2, "mean")
              }} />
              <InputItem title="pI" type="interval" name="measurements" deleteOption={(value) => { (this.searchGrid.current as DataGrid).removeFilter("pI", value) }} onChange={(name, value, value2) => {
                (this.searchGrid.current as DataGrid).handleSort("Measurement",name, value, value2, "pI")
              }} />
              <InputItem title="dF" type="interval" name="measurements" deleteOption={(value) => { (this.searchGrid.current as DataGrid).removeFilter("dF", value) }} onChange={(name, value, value2) => {
                (this.searchGrid.current as DataGrid).handleSort("Measurement",name, value, value2, "dF")
              }} />
            
            </div>)
        }, SummaryTemplate: (rows: DataGridRow[]) =>
        {
          let totalmF = 0;
          let totaldF = 0;
          let totalPI = 0;
          let totalRows = 0;
          let totalDfRows = 0;
          let totalMfRows = 0;
          let totalPIRows = 0;
          for (let index = 0; index < rows.length; index++) {
            const element = rows[index] as PreviewProcedureModel;
            totalRows += element.measurements.length;
            for (let measindex = 0; measindex < element.measurements.length; measindex++) {
              const measurementIn = element.measurements[measindex];
              if (measurementIn.measurementType === "Flow")
              {
              if (!Number.isNaN(measurementIn.mean) && Math.abs(measurementIn.mean as number) > 0)
              {
                totalmF += Math.abs((measurementIn.mean as number)*1000);
                totalMfRows++;
              }
              if (!Number.isNaN(measurementIn.pI) && measurementIn.pI as number > -1 && measurementIn.pI as number < 99)
              { 
                //99 is considered invalid for PI for now.
                totalPI += measurementIn.pI as number;
                totalPIRows++;
              }
               
              if (!Number.isNaN(measurementIn.dF) && measurementIn.dF as number > 0)
              {
                totaldF += measurementIn.dF as number;
                totalDfRows++;
              }
            }
            }
         
          }
            return <div className="subTotalDisplay">
              {
                totalRows > 0   ?
                <><span className="subTotalDisplayItem">Avg MF: {totalMfRows > 0 ? (totalmF/totalMfRows).toFixed(2) : "--"}</span>
                <span className="subTotalDisplayItem">Avg PI: {totalPIRows > 0 ?(totalPI/totalPIRows).toFixed(2) : "--"}</span>
                <span className="subTotalDisplayItem">Avg DF: {totalDfRows > 0 ?(totaldF/totalDfRows).toFixed(2) : "--"}</span></>
             :
                <></>
              }
              
            </div>;
        }
      },
      
      { Name: "otherComments", Caption: "Added Comments", Editable: true, searchChildren: true, Type: "textarea" , SummaryTemplate: (rows: DataGridRow[]) =>
      {
        let totalCases =  rows.length;

 
        return <div className="subTotalDisplay">
                <span className="subTotalDisplayItem">Cases: {totalCases}</span>
            </div>;
      }
      },
      { Name: "createReport", Caption: "Report", RenderTemplate: this.renderToButton, SummaryTemplate: (rows: DataGridRow[]) =>
      {
        let totalMeasurements =  0;
        for (let index = 0; index < rows.length; index++) {
          const element = rows[index] as PreviewProcedureModel;
          totalMeasurements += element.measurements.length;
        }
        return <div className="subTotalDisplay">
                <span className="subTotalDisplayItem">Saves: {totalMeasurements}</span>
            </div>;
      } }


    ];

     

      let childGridColumns: DataGridColumn[] = [
        {Name: "qualified", Caption: "Qualified", Type: "boolean"},
        {Name: "index", Caption: "Name"},
        {Name: "measurementType", Caption: "Type"},
    
        {Name: "recordDate", Caption: "Date", Type: this.props.studySelected.allowPersonalData ? "date" : "datenoday"},
        {Name: "mean", Caption: "MF", Type: "number", RenderTemplate: this.renderMeanValueInGrid, SummaryTemplate: (rows: DataGridRow[])=>{

          let totalSum = 0;
          let totalCount = 0;
          for (let loopindex = 0; loopindex < rows.length; loopindex++) {
            const element = rows[loopindex] as PreviewMeasurement;
            if (element.measurementType  === "Flow" && element.mean && !Number.isNaN(element.mean))
            {   totalSum += Math.abs(element.mean)*1000;
              totalCount++;
            }
           
          }
          return <td>Avg: { totalCount ? (totalSum/totalCount).toFixed(2) : "--"}</td>

        }},
        {Name: "pI", Caption: "PI",  Type: "number", RenderTemplate: this.renderKeyValueInGrid},
        {Name: "dF", Caption: "DF", Type: "number",  RenderTemplate: this.renderKeyValueInGrid},
        {Name: "probeSize", Caption: "Probe size", Type: "number", RenderTemplate: this.renderSizeValueInGrid},
        {Name: "imageDepth", Caption: "Depth", Type: "number", RenderTemplate: this.renderDepthValueInGrid},
        {Name: "operatingMode", Caption: "Mode", Type: "number"},
        {Name: "phasicityPreview", Caption: "Phasicity"},
        // {Name: "anatomicalLocations", Caption: "Anatomical Locations", RenderTemplate:  this.renderListInGrid},
        {Name: "measurements", Type:"dropdownMultipleSelect", FilterOptions:this.state.options, Caption: "Labels", RenderTemplate: (item: PreviewMeasurement[], rowItem: PreviewMeasurement, parentRow)=>{
          let suggestedLabels: string[] = [];
          
       
          var labels=rowItem.labels;
          if(labels){
          for (let index = 0; index < labels.length; index++) {
            const element = labels[index].suggestedLabels
            if (element)
            {
            for (let j = 0; j < element.length; j++) {
              suggestedLabels.push(element[j].name) 
            } 
            
              
          }
          }
        }
          
          
          
          let uniqueLabels = Array.from(new Set(suggestedLabels));
  
          if (uniqueLabels && uniqueLabels.length > 0) {
            return (
              <div>
                {uniqueLabels.map((label, index) => (
                  <div key={index} className={"labelInDataGrid"} style={{backgroundColor: this.stringToColor(label)}}>
                    {label}
                  </div>
                ))}
              </div>
            );
          }else
          {
            let suggestedLabels: string = "";
            let isAdded: string[]  = [];
           
              if (labels) {
                labels.forEach(label => {
                  if (isAdded.indexOf(label.name) === -1)
                  {
                    if (suggestedLabels)
                    suggestedLabels +=" - ";
                    suggestedLabels += (label.name);
                    isAdded.push(label.name);
                  }
                });
              }
            
  
  
              return <div>{suggestedLabels? suggestedLabels : rowItem.anatomicalLocations}</div>;
          }
  
  
        }},{Name: "subwaymap", Caption: "Subway Map", RenderTemplate: (item: PreviewMeasurement[], rowItem, parentRow)=>{
          let suggestedLabels: LabelModel[] = [];
          let subwayMap: React.ReactElement[] =[];
           //Remove label duplicates
           let newLabelList = [];
           let labelsAdded = [];
           if (rowItem && rowItem.labels)
           {
        for (let index = 0; index < rowItem.labels.length; index++) {
          const element = rowItem.labels[index];
          if (labelsAdded.indexOf(element.id) === -1)
          {
            labelsAdded.push(element.id);
            newLabelList.push(element);
          }
        
        }
      }
          if (newLabelList) {
            newLabelList.forEach((label: LabelModel) => {
              if(label.suggestedLabels){
              suggestedLabels=[...suggestedLabels,...label.suggestedLabels];}
            });
          }
          
          let uniqueLabels = Array.from(new Set(suggestedLabels));
  
          if (uniqueLabels) {
            let graftOut=Graft.GenerateGrafts([uniqueLabels])
            if (graftOut.length > 0 && !graftOut[0].BlankNewItem)
            {
              
                subwayMap = [<SubwayMap CurrentItem={graftOut[0]} Context={"TTFM"} customGraftText={""} index={0} commentToggled={function (e: boolean): void {
                    throw new Error("Function not implemented.");
                } } measurementSelectionOpen={false} measurementSelectionSelected={function (e: boolean): void {
                    throw new Error("Function not implemented.");
                } } tileColorOn={false} ></SubwayMap>];
            }
            return (
              <div>
                {subwayMap}
                
              </div>
            );
          }
  
  
        },FilterTemplate: (item: any) => {return <></>}},
        {Name: "measurementType", Caption: "Thumbnail", RenderTemplate: (item: string, rowItem: any, parentRow) => {

          let itemsOut = [];
          itemsOut.push(<MeasurmentThumbnail  key={"measurementssub" +  rowItem.id} studySelected={this.props.studySelected} dataHelper={this.props.dataHelper} procedureId={parentRow.id} rowData={rowItem as PreviewMeasurement} detailed={true} ></MeasurmentThumbnail>);

          return <div className="thumbnails-container">{itemsOut}</div>;
        }
      },
      { Name: "otherComments", Caption: "Comments", Editable: true, Type: "textarea" },
      { Name: "protocolStep", Caption: "Protocol Step", Type: "dropdown", ValueList: steps, Editable: true, ShowValueOncePerAlternate: true }
      //   RenderTemplate: (item: string) =>
      // {
      //   return <select><option>Step 1</option><option>Step 2</option></select>;
      // }}
    ];

    if (this.props.editMode) {
      //Insert delete buttons
      if (mainGridColumns[0].Name !== "delete")
      {
      mainGridColumns.splice(0, 0, {
        Name: "delete", Caption: "Delete", RenderTemplate: (rowData, row: PreviewProcedureModel) => (
          <PiTrash className="buttonIcon" onClick={() => {
            //this.handleDelete(rowData)
            this.props.dataHelper.CallStudyService("DeleteProcedure", { studyId: this.props.studySelected.id, procedureId: row.id }, {}, (dataOut: string) => {
              if (dataOut)
              {
                  let toShow = JSON.parse(dataOut);
              if (toShow === "DONE") {
                let proceduresIn = this.props.procedures;
                for (let index = 0; index < proceduresIn.length; index++) {
                  const element = proceduresIn[index];
                  if (element.id === row.id) {
                    proceduresIn.splice(index, 1);
                  }
                }
              }
                // this.setState({
                //   procedures: proceduresIn
                // });
              }


            }, () => { }, "DELETE");
          }
          } />
        )
      }
      );
    }
    if (childGridColumns[0].Name !== "delete")
      {
      childGridColumns.splice(0, 0, {
        Name: "delete", Caption: "Delete", RenderTemplate: (rowData, row: PreviewMeasurement, parentRow: PreviewProcedureModel) => (
          <PiTrash className="buttonIcon" onClick={() => {

            for (let index2 = 0; index2 < parentRow.measurements.length; index2++) {
              const measurement = parentRow.measurements[index2];
              if (measurement.id === row.id) {
                parentRow.measurements.splice(index2, 1);
              }
            }
            //  let proceduresIn = this.state.procedures;
            //   for (let index = 0; index < proceduresIn.length; index++) {
            //     const element = proceduresIn[index];
            //     if (element.id === parentRow.id)
            //       {
            //     for (let index2 = 0; index2 < element.measurements.length; index2++) {
            //       const measurement = element.measurements[index2];
            //       if (measurement.id === row.id)
            //       {
            //           proceduresIn.splice(index, 1);
            //       }
            //     }

            //   }
            // this.setState({
            //   procedures: proceduresIn
            // });
            // }
            //this.handleDelete(rowData)
            this.props.dataHelper.CallStudyService("SaveProcedure", { studyId: this.props.studySelected.id, previewProcedure: parentRow, deleteMeasurementId: row.id }, {}, (dataOut: string) => {



              //   if (dataOut === "DONE")
              //   {
              //     let proceduresIn = this.state.procedures;
              //     for (let index = 0; index < proceduresIn.length; index++) {
              //       const element = proceduresIn[index];
              //       if (element.id === parentRow.id)
              //         {
              //       for (let index2 = 0; index2 < element.measurements.length; index2++) {
              //         const measurement = element.measurements[index2];
              //         if (measurement.id === row.id)
              //         {
              //             proceduresIn.splice(index, 1);
              //         }
              //       }

              //     }
              //     this.setState({
              //       procedures: proceduresIn
              //     });
              //   }

              // }
            }, () => { }, "POST");
          }
          } />
        )
      }
      );
    }
  }

    if (this.props.exportMode)
    {
      if (this.props.studySelected.exportEnabled)
        {

      mainGridColumns.splice(0, 0, {Name: "selected", Caption: "Export", Type:"boolean", Editable: true, AdditionalFilter: (item: any) => {
        /*
        item.forEach(element => {
          if(element.measurementType=="Flow" || )
        });*/
        return (
          <> 
           <InputItem title="Hospital name"  type="dropdown" name="hospitalName" options={hospitalsList}  deleteOption={(value) => { (this.searchGrid.current as DataGrid).removeFilter("phasicityPreview", value) }} onChange={(name, value, value2) => {
            (this.searchGrid.current as DataGrid).handleSort("HospitalName","hospitalName", value, null, "hospitalName", undefined, "dropdown", true)
          }} /></>)
      } });
      childGridColumns.splice(0, 0, {Name: "selected", Caption: "Export", Type:"boolean", Editable: true });
                
    }
    }

    return (
      <>

        <div>
          <ErrorWindow ref={this.errorWindow}></ErrorWindow>
      { 
    //   this.props.exportMode ?
    //   <div className="exportbuttons">
    //   {/* {this.state.includeMeasurements ? 
    //     <button className="primaryButton" onClick={() => { this.setState({ includeMeasurements: false }) }}>Exclude measurements</button> 
    //     :<button className="primaryButton" onClick={() => { this.setState({ includeMeasurements: true }) }}>Include measurements</button>} */}
    //   <button className="primaryButton" onClick={() => {
    //     let exportData: ExportModel = {includeMeasurements: this.state.includeMeasurements, procedures: [] }
        
    //     if (this.props.toggleFilter){
    //       let filteredData: PreviewProcedureModel[] = [...(this.searchGrid.current as DataGrid).getFilteredData(this.state.includeMeasurements) as PreviewProcedureModel[]] ;
    //       exportData.procedures = filteredData.filter(data => data.selected);
    //     }else
    //     {
    //       exportData.procedures = this.props.procedures;
    //     }
    //     this.props.dataHelper.CallMetadataService("ExportData", exportData,{}, (resultOut: string) => {
    //       let filename = JSON.parse(resultOut);
    //        this.props.dataHelper.downloadFile("StudyMetadata", "GetFile", {fileid: filename})
    //     },(status:number, errorMessage:string)=>{ (this.errorWindow.current as ErrorWindow).show(status, errorMessage)},"POST");
    //   }}>Export</button>
      
    // </div>
    // : <></>
    }
          
          <DataGrid ref={this.searchGrid} returnErrorBox={this.handleErrorBox} returnInfoBox={this.handleInfoBox} key={"SearchGrid_"+ this.props.procedures.length + "_" + this.props.studySelected.name} studySelected={this.props.studySelected} usePaging={true} usePagingChild={true} pageSizeChild={5} isFilterable={this.props.toggleFilter} onChange={(rowdata: DataGridRow, columnChanged: string, value: any, index: number | undefined, submitToServer: boolean | undefined) => {

            // let proceduresIn = this.state.procedures;

            // this.setState({
            //   procedures: proceduresIn
            // });
         
            //this.forceUpdate();
            //We need to bubble this guy all the way to the main.
            (this.searchGrid.current as DataGrid).forceUpdate();

this.props.procedureItemUpdated((rowdata as any)["id"], columnChanged, value)

            // (rowdata as any)[columnChanged] = value;
            if (submitToServer) {
              this.props.dataHelper.CallStudyService("SaveProcedure", { studyId: this.props.studySelected.id, previewProcedure: rowdata }, {}, (dataOut: string) => {
                //TODO: Show a success or fail message.
                // let procParse: PreviewProcedureModel[] = JSON.parse(dataOut);
                // this.setState({
                //   procedures: procParse
                // })

                if (dataOut)
                {
                  let showTo = JSON.parse(dataOut);
                  if (showTo === "DONE")
                    (this.infoBox.current as InfoBox).show("Procedure '" + columnChanged + "' saved");
                  else
                    (this.errorWindow.current as ErrorWindow).show(700, showTo );

                }else
                {
                  (this.errorWindow.current as ErrorWindow).show(700, dataOut);
                
              }}, (stautus: number, errorMessage: string) => { (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage) }, "POST");
            }
          }}
            sort={(name: string, value: any) => {
            }}
            onChildChange={(rowdata: DataGridRow, parentrowdata: DataGridRow, columnChanged: string, value: any, index: number | undefined, parentIndex: number | undefined, submitToServer: boolean | undefined) => {
              // if (this.state.uploadPreview && this.state.uploadPreview.procedures && parentIndex as number >= 0 && parentIndex as number < this.state.uploadPreview.procedures.length)
              // {
              //   if (this.state.uploadPreview && this.state.uploadPreview.procedures && parentIndex as number >= 0 && parentIndex as number < this.state.uploadPreview.procedures.length)
              //     this.state.uploadPreview.procedures[parentIndex as number].measurements[index as number].selected = value as boolean;

              // }

              //   this.forceUpdate();

              // for (let index = 0; index < (parentrowdata as PreviewProcedureModel).measurements.length; index++) {
              //   const element = (parentrowdata as PreviewProcedureModel).measurements[index];
              //   if (element.id === (rowdata as PreviewMeasurement).id)
              //   {
              //       (element as any)[columnChanged] = value;
              //   }
              // }
              // let proceduresIn = this.state.procedures;

              // this.setState({
              //   procedures: proceduresIn
              // });
              this.forceUpdate();
              if (submitToServer) {
                this.props.dataHelper.CallStudyService("SaveProcedure", { studyId: this.props.studySelected.id, previewProcedure: parentrowdata, updateMeasurements: true }, {}, (dataOut: string) => {
                  //TODO: Show a success or fail message.
                  // let procParse: PreviewProcedureModel[] = JSON.parse(dataOut);
                  // this.setState({
                  //   procedures: procParse
                  // })
                  if (dataOut)
                  {
                    let showTo = JSON.parse(dataOut);
                    if (showTo === "DONE")
                      (this.infoBox.current as InfoBox).show("Procedure '" + columnChanged + "' saved");
                    else
                      (this.errorWindow.current as ErrorWindow).show(700, showTo );
  
                  }else
                  {
                    (this.errorWindow.current as ErrorWindow).show(700, dataOut);
                  
                }
                }, (stautus: number, errorMessage: string) => { (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage) }
                  , "POST");
              }
            }}
            columns={mainGridColumns} rows={this.props.procedures} hasHierarchy={!this.props.exportMode || this.state.includeMeasurements } childrenpropertyname="measurements" childcolumns={childGridColumns} grouping={true} groupingpropertyname="hospitalName" getChildGridRowAlternateColor={(item: DataGridRow) => {
              return ((item as PreviewMeasurement).measurementSetIndex as number) % 2 !== 0;
            }}></DataGrid>
          <InfoBox ref={this.infoBox} title="Confirmation" type="confirmation"></InfoBox>
        </div>
      </>
    );
  }
}
