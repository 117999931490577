import React, {RefObject} from "react";
import { UserModel } from "../Models/UserModel";
import { DataHelper } from "../Helpers/DataHelper";
import { InfoBox } from "../Components/InfoBox";
import { InputItem } from "../Components/InputItem";
import { ErrorWindow } from "../Components/ErrorWindow";
import { PiPlus, PiTrash } from "react-icons/pi";
import { userType } from "../Helpers/AccessMap";
import { UserAccessModel } from "../Models/UserAccessModel";

export class AddUser extends React.Component<
    {
        //Properties go here
        dataClient: DataHelper
        cancelUser: () => void
        availbleStudies: string[]
        userAccess:UserAccessModel
    },
    {
        //State goes here
        selectedUser: UserModel
        allUsers:UserModel[]
        selectedValue:string
    }
> {
    errorWindow:  RefObject<ErrorWindow>;
    
    infoBox: RefObject<InfoBox>;
    constructor(props: any) {
        super(props);
        this.errorWindow = React.createRef();
      
      this.infoBox = React.createRef();
        this.state = {
            //Initialize ALL state variables here
            selectedValue:"-----",
            allUsers:[],
            selectedUser: {
                index: 0,
                id: "",
                name: "",
                email: "",
                userType: "",
                studies:[],
                userHospital:""
            }
        }
    }

    static defaultProps = {
        //Set any properties that are optional when creating the component.
      }

    
    componentDidMount(): void {
        
}


    userFieldChanged = (fieldName: string, fieldValue: string): void =>
    {
        let userIn = {
            ...this.state.selectedUser
        };
        if (fieldName === "name")
        {
            userIn.name = fieldValue
        } else if (fieldName === "email")
        {
            userIn.email = fieldValue
        }
        else if(fieldName==="userType"){
            userIn.userType=fieldValue
        }
        else if(fieldName==="userHospital"){
            userIn.userHospital=fieldValue
        }
        this.setState({
            selectedUser: userIn
        });
    }

    onDropdownChanged=(name:string, value:string):void=>{
        let userIn = {
            ...this.state.selectedUser
        };
        userIn.userType=value;
        this.setState({
            selectedUser:userIn
        })
        }

    
    

    render() {
        
        
        let accessableStudiesForm=
            <div className="studiesForm">
                <div className="studiesFormBody">
                    <h2 className="studiesFormTitle">Add Studies</h2>
                    <button className="addStudy" onClick={()=>{
                        let user=this.state.selectedUser;
                        if(!user.studies){user.studies=[]}
                        user.studies?.push("");
                        this.setState({ selectedUser:user})
                    }}><PiPlus className="buttonIcon"/>
                    </button>
                </div>
                <div className="protocolformBody">
                    {
                        this.state.selectedUser.studies?.map((value, index: number) => {
                            return <div className="newStep">
                                    <InputItem type="dropdown" title={"Study " + (index + 1)} name={"Study" + (index + 1)} value={value} options={["--Select--",...this.props.availbleStudies]} onChange={(name, value1) => {
                                    let user = this.state.selectedUser;
                                    (user.studies as string[])[index] = value1;
                                    this.setState({ selectedUser: user});
                                    
                                    }}></InputItem>

                                    <button className="menuButton" onClick={() => {
                                    let user = this.state.selectedUser;
                                    if (!user.studies) { user.studies = [] }
                                    (user.studies as string[]).splice(index,1);  
                                    this.setState({ selectedUser: user });
                                    }}><PiTrash className="buttonIcon"/></button>
                                </div>
                        })
                    }
                </div>
            </div>

            let currentUserTypeIndex=userType.indexOf(this.props.userAccess.userType);

            let lowerTierUser=userType.filter((type, index) => index > currentUserTypeIndex);
            if(this.props.userAccess.userType===userType[0]){
                lowerTierUser=userType
            }
        
        return (
            <>
                <div className="windowContainer">
                    <ErrorWindow ref={this.errorWindow}></ErrorWindow>
                    
                    <div className="windowHeader"></div>
                    <div className="windowForm">

                    <InputItem type="text" title={"Name"} name={"name"} onChange={this.userFieldChanged} value={this.state.selectedUser.name}></InputItem>
                    <InputItem type="email" title={"Email"} name={"email"} onChange={this.userFieldChanged} value={this.state.selectedUser.email}></InputItem>
                    <InputItem type="text" title={"Hospital"} name={"userHospital"} onChange={this.userFieldChanged} value={this.props.userAccess.userType!==userType[0]?this.props.userAccess.userHospital:this.state.selectedUser.userHospital}></InputItem>

                        {/* <div><span>Name:</span><input type="text" value={this.state.selectedUser.name} onChange={(event)=>
                        {
                            this.userFieldChanged("name", event.currentTarget.value);
                        }}/></div>
                        <div><span>Email:</span><input type="email" value={this.state.selectedUser.email} onChange={(event)=>
                        {
                            this.userFieldChanged("email", event.currentTarget.value);
                        }}/></div>
                        <div><span>Hospital:</span><input type="text" value={this.props.userAccess.userType!==userType[0]?this.props.userAccess.userHospital:this.state.selectedUser.userHospital} onChange={(event)=>
                        {
                            this.userFieldChanged("userHospital", event.currentTarget.value);
                        }}/></div> */}

                        <div><InputItem title="User Type" type="dropdown" name="userType" options={["--Select--", ...lowerTierUser]} onChange={this.onDropdownChanged}/>
                        {accessableStudiesForm}

                    </div>
                    </div>
                    <div className="windowFooter"><button onClick={()=>
                    {
                        if(this.state.selectedUser.studies.length===0){
                            this.setState(prevState => {
                                return {
                                    selectedUser: {
                                        ...prevState.selectedUser,
                                        studies: [],
                                    }
                                }
                            });
                        }
                        this.props.dataClient.CallSettingsService("SaveUser", this.state.selectedUser, {}, (outputValue: string)=>{

                            if (outputValue)
                            {
                                let toShow = JSON.parse(outputValue);
                                if (toShow === "DONE")
                                    (this.infoBox.current as InfoBox).show("User Saved");
                                else
                                    (this.errorWindow.current as ErrorWindow).show(700, toShow);
                            }else
                            {
                                (this.errorWindow.current as ErrorWindow).show(700, outputValue);
                            }
                        },(stautus:number, errorMessage:string)=>{
                            (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage);
                          }, "POST");

                        
                    }}>Save</button>
                    <button onClick={()=>
                    {
                        this.props.cancelUser();
                    }}>Go Back</button></div>
                    
                    <InfoBox ref={this.infoBox} title="Confirmation" type="confirmation"></InfoBox> 
                </div>
            </>
        );
    }
}

