

/*
-------------HOW TO-------------
To catch C# errors use the errorCallback:

this.props.dataHelper.CallMetadataService("GetProcedures", {}, { studyId: this.props.studySelected}, (dataOut: string)=>
    {Your callback
    (this.errorWindow as ErrorWindow).noShow();     <-----Add this in didComponentMount(If you use it)
                                                         <-----to handle network errors
    
    }
    , (errorStautus:number, errorMessage:string)=>{                                 <-----ADD THIS ARG. TO UR 
      (this.errorWindow as ErrorWindow).show(errorStautus, errorMessage, errorTitle, Generated error title);}     <-----CALLMETADATASERVICE
      )

                                IF YOU ERROR CODE COMES BACK AS UNDEFINED, ADD ERROR CODE 
                                AND MESSAGE TO ERROR MAP, OR ASK MATHIAS



                                IF YOU WANT TO CATCH AN ERROR IN vSCODE THEN ADD AN 
                                ERRORWINDOW TO YOUR COMPONENT(IF IT DOESNT EXIST):

                                <ErrorWindow ref={this.errorWindow}></ErrorWindow>

                                AND USE "(this.errorWindow as ErrorWindow).show(errorStautus, errorMessage)"
                                WITH A/YOUR XXX CODE IN ERROR MAP AS errorStatus, AND A DESCRIPTIVE ERROR TEXT
                                IN ERROR MESSAGE

*/ 



import React from "react";
import {ERROR_MAP} from "../Helpers/ErrorMap";
import {ShowMore} from "./ShowMoreComponent";
import { PiX,PiWarningOctagonFill } from "react-icons/pi";

export class ErrorWindow extends React.Component<
    {
        
        
        //Properties go here
    },
    {
        errorMessage: string,
        errorTitle: string,
        visible:boolean,
        errorStatus:number,
        errorMessageGenerated:string,
        errorMessageInput:string
        //State goes here
    }
> {

    constructor(props: any) {
        super(props);
        this.state = {
            errorStatus:0,
            visible:false,
            errorMessageGenerated:"",
            errorMessageInput:"",
            errorMessage: "No error code given, error unknown",
            errorTitle: "UNKNOWN ERROR",
            //variables dependent on the given error type, default values if nothing is given
        }
    }

    onButtonPress=()=>{
        this.setState({visible:false})
      }
    
    show=(errorCode:number, errorMessageInput:string,errorMessageGenerated:string="", errorTitle:string="")=>{
        if(errorCode in ERROR_MAP){
            this.setState({
                visible:true,
                errorTitle:ERROR_MAP[errorCode].title,
                errorMessage:ERROR_MAP[errorCode].message, 
                errorMessageGenerated:errorMessageGenerated,
                errorMessageInput: errorMessageInput
            })
        }
        if(errorTitle!==""){this.setState({errorTitle:errorTitle})}
    }

    hide=()=>{
        this.setState({
            visible:false,
        })
    }

    render() {
        if(this.state.visible===false){return <></>}

        return (
            <>
            <div className="errorWrapper">
            <div className="errorContainer">
                <div className="errorHeader">
                    <h3 className="errorTitle">{this.state.errorTitle}</h3>
                    <PiX className="errorCloseButton" onClick={this.onButtonPress}/>
                </div>
                <div className="errorBody">
                    <PiWarningOctagonFill className="errorIcon"/>
                    <div className="errorText">
                    <div className="errorMessage">{this.state.errorMessageInput}</div>
                    <div className="errorMessage">{this.state.errorMessage}</div>
                    <ShowMore maxLength={45} text={this.state.errorMessageGenerated}/>
                    </div>
                </div>
            </div>
            </div>
            </>
        );
    }
}



