import React from "react";
import { Main } from "./Main";


export class AppFrame extends React.Component<
{ serviceAddress: string, authKey: string, user_id: string, unauthorizedCallback: (e: string)=> void  },
  {

  }
> {


  render() {
    return (
      <>
       <Main serviceAddress={this.props.serviceAddress} testingStudyId={""}  user_id={this.props.user_id} authKey={this.props.authKey} unauthorizedCallback={this.props.unauthorizedCallback}></Main>
      </>
    );
  }
}
