import React, { ReactNode } from "react";
import { PiInfo, PiX } from "react-icons/pi";

export class PopupWindow extends React.Component<
  { children: ReactNode, title: string, closeClicked: (callback?: ()=> void) => void , info?: string, hasClose?: boolean}, 
  {

  }
> {

  static defaultProps = {
    info: "",
    hasClose: true
  }

  render() {
    return (
      <>
        {/* Pernilles popup stucture */}

        <div className="transparentWrapper">
          <div className="popupContainer">
            <div className="popupTopbar">
              <div className="popupTitle">
                <h1>{this.props.title}</h1>
                {(this.props.info) ?
                <div title={this.props.info}>
                  <PiInfo className="buttonIcon"/>
                </div>:<></>
                }
                {
                  this.props.hasClose ?   <button className="closeButton" onClick={(e) => { this.props.closeClicked();  e.stopPropagation(); }}>
                  <PiX className="buttonIcon"/>
                  </button> :<></>
                }
             
              </div>
              
            </div>
            <div className="popupMainbar">
              <div className="popupContent">
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
