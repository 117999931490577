import React from "react";

export class StarPreview extends React.Component<
  { value:number  },
  {
    
  }
> {

  render() {
    let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
    let brok=(this.props.value-Math.floor(this.props.value))*100
    let brok2=100-brok

    
   
    let listOfStars: React.ReactNode[] = [];


    for (let i = 1; i < 6; i++) {
        if (i<=this.props.value) {
            listOfStars.push(  <span className="star" key={"star_" + i}>
            <svg id = "svgelem" xmlns="http://www.w3.org/2000/svg" width="36" height="34" viewBox="0 0 36 34">
              <path id="Path_10318" d="M-.411,18.383a1.075,1.075,0,0,1-.793-.34,1.1,1.1,0,0,1-.335-.793.819.819,0,0,1,.018-.192L.364,6.149-7.65-1.571a1.066,1.066,0,0,1-.335-.784A1.1,1.1,0,0,1-7.7-3.087a1.134,1.134,0,0,1,.687-.4L4.045-5.074l4.967-9.933a1.082,1.082,0,0,1,.414-.444A1.088,1.088,0,0,1,10-15.617a1.213,1.213,0,0,1,.6.157.994.994,0,0,1,.423.453l4.967,9.933L27.047-3.488a1.139,1.139,0,0,1,.7.383,1.08,1.08,0,0,1,.273.732,1.05,1.05,0,0,1-.335.8l-8.014,7.72,1.885,10.909a.819.819,0,0,1,.018.192,1.066,1.066,0,0,1-.335.784,1.089,1.089,0,0,1-.793.331,1.107,1.107,0,0,1-.528-.122l-9.9-5.158-9.9,5.158A1.056,1.056,0,0,1-.411,18.383Z" transform="translate(7.984 15.617)" fill="#F1A208"/>
            </svg>
          
          </span>)  
        }else if(this.props.value+1<=i){
            listOfStars.push(<span className="star" key={"emptystar_" + i}>
            <svg id = "svgelem" xmlns="http://www.w3.org/2000/svg" width="36" height="34" viewBox="0 0 36 34">
              <path id="Path_10318" d="M-.411,18.383a1.075,1.075,0,0,1-.793-.34,1.1,1.1,0,0,1-.335-.793.819.819,0,0,1,.018-.192L.364,6.149-7.65-1.571a1.066,1.066,0,0,1-.335-.784A1.1,1.1,0,0,1-7.7-3.087a1.134,1.134,0,0,1,.687-.4L4.045-5.074l4.967-9.933a1.082,1.082,0,0,1,.414-.444A1.088,1.088,0,0,1,10-15.617a1.213,1.213,0,0,1,.6.157.994.994,0,0,1,.423.453l4.967,9.933L27.047-3.488a1.139,1.139,0,0,1,.7.383,1.08,1.08,0,0,1,.273.732,1.05,1.05,0,0,1-.335.8l-8.014,7.72,1.885,10.909a.819.819,0,0,1,.018.192,1.066,1.066,0,0,1-.335.784,1.089,1.089,0,0,1-.793.331,1.107,1.107,0,0,1-.528-.122l-9.9-5.158-9.9,5.158A1.056,1.056,0,0,1-.411,18.383Z" transform="translate(7.984 15.617)" fill="#faebb4"/>
            </svg>
          </span>)
        }
        else{listOfStars.push( <span className="star" key={"halfstar_" + i}>
        <svg xmlns="http://www.w3.org/2000/svg" width = "36" height = "34" viewBox="0 0 36 34">
          <linearGradient id={"gradient-"+uniqueId}>
            <stop offset={brok+"%"} stopColor="#F1A208"/>
            <stop offset={brok2+"%"} stopColor="#faebb4"/>
          </linearGradient>
          <path fill={"url(#gradient-"+uniqueId+")"} d="M-.411,18.383a1.075,1.075,0,0,1-.793-.34,1.1,1.1,0,0,1-.335-.793.819.819,0,0,1,.018-.192L.364,6.149-7.65-1.571a1.066,1.066,0,0,1-.335-.784A1.1,1.1,0,0,1-7.7-3.087a1.134,1.134,0,0,1,.687-.4L4.045-5.074l4.967-9.933a1.082,1.082,0,0,1,.414-.444A1.088,1.088,0,0,1,10-15.617a1.213,1.213,0,0,1,.6.157.994.994,0,0,1,.423.453l4.967,9.933L27.047-3.488a1.139,1.139,0,0,1,.7.383,1.08,1.08,0,0,1,.273.732,1.05,1.05,0,0,1-.335.8l-8.014,7.72,1.885,10.909a.819.819,0,0,1,.018.192,1.066,1.066,0,0,1-.335.784,1.089,1.089,0,0,1-.793.331,1.107,1.107,0,0,1-.528-.122l-9.9-5.158-9.9,5.158A1.056,1.056,0,0,1-.411,18.383Z" transform="translate(7.984 15.617)"/>
        </svg>
      </span>)}
        
    }

    return (
     
        <div className="test starContainer">{listOfStars}</div>
      

    );
  }
}

