import React from "react";
import { ClusterEvalModel } from "../Models/ClusterEvalModel";
import { DataHelper } from "../Helpers/DataHelper";
import { PreviewMeasurement } from "../Models/PreviewMeasurement";
import { StudyModel } from "../Models/StudyModel";
import { EvalFlowCriteria } from "../Models/EvalFlowCriteria";
import { ImageWithSVGPoints } from "./ImageWithPoints";

export class AICurveEvalButton extends React.Component<
    {
        evaluationResult?: ClusterEvalModel,
        dataHelper: DataHelper,
        rowData: PreviewMeasurement,
        studySelected: StudyModel,
        procedureId: string,
        onEvaluationResult: (resultDict: ClusterEvalModel) => void,
    },
    {
        evaluationResult?: ClusterEvalModel

    }
> {

    

    constructor(props: any) {
        super(props);
        let initResult=undefined
        if(this.props.evaluationResult){initResult = this.props.evaluationResult}
        this.state = {
            evaluationResult:initResult,
        }
        
    }

    static defaultProps = {
        evaluationResult: null
      }



    evalElement(): JSX.Element {
        const result = this.state.evaluationResult
        const evalCriteria = new EvalFlowCriteria()



        
        if (!result) {
          return <div>NA</div>;
        }
    
        const getBackgroundColor = (value:number, label:string) => {
            const evalMetrics= evalCriteria.getCriteria(label)

            if (value < 0) return "#969696"; // Gray for invalid/unset values;

            if (evalMetrics?.smallerThanGood) {
                if (value <= evalMetrics.lowerBound) return "#4CAF50"; // Green
                if (value <= evalMetrics.upperBound) return "#FFEB3B"; // Yellow
                return "#F44336"; // Red
            } else if(evalMetrics) {
                if (value >= evalMetrics.upperBound) return "#4CAF50"; // Green
                if (value >= evalMetrics.lowerBound) return "#FFEB3B"; // Yellow
                return "#F44336"; // Red
            }

            return "#969696"; // Gray for unknown labels
            
        };
    
        const getTextColor = (backgroundColor:string) => {
          return backgroundColor === "#FFEB3B" ? "black" : "white";
        };

        const renderEmbeddingSquare = (): JSX.Element => {
          return (
            <div className="aiFlowAnalysisSquare">
              {result.newEmbedding.map((value, index) => (
                <div key={index} className="aiFlowEvalCellEm"><div>{value[0].toFixed(2)}</div><div>{value[1].toFixed(2)}</div></div>
              ))}
            </div>
          );
        };
    
        const renderCell = (value: number, label: string) => {
          const backgroundColor = getBackgroundColor(value, label);
          const textColor = getTextColor(backgroundColor);
          
          return (
            <div className="aiFlowEvalCell" style={{
              backgroundColor,
              color: textColor,
            }}>
              <span>{label}:</span>
              <span>{value>=0 ? typeof value === 'number' ? value.toFixed(2) : value : "NA"}</span>
            </div>
          );
        };

    
    
        return (
          <div>
            <table className="tableStyle">
              <tbody>
                <tr>
                  <td className="cellStyle">{renderCell(result.fromCentre, 'From Centre')}</td>
                </tr>
                <tr>
                  <td className="cellStyle">{renderCell(result.distanceMean, 'Distance Mean')}</td>
                </tr>
                <tr>
                  <td className="cellStyle">{renderCell(result.distanceMedian, 'Distance Median')}</td>
                </tr>
                <tr>
                  <td className="cellStyle">{renderCell(result.clusterConsistency, 'Cluster Consistency')}</td>
                </tr>
                <tr>
                  <td className="cellStyle">{renderCell(result.clusterRating, 'Cluster Rating')}</td>
                </tr>
                <tr>
                  <div className="embeddingHolder">
                    <h4 className="embeddingHeader">New Embedding:</h4>
                    {renderEmbeddingSquare()}
                 </div>
                </tr>
                <tr>
                  <ImageWithSVGPoints imageSrc="/umap_fftOptimalSplit_fix_16.png" imageWidth={400} imageHeight={300} points={this.props.evaluationResult?.newEmbedding} onPointClick={(point, index)=>{
                    alert("You Pressed Curve Segment: "+index)
                  }} graphAxisLengthUnits={[22.5,22.5]} graphCenterPixls={[165,175]} scalePoints={true} graphSizePixls={[250,230]}></ImageWithSVGPoints>
                </tr>
              </tbody>
            </table>
            
          </div>
        );
      }

      getFlowEval(): void{
        this.props.dataHelper.CallSettingsService("GetEvalData",{} ,{studyId:this.props.studySelected.id, measurementId:this.props.rowData.id, procedureId:this.props.procedureId, measurementType:this.props.rowData.measurementType} ,(dataOut: string)=>{
          let formattedOutput = JSON.parse(dataOut)  
          if (dataOut)
            {
              this.setState({
                evaluationResult:formattedOutput,
              },()=>{
                if(this.state.evaluationResult){
                    this.props.onEvaluationResult(this.state.evaluationResult)
                }
                
              })
            }
        })
    }

    render() {
        return (
            
            <div>
                <button className="thumbnailButton" disabled={this.state.evaluationResult?.fromCentre===-1? false : true} onClick={()=>{
                            
                            if(this.props.evaluationResult?.fromCentre!==-1){
                              this.setState({
                                evaluationResult:this.props.evaluationResult
                              })
                            }else{
                              this.getFlowEval();
                            }
                          }}>Get Flow Evaluation</button>
                          {this.state.evaluationResult?.fromCentre!==-1 && (
                                <div className="evaluationResult">
                                    {this.evalElement()}
                                </div>
                            )}
            </div>
            
        );
    }
}
