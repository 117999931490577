import { ReactNode } from "react";


export enum AppMode {
  Home,             //0
  Search,           //1
  Export,           //2
  Upload,           //3
  Settings,         //4
  User,             //5
  AddUser,          //6
  Study,            //7
  Default,          //8
  Labels,           //9
  Report,           //10
  DashboardControl, //11
  Edit,             //12
  SelectedHome,     //13
  UploadHome,       //14
  UsageHistory,     //15
  ImportHistory,     //16
  CurrentLogons,     //17
  LabelMap,           //18
  UserHistorySummary, //19
  SitesSummary,       //20
  StudyUsageHistory   //21
};


export class TileRange {
  min: number = 0;
  max: number = 0;
}

export class DataGridRow 
{
  index: number= 0; 
  children?: DataGridRow[]; 
}

export class DataGridColumn
{
  Name: string = "";
  Caption: string = "";
  
  Type?: string;
  FilterOptions?:string[];
  Editable?: boolean;
  ValueList?: string[];
  ignoreChildren?: boolean;
  searchChildren?: boolean;
  additionalChildClass?: string;
  hidden?: boolean;
  GetValueList?: (row: any)=> string[];
  RenderTemplate?: (item: any, rowItem: any, parentRow: any) => ReactNode;
  FilterTemplate?: (item: any)=>ReactNode;
  GetCellEdit?:(row:DataGridRow, column:DataGridColumn)=>boolean;
  //Defines if the value of the column is only shown once per alternate row group (See getChildGridRowAlternateColor in DataGrid) in the column 
  ShowValueOncePerAlternate?: boolean = false;
  AdditionalFilter?: (item: any)=>ReactNode;
  SummaryTemplate?: (rows: DataGridRow[])=>ReactNode;
}


export class SortVal{
  category:string="";
  value:any;
  valueEnd:any;
  measurementType?:string="";
  capiton:string="";
  overrideType?: string = "";
  ignoreChildren?: boolean = false;
  searchChildren?: boolean = false;
}

export class Helper {

  public static isPowerOf2(value: number): boolean {
    return (value & (value - 1)) === 0;
  }
}
export class Vertex {
  constructor(_x:number, _y:number, _z:number, _tu:number, _tv:number ) 
  {
    this.x = _x;
    this.y = _y;
    this.z = _z;
    this.tu = _tu;
    this.tv = _tv;
  }
  x: number;
  y: number;
  z: number;
  tu: number = 0;
  tv: number = 0;
  getVectoryArray(): number[] 
  {
    return [this.x, this.y, this.z];
  }
}

export class Coordinate {
  x: number = 0;
  y: number = 0;
  angle?: number;
}

export class Area extends Coordinate {
  width: number = 0;
  height: number = 0;
}
