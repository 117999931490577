import React from "react";
import { AccessMap } from "../Helpers/AccessMap";
import { PiPencilSimple, PiUser, PiSlidersHorizontal, PiTagChevron, PiLockOpen, PiList, PiUpload, PiMagnifyingGlass } from "react-icons/pi";

import { AppMode } from "../Helpers/SharedClasses";
import { UserAccessModel } from "../Models/UserAccessModel";
import { DataHelper } from "../Helpers/DataHelper";

export class SettingsWindow extends React.Component<
    {
        dataClient: DataHelper,
        addAppToStack: (mode: AppMode) => void,
        userAccess:UserAccessModel,
        //Properties go here
    },
    {
        //State goes here
        
    }
> {

    

    constructor(props: any) {
        super(props);
        this.state = {
            
            //Initialize ALL state variables here
        }
    }

    //add more buttons here
    menuItems = [
        { access: AccessMap[AppMode.User], mode: AppMode.User, icon: <PiUser className="buttonIcon"/>, text: 'Administer users' },
        { access: AccessMap[AppMode.Study], mode: AppMode.Study, icon: <PiPencilSimple className="buttonIcon"/>, text: 'Administer studies' },
        { access: AccessMap[AppMode.Labels], mode: AppMode.Labels, icon: <PiTagChevron className="buttonIcon"/>, text: 'Administer Labels' },
        { access: AccessMap[AppMode.DashboardControl], mode: AppMode.DashboardControl, icon: <PiSlidersHorizontal className="buttonIcon"/>, text: 'Data maintenance' },
        { access: AccessMap[AppMode.UsageHistory], mode: AppMode.UsageHistory, icon: <PiList className="buttonIcon"/>, text: 'Logon history' },
        { access: AccessMap[AppMode.StudyUsageHistory], mode: AppMode.StudyUsageHistory, icon: <PiList className="buttonIcon"/>, text: 'Study usage history' },
        { access: AccessMap[AppMode.ImportHistory], mode: AppMode.ImportHistory, icon: <PiUpload className="buttonIcon"/>, text: 'Upload history' },
        { access: AccessMap[AppMode.CurrentLogons], mode: AppMode.CurrentLogons, icon: <PiList className="buttonIcon"/>, text: 'Current logons' },
        { access: AccessMap[AppMode.UserHistorySummary], mode: AppMode.UserHistorySummary, icon: <PiList className="buttonIcon"/>, text: 'Logon summary for all users' },
      ];





    checkRenderButton=(buttonAccess:string[],userAccess:UserAccessModel):boolean=>{
        
        return buttonAccess.includes(userAccess.userType)
      }


    render() {
        //THis is where we return our HTML for the UI

        const {userAccess, addAppToStack}=this.props

        return (
            <>
                <div className="menu">
                    {this.menuItems
                    .filter(item => this.checkRenderButton(item.access, userAccess))
                    .map((item, index) => (
                        <div key={index} className="menuItem" onClick={() => addAppToStack(item.mode)}>
                            <div className="menuButton">{item.icon}</div>
                            <div className="menuItem">{item.text}</div>
                        </div>
        ))}


        <div className="menuItem" onClick={() =>  {
                window.open('https://medistim.com/about-us/personal-data-protection-policy/', "_blank");
        }  
         }>
            
                            <div className="menuButton"><PiMagnifyingGlass className="buttonIcon"></PiMagnifyingGlass></div>
                            <div className="menuItem">Privacy policy</div>
                        </div>
         <div className="menuItem" onClick={() =>  {
              this.props.dataClient.CallSettingsService("LogoutUser", "", {}, ()=>{
              window.location.href = '/.auth/logout'; //relative to domain 
            },(stautus:number, errorMessage:string)=>{
                    alert("Error logging out user!");
              }, "POST");
              
        }  
         }>

                            <div className="menuButton"><PiLockOpen className="buttonIcon"></PiLockOpen></div>
                            <div className="menuItem">Logout</div>
                        </div>
                </div>
            </>
        );
    }
}



