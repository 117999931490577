import React, { RefObject } from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip
} from "recharts";
import { LoadingWidget } from "../Components/LoadingWidget";
import { DataHelper } from "../Helpers/DataHelper";
import { ChartDataModel } from "../Models/ChartDataModel";
import { ErrorWindow } from "../Components/ErrorWindow";

export class CumulativeUploads extends React.Component<
    {
        dataClient: DataHelper
    },
    {
        uploadData: ChartDataModel[],
        isBusy: boolean
    }
> {

    //Data structure:
    // data = [
    //     {
    //       name: "category",
    //       value: number
    //     }
    // ];
    errorWindow:  RefObject<ErrorWindow>;
    constructor(props: any) {
        super(props);
        this.errorWindow = React.createRef();
        this.state = {
            uploadData: [],
            isBusy: false
        }
    }

    componentDidMount(): void {
        this.setState({ isBusy: true })
        this.props.dataClient.CallDashboardService("GetCumulativeUploads", {}, {}, (result: string) => {
            let cumulativeUploads = JSON.parse(result);
            this.setState({ uploadData: cumulativeUploads, isBusy: false })
        }, (stautus:number, errorMessage:string)=>{
            (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage)}, "GET", false)}

    render() {
    
        return (
            <>
                <ErrorWindow ref={this.errorWindow}/>
                <h3>Uploads over time</h3>
                <div className="chartHolderStreach">
                
                    <AreaChart
                        width={1200}
                        height={300}
                        data={this.state.uploadData}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 50,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis label={{ value: 'No. of uploads', position: 'top' }}/>
                        <Tooltip />
                        <Area //Later: make one per study
                            type="monotone"
                            dataKey="dataValue"
                            name="Procedures uploaded"
                            stackId="1"
                            stroke="#8884d8"
                            fill="#8dd1e1"
                        />
                    </AreaChart>
                    <LoadingWidget visible={this.state.isBusy} zIndexLayer={0}></LoadingWidget>
                </div>
            </>
        );
    }
}
