import React from "react";
import { DataHelper } from "../Helpers/DataHelper";
import { StudyProgress } from "../Dashboards/StudyProgress";
import { MeasurementTypes } from "../Dashboards/MeasurementTypes";
import { CumulativeUploads } from "../Dashboards/CumulativeUploads";


export class HomeWindow extends React.Component<
  {
    dataClient: DataHelper
  },
  {
  }
> {


  constructor(props: any) {
    super(props);
    this.state = {
    }
  }

  componentDidMount(): void {
    
  }

  render() {

    return (
      <>
        <div className="dashboardHeader">
        {/* <QuoteGenerator dataclient={this.props.dataClient}></QuoteGenerator>  */}
          <h1>Overview of all studies</h1>
        </div>
        <div className="dashboardBody">
          <div className="topContent">
            <div className="chartContainer">
            <StudyProgress dataClient={this.props.dataClient}></StudyProgress>
            </div>
            <div className="chartContainer">
            <MeasurementTypes dataClient={this.props.dataClient}></MeasurementTypes>
            </div>
          </div>
          <div className="bottomContent">
            <div className="chartContainer">
            <CumulativeUploads dataClient={this.props.dataClient}></CumulativeUploads>
            </div>
          </div>
        </div>

      </>
    );
  }
}
