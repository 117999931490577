import { DataGridRow } from "../Helpers/SharedClasses";
import { LabelModel } from "./LabelModel";

export class StudyModel extends DataGridRow
{
    
    name: string = "";
    description: string = "";
    id?: string = "";
    idFieldForStudy?: string = "None";
    allowPersonalData?: boolean = true;
    curveEvalEnabled?: boolean = true;
    exportEnabled?: boolean = false; 
    encryptionEnabled?: boolean = false;
    startdate?: Date = undefined;
    expectedProcedures?: number = 0;
    expectedHospitals?: number = 0;
    protocolSteps?: string[] = [];
    workflowFilename?: string = "";
    lowerReferenceValue?: number = 0;
    upperReferenceValue?: number = 0;
    labelTypes?: string[] = [];
    suggestedLabels? : LabelModel[] = [];
    procedures?: number = 0;
    measurements?: number = 0;
    flowNum?: number = 0;
    imagingNum?: number = 0;
    ecgNum?: number = 0;
    noLabelNum?: number = 0;
    lastUpdated?: Date = undefined;
    
}