//@ts-nocheck

import * as React from 'react';
import * as d3 from 'd3';
import * as d3interpolation from "d3-interpolate-curve";
import { TargetLocation, Coordinate,Conduit, Graft, TargetType, TargetDetails, ProcedureType } from './TriggerClasses';
import ConduitVisualize from './ConduitVisualize';
import { PeripheralGraftMaterial, VascularGraft, VascularHelpers } from './VascularClasses';

class HeartDrawingOverlay extends React.PureComponent<{ Context: string,
    GraftsLoaded: Graft[],
    AllGrafts: Graft[],
    trackClicks: boolean,
    locationx: number,
    locationy: number,
    drawDebugLine: boolean,
    //The percentage location at which to curve the line
    curveLocationOnLine: number,
      //The percentage location at which to start a Y graft
      yLocationOnLine: number,
    customGraftLocation: TargetLocation,
    heartOutlineShow: boolean,
    heartTargetsShow: boolean,
    //The frequency of wiggles (Percent of the total line at which each wiggles should fall)
    wiggleFrequency: number,
    //The absolute size of wiggles in pixels.
    wiggleAmplitude: number,
    //The point after the source and before the target that wiggles should be drawn between.
    wiggleLimit: number,
    //The radius of the heart drawn
    radiusHeart: number,
    //The y position of the heart
    heartyOffset: number,
      //The x position of the heart
    heartxOffset: number;
    //Whether targets can be dragged and dropped.
    dragDropEnabled: number,
    arteryWidth: number,
    veinWidth: number,
    targetRadius: number,
    jointRadius: number,
    showLegend: boolean,
    editGraftClicked: Function,
    showIndices: boolean,
    graftSelected: Graft,
    selectedProcedure: ProcedureType
 },
 {ImageOverlays: Array<React.ReactFragment>,ImageOverlaysBack: Array<React.ReactFragment> }> {

    constructor(props: any) {
        super(props);
        this.state ={
            ImageOverlays: [],
            ImageOverlaysBack: []
          }
      }

      static defaultProps = {
        AllGrafts: [],
        trackClicks: true,
        locationx: 1392,
        locationy: 1073,
        Context: "Planner",
        drawDebugLine: false,
        heartOutlineShow: true,
        curveLocationOnLine: 0.2,
        yLocationOnLine: 0.2, 
        wiggleFrequency: 0.2,
        wiggleAmplitude: 10,
        wiggleLimit: 20,
        radiusHeart: 425,
        heartyOffset: -250,
        heartxOffset: -40,
        dragDropEnabled: true,
        arteryWidth: 12,
        veinWidth: 14, 
        jointRadius: 13,
        targetRadius: 13,
        showLegend: true,
        showIndices: false
    }

    currentPointsToDraw = [];
    //This stores any offset
    targetYOffsets: Record<string, number>  = {
        "LITA": 0,
        "RITA": 0,
        "RA": 0,
        "SVG": 0,
        "GEA": 0,
        "RCA": 0
    };
     



      svg?: d3.Selection<SVGElement, any, HTMLElement, any>;
      svgBack?: d3.Selection<SVGElement, any, HTMLElement, any>;
      svgTop?: d3.Selection<SVGElement, any, HTMLElement, any>;

drawTargetLine= (TargetName: string, prefixTarget: string = "target-")=>
{

    if (this.props.Context === "Imaging" || this.props.Context === "TTFM")
        return;
    let target = document.getElementsByClassName(prefixTarget+TargetName);
    //Only draw target button lines if there are any. This will be the point to tweak when to draw the report buttons.
    if (target && target.length > 0)
    {
    let offsetToAdd = 0;
    if (target[0].parentElement)
    if (target[0].parentElement.classList.contains("target-left"))
    {
        offsetToAdd = offsetToAdd + (target[0] as HTMLDivElement).offsetWidth;

    }
  
        let xCoord = target[0].getBoundingClientRect().x - (document.getElementById("HeartDrawingOverlay-"+this.props.Context) as HTMLElement).getBoundingClientRect().x + offsetToAdd;
        let yCoord = target[0].getBoundingClientRect().y - (document.getElementById("HeartDrawingOverlay-"+this.props.Context) as HTMLElement).getBoundingClientRect().y + (target[0] as HTMLDivElement).offsetHeight/2;

        // var transferToSizingx = this.targetCoords[TargetName].x -(this.props.locationx - document.getElementById("HeartDrawingOverlay").offsetWidth)/2;
        // var transferToSizingy = this.targetCoords[TargetName].y -(this.props.locationy - document.getElementById("HeartDrawingOverlay").offsetHeight);
        this.drawDiscreteLineBetweenPoints({x: xCoord, y: yCoord}, this.transferToScreenSize(TargetDetails.getTargetCoords(this, TargetName)), TargetDetails.getTargetCoords(this, TargetName).backOfHeart )
    
}
}

aspectRatio=960/1000;
transferToScreenSize = (coordIn: any): Coordinate =>
{
    if (document.getElementById("HeartDrawingOverlay-"+this.props.Context))
    {
        let newHeightMultipler = (document.getElementById("HeartDrawingOverlay-"+this.props.Context) as HTMLElement).offsetHeight/1000;
        let heartWidth = this.aspectRatio * (document.getElementById("HeartDrawingOverlay-"+this.props.Context) as HTMLElement).offsetHeight;
        if (!document.getElementById("HeartDrawingOverlay-"+this.props.Context))
            return coordIn;
        var transferToSizingx = (coordIn.x*(heartWidth/960) +  (((document.getElementById("HeartDrawingOverlay-"+this.props.Context) as HTMLElement).offsetWidth)/2 - heartWidth/2));// (coordIn.x  -(this.props.locationx - document.getElementById("HeartDrawingOverlay-"+this.props.Context).offsetWidth*widthMultiplier)/2);
        var transferToSizingy = newHeightMultipler*(coordIn.y );
        var toreturn =  {x: transferToSizingx, y: transferToSizingy +10};

        if ((coordIn as TargetLocation).backOfHeart === true || (coordIn as TargetLocation).backOfHeart === false )
        {
            (toreturn as TargetLocation).backOfHeart = (coordIn as TargetLocation).backOfHeart;
        }

        return toreturn;
    }
    return coordIn;
}

transferFromScreenSize = (coordIn:  Coordinate): Coordinate =>
{
    if (document.getElementById("HeartDrawingOverlay-"+this.props.Context))
    {
    let newHeightMultipler = (document.getElementById("HeartDrawingOverlay-"+this.props.Context) as HTMLElement).offsetHeight/1000;
    let heartWidth = this.aspectRatio * (document.getElementById("HeartDrawingOverlay-"+this.props.Context) as HTMLElement).offsetHeight;

    var transferToSizingx =  ((coordIn.x as number) - (((document.getElementById("HeartDrawingOverlay-"+this.props.Context) as HTMLElement).offsetWidth)/2 - heartWidth/2))/(heartWidth/960);// coordIn.x +(this.props.locationx - (document.getElementById("HeartDrawingOverlay-"+this.props.Context) as HTMLElement).offsetWidth)/2;/// ((0.88*(document.getElementById("HeartDrawingOverlay-"+this.props.Context) as HTMLElement).offsetWidth/this.props.locationx)*(document.getElementById("HeartDrawingOverlay-"+this.props.Context) as HTMLElement).offsetHeight/(this.props.locationy));
    var transferToSizingy = (coordIn.y as number)/newHeightMultipler;// + (this.props.locationy - (document.getElementById("HeartDrawingOverlay-"+this.props.Context) as HTMLElement).offsetHeight)/2;
    var toreturn =  {x: transferToSizingx, y: transferToSizingy +10};

    if ((coordIn as TargetLocation).backOfHeart === true || (coordIn as TargetLocation).backOfHeart === false )
    {
        (toreturn as TargetLocation).backOfHeart = (coordIn as TargetLocation).backOfHeart;
    }

    return toreturn;
}

return coordIn;
}

drawDiscreteLineBetweenPoints = (point1: any, point2: any, backOfHeart?: boolean) =>
{

    var lineGenerator = d3.line();
    //.curve(d3.curveCardinal);
    var points = [
        [point1.x, point1.y],
        [point2.x, point1.y],
        [point2.x, point2.y]
    ];

    var pathData = lineGenerator(points as [number, number][]);

    //Lets put discrete lines on the layer of the back or front of heart layer.
    let svgToUse = (this.svg as any);
    if (backOfHeart)
    {
        svgToUse = (this.svgBack as any);
    }
    (svgToUse as any).append('path')
    .style("fill", "none")
    .style("stroke", "var(--shark)")
    .style("stroke-dasharray", ("6, 6"))
    .attr("class", "discrete-drawing")
        .attr('d', pathData);

    // Also draw points for reference. These are invisible at the moment because the conduits connectors are drawn over it.
    (svgToUse as any)
        .data([points[2]])
        .append('circle')
        .style("fill", "var(--shark)")
        .style("stroke", "var(--shark)")
        .attr("class", "discrete-drawing")
        .attr('cx', function(d: any) {
            return d[0];
        })
        .attr('cy', function(d: any) {
            return d[1];
        })
        .attr('r', 6);

}

generateSquigglyPointsBetween = (pointstart: Coordinate, pointend: Coordinate, wiggleSize: number, spaceBetweenWiggles: number, startfrom: number, endat: number ) =>
{
let valuesToReturn = [[pointstart.x, pointstart.y]];

   
    //Move distance on line in steps of length/number of points
    let lengthLine = Math.sqrt((pointend.x-pointstart.x)*(pointend.x-pointstart.x) + (pointend.y-pointstart.y)*(pointend.y-pointstart.y));

    //number of points to calculate
    let radius = wiggleSize;
    spaceBetweenWiggles = lengthLine * spaceBetweenWiggles;

    let numberOfPoints = lengthLine/spaceBetweenWiggles;

    let theta = Math.atan((pointend.y-pointstart.y)/(pointend.x-pointstart.x));
    let xvalue = Math.cos(theta);
    let yvalue = Math.sin(theta);

    let upxvalue = Math.sin(1*Math.PI - theta) * radius;
    let upyvalue = Math.cos(1*Math.PI - theta) * radius;
    // if ()
    // {
    //     //If the size of the wiggle is greater than 50% of the length of the line then don't wiggle,
    //     upxvalue = 0;
    //     upyvalue = 0;
    // }
    //If the size of the wiggle is greater than 50% of the length of the line then don't wiggle,
    //If the space between wiggles is more than half of the line length then dont wiggle either.
    if (radius < spaceBetweenWiggles*4) 
    {

    for (let index = 0; index <= numberOfPoints; index++) {

      

        if (index * spaceBetweenWiggles > startfrom && index * spaceBetweenWiggles < (numberOfPoints*spaceBetweenWiggles) - endat)
        {

        let nextX = pointstart.x + index * spaceBetweenWiggles * xvalue ;

        let nextY = pointstart.y + index * spaceBetweenWiggles * yvalue;

        if (this.props.drawDebugLine)
        {
        (this.svg as any)
        .data([[nextX, nextY]])
        .append('circle')

            .style("fill", "blue")
            .style("stroke", "blue")
            .style("opacity", 1)
            .attr('cx', function(d) {
                return d[0];
            })
            .attr('cy', function(d) {
                return d[1];
            })
            .attr('r', 2);
        }

        if (index % 2)
        {
            nextX = nextX + upxvalue;
            nextY = nextY + upyvalue;
        }else
        {
            nextX = nextX - upxvalue;
            nextY = nextY - upyvalue;
        }
        valuesToReturn.push([nextX, nextY]);
        if (this.props.drawDebugLine)
        {
        (this.svg as any)
        .data([[nextX, nextY]])
        .append('circle')

            .style("fill", "yellow")
            .style("stroke", "yellow")
            .style("opacity", 1)
            .attr('cx', function(d) {
                return d[0];
            })
            .attr('cy', function(d) {
                return d[1];
            })
            .attr('r', 2);
        }
   }

        //numberOfPoints = Math.sqrt((pointstart.x-xunknown)*(pointstart.x-xunknown) + (pointstart.y-yunkown)*(pointstart.y-yunkown));
    }
}
    valuesToReturn.push([pointend.x, pointend.y]);
    return valuesToReturn;

}


generateArteryCurves = (point1, point2, pointsIn): number[][] =>
{
    var pointsSet1 = this.generateSquigglyPointsBetween(point1, point2, this.props.wiggleAmplitude, this.props.wiggleFrequency, this.props.wiggleLimit, this.props.wiggleLimit);
    if (point1.x > point2.x)
    {
        pointsSet1 = this.generateSquigglyPointsBetween(point2, point1, this.props.wiggleAmplitude, this.props.wiggleFrequency, this.props.wiggleLimit, this.props.wiggleLimit);
        pointsSet1 = pointsSet1.reverse();
    }
   // pointsSet1.pop();
   // var pointsSet2 = this.generateSquigglyPointsBetween(midwayPoint, point2, 40, 0.2, 60, 60);
    if (pointsSet1.length > pointsIn.length)
        pointsIn = pointsSet1;//pointsSet1.concat(pointsSet2);
    
    return pointsIn;
} 

// generateSemiCircleAroundHeartWall = (point1: Coordinate, point2: Coordinate, pointOnWall: Coordinate): Coordinate[]=>
// {
//  //Draw a semi circle above the point on the wall.
//  let pointDegrees = [ 0, 45, 90, 135];
//  let radiusOfTurningCircle = Math.abs(point1.y - pointOnWall.y)/3;
//  let yModifier = 1;
//  let xModifier = 1;
//  if (point1.y > pointOnWall.y )
//  {
//      // If the curve is  needed downwards
//      radiusOfTurningCircle = Math.abs(point2.y - pointOnWall.y)/3;
//       yModifier = -1;
     
//  }
//  //At bottom of heart
//  let atBottom = false;
//  //If both points are above the point on the heart wall
//  if (point1.y < pointOnWall.y && point2.y < pointOnWall.y)
//  {
//     yModifier = 1;
//    // horizontalSemiCircle = true;
//     radiusOfTurningCircle = Math.abs(point1.x - pointOnWall.x)/3;
//     if (point1.x > pointOnWall.x )
//     {
//         // If the curve is  needed left to right
//         radiusOfTurningCircle = Math.abs(point2.x - pointOnWall.x)/3;
     
//         xModifier=-1;
//     }
//     atBottom = true;
//  }
//  if (radiusOfTurningCircle > 30)
//  {  
//      //If there is going to be too big a circle then limit the circle size
//     radiusOfTurningCircle = radiusOfTurningCircle/1.5;
//     //and only do a portion of the semi circle.
//     //TODO: Not sure about this.
//     //If we are going upwards from point 1 to 2 then the full semi circle is still required to prevent kinks
//     if (yModifier ===  1)
//         pointDegrees = [ 45, 120];
//         else
//         {
//             radiusOfTurningCircle = radiusOfTurningCircle*1.5;
//             pointDegrees = [  45, 90, 135];
//         }
      
    
//  }else if (radiusOfTurningCircle < 15)
//  { 
//      radiusOfTurningCircle = 15;

//  }
//  if (pointOnWall.x < point2.x)
//  {
//       // If the point on the wall is to the left of the endpnt
//       xModifier = -1;
//  }
//  let pointsOut: Coordinate[];
//  //Add a rounding only if both points are on the same side of the heart wall 
//  //Both to the left or both to the right.
//  if ((((point1.x < pointOnWall.x ) && (point2.x < pointOnWall.x )) ||((point1.x > pointOnWall.x ) && (point2.x > pointOnWall.x ))) || (((point1.y < pointOnWall.y ) && (point2.y < pointOnWall.y )) ||((point1.y > pointOnWall.y ) && (point2.y > pointOnWall.y ))) )
//  {
//  //First go up from point on wall by radius
//  let pointOnWallFresh = { ...pointOnWall };

//  pointOnWallFresh.y = pointOnWallFresh.y - yModifier * radiusOfTurningCircle;
// if (atBottom)
// {
//      //If we are at the bottom then go across instead of up.
//     pointOnWallFresh.x = pointOnWallFresh.x - xModifier * radiusOfTurningCircle;
//     pointOnWallFresh.y = pointOnWall.y;
// }

//  pointsOut = [];

//  let degreeShift = !(point1.y < pointOnWall.y && point2.y < pointOnWall.y) ? 0 : 90;
//  //Calculate degreeShift from point on wall and angle from centre of heart.
// //  if (pointOnWall.angle)
// //  {
// //     degreeShift = pointOnWall.angle;
// //     yModifier = 1;
// //     yModifier = 1;
// //  }

//   for (let index = 0; index < pointDegrees.length; index++) {
//       const element = pointDegrees[index]+degreeShift;
//       let yadjust = pointOnWallFresh.y - yModifier * radiusOfTurningCircle*Math.cos(element*Math.PI/180);
//       let xadjust = pointOnWallFresh.x + xModifier * radiusOfTurningCircle*Math.sin(element*Math.PI/180);
//       //Don't add data points on the same y value because it causes kinks.
//       //if (yadjust !== point1.y)
//         pointsOut.push({x: xadjust, y: yadjust});
//   }
// }
// return pointsOut;
// }
generateSemiCircleAroundHeartWall = (point1: Coordinate, point2: Coordinate, pointOnWall: Coordinate): Coordinate[]=>
{
 //Draw a semi circle above the point on the wall.
 let pointDegrees = [ 0, 45, 90, 135];
 let radiusOfTurningCircle = Math.abs(point1.y - pointOnWall.y)/3;
 let yModifier = 1;
 let xModifier = 1;
 if (point1.y > pointOnWall.y )
 {
     // If the curve is  needed downwards
     //radiusOfTurningCircle = Math.abs(point2.y - pointOnWall.y)/3;
     // yModifier = -1;
     
 }
 if (radiusOfTurningCircle > 30)
 {  
     //If there is going to be too big a circle then limit the circle size
    radiusOfTurningCircle = radiusOfTurningCircle/1.5;
    //and only do a portion of the semi circle.
    //TODO: Not sure about this.
    //If we are going upwards from point 1 to 2 then the full semi circle is still required to prevent kinks
    if (yModifier ===  1)
        pointDegrees = [0,  90, 135];
        else
        {
            radiusOfTurningCircle = radiusOfTurningCircle*1.5;
            pointDegrees = [  45, 90, 135];
        }
      
    
 }else if (radiusOfTurningCircle < 15)
 { 
     radiusOfTurningCircle = 15;

 }
 if (pointOnWall.x < point2.x)
 {
      // If the point on the wall is to the left of the endpnt
      xModifier = -1;
 }
 let pointsOut: Coordinate[];
 //Add a rounding only if both points are on the same side of the heart wall 
 //Both to the left or both to the right.
 if ((((point1.x < pointOnWall.x ) && (point2.x < pointOnWall.x )) ||((point1.x > pointOnWall.x ) && (point2.x > pointOnWall.x ))) || (((point1.y < pointOnWall.y ) && (point2.y < pointOnWall.y )) ||((point1.y > pointOnWall.y ) && (point2.y > pointOnWall.y ))) )
 {
 //First go up from point on wall by radius
 let pointOnWallFresh = { ...pointOnWall };
 pointOnWallFresh.y = pointOnWallFresh.y - yModifier * radiusOfTurningCircle;
 pointsOut = [];

 let degreeShift =(((point1.x < pointOnWall.x ) && (point2.x < pointOnWall.x )) ||((point1.x > pointOnWall.x ) && (point2.x > pointOnWall.x ))) ? 0 : 90;
 //Calculate degreeShift from point on wall and angle from centre of heart.
 if (pointOnWall.angle)
 {
     if (pointOnWall.x < this.findHeartCenter().x || pointOnWall.y < this.findHeartCenter().y)
     {
            degreeShift = 180 - pointOnWall.angle;
     }else
     {
        degreeShift = pointOnWall.angle;
     }
   
    yModifier = 1;
    yModifier = 1;
    let deadZoneWidth = this.props.radiusHeart * 2 * 0.3;
    let deadZoneWidthAngle = deadZoneWidth/(this.props.radiusHeart *2) * 180;//Math.acos((xdeadZone/2)/radius)*180/Math.PI;
    let deadZoneStart = (180 - deadZoneWidthAngle)/ 2;
    let deadZoneEnd = 180 - deadZoneStart;
    if (pointOnWall.angle > deadZoneStart && pointOnWall.angle < deadZoneEnd )
    {
        yModifier = -1;
        degreeShift = 270;
        pointOnWallFresh.y = pointOnWallFresh.y - yModifier * radiusOfTurningCircle -15;
        radiusOfTurningCircle = radiusOfTurningCircle/2;
        pointDegrees = [ 0, 90];
    }

    // if (point1.y > pointOnWall.y )
    // {
    //     // If the curve is  needed downwards
    //      yModifier = -1;
        
    // }

 }

  for (let index = 0; index < pointDegrees.length; index++) {
      const element = pointDegrees[index]+degreeShift;
      let yadjust = pointOnWallFresh.y - yModifier * radiusOfTurningCircle*Math.cos(element*Math.PI/180);
      let xadjust = pointOnWallFresh.x + xModifier * radiusOfTurningCircle*Math.sin(element*Math.PI/180);
      //Don't add data points on the same y value because it causes kinks.
      //if (yadjust !== point1.y)
        pointsOut.push({x: xadjust, y: yadjust});
  }
}
return pointsOut;
}

getDistanceBetweenPoints = (point1: Coordinate, point2: Coordinate) =>
{
    let xIn = (point1.x -point2.x);
    let yIn = (point1.y -point2.y);
    let distanceBetweenPoints = Math.sqrt(xIn*xIn + yIn*yIn);
    return distanceBetweenPoints;
}

getMidpointBetweenLines = (point1: Coordinate, point2: Coordinate, point2BackOfHeart: boolean, pointsToTackOn: Coordinate[], comingDirectFromFreeSource: boolean = false, transferToScreenRequired: boolean = false) => 
{
    let point2ForMidpoint = point2;
if (pointsToTackOn)
{
    //If there are points to tack on then use the first point on the semi circle to find the midpoint.
    point2ForMidpoint = pointsToTackOn[0];
}

let midwaypoint = point1;
if (point2BackOfHeart && point1.x > point2ForMidpoint.x)
{
    //midwaypoint = point2ForMidpoint;
}


    let absoluteDifferenceBetweenPoints = (point2ForMidpoint.x - point1.x);
 
    let xMidPoint = (point2ForMidpoint.x - point1.x)*this.props.curveLocationOnLine + point1.x;

    let heartCenter = this.findHeartCenter();
    if (transferToScreenRequired)
    {
        heartCenter = this.transferFromScreenSize(heartCenter);
    }
      //If it starts on the left side of the heart we want it to curve up, and on the right curve down.
        //      Left                    Right  
        //   _________start  |           start     
        //  / <-Curve Up     |            / <-Curve Down 
        // /                 |    _______/
        //end                |  end
      
        //To make it curve down make the midway point, the second point x or y not the first.
       
    if ((point1.x > heartCenter.x && point2ForMidpoint.y > point1.y) )
            midwaypoint = point2ForMidpoint;
    let yMidPoint = midwaypoint.y;
    //Ensure the smoothest curve possible for veins by choosing the longest x or y to put the curve on.
    //Also if it is coming direct from source and it is not going around the heart then curve up regardless.
    if (Math.abs(point2ForMidpoint.y - point1.y) > Math.abs(absoluteDifferenceBetweenPoints) || (comingDirectFromFreeSource && !pointsToTackOn && Math.abs(point2ForMidpoint.y - point1.y) < 30))
    {
        if ((point1.x > heartCenter.x && point2ForMidpoint.x > point1.x) || (comingDirectFromFreeSource && !pointsToTackOn) )
            midwaypoint = point2ForMidpoint;
        xMidPoint = midwaypoint.x;
        yMidPoint = (point2ForMidpoint.y - point1.y)*this.props.curveLocationOnLine + point1.y;
    }

    return {x: xMidPoint, y: yMidPoint};
}

drawCurvedLineBetweenPoints(point1: Coordinate, point2: Coordinate, thickness, color, point1BackOfHeart: boolean = false, point2BackOfHeart: boolean = false, connectedToTarget?: boolean, notConnectedToSource?: boolean, finalTarget: boolean = true, sourceisFree: boolean = false, pointsToTackOn: Coordinate[] = null, isMammoryComingOffY: boolean = false, targetName: TargetType = null, indexIn: number = 0, fromSource: boolean = false)
{

    //let changeToBackHere: Coordinate = null;
    if (this.props.selectedProcedure.name === "PeripheralBypass")
    {
        thickness = thickness /2;
    }
    //if the points are on different side of the heart then need to divert around the heart wall.
    if (point1BackOfHeart !== point2BackOfHeart && connectedToTarget)
    {
        
        //First draw a curved live line to the heart wall then draw a line back to the eventual target.
       
        let isFindingStartingPoint = true;
        let pointOnWall = this.findClosestPointOnHeartWallCircle(point1, point2, !notConnectedToSource, sourceisFree, null, isMammoryComingOffY, isFindingStartingPoint)
        if (pointOnWall)
        {

            let startingfromBack = point1BackOfHeart;

            //Draw a regular curved line from point 1 to wall
            //finalTarget is always true here because we want it to stop here.
            //ROB- (Worth a try but not possible)Instead of stopping here, how about continueing but splitting the drawing up.
            //changeToBackHere = pointOnWall;
            //If we are drawing to the heart wall, tack on one more before the final point just outside the heart.
          
        //     let  pointToTackOnInput = this.findClosestPointOnHeartWallCircle(pointOnWall, pointOnWall, true, true, 30);
        //    pointToTackOnInput.y = pointToTackOnInput.y-20;
        //    pointToTackOnInput.x = pointToTackOnInput.x-20;

           
            let pointsOut = this.generateSemiCircleAroundHeartWall(point1, point2, pointOnWall);

            this.drawCurvedLineBetweenPoints(point1, pointOnWall, thickness, color, startingfromBack, startingfromBack, false, notConnectedToSource, true, sourceisFree, pointsOut, false, targetName, indexIn, fromSource);
            fromSource = false;
            //THen setup parameters to draw from the wall of the heart to point two.
            //This line is behins heart so the opacity or color will be set later on.
            point1 = pointOnWall;
            //If it's starting from the back then it will be on the front when it curves around.
            point1BackOfHeart = !startingfromBack;
            point2BackOfHeart = !startingfromBack;
            notConnectedToSource = true;
            connectedToTarget = true;
        }


    }


 
let opacityToUse = 1;
let SvgToUse = (this.svg as any);
let DashToUse ="";
if (point1BackOfHeart && point2BackOfHeart)
{
    //If both points are behind the heart then drop the opactivity because the graft is behind the heart.
    //opacityToUse = 0.3;
    SvgToUse = (this.svgBack as any);
    DashToUse = "10 4";
}
let lineMidPoint = this.getMidpointBetweenLines(point1, point2, point2BackOfHeart, pointsToTackOn, !notConnectedToSource && sourceisFree);

    var points = [
        [point1.x, point1.y],
        //[point1.x, (point2.y - point1.y)*0.5 + point1.y],
        [lineMidPoint.x, lineMidPoint.y],
        
        [point2.x, point2.y]
    ];

    //If there are points to tack on and the distance between the two points is small then dont add a mid point.
    if (pointsToTackOn && pointsToTackOn.length > 0)
    {
    if (this.getDistanceBetweenPoints(point1, point2) < 300)
    {
        points = [
            [point1.x, point1.y],
       
            
            [point2.x, point2.y]
        ];
    }
    }

    //If its not a vein
    if ( !TargetDetails.getIsVein(color) )
    {
        if (!pointsToTackOn)
        {
        points = this.generateArteryCurves(point1, point2, points);
        }
//     var pointsSet1 = this.generateSquigglyPointsBetween(point1, point2, 40, 0.2, 40, 40);
//     if (point1.x > point2.x)
//     {pointsSet1 = this.generateSquigglyPointsBetween(point2, point1, 40, 0.2, 40, 40);}
//    // pointsSet1.pop();
//    // var pointsSet2 = this.generateSquigglyPointsBetween(midwayPoint, point2, 40, 0.2, 60, 60);
//     if (pointsSet1.length > points.length)
//     points = pointsSet1;//pointsSet1.concat(pointsSet2);
    }
    if (pointsToTackOn)
    {
        let buildList = [];

        for (let index = 0; index < pointsToTackOn.length; index++) {
            const element = pointsToTackOn[index];
            buildList.push([element.x, element.y]);
            points.splice(points.length-1, 0, [element.x, element.y]); 
        }
        
    }



//Draw the circles required first.
    let dataPointsToDraw = [points[0]];
    //Also draw points for reference
    //Only connected to Source should have a large first circle.
    let SourceCircleSize = this.props.targetRadius; //;9;
    if (notConnectedToSource )
    {
        //notConnectedToSource seems to be true when curving around heart wall which causes a small circle draw on the source.
        SourceCircleSize = this.props.jointRadius;//7;
    }


    var lineGenerator = null;
    lineGenerator = d3.line()
    .curve(d3.curveCardinal);

    var selectTargetFromConduit = lineGenerator(points as [number, number][]);
//Always add an invisible path for clicks on top even if it isnæ't the find target to make sure thgeyare differentitated-
(this.svgTop as any).append('path')
.style("fill", "none")
.style("stroke", "black")
//Make it thicker so that it picks up clicks that miss slightly.
.style("stroke-width", thickness + 8)
.style("cursor", "pointer")
.style("opacity", 0)
.attr("class", "graft-drawing")
.attr("data-targetname", targetName)
  .attr('d', selectTargetFromConduit).on("mousedown", ()=>{
      //Assume that from here only the current graft will be editted.
      this.props.editGraftClicked(targetName);
      console.log("TargetSet: "+targetName);});

      if (!notConnectedToSource || !color)
      {
        (this.svgTop as any)
    .data(dataPointsToDraw)
    .append('circle')
        .style("fill", color)
        .style("stroke", color)
        .style("opacity", opacityToUse)  
        .attr("class", "graft-drawing target-location")
        .attr('cx', function(d) {
            return d[0];
        })
        .attr('cy', function(d) {
            return d[1];
        })
        .attr('r', SourceCircleSize)
        .on("mouseover", this.handleTargetMouseOver)
          .on("mouseout", this.handleTargetMouseOut);

          let indexX = dataPointsToDraw[0][0] - 60;
          let indexY = dataPointsToDraw[0][1] + 30;

          if (this.props.showIndices && fromSource)
          {
           
          let widthToUse = 60;
          (this.svgTop as any).append("rect")		// pre-defined shape
          .attr("x", indexX )								// displacement from origin
          .attr("y", indexY )								// displacement from origin
          .attr("rx", 7)								// how much to round corners - to be transitioned below
          .attr("ry", 7)								// how much to round corners - to be transitioned below
          .attr("width", widthToUse)						// size of shape
          .attr("height", widthToUse)
          .attr('class', 'heart-index heart-index-box')
          (this.svgTop as any)
          .append("text")
          .attr('class', 'heart-index heart-index-text')
          .attr("x", indexX + widthToUse/2 - 15)								// displacement from origin
          .attr("y", indexY + widthToUse/2 + 15)	
        
            .text(indexIn);
              
          }

    }
        //Should we draw the ending graft?
         dataPointsToDraw = [points[points.length-1]];
         //Only connected to Target should have a second circle.
        if (connectedToTarget)
        {

            (this.svgTop as any)
        .data(dataPointsToDraw)
        .append('circle')
            .style("fill", color)
            .style("stroke", color)
            .style("opacity", opacityToUse)
            .attr("class", "graft-drawing target-location")
            .attr('cx', function(d) {
                return d[0];
            })
            .attr('cy', function(d) {
                return d[1];
            })
            .attr('r',this.props.jointRadius)
            .on("mouseover", this.handleTargetMouseOver)
          .on("mouseout", this.handleTargetMouseOut);;
        }
        if (this.props.dragDropEnabled)
        {
        d3.selectAll(".target-location")
                    .call(d3.drag().on("start", this.dragTargetStart).on("end", this.dragTargetEnd).on("drag", this.dragTargetMove));
        }

      

//Drawing should only occur if it is the final target. 
if (finalTarget)
{   

    this.currentPointsToDraw = this.currentPointsToDraw.concat(points);
   

    var pathData = lineGenerator(this.currentPointsToDraw as [number, number][]);
//     if (changeToBackHere)
//     {
//         //Split Path data at changeToBackHere
//         if (!point2BackOfHeart)
//         {
//             let firstHalf;
//             let indexIn = -1;
//             for (let index = 0; index < pathData.length; index++) {
//                 const element = pathData[index];
//                 if (changeToBackHere.x === element[0] && changeToBackHere.y === element[1])
//                 {
//                     indexIn = index;
//                 }
//             }
//             if (indexIn > -1)
//             {
//             //If both points are behind the heart then drop the opactivity because the graft is behind the heart.
//             SvgToUse = (this.svgBack as any);
//             DashToUse = "10 4";
          
//             firstHalf = pathData.splice(0, indexIn);
//             pathData = pathData.splice(indexIn);
//             SvgToUse.append('path')
//             .style("fill", "none")
//             .style("stroke", color)
//             .style("stroke-width", thickness)
//             .style("stroke-dasharray", DashToUse)
//             .style("opacity", opacityToUse)
//             .attr("class", "graft-drawing")
//                 .attr('d', firstHalf);
//             SvgToUse = this.svg;
//              DashToUse ="";
//             }
//         }else{
       
//              let firstHalf;
//              let indexIn = -1;
//              for (let index = 0; index < pathData.length; index++) {
//                  const element = pathData[index];
//                  if (changeToBackHere.x === element[0] && changeToBackHere.y === element[1])
//                  {
//                      indexIn = index;
//                  }
//              }
//              if (indexIn > -1)
//              {
//              //If both points are behind the heart then drop the opactivity because the graft is behind the heart.
//              SvgToUse = this.svg;
//              DashToUse ="";
//              firstHalf = pathData.splice(0, indexIn);
//              pathData = pathData.splice(indexIn);
//              SvgToUse.append('path')
//              .style("fill", "none")
//              .style("stroke", color)
//              .style("stroke-width", thickness)
//              .style("stroke-dasharray", DashToUse)
//              .style("opacity", opacityToUse)
//              .attr("class", "graft-drawing")
//                  .attr('d', firstHalf);
//              SvgToUse = (this.svgBack as any);
//              DashToUse = "10 4";
//         }
//     }
// }
   
    if (this.props.graftSelected && this.recurseTargets(this.props.graftSelected, targetName))
    {
        // let newColor = color;
        // if ((color as string).indexOf(")") > 0)
        // {
        //     newColor = (color as string).substr(0, (color as string).indexOf(")")) + "Dark)";
        // }
        SvgToUse.append('path')
        .style("fill", "none")
        .style("stroke", "var(--DarkCantaloupe)")
        .style("stroke-width", thickness+8)
        
        .style("opacity", 0.5)
        .style("cursor", "pointer")
        .attr("class", "graft-drawing")
        // .style("filter", "brightness(35%)")
        .attr("data-targetname", targetName).attr('d', pathData);
    }
    
    SvgToUse.append('path')
    .style("fill", "none")
    .style("stroke", color)
    .style("stroke-width", thickness)
    .style("stroke-dasharray", DashToUse)
    .style("opacity", opacityToUse)
    .style("cursor", "pointer")
    .attr("class", "graft-drawing")
    .attr("data-targetname", targetName).attr('d', pathData);


        if (this.props.drawDebugLine)
        {
            SvgToUse.selectAll(null)
        .data(this.currentPointsToDraw)
.enter()
        .append('circle')
    
            .style("fill", "purple")
            .style("stroke", "purple")
            .style("opacity", 1)
            .attr("class", "debug-line")
            .attr('cx', function(d) {
                return d[0];
            })
            .attr('cy', function(d) {
                return d[1];
            })
            .attr('r', 2);

            SvgToUse.selectAll(null).data(this.currentPointsToDraw)
.enter()
        .append('text')
    
            .style("fill", "red")
            .style("stroke", "red")
            .style("opacity", 1)
            .attr("class", "debug-line")
            .attr('dx', function(d) {
                return d[0];
            })
            .attr('dy', function(d) {
                return d[1];
            })
            .text(function(d, index) {
                return index;
            });
        }
        //Clear out the old data points.
        this.currentPointsToDraw = [];

//         if (color !== "var(--Bluejay)")
//         {
//     SvgToUse.append('path')
//     .style("fill", "none")
//    // .style("stroke-dasharray", "10 45")
//     .style("stroke", color)
//    .style("stroke-width", thickness +4)
//     .style("opacity", opacityToUse)
//     .attr("class", "graft-drawing")
//         .attr('d', pathData);
//         }
}
else{
  
    //otherwise if there are more targets to come then add the points to a list that will eventually draw all together.
    //First remove the last point because it is the starting point of the next line.
    points.pop();
    this.currentPointsToDraw = this.currentPointsToDraw.concat(points);
}

      
}
      componentDidMount = () => {

        let thisHolder = this;

        if (window.matchMedia)
        {
            var mediaQueryList = window.matchMedia('print');
            if (mediaQueryList)
            {
                mediaQueryList.addListener(function(mql) {
                if (mql.matches) {
                   // console.log('onbeforeprint equivalent');
                    thisHolder.reportWindowSize();
                }
                });
            }
        }

        this.reportWindowSize();

        window.onresize = this.reportWindowSize;


    }

    relativedragStartPoint: Coordinate;
    relativedragMovePoint: Coordinate;
    draggingTarget: TargetType;

    identifyDraggingTarget = (pointIn: Coordinate): TargetType =>
    {
       let distanceToPoint = 100;
       let pointToReturn: TargetType;
        for (let index = 0; index < Object.keys(TargetDetails.targetCoords).length; index++) {
            let TargetName = Object.keys(TargetDetails.targetCoords)[index];

            const TargetDetail = TargetDetails.getTargetDetail(TargetName);
            if (TargetDetail && TargetDetail.vascular)
            {
                let newTargetName = TargetName + "-LEFT";
                //Do Left
                const TargetPoint = TargetDetails.getTargetCoords(this, newTargetName);
                        
                let xIn = pointIn.x - TargetPoint.x;
                let yIn = pointIn.y - TargetPoint.y;
            
                let distanceBetweenPoints = Math.sqrt(xIn*xIn + yIn*yIn);

                if (distanceBetweenPoints < distanceToPoint)
                {
                    pointToReturn = newTargetName as TargetType;
                    console.log("switch to: " + newTargetName + " - " + distanceBetweenPoints)
                    distanceToPoint = distanceBetweenPoints;
                }
                //And then Right
                TargetName = TargetName + "-RIGHT";
            }
                    const TargetPoint = TargetDetails.getTargetCoords(this, TargetName);
                        
                        let xIn = pointIn.x - TargetPoint.x;
                        let yIn = pointIn.y - TargetPoint.y;
                    
                        let distanceBetweenPoints = Math.sqrt(xIn*xIn + yIn*yIn);

                        if (distanceBetweenPoints < distanceToPoint)
                        {
                            pointToReturn = TargetName as TargetType;
                            console.log("switch to: " + TargetName + " - " + distanceBetweenPoints)
                            distanceToPoint = distanceBetweenPoints;
                        }
        

            
            
            
        }
        //If the point to return is null then no point exists in 100 so return null
        if (pointToReturn)
        {
            if (pointToReturn.indexOf("-LEFT") > -1 || pointToReturn.indexOf("-RIGHT") > -1)
            {
                TargetDetails.targetCoords[pointToReturn] = TargetDetails.getTargetCoords(this, pointToReturn);
            }
        }
        return pointToReturn;
    }

    dragTargetStart = (event) =>
    {
        this.relativedragStartPoint = {
            x: event.x,
            y: event.y
        };
        this.relativedragMovePoint = {
            x: event.x,
            y: event.y
        };
        this.draggingTarget = this.identifyDraggingTarget(this.transferFromScreenSize(this.relativedragStartPoint));
        
        console.log("dragTargetStart: "+this.draggingTarget);
       
    }


    dragTargetMove = (event) =>
    {
       if (this.relativedragStartPoint && this.draggingTarget)
       {
            let xTransform = event.x - this.relativedragMovePoint.x;
            let yTransform = event.y - this.relativedragMovePoint.y;
            this.relativedragMovePoint = {
                x: event.x,
                y: event.y
            };
            TargetDetails.targetCoords[this.draggingTarget].x = TargetDetails.targetCoords[this.draggingTarget].x + xTransform;
            TargetDetails.targetCoords[this.draggingTarget].y = TargetDetails.targetCoords[this.draggingTarget].y + yTransform;
            this.redrawConduitsSelected();
        }
    }

     handleTargetMouseOver(d, i) 
    { 

        // console.log("handleTargetMouseOver");
        // console.log(d);
        // console.log(this);
        // console.log(i);
        // Add interactivity

        // Use D3 to select element, change color and size
        let existingRadius = d3.select(this as any).attr("r") as any;
        d3.select(this as any).attr("fill", "orange").attr("r", existingRadius * 1.5);
    }

    handleTargetMouseOut(d, i)
    {  
        
        //d3.select(this as any).attr("r", 6);
        let existingRadius = d3.select(this as any).attr("r") as any;
        d3.select(this as any).attr("r", existingRadius / 1.5);
        // console.log("handleTargetMouseOut");
        // console.log(d);
        // console.log(i);
        // Add interactivity

        // Use D3 to select element, change color and size
        // d3.select(this).attr({
        //   fill: "orange",
        //   r: radius * 2
        // });
    }


    dragTargetEnd = () =>
    {
        console.log("COPY THIS FOR TARGET DATA:");
        console.log(JSON.stringify(TargetDetails.targetCoords));
        this.relativedragStartPoint = null;
        this.relativedragMovePoint = null;
    }


    findOnCurve =(dataIn, x, typeCall =1) => {
        
        let interp =  d3interpolation.interpolateCardinal(dataIn.map(function(p) {
            return p[typeCall];
        }));

        return interp(x);
    }


    drawGraftOnDiagram = (currentGraft : Graft, parentConduit: Conduit, parentTarget: string, parentSource: string, sourceLocation?: TargetLocation, parentIsSource?: boolean, _isMammoryComingOffY?: boolean, sourceisFree?: boolean, indexIn: number = 0, fromSource: boolean = false): TargetLocation =>{
      //console.log("drawGraftOnDiagram - "+ currentGraft  +", " + parentConduit  +", " + parentTarget   )
      let locationToReturn: TargetLocation = sourceLocation; 
      let SourceName: string = parentConduit;
      if (currentGraft.Type === "PeripheralGraft")
        SourceName = "";
        let parentIsSourceForChild = parentIsSource;
        let isMammoryComingOffYForChild = _isMammoryComingOffY;
        let drawingStartPoint: TargetLocation =null;
        let conduitCurrent = parentConduit;
        //let parentConduitStorage = parentConduit;
        let IsSequential = true;
        //When conduit is set it is a Y graft.
        //When COnduits are the same they could still be Y grafts.
        if (currentGraft.Sequential === undefined)
        {
            //By default grafts are sequential.
            currentGraft.Sequential = true;
        }

        if (!currentGraft.Sequential)// <--- Only when Conduit is null is it sequential?
        {
            // If it has a different conduit then it is a Y graft so the source is different.
           // SourceName = parentTarget;
            IsSequential = false;
            conduitCurrent = (currentGraft.Conduit ? currentGraft.Conduit + (currentGraft.inSitu ? "" : "FREE") as any : currentGraft.Conduit);
            
            //For Y grafts, to draw in the mid point, find it between the parent Target and the PreviousSibling.
            SourceName = parentTarget;

            //Set the parent target to null as well for the drawing of the Y.
            parentTarget = null;
           
            if (!parentSource)
                parentSource = parentConduit;
                
            if (!SourceName)
                SourceName = parentConduit + "END";
                //If there is a previous sibling and we are using the same conduit then the source is the previous
                // if (grandParent)// && currentGraft.Conduit === parentConduit)
                // {
                //     SourceName = grandParent;
                //   //  parentTarget = null;
                // }else
                // {
                //     //If there is no previous sibling lets just default to the parentTarget;
                //     SourceName = parentTarget;
                // }

        }
        else{

            if (currentGraft.Conduit + "FREE" !== conduitCurrent)
            {
                conduitCurrent = (currentGraft.Conduit ? currentGraft.Conduit : conduitCurrent);
            }
           
            
            //If it is not sequential switch parentisSource to false
             if (parentConduit !== SourceName)
                parentIsSourceForChild = false;
             // If it has the same conduit it is sequential so the previous target becomes the source.
             if (parentTarget)
                SourceName = parentTarget;
                
        }
        let CurrentGraftTargetName: any = currentGraft.Name ;
        if (currentGraft.Type === "PeripheralGraft")
        {
            if ((currentGraft as VascularGraft).GraftPoint)
            {
                if (!SourceName)
                {
                    SourceName = (currentGraft as VascularGraft).GraftPoint + "-" + (currentGraft as VascularGraft).hemisphere;
                }
            //SourceName = (currentGraft as VascularGraft).GraftPoint + "-" + (currentGraft as VascularGraft).hemisphere;
            CurrentGraftTargetName = (currentGraft as VascularGraft).GraftPoint + "-" + (currentGraft as VascularGraft).hemisphere;
             
        }
        }
        if (SourceName)
        {

            
           

                                    //If the graft has a valid target 
           let firstTargetIsOnLeft = CurrentGraftTargetName &&
                                    //and we are directly from the source
                                     SourceName === parentConduit && 
                                    //And the target is on the left side (Stage left) of the heart.
                                    TargetDetails.getTargetCoords(this, CurrentGraftTargetName) && TargetDetails.getTargetCoords(this, CurrentGraftTargetName).x > this.findHeartCenter().x;
        drawingStartPoint = this.transferToScreenSize(TargetDetails.getTargetCoords(this, SourceName,  firstTargetIsOnLeft));

        let SourceBackOfHeart = TargetDetails.getTargetCoords(this, SourceName).backOfHeart;
        // let endOnHeartWall = false;
        if ((CurrentGraftTargetName && (currentGraft.Type !== "PeripheralGraft" || ((currentGraft.Targets && currentGraft.Targets.length > 0) || !fromSource))  )  || currentGraft.Type === "Conduit")
        {

            let previousLineStart = TargetDetails.getTargetCoords(this, parentSource);
            let previousLineEnd = TargetDetails.getTargetCoords(this, SourceName);
         
            if (!IsSequential)
            {
               
                if (previousLineStart && previousLineEnd)
                {  
                    previousLineStart = this.transferToScreenSize(previousLineStart);
                    previousLineEnd = this.transferToScreenSize(previousLineEnd);
                }
                //if it is not sequential then we want to draw from somewhere on the conduit line instead of on the source.
                if (parentConduit)
                {
                    //If the parent conduit was set then it was not sequential so the parent line was drawn from a different source location if given.
                    if (sourceLocation)
                        previousLineStart = sourceLocation;
                }
                   
                    let tackedOnPoints: Coordinate[] = null;
                    //If it is a mammory and coming off a Y graft then set this to true.                
                    if (TargetDetails.getTargetCoords(this, parentSource) && TargetDetails.getTargetCoords(this, parentSource).isMammary)
                        isMammoryComingOffYForChild = TargetDetails.getTargetCoords(this, parentSource).isMammary;
                    //Look between source and this target that has become the source                   
                    
                    if (previousLineStart && previousLineEnd && previousLineStart.backOfHeart as boolean !==  previousLineEnd.backOfHeart as boolean)
                    {
                      
                        //If the previous conduit start and end are on opposite sides of the heart then the line curves around the heart wall.

                        //First define the first segment and then the second segment.
                        //First segment goes from target to heart wall.
                       
                       
                    
                        let isFindingStartingPoint = true;
                       
                        var pointOnHeartWall = this.findClosestPointOnHeartWallCircle(previousLineStart, previousLineEnd, parentIsSource, sourceisFree, null, _isMammoryComingOffY, isFindingStartingPoint);
                      
                        if (!CurrentGraftTargetName || previousLineStart.backOfHeart as boolean === TargetDetails.getTargetCoords(this, CurrentGraftTargetName).backOfHeart as boolean)
                        {
                           //If it curves around the heart wall there will be a circle so we should y off the piece between it and the heart.
                        
                            tackedOnPoints = this.generateSemiCircleAroundHeartWall(previousLineStart, previousLineEnd,  pointOnHeartWall);
                            previousLineEnd = pointOnHeartWall;
                            SourceBackOfHeart = previousLineStart.backOfHeart;
                            // endOnHeartWall = true;

                        }else if ( previousLineEnd.backOfHeart as boolean === TargetDetails.getTargetCoords(this, CurrentGraftTargetName).backOfHeart as boolean)
                        {
                            
                                 //If it ends on the same side of the heart as the end target then draw from the piece going from the heart wall.
                                 previousLineStart = pointOnHeartWall;
                                 
                                 SourceBackOfHeart = previousLineEnd.backOfHeart;
                                 //Parent is no longer source because we are starting from the eart wall.
                                 parentIsSource = false;
                        }
                        


                    }
                    if (previousLineStart && previousLineEnd)
                    {
                        // if (tackedOnPoints)
                        // {
                        //     for (let index = 0; index < tackedOnPoints.length; index++) {
                        //         tackedOnPoints[index] = this.transferToScreenSize(tackedOnPoints[index]);
                        //     }
                        // }

                        let lineMidpoint = this.getMidpointBetweenLines(previousLineStart, previousLineEnd, previousLineEnd.backOfHeart, tackedOnPoints, parentIsSource && sourceisFree);
                        // if (tackedOnPoints)
                        // {
                        //     //If there are points to tack on then use the first point on the semi circle to find the midpoint.
                        //     previousLineEnd = tackedOnPoints[0];
                        // }
                        var points = [
                            [previousLineStart.x, previousLineStart.y],
                            //[point1.x, (point2.y - point1.y)*0.5 + point1.y],
                            [lineMidpoint.x, lineMidpoint.y],
                            [previousLineEnd.x, previousLineEnd.y]
                        ];
                        //If there are points to tack on and the distance between the two points is small then dont add a mid point.
                        if (tackedOnPoints && tackedOnPoints.length > 0)
                        {
                        if (this.getDistanceBetweenPoints(previousLineStart, previousLineEnd) < 300)
                        {
                            points = [
                                [previousLineStart.x, previousLineStart.y],
                                [previousLineEnd.x, previousLineEnd.y]
                            ];
                        }
                    }
                
                  
                   
                    //Take away free just in case.
                    if ( parentConduit && (!TargetDetails.getIsVein(TargetDetails.getTargetDetail(parentConduit.replace("FREE", "")).color) ) )
                    {
                        points = this.generateArteryCurves(previousLineStart, previousLineEnd, points);
    
                    }
                    if (tackedOnPoints)
                    {
                        let buildList = [];
                        for (let index = 0; index < tackedOnPoints.length; index++) {
                            const element = tackedOnPoints[index];
                            buildList.push([element.x, element.y]);
                            points.splice(points.length-1, 0, [element.x, element.y]); 
                        }
                        
                    }

                    //----------------Uncomment below to find strange Y graft locations----------------
                //     var lineGenerator = null;
                //     lineGenerator = d3.line()
                //     .curve(d3.curveCardinal);
                
                //     var selectTargetFromConduit = lineGenerator(points as [number, number][]);
                // //Always add an invisible path for clicks on top even if it isnæ't the find target to make sure thgeyare differentitated-
                // (this.svgTop as any).append('path')
                // .style("fill", "none")
                // .style("stroke", "pink")
                // //Make it thicker so that it picks up clicks that miss slightly.
                // .style("stroke-width", 8)
                // .style("cursor", "pointer")
                // .style("opacity", 1)
                // .attr("class", "graft-drawing")
                //   .attr('d', selectTargetFromConduit);
                //-------------------------------------------------------------------------------------------

                
                    //For this to work we need to find the closest y point in points to the yFound in order to find the x otherwise no dice.
                    let yFound = this.findOnCurve(points, this.props.yLocationOnLine);
                    //Now find the matching x on the curve.
                    let xFound = this.findOnCurve(points, this.props.yLocationOnLine, 0);
                    if (this.getDistanceBetweenPoints({x: xFound, y: yFound},{x: points[0][0], y: points[0][1]}) < 150)
                    {
                        //IF the Y is too close to the start then move it further from the point.
                        yFound = this.findOnCurve(points, this.props.yLocationOnLine*2);
                        xFound = this.findOnCurve(points, this.props.yLocationOnLine*2, 0);
                    }
                  // let xFound = this.findClosestXOnCurve
                    drawingStartPoint = {x: xFound, y: yFound, backOfHeart: SourceBackOfHeart };
                }
                // }

            }

            let TargetCoordsIn: TargetLocation;
            //If the current graft is a target
            if (CurrentGraftTargetName)
            {
                this.drawTargetLine(CurrentGraftTargetName);
                TargetCoordsIn = TargetDetails.getTargetCoords(this, CurrentGraftTargetName);
            }else
            {
                // or it's a conduit with no targets yet
                if (currentGraft.Targets  && currentGraft.Targets.length === 0)
                {
                TargetCoordsIn = TargetDetails.getTargetCoords(this, parentConduit + "YEND", false,  drawingStartPoint);
                if (!TargetCoordsIn)
                {
                    TargetCoordsIn = TargetDetails.getTargetCoords(this, "AORTAYEND");
                }
            }
            }
              
            if (TargetCoordsIn)
            {
                //if it has no more sequential targets it is the final target.
                let finalTarget = true;
                if (currentGraft.Targets  && currentGraft.Targets.length > 0)
                {
                    //Check for sequential targets
                    for (let index = 0; index < currentGraft.Targets.length; index++) {
                        const element = currentGraft.Targets[index];

                        if ((element.Sequential || element.Sequential === undefined) && (!element.Conduit || currentGraft.Conduit === element.Conduit  ))
                        {
                            //If any element is sequential it is not the final target unless it has a different conduit.
                            finalTarget = false;
                            break;
                        }
                        
                    }
                }
                // if (conduitCurrent)
                // {
                    this.drawCurvedLineBetweenPoints(drawingStartPoint, 
                    this.transferToScreenSize( 
                        TargetCoordsIn),
                        conduitCurrent && !TargetDetails.getIsVein(TargetDetails.getTargetDetail(conduitCurrent).color) ? this.props.arteryWidth :  this.props.veinWidth, 
                        conduitCurrent ? TargetDetails.getTargetDetail(conduitCurrent).color : null,
                    SourceBackOfHeart,
                    TargetCoordsIn.backOfHeart,
                    currentGraft.Type !== "Conduit", false,
                        finalTarget,
                    sourceisFree, null, isMammoryComingOffYForChild, CurrentGraftTargetName, indexIn, fromSource);
                // }
            }

        }else
        {

                //If it's Source then just draw it's starting point if it has no targets or the targets are all sources
                let ChildrenWithTargetSet = 0;
                if (currentGraft.Targets && currentGraft.Targets.length > 0)
                {
                    currentGraft.Targets.forEach((element)=>
                    {
                        if (element.Sequential)
                            ChildrenWithTargetSet++;
                    });
                }
                if (!currentGraft.Targets || ChildrenWithTargetSet === 0)
                {
                    if (currentGraft.Type !== "PeripheralGraft" || !conduitCurrent)
                    {
                    if (TargetDetails.getTargetCoords(this, SourceName + "END"))
                    {
                        var isFree = currentGraft.inSitu !== true;
                        //If there's an end marker then the conduit needs to be drawn as a starting point.

                        this.drawCurvedLineBetweenPoints(drawingStartPoint, this.transferToScreenSize( TargetDetails.getTargetCoords(this, SourceName + "END")), conduitCurrent && !TargetDetails.getIsVein(TargetDetails.getTargetDetail(conduitCurrent).color) ? this.props.arteryWidth :  this.props.veinWidth, conduitCurrent ? TargetDetails.getTargetDetail(conduitCurrent).color : null, false, false, false, false, true, isFree, null, false, null, indexIn,  currentGraft.Type === "Source" );
                    }
                }
                }

        }
    }

        //If it has targets then loop through them to draw.
        if (currentGraft.Targets)
        {
            let indexTarget = 0;
            let previousConduit = conduitCurrent;
            let previousGraft: Graft = {};
        currentGraft.Targets.forEach((childGraft) => {
            parentTarget = CurrentGraftTargetName;
            //drawing starting point is necessary for recursive Y drawing.
            //If we have reached this point the parent is not the source so pass through false.
            if (previousGraft && previousGraft.Sequential && !childGraft.Sequential && currentGraft.Type==="Source")
            {
                //If it's a non sequential after a sequential then Y it off the last one if it's parent is the source.
                parentTarget = previousGraft.Name as any;
            }
            this.drawGraftOnDiagram(childGraft, previousConduit, parentTarget, currentGraft.Conduit as Conduit, drawingStartPoint, parentIsSourceForChild, isMammoryComingOffYForChild, sourceisFree, indexIn, currentGraft.Type==="Source" && indexTarget === 0);
            indexTarget++;
            previousGraft = childGraft;
        });
        }
        return locationToReturn;
    }

    componentDidUpdate = ()=>
    {
        this.redrawConduitsSelected();

    }
    redrawConduitsSelected = () =>
    {
        let CurrentConduit;
        (this
        .svg as any)
        .selectAll("path.graft-drawing")
        .remove();
        (this
        .svgBack as any)
        .selectAll("path.graft-drawing")
        .remove();
        (this
        .svg as any)
        .selectAll("circle.graft-drawing")
        .remove();
        (this
        .svgBack as any)
        .selectAll("circle.graft-drawing")
        .remove();
        (this
        .svgTop as any)
        .selectAll("circle.graft-drawing")
        .remove();
        (this
        .svgTop as any)
        .selectAll("path.graft-drawing")
        .remove();
        (this
        .svgTop as any)
        .selectAll(".heart-index")
        .remove();
        (this
        .svg as any)
        .selectAll("circle.discrete-drawing")
        .remove();
           (this
        .svg as any)
        .selectAll("path.discrete-drawing")
        .remove();

        (this
        .svgBack as any)
        .selectAll("circle.discrete-drawing")
        .remove();
           (this
        .svgBack as any)
        .selectAll("path.discrete-drawing")
        .remove();

        (this
        .svg as any)
        .selectAll(".debug-line")
        .remove();
        (this
        .svgTop as any)
        .selectAll(".debug-line")
        .remove();
        (this
        .svgBack as any)
        .selectAll(".debug-line")
        .remove();
 //First draw all source grafts in grey.
 //Not longer draw grey sources
//  Object.keys(Conduit).forEach((sourceName) => {
//     //Check that none of the source grafts in grey are used as top level conduits.
//     let conduitUsedAsSource: boolean = false;
//     for (let index = 0; index < this.props.GraftsLoaded.length; index++) {
//         const element = this.props.GraftsLoaded[index];
//         if (element.Conduit === sourceName)
//             conduitUsedAsSource = true;
        
//     }
//     if (TargetDetails.getTargetDetail(sourceName).isSource && (this.props.GraftsLoaded.length > 0 && !conduitUsedAsSource))
//     {
//         this.drawCurvedLineBetweenPoints(this.transferToScreenSize(TargetDetails.getTargetCoords(this, sourceName)), this.transferToScreenSize( TargetDetails.getTargetCoords(this, sourceName + "END")), this.props.arteryWidth, "var(--seal)", false, false , false, false );
//     }

//     }); 


        let PreviousGroupId = "";
        //let indexIn: number = 0;
        for (let index = 0; index < this.props.GraftsLoaded.length; index++) {
         
            const graftCurrent = this.props.GraftsLoaded[index];
            if (graftCurrent.Type !== "Target")
            {
       
        

            CurrentConduit = (graftCurrent.Conduit ? graftCurrent.Conduit + (graftCurrent.inSitu ? "" : "FREE") : graftCurrent.Conduit);
            if (graftCurrent.Type === "PeripheralGraft")
            {
                CurrentConduit = (graftCurrent as VascularGraft).Material;
            }
            let sourceIsFree = !graftCurrent.inSitu;

            //This refers to targetCoords directly rather than getTargetCoords because it needs the isConduit that may be lost using the method with location transform.
            if (TargetDetails.targetCoords[CurrentConduit] && TargetDetails.targetCoords[CurrentConduit].isConduit)
            {
                if (PreviousGroupId !== graftCurrent.GroupId)
                {
                    //Only increment target offsets if it is a new group.
                    if (PreviousGroupId)
                    //Dont do it for the first one.
                        this.targetYOffsets[CurrentConduit] = this.targetYOffsets[CurrentConduit] + 16;
                    PreviousGroupId = graftCurrent.GroupId;
                }
                //The source is free should only be determined at the very top
                //sourceIsFree = this.targetCoords[CurrentConduit].isFree;
            }
            
            this.drawGraftOnDiagram(graftCurrent, CurrentConduit, null, null, null, true, false, sourceIsFree, index+1, true);
           
        }
    }
        //Clear the offsets
        this.targetYOffsets = {
            "LITA": 0,
            "RITA": 0,
            "RA": 0,
            "SVG": 0,
            "GEA": 0,
            "RCA": 0
        };

    }

    reportWindowSize = () => {
        d3.select("#HeartDrawingOverlayTop-"+this.props.Context).select("svg").remove();
        (this.svgTop as any) = d3.select("#HeartDrawingOverlayTop-"+this.props.Context).append("svg");
        (this.svgTop as any).style("height", "100%").style("width", "100%");

        d3.select("#HeartDrawingOverlay-"+this.props.Context).select("svg").remove();
        (this.svg as any) = d3.select("#HeartDrawingOverlay-"+this.props.Context).append("svg");
        (this.svg as any).style("height", "100%").style("width", "100%");
     

        d3.select("#HeartDrawingOverlayBack-"+this.props.Context).select("svg").remove();
        (this.svgBack as any) = d3.select("#HeartDrawingOverlayBack-"+this.props.Context).append("svg");
      
        (this.svgBack as any).style("height", "100%").style("width", "100%");

     
        this.redrawConduitsSelected();

    }
    MouseDownIn =(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    {
        console.log("clientX: " + e.clientX);
        console.log("clientY: " + e.clientY);
        console.log("clientX: " + (e.clientX - (document.getElementById("HeartDrawingOverlay-"+this.props.Context) as HTMLElement).getBoundingClientRect().x));
        console.log("clientY: " + (e.clientY - (document.getElementById("HeartDrawingOverlay-"+this.props.Context) as HTMLElement).getBoundingClientRect().y));
    }
    ImageOverlays: React.ReactFragment[];
    ImageOverlaysBack: React.ReactFragment[];
    recurseTargetsAndDrawImageOverlay = (graftToCheck: Graft) =>
    {
        if (graftToCheck.Type === "Target")
        {
            if (graftToCheck.Name !== "CUSTOM")
            {
                if (!TargetDetails.getTargetCoords(this, graftToCheck.Name).backOfHeart)
                this.ImageOverlays.push(<img className="heart-target-image"src={"/"+graftToCheck.Name+".png"} alt=""></img>);
                else
                this.ImageOverlaysBack.push(<img className="heart-target-image"src={"/"+graftToCheck.Name+".png"} alt=""></img>);
            }
        }
        if (graftToCheck.Type === "PreGraft")
        {
          
                // if (!TargetDetails.getTargetCoords(this, graftToCheck.Name).backOfHeart)
                this.ImageOverlays.push(<img className="heart-target-image"src={"/aorta.png"} alt=""></img>);
                for (let index = 0; index < this.props.AllGrafts.length; index++) {
                    const element = this.props.AllGrafts[index];
                    this.recurseTargetsAndDrawImageOverlay(element);   
                }
                // else
                // this.ImageOverlaysBack.push(<img className="heart-target-image"src={"/aorta.png"} alt=""></img>);
            
        }
        if (graftToCheck.Targets && graftToCheck.Targets.length > 0)
        {
            graftToCheck.Targets.forEach(element => {
                this.recurseTargetsAndDrawImageOverlay(element);
            });

        }

    }

    generatePointsInCircumference = (centerPoint: Coordinate, radius: number, squashFactor: number = 0, xdeadZone: number = 0) =>
    {
        let pointsOut: Array<Coordinate> = [];
        let previousYValue = 0;

      


        for (let index = 0; index < 359; index++) {
            const radian = index*Math.PI/180;

            let xOffset = radius*Math.cos(radian);
            let yOffset = radius*Math.sin(radian);
            if (xOffset > squashFactor)
                xOffset = xOffset - squashFactor;
            if (xOffset < -squashFactor)
                xOffset = xOffset + squashFactor;
            //             (----|x Dead Zone|------)
            //Dont apply dead zone at bottom of heart (ie. between 90 and 270 degrees )
            let deadZoneWidthAngle = xdeadZone/(radius*2) * 180;//Math.acos((xdeadZone/2)/radius)*180/Math.PI;
            let deadZoneStart = (180 - deadZoneWidthAngle)/ 2;
            let deadZoneEnd = 180 - deadZoneStart;

            if (index < deadZoneStart-1)
                previousYValue = centerPoint.y+yOffset;
            if (Math.abs(xOffset) > xdeadZone || (index > deadZoneStart && index < deadZoneEnd) )
            {
              
            let positionInScreen = {x: centerPoint.x + xOffset, y: centerPoint.y+yOffset};//this.transferToScreenSize();
            if (Math.abs(xOffset) < xdeadZone )
            {
                positionInScreen.y = previousYValue-40;
            }
            if (positionInScreen.x > this.findHeartCenter().x)
            {
                positionInScreen.y = positionInScreen.y +20;
            }
            pointsOut.push(positionInScreen);
            if (this.props.drawDebugLine)
            {
            (this.svg as any)
            .data([[positionInScreen.x, positionInScreen.y]])
            .append('circle')

                .style("fill", "green")
                .style("stroke", "green")
                .style("opacity", 1)
                .attr("class", "debug-line")
                .attr('cx', function(d) {
                    return d[0];
                })
                .attr('cy', function(d) {
                    return d[1];
                })
                .attr('r', 2);
            }
        }
        }
        return pointsOut;
    }


//Point2 added so it can find the point on the heart wall that is the shortest distance between both points.
    findClosestPointInList = (coordsIn: Array<Coordinate>, pointIn: Coordinate, point2: Coordinate, comingDirectFromSource: boolean = false): Coordinate =>
    {
        var indexLowestFound = 0;
        var lowestDistance = -1;
        for (let index = 0; index < coordsIn.length; index++) {
            const element = coordsIn[index];

            //Calculate distance between points using pythagorus
            let xTriag = (element.x-pointIn.x);
            let yTriag = (element.y-pointIn.y);
            let distanceBetweenPoints = Math.sqrt(xTriag*xTriag + yTriag*yTriag);

            let xTriag2 = (element.x-point2.x);
            let yTriag2 = (element.y-point2.y);
            let distanceBetweenPoints2 = Math.sqrt(xTriag2*xTriag2 + yTriag2*yTriag2);
            // (Increase this counter everytime this total distance changes)
            //COUNTER: 2
            let totalDistance = distanceBetweenPoints + distanceBetweenPoints2 ;
            // 2020/07/03 - Rob - I am commenting this out because for LITA->OM2 it goes to the wrong side of the heart.
            // 2020/07/03 - Now adding it back but changed coming direct from source to only be set for when the source is on the AORTA.
            // If it is coming directly from the source then always go to the side of the heart closest to the target/ point 2.
            if (comingDirectFromSource)
                totalDistance = distanceBetweenPoints2;
            if (lowestDistance === -1 || totalDistance < lowestDistance)
            {
                indexLowestFound = index;
                lowestDistance = totalDistance;
            }

        }
        if (indexLowestFound === -1)
        {
            indexLowestFound = 0;
        }
        if (coordsIn[indexLowestFound])
        {
            coordsIn[indexLowestFound].angle = indexLowestFound;
            return coordsIn[indexLowestFound]; 
        }else {
            return pointIn;
        }

    }

    //Aum, plug in to your heart center Yogi.
    findHeartCenter = (): Coordinate =>
    {
        let xOffset = this.props.heartxOffset;
        if (document.getElementsByClassName("heart-canvas-"+this.props.Context).length > 0 )
        {
        let rectCanvas = document.getElementsByClassName("heart-canvas-"+this.props.Context)[0].getBoundingClientRect();
        //If we are in Quick Definitions looks for the x value of the parent and apply it as well
        if (this.props.Context === "Imaging" || this.props.Context === "TTFM")
        {
            xOffset = this.props.heartxOffset - document.getElementsByClassName("alternative-grafts-container")[0].getBoundingClientRect().left;
        }
        let xValue = rectCanvas.width/2 + rectCanvas.left + xOffset;

        let yValue = rectCanvas.height/2 + window.scrollY;
        return {x: xValue, y: yValue};
    }
    return {x: 0, y: 0};
    }

    //Point2 added so it can find the point on the heart wall that is the shortest distance between both points.
    findClosestPointOnHeartWallCircle=(pointIn: Coordinate, point2: Coordinate, comingDirectFromSource: boolean = false, isisFree: boolean = false, radiusInOverride: number = null, inSituMamory: boolean = false, findingPointForY: boolean = false): Coordinate=>
    {
        //Get the center of the canvas
        if (document.getElementsByClassName("heart-canvas-"+this.props.Context)[0])
        {
           
            let horizontalSquashFactor = 15;
               //Assumed heart circumference is 200.


            let radiusIn = this.props.radiusHeart;
            if (radiusInOverride)
            {
                radiusIn = radiusIn + radiusInOverride;
            }
            let deadZoneWidth =190;
            if (document.getElementsByClassName("heart-canvas-"+this.props.Context).length > 0 )
            {
                //horizontalSquashFactor = (document.getElementsByClassName("heart-canvas-"+this.props.Context)[0].getBoundingClientRect().height*this.aspectRatio)*0.3;
                radiusIn = (document.getElementsByClassName("heart-canvas-"+this.props.Context)[0].getBoundingClientRect().height/this.aspectRatio)/2;
                horizontalSquashFactor = radiusIn * 0;
                deadZoneWidth = radiusIn * 2 * 0.3;
                
                // (document.getElementsByClassName("heart-canvas-"+this.props.Context)[0].getBoundingClientRect().height/this.aspectRatio)*0.35;
            }

           
            let heartCenter = this.findHeartCenter();
            if (this.props.drawDebugLine)
            {
            (this.svg as any)
            .data([[heartCenter.x, heartCenter.y]])
            .append('circle')

                .style("fill", "black")
                .style("stroke", "yellow")
                .style("opacity", 1)
                .attr("class", "debug-line")
                .attr('cx', function(d) {
                    return d[0];
                })
                .attr('cy', function(d) {
                    return d[1];
                })
                .attr('r', 2);
            }
     
            let circumPoints = this.generatePointsInCircumference(heartCenter, radiusIn, horizontalSquashFactor,  deadZoneWidth);

            //Make a copy so it does't change possible passing by ref in js
            //Mamory rule for Y graft to back of heart.
            let tempPoint1 = { ...pointIn };
            if (inSituMamory && findingPointForY)
                tempPoint1.y = point2.y;
            return this.findClosestPointInList(circumPoints, tempPoint1, point2, comingDirectFromSource && isisFree);
        }
        return null;
    }

    recurseConduits = (currentGraft: Graft, conduitToCheckFor: Conduit, remove?: boolean, goToBottom?: boolean, ignoreDraft: boolean = false) => 
    {
        
      if (currentGraft.Conduit === conduitToCheckFor && (!ignoreDraft || !currentGraft.Draft))
        {
            if (goToBottom)
            {
                let itemFound =this.recurseSequential(currentGraft);
                this.setRecurseGraft(itemFound);
                return true;
            }else
            {
                this.setRecurseGraft(currentGraft);
                return true;
            }
          
        }
    
        if (currentGraft.Targets && currentGraft.Targets.length > 0)
    {
      let isFound = false;
      for (let index = 0; index < currentGraft.Targets.length; index++) {
        const element = currentGraft.Targets[index];
    
        if (remove)
          {
            if (element.Conduit === conduitToCheckFor && (!ignoreDraft || !element.Draft))
            {
              this.graftRemoved = element;
                     //if the target was found, it means it has been removed and so we need to set it's parent as the active one.
                     //HAAKON???? parent cannot be set to parent, should be set to the previous last graft.
                       //Set the previous to the last graft clicked before it changes.
              
                       //If the previous item selected hasns't been set or it doesn't exist anymore then set the parent graft as the last graft.
              
    
              //Take the parent as the next item in the default focus tree.
              //this.previousGraftClicked = currentGraft;
              currentGraft.Targets.splice(index, 1);
              this.setRecurseGraft(element);
              isFound = true;
              
            }else
            {
              isFound = this.recurseConduits(element, conduitToCheckFor, remove, false, ignoreDraft);
              
            }
          }
          else{
            isFound = this.recurseConduits(element, conduitToCheckFor, false, false, ignoreDraft);
          }
          if (isFound)
          {
            return isFound;
          }
      }
     
    }
    return false;
}

    recurseTargets = (currentGraft: Graft, targetToCheckFor: TargetType, remove?: boolean, ignoreDraft: boolean = false): boolean => 
    {
     
        if (!remove && currentGraft.Name === targetToCheckFor && (!ignoreDraft || !currentGraft.Draft))
        {
            this.graftFound = currentGraft;
          return true;
        }
        if (currentGraft.Targets && currentGraft.Targets.length > 0)
    {
      let isFound = false;
      for (let index = 0; index < currentGraft.Targets.length; index++) {
        const element = currentGraft.Targets[index];
    
        if (remove)
          {
            isFound = this.recurseTargets(element, targetToCheckFor, remove, ignoreDraft);
            if (!isFound && element.Name === targetToCheckFor)
            {
              this.graftRemoved = element;
                     //if the target was found, it means it has been removed and so we need to set it's parent as the active one.
                     //HAAKON???? parent cannot be set to parent, should be set to the previous last graft.
                       //Set the previous to the last graft clicked before it changes.
              
                       //If the previous item selected hasns't been set or it doesn't exist anymore then set the parent graft as the last graft.
              
    
              //Take the parent as the next item in the default focus tree.
              //this.previousGraftClicked = currentGraft;
              currentGraft.Targets.splice(index, 1);
                
              isFound = true;
              
            }
          }
          else{
            isFound = this.recurseTargets(element, targetToCheckFor, false, ignoreDraft);
          }
          if (isFound)
          {
            
            return isFound;
          }

      }
      if (remove && currentGraft.Name === targetToCheckFor && (!ignoreDraft || !currentGraft.Draft))
      {
          this.graftFound = currentGraft;
        return true;
      }
     
    }
    return false;
    }

    recurseConduitGraftFound: Graft = {};

    setRecurseGraft = (graftIn: Graft)=>
    {
      this.recurseConduitGraftFound = graftIn;
      
      
    }

    render() {

        if (!this.props.GraftsLoaded || this.props.GraftsLoaded.length === 0)
        {
            //For now we don't show an illustration if there are no grafts to show. 
            return <></>;
        }

        let legendHolder: React.ReactElement = null;
        if (this.props.showLegend)
        {

            let conduitsIn: React.ReactElement[] = Object.keys(Conduit).map(item =>{
                //Determine if conduit is in use-
                let conduitInUse = false;
                for (let index = 0; index < this.props.GraftsLoaded.length; index++) {
                    const element = this.props.GraftsLoaded[index];
                    if (element.Type === "PeripheralGraft" && VascularHelpers.recurseGraftForMaterials(element as VascularGraft, item as PeripheralGraftMaterial))
                        conduitInUse = true;
                    if (this.recurseConduits(element, item))
                        conduitInUse = true;
                } 
                
                return  conduitInUse ? <div className="legend-conduit-item"><div className="legend-conduit-item-line"><ConduitVisualize leftConduit={item} rightConduit={item}></ConduitVisualize></div><div className="legend-conduit-item-text">{item}</div></div> : null;

            });
            
            legendHolder =  <div className={"heart-conduits-legend"}>

                {conduitsIn}
            </div>
        }    

    this.ImageOverlays = [];
    this.ImageOverlaysBack = [];
        this.props.GraftsLoaded.forEach(element => {
            //Don't draw other cardiac targets
            if (element.Type !== "Target")
            {
                this.recurseTargetsAndDrawImageOverlay(element);
            }
        });
        return (
            <>
               <div id={"HeartDrawingOverlayTop-"+this.props.Context} className={"heart-canvas drawingoverlay-layer7 heart-canvas-"+this.props.Context}  style={{pointerEvents: (!this.props.trackClicks ? "none" : "auto") as any }}>

</div>
        <div id={"HeartDrawingOverlay-"+this.props.Context} className={"heart-canvas drawingoverlay-layer6 heart-canvas-"+this.props.Context}  style={{pointerEvents: (!this.props.trackClicks ? "none" : "auto") as any }}>

          </div>
          <div id={"HeartDrawingOverlayBack-"+this.props.Context} className={"heart-canvas drawingoverlay-layer2 heart-canvas-"+this.props.Context +" heart-canvas-back-"+this.props.Context}  style={{pointerEvents: (!this.props.trackClicks ? "none" : "auto") as any }}>

          </div>
          <div className={"drawingoverlay-layer4 heart-image-container heart-image-container-" + this.props.Context + (this.props.heartOutlineShow ? "" : " heart-image-container-nowall") + (this.props.selectedProcedure.name === "PeripheralBypass"  ? " peripheral-bypass-background": "") + (this.props.selectedProcedure.name === "AVAccess"  ? " av-access-background": "")} ></div>
          <div className={(this.props.heartTargetsShow  ? "heart-back-veins" : "") +" drawingoverlay-layer2"}></div>
          <div className={"heart-targets-display  drawingoverlay-layer5 heart-targets-display-"+this.props.Context}>
          <div className="heart-target-image-container">
            {this.ImageOverlays}
            </div>

            </div>
            <div className={(this.props.heartTargetsShow  ? "heart-front-veins" : "") +" drawingoverlay-layer4"}></div>
            
            <div className={"heart-targets-display  drawingoverlay-layer2 heart-targets-display-"+this.props.Context}>
            <div className="heart-target-image-container heart-target-image-container-back">
            {this.ImageOverlaysBack}
            </div>
            </div>

            {legendHolder}
          </>
        );
    }


}

export default HeartDrawingOverlay;