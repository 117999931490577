import React, { RefObject } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";
import { DataHelper } from "../Helpers/DataHelper";
import { ChartDataModel } from "../Models/ChartDataModel";
import { LoadingWidget } from "../Components/LoadingWidget";
import { ErrorWindow } from "../Components/ErrorWindow";

export class MeasurementTypes extends React.Component<
    {
        dataClient: DataHelper
    },
    {
        measurementData: ChartDataModel[],
        isBusy: boolean
    }
> {

    //Data structure: 
    // data = [
    //     {
    //       name: 'category',
    //       value1: number,
    //       value2: number,
    //       value3: number,
    //       value4: number
    //     }
    // ];

    errorWindow:  RefObject<ErrorWindow>;
    constructor(props: any) {
        super(props);
        this.errorWindow = React.createRef();
        this.state = {
            measurementData: [],
            isBusy: false
        }
    }

    componentDidMount(): void {
        this.setState({ isBusy: true })
        this.props.dataClient.CallDashboardService("GetMeasurementTypes", {}, {}, (result: string) => {
            let measurementTypes = JSON.parse(result);
            this.setState({ measurementData: measurementTypes, isBusy: false })
        }, (stautus:number, errorMessage:string)=>{
            (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage)}, "GET", false)}

    render() {

        return (
            <>
            <ErrorWindow ref={this.errorWindow}/>
            <h3>Measurement types</h3>
            <div className="chartHolder">
            
                <BarChart
                    width={900}
                    height={300}
                    data={this.state.measurementData}
                    margin={{
                        top: 0,
                        right: 30,
                        left: 50,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis label={{ value: 'No. of measurements', position: 'top' }}/>
                    <Tooltip />
                    <Legend  />
                    <Bar name="Flow" dataKey="value1" stackId="name" fill="#8884d8" />
                    <Bar name="Imaging" dataKey="value2" stackId="name" fill="#83a6ed" />
                    <Bar name="Ecg" dataKey="value3" stackId="name" fill="#8dd1e1" />
                    <Bar name="No label" dataKey="value4" stackId="name" fill="#82ca9d" />
                </BarChart>
                <LoadingWidget zIndexLayer={0} visible={this.state.isBusy} ></LoadingWidget>
                </div>
            </>
        );
    }
}
