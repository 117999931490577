import {Guid} from "guid-typescript";
import { LabelModel } from "../LabelModel";
import HeartDrawingOverlay from "./HeartDrawingOverlay";

// import HeartDrawingOverlay from "../Planner/HeartDrawingOverlay";
// import VascularDrawingOverlay from "../Planner/VascularDrawingOverlay";
// import {Annotation, AnnotationType, Hemisphere, SavedInstance} from "../Shared/SharedClasses";



export enum Conduit
{
    LITA = "LITA",
    RITA = "RITA",
    RA = "RA",
    SVG = "SVG",
    GEA = "GEA"

}

export enum TargetType
{
    RCA = "RCA",
    RVB = "RVB", //Unusual
    AM = "AM", //Unusual
    PDA = "PDA",
    LVB = "LVB",
    CUSTOM = "CUSTOM",
    LAD = "LAD",
    DIAG = "DIAG",
    RI = "RI",
    CX = "CX",
    OM1 = "OM1",
    OM2 = "OM2",
    PLA = "PLA"

}

export enum IndicatorState
{
		none,
		measuring,
    saved,
    measuringsaved,
}

export enum Hemisphere
{
    LEFT = "LEFT",
    RIGHT = "RIGHT"
}
export class ProcedureType{
    name: string = "";
    title: string = "";
    type: string = "";
  
  }

  export enum Tabs
{
    TTFM = "TTFM",
    Imaging = "Imaging",
}

export class Coordinate {
    x: number = 0;
    y: number = 0;
    angle?: number;
  }

export class TargetLocation extends Coordinate {
    backOfHeart?: boolean = false;
    isConduit?: boolean = false;
    //Is it a free graft on the Aorta. 
    isFree?: boolean = false;
    isMammary?: boolean = false;
  }

export class WorkStage
{
    Id : string = "";
    Title : string = "";
    Items : Array < Graft > = [];
    Checked?: boolean;
    Selected?: boolean;
    Enabled?: boolean;
    Type?: string;
}

export class Graft {
    Name?: TargetType;
    Conduit?: Conduit;
    //Is the conduit connected in situ.
    inSitu?: boolean;
    aortaSelected?: boolean;
    Targets?: Array < Graft >;
    Title?: string;
    Type?: string;
    Primary?: boolean;
    Id?: string;
    Checked?: boolean;
    Selected?: boolean;

    GroupId?: string;
    BlankNewItem?: boolean;
    Sequential?: boolean = true;
    // Auto generated grafts must be removed and consolidated into a single graft
    // before editing. Use the groupId to identify which ones fall part of it.
    AutoGenerated?: boolean;
    Draft?: boolean;
    // Indicates if the graft is current undergoing editing. (TODO: INvestigate if
    // this could be replaced by using auto generated)
    InEdit?: boolean;
    // annotations?: Annotation[];
    // annotationsImaging?: Annotation[];
    // annotationsMain?: Annotation[];
    // annotationsImagingMain?: Annotation[];
    // commentMain?: string;
    // comment?: string;
    // commentImaging?: string;
    // commentImagingMain?: string;

    graftFound?: Graft;

    private static newAttributesLookup: any = {};

    // public static getCurrentAnnotationsPlain(graftIn : Graft, mainSelected : boolean = false, context : string = "TTFM") : string[]
    // {
    //     let plainAnnotations : string[] = Graft
    //         .getCurrentAnnotations(graftIn, mainSelected, context)
    //         .map((item => {
    //             return item.annotationValue1
    //         }));
    //     return plainAnnotations;
    // }

    // public static getNewInternalName(graftIn : Graft, internalType : string, mainSelected : boolean = false, context : string = "TTFM") : string
    // {
    //     let index : number = 0;
    //     // Could do this way more efficiently but lets keep it simple for now. Likely to
    //     // change.
    //     while (Graft.getCurrentAnnotationsPlain(graftIn, mainSelected, context).indexOf(internalType + index) > -1) {
    //         index++;
    //     }
    //     return internalType + index;
    // }

    // public static getActiveAnnotation(graftIn : Graft, mainSelected : boolean = false, context : string = "TTFM") : AnnotationType
    // {
    //     if (!graftIn)
    //         return null;
    //     let ActivateCommand : AnnotationType = null;
    //     for (let index = 0; index < this.getCurrentAnnotations(graftIn, mainSelected, context).length; index++) {
    //         const element = this.getCurrentAnnotations(graftIn, mainSelected, context)[index];
    //         if (element.annotationType === AnnotationType.inking && !element.annotationLocations) {
    //             ActivateCommand = AnnotationType.inking;
    //         }
    //         if (element.annotationType === AnnotationType.ruler && !element.annotationEndingLocation) {
    //             ActivateCommand = AnnotationType.ruler;
    //         }
    //     }
    //     return ActivateCommand;

    // }

    // public static getCurrentAnnotations(graftIn : Graft, mainSelected : boolean = false, context : string = "TTFM") : Annotation[]
    // {
    //     if (!graftIn) 
    //         return [];
    //     if (context === "TTFM") {

    //         if (!mainSelected) {
    //             if (!graftIn.annotations) 
    //                 return [];
                
    //             return graftIn.annotations;
    //         } else {
    //             if (!graftIn.annotationsMain) 
    //                 return [];
    //             return graftIn.annotationsMain;
    //         }

    //     } else if (context === "Imaging") {
    //         if (!mainSelected) {
    //             if (!graftIn.annotationsImaging) 
    //                 return [];
                
    //             return graftIn.annotationsImaging;
    //         } else {
    //             if (!graftIn.annotationsImagingMain) 
    //                 return [];
    //             return graftIn.annotationsImagingMain;
    //         }

    //     }
    //     return [];
    // }

    // public static getCurrentComment(graftIn : Graft, mainSelected : boolean = false, context : string = "TTFM")
    // {
    //     let valueToReturn = "";
    //     if (!graftIn) 
    //         return valueToReturn;
    //     if (context === "TTFM") {

    //         if (!mainSelected) {

    //             valueToReturn = graftIn.comment;
    //         } else {

    //             valueToReturn = graftIn.commentMain;
    //         }

    //     } else if (context === "Imaging") {
    //         if (!mainSelected) {

    //             valueToReturn = graftIn.commentImaging;
    //         } else {

    //             valueToReturn = graftIn.commentImagingMain;
    //         }

    //     }
    //     return valueToReturn
    //         ? valueToReturn
    //         : "";
    // }

    // public static addAnnotationComplex(graftIn : Graft, itemToAdd : Annotation, mainSelected : boolean = false, context : string = "TTFM", update : boolean = false) : boolean
    // {
    //   //This is not very nice code but need a way to get the correct list and set it again without needing a new layer.

    //     let annotationList : Annotation[];

    //     if (!graftIn.annotations) 
    //         graftIn.annotations = [];
        
    //     if (context === "TTFM") {

    //         if (!mainSelected) {
    //             annotationList = graftIn.annotations;

    //         } else {
    //             annotationList = graftIn.annotationsMain;
    //         }

    //     } else if (context === "Imaging") {
    //         if (!mainSelected) {
    //             annotationList = graftIn.annotationsImaging;

    //         } else {
    //             annotationList = graftIn.annotationsImagingMain;

    //         }

    //     }

    //     let valToReturn = true;


    //     if (!annotationList) 
    //         annotationList = [];
    //     if (update)
    //     {
    //     if (itemToAdd.annotationValue1.indexOf("INTERNAL:MEASURE") > -1)
    //     {
    //         //If it's an update then remove any old measure or drawing holders because they are just to toggle the buttons.
    //         if (Graft.getCurrentAnnotationsPlain(graftIn, mainSelected, context).indexOf("INTERNAL:MEASURE") > -1) {
    //           annotationList.splice(Graft.getCurrentAnnotationsPlain(graftIn, mainSelected, context).indexOf("INTERNAL:MEASURE"), 1);
    //         }
    //     }
    //     if (itemToAdd.annotationValue1.indexOf("INTERNAL:DRAW") > -1)
    //     {
    //         //If it's an update then remove any old measure or drawing holders because they are just to toggle the buttons.
    //         if (Graft.getCurrentAnnotationsPlain(graftIn, mainSelected, context).indexOf("INTERNAL:DRAW") > -1) {
    //           annotationList.splice(Graft.getCurrentAnnotationsPlain(graftIn, mainSelected, context).indexOf("INTERNAL:DRAW"), 1);
    //         }
    //     }
    //   }

    //     if (Graft.getCurrentAnnotationsPlain(graftIn, mainSelected, context).indexOf(itemToAdd.annotationValue1) > -1) {
    //         if (update) 
    //             annotationList.splice(Graft.getCurrentAnnotationsPlain(graftIn, mainSelected, context).indexOf(itemToAdd.annotationValue1), 1, itemToAdd);
    //         else 
    //             annotationList.splice(Graft.getCurrentAnnotationsPlain(graftIn, mainSelected, context).indexOf(itemToAdd.annotationValue1), 1);
    //         valToReturn = update;
    //     } else 
    //         annotationList.push(itemToAdd);
        
    //     if (context === "TTFM") {

    //         if (!mainSelected) {
    //             graftIn.annotations = annotationList;

    //         } else {
    //             graftIn.annotationsMain = annotationList;
    //         }

    //     } else if (context === "Imaging") {
    //         if (!mainSelected) {
    //             graftIn.annotationsImaging = annotationList;

    //         } else {
    //             graftIn.annotationsImagingMain = annotationList;

    //         }

    //     }
    //     return valToReturn;
    // }
    // public static addAnnotation(graftIn : Graft, item : string, mainSelected : boolean = false, context : string = "TTFM", annotationIn : AnnotationType = AnnotationType.attribute)
    // {
    //     let itemToAdd : Annotation = {
    //         x: 0,
    //         y: 0,
    //         annotationType: annotationIn,
    //         annotationValue1: item
    //     }
    //     Graft.addAnnotationComplex(graftIn, itemToAdd, mainSelected, context);
    // }
    // public static countAnnotations(graftIn : Graft, context : string = "TTFM", recurse : boolean = true, getSavedInstance?: Function, selectedIndex: number = 0) : number
    // {
    //     let runningTotal : number = 0;

    //     let savedItems = getSavedInstance ? getSavedInstance(graftIn.Id) : [];
    //     if (savedItems)
    //     {
      

    //     for (let index = 0; index < savedItems.length; index++) {
    //         const savedInstance = savedItems[index];
        

    //         runningTotal = runningTotal + SavedInstance.countAnnotations(savedInstance, context);

            
    //     }
    // }
    //     if (graftIn.Targets && graftIn.Targets.length > 0 && recurse) {
    //         for (let index = 0; index < graftIn.Targets.length; index++) {
    //             const element = graftIn.Targets[index];
    //             runningTotal = runningTotal + Graft.countAnnotations(element, context, true, getSavedInstance, selectedIndex);

    //         }
    //     }
    //         return runningTotal;
    // }
    public static newAttribute(graftIn : Graft, item : string, mainSelected : boolean = false, Context : string = "TTFM")
    {
        if (item) {
            let graftTypeCode = Context + "-" + (mainSelected
                ? "MAIN"
                : graftIn.Type);
            if (!Graft.newAttributesLookup[graftTypeCode]) 
                Graft.newAttributesLookup[graftTypeCode] = [];
            Graft
                .newAttributesLookup[graftTypeCode]
                .push(item);
            // if (Context !== "Imaging")
            //   Graft.addAnnotation(graftIn, item, mainSelected, Context)
        }
    }
    public static getFrequentAnnotations(graftIn : Graft, mainSelected : boolean = true, Context : string = "TTFM") : string[]
    {
        let graftTypeCode = Context + "-" + (mainSelected
            ? "MAIN"
            : graftIn.Type);
        let annotationsOut : string[] = [];

        if (graftIn.Type === "PreGraft") {
            annotationsOut = ["Plaque"];
        } else if (graftIn.Type === "Target") {
            annotationsOut = ["Revised"];
        } else if (graftIn.Type === "Source") {
            annotationsOut = ["Revised", "Distal"];
        }
        if (Context === "Imaging") {
            if (graftIn.Type === "Target") {
                annotationsOut = ["Intimal flap", "Occlusion"];
            } else if (graftIn.Type === "Source") {
                annotationsOut = ["Plaque"];
            }
        }

        return (Graft.newAttributesLookup[graftTypeCode]
            ? annotationsOut.concat(Graft.newAttributesLookup[graftTypeCode])
            : annotationsOut);
    }

    public static GenerateId() : string
    {
        return Guid
            .create()
            .toString();
    }


    public static recurseTargets(currentGraft: Graft, targetToCheckFor: TargetType, remove?: boolean, ignoreDraft: boolean = false): boolean
    {
     
        if (!remove && currentGraft.Name === targetToCheckFor && (!ignoreDraft || !currentGraft.Draft))
        {
            //Graft.graftFound = currentGraft;
          return true;
        }
        if (currentGraft.Targets && currentGraft.Targets.length > 0)
    {
      let isFound = false;
      for (let index = 0; index < currentGraft.Targets.length; index++) {
        const element = currentGraft.Targets[index];
    
        if (remove)
          {
            isFound = this.recurseTargets(element, targetToCheckFor, remove, ignoreDraft);
            if (!isFound && element.Name === targetToCheckFor)
            {
              //this.graftRemoved = element;
                     //if the target was found, it means it has been removed and so we need to set it's parent as the active one.
                     //HAAKON???? parent cannot be set to parent, should be set to the previous last graft.
                       //Set the previous to the last graft clicked before it changes.
              
                       //If the previous item selected hasns't been set or it doesn't exist anymore then set the parent graft as the last graft.
              
    
              //Take the parent as the next item in the default focus tree.
              //this.previousGraftClicked = currentGraft;
              currentGraft.Targets.splice(index, 1);
                
              isFound = true;
              
            }
          }
          else{
            isFound = this.recurseTargets(element, targetToCheckFor, false, ignoreDraft);
          }
          if (isFound)
          {
            
            return isFound;
          }

      }
      if (remove && currentGraft.Name === targetToCheckFor && (!ignoreDraft || !currentGraft.Draft))
      {
          //this.graftFound = currentGraft;
        return true;
      }
     
    }
    return false;
    }


    public static recurseGraftOut: Graft;

    public static recurseSequential(currentGraft: Graft): Graft
    {
        if (currentGraft.Targets && currentGraft.Targets.length > 0)
    {
    
      for (let index = 0; index < currentGraft.Targets.length; index++) {
        const element = currentGraft.Targets[index];
          if (element.Sequential)
          {
              let itemFound =this.recurseSequential(element);
              if (itemFound)
                    return itemFound;
          }
      }
    }
    return currentGraft;
    }
    public static recurseConduits(currentGraft: Graft, conduitToCheckFor: Conduit, remove?: boolean, goToBottom?: boolean, ignoreDraft: boolean = false): boolean
    {
        
      if (currentGraft.Conduit === conduitToCheckFor && (!ignoreDraft || !currentGraft.Draft))
        {
            if (goToBottom)
            {
                let itemFound =this.recurseSequential(currentGraft);
                Graft.recurseGraftOut = itemFound;
                //this.context.setRecurseGraft(itemFound);
                return true;
            }else
            {
                      Graft.recurseGraftOut = currentGraft;
                //this.context.setRecurseGraft(currentGraft);
                return true;
            }
          
        }
    
        if (currentGraft.Targets && currentGraft.Targets.length > 0)
    {
      let isFound = false;
      for (let index = 0; index < currentGraft.Targets.length; index++) {
        const element = currentGraft.Targets[index];
    
        if (remove)
          {
            if (element.Conduit === conduitToCheckFor && (!ignoreDraft || !element.Draft))
            {
             //this.graftRemoved = element;
                     //if the target was found, it means it has been removed and so we need to set it's parent as the active one.
                     //HAAKON???? parent cannot be set to parent, should be set to the previous last graft.
                       //Set the previous to the last graft clicked before it changes.
              
                       //If the previous item selected hasns't been set or it doesn't exist anymore then set the parent graft as the last graft.
              
    
              //Take the parent as the next item in the default focus tree.
              //this.previousGraftClicked = currentGraft;
              currentGraft.Targets.splice(index, 1);
              Graft.recurseGraftOut = element;
              //this.context.setRecurseGraft(element);
              isFound = true;
              
            }else
            {
              isFound = this.recurseConduits(element, conduitToCheckFor, remove, false, ignoreDraft);
              
            }
          }
          else{
            isFound = this.recurseConduits(element, conduitToCheckFor, false, false, ignoreDraft);
          }
          if (isFound)
          {
            return isFound;
          }
      }
     
    }
  
    return false;
    }

    public static GenerateGrafts(suggestedLabels: LabelModel[][]) : Graft[]
    {
        //THis is the root graft to return;

        let graftsOut: Graft[] = [];
  
        for (let procindex = 0; procindex < suggestedLabels.length; procindex++) {
            const procedureLabels = suggestedLabels[procindex];
            if (procedureLabels && procedureLabels.length > 0)
            {
             
            let lastGraftConfig ="";
            let stillRoot = true;
            let currentGraft : Graft = Graft.getBlankGraft(false, undefined, "CABG");
            let latestGraft: Graft = currentGraft;
        
            for (let measindex = 0; measindex < procedureLabels.length; measindex++) {
                const measurementLabel = procedureLabels[measindex];

                if (measurementLabel.name === "Y")
                {
                    //Hardcoding for Y grafts.
                    lastGraftConfig = "Y";
                }

                //First check Coronary Conduit
                if (Object.keys(Conduit).indexOf(measurementLabel.name) > -1)
                {
                    //It's a Conduit
                    let conduitName =  measurementLabel.name as Conduit;
                    if (latestGraft.Conduit || latestGraft.Name )
                    {
                        //If the current graft has a conduit or a target set already then add a new graft to it with a conduit.
                        let childGraft : Graft = Graft.getBlankGraft(false, undefined, "CABG");
                        childGraft.BlankNewItem =false;
                        childGraft.Title = conduitName;
                        childGraft.Conduit = conduitName;
                        childGraft.Type = "Conduit";
                        latestGraft.Targets?.push(childGraft);
                        latestGraft = childGraft;
                        stillRoot = false; 
                    }else
                    {
                        let conduitInUse = false;                        
                        //If there is already this conduit in use as a source then just tack it one as a sequential target?
                        for (let index = 0; index < graftsOut.length; index++) {
                            const element = graftsOut[index];
                            if (element && element.Conduit === conduitName)
                            {
                                conduitInUse = true;
                            }
                        }
                        if (!conduitInUse)
                        {
                        //If the current graft doesn't have a conduit then we set it here and assume we are at the root.
                        latestGraft.inSitu = TargetDetails.getTargetDetail(conduitName).isSource;
                        latestGraft.Type = "Source";
                        latestGraft.BlankNewItem =false;
                        latestGraft.Title = conduitName;
                        latestGraft.Conduit = conduitName;
                        latestGraft.Targets =[];
                        }
                    }
                }

                if (Object.keys(TargetType).indexOf(measurementLabel.name) > -1)
                {
                    //It's a Target

                    let targetName =  measurementLabel.name as TargetType;

                    //First check if it's used before and if so then don't add it.
                    let targetAddedBefore = false;
                    for (let index = 0; index < graftsOut.length; index++) {
                        const elementGraft = graftsOut[index];
                        if (Graft.recurseTargets(elementGraft, targetName))
                        {
                            targetAddedBefore = true;
                        }
                        
                    }
                    //TODO: In future we can check if the target has been added with the same conduit because it could be grafted twice maybe?
                    if (!targetAddedBefore)
                    {
               

                    if ((latestGraft.Conduit && latestGraft.Type==="Source") || latestGraft.Name )
                    {
                        //If the current graft has a conduit and it's at the root or a target set already then add a new graft to it with a target set.
                        let childGraft : Graft = Graft.getBlankGraft(false, undefined, "CABG");
                        childGraft.Sequential = lastGraftConfig !== "Y";
                        childGraft.BlankNewItem =false;
                        childGraft.Title =  measurementLabel.name;
                        lastGraftConfig = "";
                        childGraft.Type = "Target";
                        childGraft.Name = measurementLabel.name as TargetType;
                        latestGraft.Targets?.push(childGraft);
                        latestGraft = childGraft;
                        stillRoot = false; 
                    }
                    else if (!latestGraft.Conduit &&  stillRoot)
                    {
                        //If there is already this conduit in use as a source then just tack it one as a sequential target?
                        let conduitInUse = false;  
                        let graftWithConduit = Graft.getBlankGraft(false, undefined, "CABG");            

                        for (let index = graftsOut.length-1; index >= 0; index--) {
                            const element = graftsOut[index];
                            if (element && element.Type === "Source")
                            {   
                                //Get the last added source and add the target onto it.
                                graftWithConduit = element;
                                conduitInUse = true;
                            }
                        }

                        //If there is already this conduit in use as a source then find the bottom of the conduit and tack it onto that one.
                        let targetAddedBefore = false;
                   
                        for (let index = 0; index < graftsOut.length; index++) {
                            const elementGraft = graftsOut[index];
                            if (!targetAddedBefore)
                            {
                           
                                if (Graft.recurseConduits(elementGraft, graftWithConduit.Conduit as Conduit, false, true))
                                {
                                    graftWithConduit = Graft.recurseGraftOut;
                                    targetAddedBefore = true;
                                }
                                 
                            }
                        }
                        


                      

                        //If we are still at the root and the conduit is blank then lets create a default inflow.
                        
                        if (!conduitInUse)
                        {
                        latestGraft.Type = "Source";
                        latestGraft.Conduit = Conduit.LITA;
                        latestGraft.inSitu = true;
                        latestGraft.BlankNewItem =false;
                        latestGraft.Title =  Conduit.LITA;
                        }
                        let childGraft : Graft = Graft.getBlankGraft(false, undefined, "CABG");
                        childGraft.Type = "Target";
                        childGraft.Name = targetName;
                        childGraft.Title =  targetName;
                        childGraft.Sequential = lastGraftConfig !== "Y";
                        childGraft.Targets =[];
                        if (!conduitInUse)
                        {
                        latestGraft.Targets =[childGraft];
                        }else
                        {
                            graftWithConduit.Targets?.push(childGraft);
                        }

                        latestGraft = childGraft;
                    }
                    else
                    {
                      

                        //If the current graft doesn't have a conduit then we set it here and assume we are at the root.
                        latestGraft.inSitu = TargetDetails.getTargetDetail(targetName).isSource;
                        latestGraft.Type = "Target";
                        latestGraft.Name = targetName;
                        latestGraft.BlankNewItem =false;
                        latestGraft.Title = targetName;
                        latestGraft.Sequential = lastGraftConfig === "Y";
                        latestGraft.Targets =[];
                    }
                }
                }


          
        }
        if (!currentGraft.BlankNewItem)
            graftsOut.push(currentGraft);
        }
           
        }
        return graftsOut;
    }



    public static SetId
        ? (graftIn : Graft, IdInput?: string, GroupInput?: string)
    {
        if (!IdInput) 
            IdInput = Guid.create().toString();
        graftIn.Id = IdInput;

        if (!GroupInput) 
            GroupInput = Guid.create().toString();
        graftIn.GroupId = GroupInput;
        // graftIn.GroupId = Context + "_" + Stage;   if (ParentName)   { //graftIn.Id
        // += "_" +ParentName ;   }   if (graftIn.Primary)   { graftIn.Id +=
        // "_Primary_";   }   if (graftIn.AutoGenerated)   {   }   if (graftIn.Conduit)
        // {     graftIn.Id += "_" +graftIn.Conduit; graftIn.GroupId += "_"
        // +graftIn.Conduit;   }   if (graftIn.BlankNewItem)   {    graftIn.Id +=
        // "_UNDEFINEDGRAFT";     graftIn.GroupId += "_UNDEFINEDGRAFT";   }   else   {
        // if (graftIn.Name)     {     graftIn.Id += "_" +graftIn.Name; graftIn.GroupId
        // += "_" +graftIn.Name;     }   }
    }

    public static ResetAllIds(graftIn : Graft, GroupInput : string, savedTTFMItems : any, savedImagingItems : any, maintainSaves: boolean = true)
    {
        let originalId = graftIn.Id as string;
        if (Graft.SetId)
        Graft.SetId(graftIn, "", GroupInput);
        if (maintainSaves)
        {
            if (savedTTFMItems[originalId]) {
                savedTTFMItems[graftIn.Id as string] = savedTTFMItems[originalId];
            }
            if (savedImagingItems[originalId]) {
                savedImagingItems[graftIn.Id as string] = savedImagingItems[originalId];
            }
        }
        // When resting the graft id, make sure to change the saved item ID as well. if
        // (savedTTFMItemsWithoutParent && savedImagingItemsWithoutParent)      {
        // //let primaryFound = graftIn.Id;      if (graftIn &&
        // (Object.keys(savedTTFMItemsWithoutParent).indexOf(originalId) > -1 ||
        // Object.keys(savedImagingItemsWithoutParent).indexOf(originalId) > -1)) { //If
        // previous data was saved then link it up correctly and check the item.
        // graftIn.Checked = true;       if (savedTTFMItemsWithoutParent[originalId]) {
        //          Graft.SetId(graftIn, savedTTFMItemsWithoutParent[originalId],
        // graftIn.GroupId);       }       else {         graftIn.Checked = false; }
        // if(savedImagingItemsWithoutParent[originalId])   { Graft.SetId(graftIn,
        // savedImagingItemsWithoutParent[originalId], graftIn.GroupId);           }   }
        // else {         graftIn.Checked = false;   } }

        if (graftIn.Targets && graftIn.Targets.length > 0) {
            for (let index = 0; index < graftIn.Targets.length; index++) {
                const element = graftIn.Targets[index];
                Graft.ResetAllIds(element, GroupInput, savedTTFMItems, savedImagingItems, maintainSaves);
            }
        }

    }

    public static CompareIds(Id1 : string, Id2 : string)
    {
        if (Id1 === Id2) {
            // if (Id1.substring(Id1.indexOf("_")) === Id2.substring(Id2.indexOf("_")) ) {
            return true;

            //}
        }
        return false;

    }

    // public static getVascularBlankGraft(isProcedure : boolean) : VascularGraft
    // {
    //     if (!isProcedure) {

    //         let blankGraft = {
    //             Id: "UNDEFINED_GRAFT",
    //             Type: "NewGraft",
    //             Targets: [],
    //             BlankNewItem: true,
    //             Selected: false,
    //             Title: "SELECT CAROTID",
    //             CarotidVessels: []
    //         };
    //         return blankGraft;
    //     } else {
    //         let blankGraft = {
    //             Id: "UNDEFINED_GRAFT",
    //             Type: "NewGraft",
    //             Targets: [],
    //             BlankNewItem: true,
    //             Selected: false,
    //             Title: "DEFINE PROCEDURE",
    //             CarotidVessels: []
    //         };
    //         return blankGraft;
    //     }

    // }

    public static getBlankGraft(isSelected?: boolean, duplicateInOtherTab?: Graft, selectedProcedure?: string) : Graft {
        let titleIn = "NEW GRAFT";
        let CardotidsIn = null;
        if (selectedProcedure === "CABG")
            titleIn = "NEW GRAFT";
        else 
        if (selectedProcedure === "OtherCardiac")
            titleIn = "NEW TARGET";
        else if (selectedProcedure === "Carotid")
        {
            titleIn = "DEFINE"; 
            CardotidsIn = [];
        }
        else if (selectedProcedure === "PeripheralBypass")
        {
            titleIn = "VESSEL DISEASE"; 
            CardotidsIn = [];
        }
        else if (selectedProcedure === "AVAccess")
        {
            titleIn = "DEFINE"; 
            CardotidsIn = [];
        }else
        {
            titleIn = "NEW VESSEL";
        }
        
            
        let blankGraft = {
            Id: "UNDEFINED_GRAFT",
            Type: "NewGraft",
            Targets: [],
            Selected: isSelected,
            BlankNewItem: true,
            Draft: true,
            Title: titleIn,
            CarotidVessels: CardotidsIn
        };

        if (!duplicateInOtherTab &&  Graft.SetId) 
            Graft.SetId(blankGraft);
        else 
        if (Graft.SetId)
            Graft.SetId(blankGraft, (duplicateInOtherTab as Graft).Id, (duplicateInOtherTab as Graft).GroupId);
        return blankGraft;
    }

}
//THis is called TargetDetail but is also used for Conduits.
export class TargetDetail
{
    priority : number = 0;
    mostUsed?: boolean = false;
    color?: string = "var(--BernRed)";
    //This defines whether the Conduit is a Source
    isSource?: boolean = false;
    isMammary?: boolean = false;
    vascular?: boolean = false;
    upperLimb?: boolean = false;
}

export class TargetDetails
{
    static targetCoords : Record < string,
    TargetLocation > = {
        "AORTA": {
            "x": 310,
            "y": 105,
            "isConduit": true,
            "isFree": true,
            "backOfHeart": false
        },
        "AORTAEND": {
            "x": 340,
            "y": 165,
            "backOfHeart": false,
            "isFree": false
        },
        "AORTAYEND": {
            "x": 385,
            "y": 145,
            "backOfHeart": false,
            "isFree": true
        },
        "LITA": {
            "x": 933,
            "y": 26.999998331069946,
            "isConduit": true,
            "isMammary": true,
            "backOfHeart": false,
            "isFree": false
        },
        "LITAEND": {
            "x": 850,
            "y": 90,
            "backOfHeart": false,
            "isFree": false
        },
        "LITAYEND": {
            "x": 850,
            "y": 140,
            "backOfHeart": false,
            "isFree": false
        },
        "RITA": {
            "x": -122.00001525878906,
            "y": 16,
            "isConduit": true,
            "isMammary": true,
            "backOfHeart": false,
            "isFree": false
        },
        "RITAEND": {
            "x": -60,
            "y": 110,
            "backOfHeart": false,
            "isFree": false
        },
        "RITAYEND": {
            "x": -70,
            "y": 150,
            "backOfHeart": false,
            "isFree": false
        },
        "RA": {
            "x": 260,
            "y": 90,
            "isConduit": true,
            "isFree": true,
            "backOfHeart": false
        },
        "RAEND": {
            "x": 320,
            "y": 150,
            "backOfHeart": false,
            "isFree": false
        },
        "SVG": {
            "x": 314.9999694824219,
            "y": 106.00000381469727,
            "isConduit": true,
            "isFree": true,
            "backOfHeart": false
        },
        "SVGEND": {
            "x": 380,
            "y": 180,
            "backOfHeart": false,
            "isFree": false
        },
        "GEA": {
            "x": -120.00001525878906,
            "y": 834.9999694824219,
            "isConduit": true,
            "backOfHeart": false,
            "isFree": false
        },
        "GEAEND": {
            "x": 30,
            "y": 700,
            "backOfHeart": false,
            "isFree": false
        },
        "GEAYEND": {
            "x": 100,
            "y": 650,
            "backOfHeart": false,
            "isFree": false
        },
        "RCA": {
            "x": 33.999969482421875,
            "y": 589.0000152587891,
            "isConduit": true,
            "isFree": true,
            "backOfHeart": false
        },
        "RVB": {
            "x": 213.99996948242188,
            "y": 438.00001525878906,
            "backOfHeart": false,
            "isFree": false
        },
        "AM": {
            "x": 285.9999694824219,
            "y": 724,
            "backOfHeart": false,
            "isFree": false
        },
        "PDA": {
            "x": 470,
            "y": 775.9999694824219,
            "backOfHeart": true,
            "isFree": false
        },
        "LVB": {
            "x": 591,
            "y": 713.0000152587891,
            "backOfHeart": true,
            "isFree": false
        },
        "LAD": {
            "x": 740,
            "y": 568.9999694824219,
            "backOfHeart": false,
            "isFree": false
        },
        "DIAG": {
            "x": 841,
            "y": 528.0000152587891,
            "backOfHeart": false,
            "isFree": false
        },
        "RI": {
            "x": 833,
            "y": 304,
            "backOfHeart": true,
            "isFree": false
        },
        "CX": {
            "x": 565,
            "y": 266.00000762939453,
            "backOfHeart": true,
            "isFree": false
        },
        "OM1": {
            "x": 805,
            "y": 593.0000152587891,
            "backOfHeart": true,
            "isFree": false
        },
        "OM2": {
            "x": 682,
            "y": 565,
            "backOfHeart": true,
            "isFree": false
        },
        "PLA": {
            "x": 657,
            "y": 660.0000152587891,
            "backOfHeart": true,
            "isFree": false
        },
        "CIA": {
            "x": 27,
            "y": 280,
            "backOfHeart": false,
            "isFree": false
        },
        "IIA": {
            "x": -10,
            "y": 270,
            "backOfHeart": false,
            "isFree": false
        },
        
        "CFA": {
            "x": 40,
            "y": 420,
            "backOfHeart": false,
            "isFree": false
        },
        "PFA": {
            "x": 40,
            "y": 470,
            "backOfHeart": false,
            "isFree": false
        },
        "POP": {
            "x": 50,
            "y": 550,
            "backOfHeart": false,
            "isFree": false
        },
        "ATA": {
            "x": 50,
            "y": 580,
            "backOfHeart": false,
            "isFree": false
        },
        "PTA": {
            "x": 50,
            "y": 620,
            "backOfHeart": false,
            "isFree": false
        },
        "PA": {
            "x": 75,
            "y": 780,
            "backOfHeart": false,
            "isFree": false
        },
        "RADIAL-CEPHALIC": {
            "x": 260,
            "y": 780,
            "backOfHeart": false,
            "isFree": false
        },
        "RADIAL-BASILIC": {
            "x": 320,
            "y": 800,
            "backOfHeart": false,
            "isFree": false
        },
        "RADIAL-VEINBRACHIAL": {
            "x": 320,
            "y": 800,
            "backOfHeart": false,
            "isFree": false
        },
        "RADIAL-MEDIANCUBITAL": {
            "x": 320,
            "y": 800,
            "backOfHeart": false,
            "isFree": false
        },
        "RADIAL-AXILLARY": {
            "x": 320,
            "y": 800,
            "backOfHeart": false,
            "isFree": false
        },
        "ULNAR-CEPHALIC": {
            "x": 313,
            "y": 862,
            "backOfHeart": false,
            "isFree": false
        },
        "ULNAR-BASILIC": {
            "x": 320,
            "y": 800,
            "backOfHeart": false,
            "isFree": false
        },
        "ULNAR-VEINBRACHIAL": {
            "x": 320,
            "y": 800,
            "backOfHeart": false,
            "isFree": false
        },
        "ULNAR-MEDIANCUBITAL": {
            "x": 320,
            "y": 800,
            "backOfHeart": false,
            "isFree": false
        },
        "ULNAR-AXILLARY": {
            "x": 320,
            "y": 800,
            "backOfHeart": false,
            "isFree": false
        },
        "ARTERYBRACHIAL-CEPHALIC": {
            "x": 320,
            "y": 800,
            "backOfHeart": false,
            "isFree": false
        },
        "ARTERYBRACHIAL-BASILIC": {
            "x": 320,
            "y": 800,
            "backOfHeart": false,
            "isFree": false
        },
        "ARTERYBRACHIAL-VEINBRACHIAL": {
            "x": 320,
            "y": 800,
            "backOfHeart": false,
            "isFree": false
        },
        "ARTERYBRACHIAL-MEDIANCUBITAL": {
            "x": 320,
            "y": 800,
            "backOfHeart": false,
            "isFree": false
        },
        "ARTERYBRACHIAL-AXILLARY": {
            "x": 320,
            "y": 800,
            "backOfHeart": false,
            "isFree": false
        }

    }

    static targetDetailsDefined : Record < string,
    TargetDetail > = {
        "LITA": {
            priority: 0,
            mostUsed: true,
            isSource: true
        },
        "RITA": {
            priority: 1,
            isSource: true
        },
        "RA": {
            priority: 1,
            color: "var(--GreenApple)"
        },
        "SVG": {
            priority: 0,
            color: "var(--Bluejay)"
        },
        "GEA": {
            priority: 2,
            color: "var(--Raven)",
            isSource: true
        },
        "RCA": {
            priority: 1
        },
        "RVB": {
            priority: 1
        },
        "AM": {
            priority: 1
        },
        "PDA": {
            priority: 1
        },
        "LVB": {
            priority: 1
        },
        "CUSTOM": {
            priority: 2,
            color: "var(--GreenApple)"
        },
        "LAD": {
            priority: 0
        },
        "DIAG": {
            priority: 0
        },
        "RI": {
            priority: 0
        },
        "CX": {
            priority: 0
        },
        "OM1": {
            priority: 0
        },
        "OM2": {
            priority: 0
        },
        "PLA": {
            priority: 0
        },
        "PROSTHETIC": {
            priority: 0,
            color: "var(--Crocodile)",
            vascular: true
        },
       
        "BIOGRAFT": {
            priority: 0,
            color: "var(--BananaClan)",
            vascular: true
        },
        "Synthetic": {
            priority: 0,
            color: "var(--swan)",
            vascular: true
        },
       
        "Bovine": {
            priority: 0,
            color: "var(--Raven)",
            vascular: true
        },
        "None": {
            priority: 0,
            color: "var(--BananaClan)",
            vascular: true
        },
        "Vein": {
            priority: 0,
            color: "var(--Bluejay)",
            vascular: true
        },
        "VEIN": {
            priority: 0,
            color: "var(--Bluejay)",
            vascular: true
        },

        "CIA": {
            priority: 0,
            vascular: true
        },
        "IIA": {
            priority: 0,
            vascular: true
        },
        
        "CFA": {
            priority: 0,
            vascular: true
        },
        "PFA": {
            priority: 0,
            vascular: true
        },
        "POP": {
            priority: 0,
            vascular: true
        },
        "ATA": {
            priority: 0,
            vascular: true
        },
        "PTA": {
            priority: 0,
            vascular: true
        },
        "PA": {
            priority: 0,
            vascular: true
        },
        "SA": {
            upperLimb: true,
            priority: 0,
            vascular: true
        },
        "AA": {
            upperLimb: true,
            priority: 0,
            vascular: true
        },"ASCAORTA": {
            isSource: true,
            priority: 0,
            vascular: true
        },
        "ABAORTA": {
            isSource: true,
            priority: 0,
            vascular: true
        },
        "RADIAL-CEPHALIC": {
            priority: 0,
            vascular: true
        },
        "RADIAL-BASILIC": {
            priority: 0,
            vascular: true
        },
        "RADIAL-VEINBRACHIAL": {
            priority: 0,
            vascular: true
        },
        "RADIAL-MEDIANCUBITAL": {
            priority: 0,
            vascular: true
        },
        "RADIAL-AXILLARY": {
            priority: 0,
            vascular: true
        },
        "ULNAR-CEPHALIC": {
            priority: 0,
            vascular: true
        },
        "ULNAR-BASILIC": {
            priority: 0,
            vascular: true
        },
        "ULNAR-VEINBRACHIAL": {
            priority: 0,
            vascular: true
        },
        "ULNAR-MEDIANCUBITAL": {
            priority: 0,
            vascular: true
        },
        "ULNAR-AXILLARY": {
            priority: 0,
            vascular: true
        },
        "ARTERYBRACHIAL-CEPHALIC": {
            priority: 0,
            vascular: true
        },
        "ARTERYBRACHIAL-BASILIC": {
            priority: 0,
            vascular: true
        },
        "ARTERYBRACHIAL-VEINBRACHIAL": {
            priority: 0,
            vascular: true
        },
        "ARTERYBRACHIAL-MEDIANCUBITAL": {
            priority: 0,
            vascular: true
        },
        "ARTERYBRACHIAL-AXILLARY": {
            priority: 0,
            vascular: true
        }
    }


    public static getTargetCoords(heartCanvas : HeartDrawingOverlay, TargetName : string, firstTargetIsOnLeft : boolean = false, drawingStartPoint?: TargetLocation) : TargetLocation
    {
        let hemisphereIn: Hemisphere | null = null;
        if (!this.targetCoords[TargetName] && TargetName && TargetName.indexOf("-") > -1)
        {
            hemisphereIn = TargetName.substr(TargetName.indexOf("-") + 1, TargetName.length - TargetName.indexOf("-") - 1) as Hemisphere;
            TargetName = TargetName.substr(0, TargetName.indexOf("-"));
        }
        if (TargetName === "CUSTOM") {
            let locationTransfer = heartCanvas.transferFromScreenSize(heartCanvas.props.customGraftLocation);
            let customGraft : TargetLocation = {
                x: locationTransfer.x,
                y: locationTransfer.y,
                backOfHeart: heartCanvas.props.customGraftLocation.backOfHeart
            }
            return customGraft;
        }

        // If it includes FREE in the name remove it and if it is a source then return
        // the AORTA location.
        if (TargetName && TargetName.indexOf("FREE") > -1) {
            TargetName = TargetName.replace("FREE", "");
            let isEnd = TargetName.indexOf("END") > -1;
            var CleanName = TargetName
                .replace("YEND", "")
                .replace("END", "");
            if (TargetDetails.getTargetDetail(CleanName) && TargetDetails.getTargetDetail(CleanName).isSource) {
                let locationGraft : TargetLocation = {
                    x: this.targetCoords["AORTA" + (isEnd
                            ? "END"
                            : "")].x,
                    y: this.targetCoords["AORTA" + (isEnd
                            ? "END"
                            : "")].y,
                    backOfHeart: this.targetCoords[TargetName].backOfHeart,
                    isConduit: this.targetCoords[TargetName].isConduit,
                    isFree: true,
                    isMammary: this.targetCoords[TargetName].isMammary
                }
                return locationGraft;
            }
        }

        if (this.targetCoords[TargetName] && this.targetCoords[TargetName].backOfHeart === undefined) 
            this.targetCoords[TargetName].backOfHeart = false;
        if (this.targetCoords[TargetName] && this.targetCoords[TargetName].isFree === undefined) 
            this.targetCoords[TargetName].isFree = false;
        let isEnd = TargetName ? TargetName.indexOf("END") > -1 : false;
        if (TargetName)
        TargetName = TargetName
                .replace("YEND", "")
                .replace("END", "");

        if (this.targetCoords[TargetName] && this.targetCoords[TargetName].isConduit) {
            let xOffset = 0;
            let yOffset = 0;
            // if conduit is from the Aorta and it is going to a target on the left (Stage
            // Left! ---->) of the heart then it should start more to the left on the Aorta.

            if (this.targetCoords[TargetName].isFree && firstTargetIsOnLeft) {
                xOffset = 50;
            }
            // If its a conduit and there is already another one drawn then add an increment
            // so it doesn't overlap too badly.
            let targetCoordToUse = this.targetCoords[TargetName] as TargetLocation;
            //If it's the preview of an END then add a base offset 
            if (isEnd)
            {
                //Add the offset to the drawing start point if one was given.
                if (drawingStartPoint)
                    targetCoordToUse = drawingStartPoint;
                if (targetCoordToUse.x)
                {
                //If it's on the right hand side of the heart then offset inwards and if its on the left add a positive one.
                let offsetInput = ((heartCanvas.findHeartCenter() as Coordinate).x as number) < targetCoordToUse.x ? -50 : 50;

                xOffset = xOffset + offsetInput;
                //Offset downwards always with the exception of the GEA where it needs to go upwards.
                yOffset = yOffset + (TargetName === "GEA" ? -250 : 250);
                }
            }
            else{
                yOffset = yOffset + heartCanvas.targetYOffsets[TargetName];
            }

            

            let locationGraft : TargetLocation = {
                x: targetCoordToUse.x ? targetCoordToUse.x + xOffset : xOffset,
                y: targetCoordToUse.y ? targetCoordToUse.y  + yOffset : yOffset,
                backOfHeart: this.targetCoords[TargetName].backOfHeart,
                isConduit: this.targetCoords[TargetName].isConduit,
                isFree: this.targetCoords[TargetName].isFree,
                isMammary: this.targetCoords[TargetName].isMammary
            }
            return locationGraft;
        }
        let locationGraft = { ...this.targetCoords[TargetName] };
        if (hemisphereIn)
        {
            //If a hemisphere was specified then give the point relative to the heart center.
            //This assumes symmetry of the targets we draw on.
            locationGraft.x = (heartCanvas.transferFromScreenSize(heartCanvas.findHeartCenter()).x as number) + (hemisphereIn === Hemisphere.LEFT ? -1 : 1 ) * (locationGraft.x as number) +  (hemisphereIn === Hemisphere.LEFT ? 0 : 50 ) ;

        }

        return locationGraft;
    }

    // public static getTargetCoordsVascular(vascularCanvas : VascularDrawingOverlay, TargetName : string, firstTargetIsOnLeft : boolean = false, drawingStartPoint: TargetLocation = null) : TargetLocation
    // {
    //     let hemisphereIn: Hemisphere;
    //     if (!this.targetCoords[TargetName] && TargetName && TargetName.lastIndexOf("-") > -1)
    //     {
    //         hemisphereIn = TargetName.substr(TargetName.lastIndexOf("-") + 1, TargetName.length - TargetName.lastIndexOf("-") - 1) as Hemisphere;
    //         TargetName = TargetName.substr(0, TargetName.lastIndexOf("-"));
    //     }
    //     // if (TargetName === "CUSTOM") {
    //     //     let locationTransfer = heartCanvas.transferFromScreenSize(heartCanvas.props.customGraftLocation);
    //     //     let customGraft : TargetLocation = {
    //     //         x: locationTransfer.x,
    //     //         y: locationTransfer.y,
    //     //         backOfHeart: heartCanvas.props.customGraftLocation.backOfHeart
    //     //     }
    //     //     return customGraft;
    //     // }


       
    //     let locationGraft = { ...this.targetCoords[TargetName] };
    //     if (hemisphereIn)
    //     {
    //         //If a hemisphere was specified then give the point relative to the heart center.
    //         //This assumes symmetry of the targets we draw on.
    //         locationGraft.x = vascularCanvas.transferFromScreenSize(vascularCanvas.findBodyCenter()).x + (hemisphereIn === Hemisphere.LEFT ? 1 : -1 ) * locationGraft.x +  (hemisphereIn === Hemisphere.LEFT ? 50 : 0 ) ;

    //     }

    //     return locationGraft;
    // }

    public static getTargetDetail(targetOrConduit: string) : TargetDetail
    {
        targetOrConduit = (targetOrConduit
            ? targetOrConduit.replace("FREE", "")
            : "");
        if (!this.targetDetailsDefined[targetOrConduit]) {
            console.log("INVALID TARGET: " + targetOrConduit);
            return { priority:0};
        }

        if (!this.targetDetailsDefined[targetOrConduit].color) {
            this.targetDetailsDefined[targetOrConduit].color = "var(--BernRed)";
        }
        return this.targetDetailsDefined[targetOrConduit];
    }
    public static getIsVein(colorOfVessel: string) : boolean
    {
      
        return (colorOfVessel === "var(--Bluejay)" || colorOfVessel === "var(--Crocodile)" || colorOfVessel === "var(--GreenApple)" || colorOfVessel === "var(--BananaClan)")
        
    }
}

