import React, {RefObject} from "react";
import DataGrid from "../Components/DataGrid";
import { DataHelper } from "../Helpers/DataHelper";
import { ErrorWindow } from "../Components/ErrorWindow";
import { InfoBox } from "../Components/InfoBox";
import { UserSummaryModel } from "../Models/UserHistorySummary";

export class LogOnHistoryWindow extends React.Component<
    {
        dataClient: DataHelper,
    },
    {
        userSummaryModel:UserSummaryModel[],
    }
> {
    errorWindow:  RefObject<ErrorWindow>;
    infoBox: RefObject<InfoBox>;
    
    constructor(props: any) {
        super(props);
        this.errorWindow = React.createRef();
        this.infoBox = React.createRef();
        this.state={
            userSummaryModel:[],
        }
    }


    componentDidMount(): void {
        this.refreshUsers();       
    }

    sortUsersByMostRecentDate(users: UserSummaryModel[]): UserSummaryModel[] {
        return users.sort((a, b) => {
          const dateA = new Date(a.last_access_date);
          const dateB = new Date(b.last_access_date);
          return dateB.getTime() - dateA.getTime();
        });
      }


    refreshUsers():void{
        this.props.dataClient.CallMetadataService("GetUserHistorySummary", {}, {}, (resultOut: string)=>
            {
                let usageHistoryLog:UserSummaryModel[] = JSON.parse(resultOut);
                usageHistoryLog=this.sortUsersByMostRecentDate(usageHistoryLog)
                this.setState({
                    userSummaryModel: usageHistoryLog
                })
            },(stautus:number, errorMessage:string)=>{
                (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage);
            });
    }
   

   
    render() {
       
        let columns=[
            {Name: "email", Caption: "Email"},
            {Name: "last_access_date", Caption: "Last logon date", Type: "string"},
            {Name: "usage_count", Caption: "Times logged in"},
            ];
          
        return (
            <>
            <ErrorWindow ref={this.errorWindow}/>
            <InfoBox ref={this.infoBox} title="Confirmation" type="confirmation"></InfoBox> 
            <div className="userWindowWrapper">
                <div className="userWindowBody">
                    <div className="usersGridHolder">
                        <DataGrid columns={columns}

                            rows={this.state.userSummaryModel}
                            
                        ></DataGrid>

                    </div>
                </div>
                
            </div>
            </>
        );
    }
}

