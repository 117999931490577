import React from 'react';
import { IndicatorState, Tabs } from './TriggerClasses';

class ProbeIndicator extends React.PureComponent<{  
    showSave: boolean, classToUse: string, visible: boolean, mode: Tabs, indicator: IndicatorState, rotation: number, offsetX: number, offsetY: number}, {}> {

    constructor(props: any) {
        super(props);
        this.state = {
         
        };
      }
      static defaultProps = {
        visible: true,
        mode: Tabs.TTFM,
        indicator: IndicatorState.none,
        showSave: false
      }
      componentDidMount(){
 
      }

      componentWillUnmount(){
       
      }
     
 
    render() {
        let showMeasurementProbe = false;
        return (
           
            <>
            {
                 showMeasurementProbe ?
            <div className={"probe-indicator-holder " + (this.props.mode === Tabs.TTFM?" ttfm-probe ": " imaging-probe ")+ this.props.classToUse}>
                { this.props.showSave && (this.props.indicator === IndicatorState.measuringsaved ||this.props.indicator === IndicatorState.saved)  ? 
        <div className="probe-indicator-saved">
   <i className="material-icons underlayer">brightness_1</i>
    <i className="material-icons checkmark">check_circle</i>
     
    </div>
    : null}
           { this.props.indicator !== IndicatorState.saved ?
    <div className="probe-indicator" style={{display: (this.props.visible ? "" : "none"), transform: "rotate("+this.props.rotation+"deg) "}}>
     
        { this.props.mode === Tabs.TTFM
        ?
        <svg width="31px" height="44px" viewBox="0 0 31 44" version="1.1" >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="probe-head">
            <g id="Group-8">
                <rect id="Rectangle" fill="#588BFF" x="8.5" y="0" width="14" height="13" rx="1"></rect>
                <g id="Group-7" transform="translate(0.000000, 3.000000)" stroke="#979797">
                    <path d="M15.5,1.71663819 C12.0320374,1.71663819 8.88250141,3.56322638 6.46778637,6.04363658 C3.56095164,9.02955516 1.67391304,12.9706847 1.67391304,15.9460862 C1.67391304,17.701837 1.96166025,19.9447504 3.03237116,21.5204297 C3.72061951,22.5332695 4.76342894,23.2307692 6.30354897,23.2307692 C8.22644386,23.2307692 9.41358571,21.4875283 10.5923567,19.9992137 C12.0977249,18.0985377 13.5886566,16.5 15.5,16.5 C17.5398913,16.5 19.2751066,18.2111451 20.9692412,20.1087376 C22.2071596,21.4953245 23.4032849,23.0664635 24.9805015,23.0664635 C26.2338311,23.0664635 27.1600458,22.2816654 27.8349328,21.2564574 C28.8459875,19.7205834 29.326087,17.6046162 29.326087,15.9460862 C29.326087,12.9706847 27.4390484,9.02955516 24.5322136,6.04363658 C22.1174986,3.56322638 18.9679626,1.71663819 15.5,1.71663819 Z" id="Oval" stroke-width="2" fill="#FFFFFF"></path>
                    <line x1="10.1086957" y1="38.1941137" x2="20.8913043" y2="38.1941137" id="Line-9" stroke-width="5" stroke-linecap="round"></line>
                    
                </g>
            </g>
        </g>
    </g>
</svg>
:
<svg width="36px" height="24px" viewBox="0 0 36 24" version="1.1" >
    <title>Symbols</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="probe-head" transform="translate(-1.000000, -3.000000)" stroke="#5A5A5A">
            <path d="M35.8605912,23.6726725 C36.1671103,17.7486517 35.9345116,13.9896672 35.1627951,12.3957189 C32.8143992,7.54520519 27.7998955,7.4395261 26.2539814,7.31555669 C22.7920891,7.0379418 14.4337114,6.39019957 11.3699525,5.88925105 C9.32744655,5.55528536 7.42230981,4.90799749 5.65454229,3.94738743 L2.32082752,10.9973879 C3.91910774,11.6872999 5.63525084,12.6058161 7.46925683,13.7529365 C9.30326281,14.900057 10.0672606,18.2049214 9.76125006,23.6675296 L35.8605912,23.6726725 Z" id="Path-2" stroke-width="2" fill="#FFFFFF" stroke-linejoin="round"></path>
            <line x1="11" y1="25.5" x2="34" y2="25.5" id="Line" stroke-width="2" stroke-linecap="square"></line>
            <line x1="7" y1="5.5" x2="4" y2="10.5" id="Line-2" stroke-width="3" stroke-linecap="square"></line>
        </g>
    </g>
</svg>
    } 
   
        </div>
        : null}
        
        </div>
        

        :
    <></>
    }
    
    </>
    );
    }


}

export default ProbeIndicator;