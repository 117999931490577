
//Map for handling error codes, if you see the need for a new error code
//or you want to pass an unused error code through the server side CS code
//add an addition to the ERROR_MAP


type LOCAL_MAP = {
    [key: string]: {
      title: string;
      message: string;
    };
  };

//Title and message is the body and header text of the errorWindow
export const ERROR_MAP : LOCAL_MAP = {
    '500': {
        title: "Server Error",
        message: "There was a problem with the server. Please try again later."
      },
    '404': {
        title: "Page Not Found",
        message: "The page you are trying to access does not exist."
    },
    '400':{
      title:"Study Not Selected",
      message:"Please select a study in the drop down menu"
    },
    '0':{
      title:"Unknown user or environment error",
      message:"You either do not have access to this server or the environment is currently under maintenance."
    },
    '600':{
      title:"Java Error",
      message:"Please Contact Developer"
    },
    '700':{
      title:"Invalid data",
      message:""
    }

    
  };