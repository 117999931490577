
import { Conduit, Graft, Hemisphere } from "./TriggerClasses";



export enum CarotidVessel
{
    RICA = "RICA",
    LICA = "LICA",
    RCCA = "RCCA",
    LCCA = "LCCA"
}

export enum CarotidProcedures
{
    CONVENTIONAL = "Conventional",
    EVERSION = "Eversion",
    INTERPOSITION = "Interposition"
}

export enum HemisphereLocation
{
    Right = "Right",
    Left = "Left"
}

export enum PatchMaterial
{
  Bovine = "Bovine",
    Synthetic = "Synthetic",
    Vein = "Vein",
    None = "None"
}

export enum PeripheralGraftPoints
{
    SA = "SA",
    AA = "AA",
    ASCAORTA = "ASCAORTA",
    ABAORTA = "ABAORTA",
    CIA = "CIA",
    IIA = "IIA",
    CFA = "CFA",
    PFA = "PFA",
    POP = "POP",
    ATA = "ATA",
    PTA = "PTA",
    PA = "PA"

}

export enum PeripheralGraftMaterial
{
  VEIN="VEIN",
    BIOGRAFT="BIOGRAFT",
    PROSTHETIC="PROSTHETIC",
    CUSTOM="CUSTOM"
}

export enum PeripheralType
{
  BYPASS = "BYPASS",
  OTHER = "OTHER"
}

export enum AVAccessType
{
    SIDEATOENDV = "Side A to End V",
    ENDATOENDV = "End A to End V",
    ENDATOSIDEV = "End A to Side V",
    SIDEATOSIDEV = "Side A to Side V",
    PROTHESTICLOOP = "Prosethic Loop"
}

export enum AVAccessVein
{
    CEPHALIC = "Cephalic",
    BASILIC = "Basilic",
    VEINBRACHIAL = "Brachial",
    MEDIANCUBITAL = "Median Cubital",
    AXILLARY = "Axillary"
}

export enum AVAccessArtery
{
    ULNAR = "Ulnar",
    RADIAL = "Radial",
    ARTERYBRACHIAL = "Brachial"
}

export class VascularGraft extends Graft{

    CarotidVessels:  CarotidVessel[] = [];
    GraftPoint?: PeripheralGraftPoints;
    Material?: PeripheralGraftMaterial;
    hemisphere?: Hemisphere;
    Vein?: AVAccessVein;
    Artery?: AVAccessArtery; 
    AVConfig?: AVAccessType;
    ProcedureType?: CarotidProcedures | PeripheralType;
    CarotidPatchMaterial?: PatchMaterial; 
}

export class VascularHelpers {

    public static graftFound: VascularGraft;
    public static graftRemoved: VascularGraft;

    public static recursePoints(currentGraft: VascularGraft, pointToCheckFor: PeripheralGraftPoints, hemisphere: Hemisphere, remove?: boolean, ignoreDraft?: boolean): boolean
    {
     
        if (!remove && currentGraft.GraftPoint === pointToCheckFor && currentGraft.hemisphere === hemisphere && (!ignoreDraft || !currentGraft.Draft))
        {
            this.graftFound = currentGraft;
          return true;
        }
        if (currentGraft.Targets && currentGraft.Targets.length > 0)
    {
      let isFound = false;
      for (let index = 0; index < currentGraft.Targets.length; index++) {
        const element = currentGraft.Targets[index] as VascularGraft;
    
        if (remove)
          {
            isFound = this.recursePoints(element, pointToCheckFor, hemisphere, remove, ignoreDraft);
            if (!isFound && element.GraftPoint === pointToCheckFor && element.hemisphere === hemisphere)
            {
              this.graftRemoved = element;
                     //if the target was found, it means it has been removed and so we need to set it's parent as the active one.
                     //HAAKON???? parent cannot be set to parent, should be set to the previous last graft.
                       //Set the previous to the last graft clicked before it changes.
              
                       //If the previous item selected hasns't been set or it doesn't exist anymore then set the parent graft as the last graft.
              
    
              //Take the parent as the next item in the default focus tree.
              //this.previousGraftClicked = currentGraft;
              currentGraft.Targets.splice(index, 1);
                
              isFound = true;
              
            }
          }
          else{
            isFound = this.recursePoints(element as VascularGraft, pointToCheckFor, hemisphere, false, ignoreDraft);
          }
          if (isFound)
          {
            
            return isFound;
          }

      }
    
     
    }
    if (remove && currentGraft.GraftPoint === pointToCheckFor && currentGraft.hemisphere === hemisphere && (!ignoreDraft || !currentGraft.Draft))
    {
        this.graftFound = currentGraft;
        // currentGraft.GraftPoint = null;
        // currentGraft.hemisphere = null;
        // currentGraft.Title = null;
          return true;
    }
    return false;
    }
    public static addToBottom(currentGraft: VascularGraft, graftToAdd: VascularGraft, ignoreTop: boolean = false): boolean
    {
     
        if (!currentGraft.GraftPoint && !ignoreTop)
        {
            //currentGraft.BlankNewItem = false;
            currentGraft.Title = graftToAdd.Title;
            currentGraft.GraftPoint = graftToAdd.GraftPoint;
            currentGraft.Type = "PeripheralGraft";
            currentGraft.hemisphere = graftToAdd.hemisphere;
            return true;
        }
        if (currentGraft.Targets && currentGraft.Targets.length > 0)
        {
            for (let index = 0; index < currentGraft.Targets.length; index++) {
                const element = currentGraft.Targets[index] as VascularGraft;
                if (this.addToBottom(element, graftToAdd, ignoreTop))
                    break;
            }
        }else
        {
            if (!currentGraft.Targets)
                currentGraft.Targets = [];
            currentGraft.Targets.push(graftToAdd);
            return true;
        }
        return false;
    }
    public static recurseGraftForMaterials(currentGraft: VascularGraft, material: PeripheralGraftMaterial): boolean
    {
        if (currentGraft.Material === material)
            return true;
        if (currentGraft.Targets && currentGraft.Targets.length > 0)
        {
            for (let index = 0; index < currentGraft.Targets.length; index++) {
                const element = currentGraft.Targets[index] as VascularGraft;
                if (this.recurseGraftForMaterials(element, material))
                    return true;
            }
        }
        return false;
    }
    public static recurseGraftSetMaterials(currentGraft: VascularGraft, material: PeripheralGraftMaterial)
    {
        currentGraft.Material = material;
        if (currentGraft.Targets && currentGraft.Targets.length > 0)
        {
            for (let index = 0; index < currentGraft.Targets.length; index++) {
                const element = currentGraft.Targets[index] as VascularGraft;
                this.recurseGraftForMaterials(element, material);
            }
        }
    }
    public static recurseVein(currentGraft: VascularGraft, pointToCheckFor: AVAccessVein, remove?: boolean, ignoreDraft?: boolean): boolean
    {
     
        if (!remove && currentGraft.Vein === pointToCheckFor  && (!ignoreDraft || !currentGraft.Draft))
        {
            this.graftFound = currentGraft;
          return true;
        }
        if (currentGraft.Targets && currentGraft.Targets.length > 0)
    {
      let isFound = false;
      for (let index = 0; index < currentGraft.Targets.length; index++) {
        const element = currentGraft.Targets[index] as VascularGraft;
    
        if (remove)
          {
            isFound = this.recurseVein(element, pointToCheckFor, remove, ignoreDraft);
            if (!isFound && element.Vein === pointToCheckFor)
            {
              this.graftRemoved = element;
                     //if the target was found, it means it has been removed and so we need to set it's parent as the active one.
                     //HAAKON???? parent cannot be set to parent, should be set to the previous last graft.
                       //Set the previous to the last graft clicked before it changes.
              
                       //If the previous item selected hasns't been set or it doesn't exist anymore then set the parent graft as the last graft.
              
    
              //Take the parent as the next item in the default focus tree.
              //this.previousGraftClicked = currentGraft;
              currentGraft.Targets.splice(index, 1);
                
              isFound = true;
              
            }
          }
          else{
            isFound = this.recurseVein(element as VascularGraft, pointToCheckFor, false, ignoreDraft);
          }
          if (isFound)
          {
            
            return isFound;
          }

      }
    
     
    }
    if (remove && currentGraft.Vein === pointToCheckFor && (!ignoreDraft || !currentGraft.Draft))
    {
        this.graftFound = currentGraft;
        // currentGraft.GraftPoint = null;
        // currentGraft.hemisphere = null;
        // currentGraft.Title = null;
          return true;
    }
    return false;
    }
    public static recurseArtery(currentGraft: VascularGraft, pointToCheckFor: AVAccessArtery, remove?: boolean, ignoreDraft?: boolean): boolean
    {
     
        if (!remove && currentGraft.Artery === pointToCheckFor  && (!ignoreDraft || !currentGraft.Draft))
        {
            this.graftFound = currentGraft;
          return true;
        }
        if (currentGraft.Targets && currentGraft.Targets.length > 0)
    {
      let isFound = false;
      for (let index = 0; index < currentGraft.Targets.length; index++) {
        const element = currentGraft.Targets[index] as VascularGraft;
    
        if (remove)
          {
            isFound = this.recurseArtery(element, pointToCheckFor, remove, ignoreDraft);
            if (!isFound && element.Artery === pointToCheckFor)
            {
              this.graftRemoved = element;
                     //if the target was found, it means it has been removed and so we need to set it's parent as the active one.
                     //HAAKON???? parent cannot be set to parent, should be set to the previous last graft.
                       //Set the previous to the last graft clicked before it changes.
              
                       //If the previous item selected hasns't been set or it doesn't exist anymore then set the parent graft as the last graft.
              
    
              //Take the parent as the next item in the default focus tree.
              //this.previousGraftClicked = currentGraft;
              currentGraft.Targets.splice(index, 1);
                
              isFound = true;
              
            }
          }
          else{
            isFound = this.recurseArtery(element as VascularGraft, pointToCheckFor, false, ignoreDraft);
          }
          if (isFound)
          {
            
            return isFound;
          }

      }
    
     
    }
    if (remove && currentGraft.Artery === pointToCheckFor && (!ignoreDraft || !currentGraft.Draft))
    {
        this.graftFound = currentGraft;
        // currentGraft.GraftPoint = null;
        // currentGraft.hemisphere = null;
        // currentGraft.Title = null;
          return true;
    }
    return false;
    }
    public static toggleVein(currentGraft: VascularGraft, veinToAdd: AVAccessVein, hemisphere: Hemisphere): boolean
    {
        if (currentGraft.Vein === veinToAdd)
        {
            //currentGraft.BlankNewItem = false;
            currentGraft.Title = "";
            currentGraft.Vein = undefined;
            currentGraft.Type = "AVVein";
            currentGraft.Sequential = true;
            currentGraft.hemisphere = hemisphere;
            return false;
        }
        else
        {
            //currentGraft.BlankNewItem = false;
            currentGraft.Title = veinToAdd;
            currentGraft.Sequential = true;
            currentGraft.Vein = veinToAdd;
            currentGraft.Type = "AVVein";
            //Make it blue
            currentGraft.Conduit = Conduit.SVG;
            currentGraft.hemisphere = hemisphere;
            return true;
        }
        
    }
    public static toggleArtery(currentGraft: VascularGraft, veinToAdd: AVAccessArtery, hemisphere: Hemisphere): boolean
    {
        if (currentGraft.Artery === veinToAdd)
        {
            //currentGraft.BlankNewItem = false;
            currentGraft.Title = "";
            currentGraft.Artery = undefined;
            currentGraft.Type = "AVVein";
            currentGraft.Sequential = true;
            currentGraft.hemisphere = hemisphere;
            return false;
        }
        else
        {
            //currentGraft.BlankNewItem = false;
            currentGraft.Title = veinToAdd;
            currentGraft.Sequential = true;
            currentGraft.Artery = veinToAdd;
            currentGraft.Type = "AVVein";
            //Make it blue
            currentGraft.Conduit = Conduit.LITA;
            currentGraft.hemisphere = hemisphere;
            return true;
        }
        
    }
    
   

}
