
//Ignore any TS errors here bcaus the d3 and html references don't play very well
//@ts-nocheck
import * as React from 'react';
import * as d3 from 'd3'
import {ScaleLinear} from 'd3';
import {Coordinate} from '../Helpers/SharedClasses';

class DataPoint
{
    constructor()
    {
        this.x= 0;
        this.y= 0;
    }
    x : number;
    y : number;
}

class ChartD3 extends React.PureComponent < any,
any,
any > {

    constructor(props : any) {
        super(props);
        this.state = {};
        this.xAxisOffset = 0;
    }

    xaxis?: ScaleLinear < number,
    number >;
    yaxis?: ScaleLinear < number,
    number >;
    svg?: d3.Selection < SVGElement,
    any,
    HTMLElement,
    any >;
    svglinecanvas?: d3.Selection < SVGElement,
    any,
    HTMLElement,
    any >;
    internalwidth = 100;
    internalheight = 100;
    lastDataPointLookup: any = {};
    allDataPointsLookup: any = {};
    dataPhaseGroups = [];
    currentDataPointLookup = {};
    static defaultProps = {
        id: "BaseChartD3",
        height: 100,
        width: 1050,
        xaxismin: 0,
        xaxismax: 100,
        yaxismin: -0.1,
        yaxismax: 0.1,
        margintop: 10,
        marginright: 30,
        marginbottom: 30,
        marginleft: 50,
        color: "blue",
        autoscroll: true,
        xaxisposition: 0,
        colorOutline: "#767676",
        axescolor: "#767676",
        xAxisLabelsFormat: function (e) {
            return (e / 200).toFixed(1) + "s";
        },
        yAxisLabelsFormat: function (e) {
            return e ;
        },
        yAxisLabel: "",
        yAxisVisible: true,
        xAxisVisible: true,
        xAxisLabelsVisible: true,
        yAxisMinimal: false
    }
    componentDidMount = () => {
        // set the dimensions and margins of the graph
        this.resetDrawingArea();
    }

    resetDrawingArea = (height = null, width = null, yaxismin: number=null, yaxismax: number=null,xaxismax: number=null, xaxismin: number =null,xaxisposition: number=null  ) => {
        var margin = {
            top: this.props.margintop,
            right: this.props.marginright,
            bottom: this.props.marginbottom,
            left: this.props.marginleft
        };
        let widthToUse = this.props.width;
        
        if (!widthToUse || widthToUse === -1)
        {
            
            widthToUse = document.getElementById(this.props.id).offsetWidth;
        }
        this.internalwidth = widthToUse - margin.left - margin.right;
        this.internalheight = this.props.height - margin.top - margin.bottom;
        if (this.svg) {
            d3
                .selectAll("#" + this.props.id)
                .select("svg")
                .remove();
        }

        this.svg = d3
            .select("#" + this.props.id)
            .append("svg")
            .attr("id", this.props.id + "svg")
            .attr("width", this.internalwidth + margin.left + margin.right)
            .attr("height", this.internalheight + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        this.svglinecanvas = d3
            .select("#" + this.props.id + " svg")
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        this.createYAxis(yaxismin, yaxismax);
        this.createXAxis(xaxismin, xaxismax, xaxisposition);
        // let inkSup = new InkingSupport(); 
        // inkSup.Initialize(this.props.id, this.props.width, this.props.height);
    }

    createYAxis = (yaxismin=null, yaxismax=null) => {
        d3
            .selectAll("#" + this.props.id + "-yaxismain")
            .remove();
        // Add Y axis
        this.yaxis = d3
            .scaleLinear()
            .domain([yaxismin ? yaxismin : this.props.yaxismin, yaxismax ? yaxismax : this.props.yaxismax])
            .range([this.internalheight, 0])
           

            
        if (this.props.yAxisVisible) {
            let axisIn = d3.axisLeft(this.yaxis).tickFormat(this.props.yAxisLabelsFormat); 
            if (this.props.yAxisMinimal)
                axisIn = axisIn.ticks(4)

           this.svg
                .append("g")
                .call(axisIn)
                .attr("class", "axis-default")
                .attr("id", this.props.id + "-yaxismain").attr("transform", "translate("+this.props.marginleft+",0)");;
            // text label for the y axis
            this.svg
                .append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - this.props.marginleft)
                .attr("x", 0 - (this.props.height / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text(this.props.yAxisLabel);
        }
    }

    createYAxisRight = (yaxismin=null, yaxismax=null, xaxismax= null) => {
        this
            .svg
            .selectAll("#" + this.props.id + "-yaxisright")
            .remove();
        // Add Y axis
        this.yaxis = d3
            .scaleLinear()
            .domain([yaxismin ? yaxismin :this.props.yaxismin,yaxismax ? yaxismax : this.props.yaxismax])
            .range([this.internalheight, 0]);

        this
            .svg
            .append("g")
            .attr("transform", "translate(" + this.xaxis(xaxismax ? xaxismax : this.props.xaxismax) + ",0)")
            .call(d3.axisRight(this.yaxis).tickValues([]))
            .attr("class", "axis-default")
            .attr("id", this.props.id + "-yaxisright");
    }

    createXAxis = (xaxismin=null, xaxismax=null, xaxisposition=null) => {
        this
            .svglinecanvas
            .selectAll("#" + this.props.id + "-xaxismain")
            .remove();
        this.xaxis = d3
            .scaleLinear()
            .domain([xaxismin ? xaxismin : this.props.xaxismin, xaxismax ? xaxismax : this.props.xaxismax])
            .range([this.props.marginleft, this.internalwidth]);
        if (this.props.xAxisVisible) {
            this
                .svglinecanvas
                .append("g")
                .attr("transform", "translate(0," + this.yaxis(xaxisposition ? xaxisposition : this.props.xaxisposition) + ")")
                .call(d3.axisBottom(this.xaxis).tickFormat(this.props.xAxisLabelsFormat))
                .attr("class", "axis-default")
                .attr("id", this.props.id + "-xaxismain");
        }
    }
    xAxisOffset : number;
    prepareData = (dataIn : Array < number >, seriesName : string, xValuesOrig : Array < number >, compareMode: boolean, scrubberPos: number) : Array < DataPoint > => {
        let maxX = 0;
        let xValues = JSON.parse(JSON.stringify(xValuesOrig));
        let dataOut : Array < DataPoint > = [];
        if (this.props.autoscroll) {
            if (!this.currentDataPointLookup[seriesName]) {
                this.currentDataPointLookup[seriesName] = 0;
            }
            //Check if any xValues are zero to see if it has reset.
            let zeroFound = false;
            let passedMax = false;
            let indexToChop = 0;
            let previousElement : Coordinate = this.lastDataPointLookup[seriesName];
            for (let index = 0; index < xValues.length; index++) {
                const element = xValues[index];
                if (maxX < element)
                 maxX = element;
                if (element === 0) {
                    zeroFound = true;
                    //  console.log(seriesName +": zeroFound"); When zero is found whe need to clear
                    // data and drop off the last bits in the sample off.
                    indexToChop = index;
                }
                if (element >= this.props.xaxismax && index < xValues.length - 1) {
                    passedMax = true;
                    indexToChop = index;
                    //                    console.log(seriesName +": passedMax");

                }
                // Never draw a split across the x axis so check the x values to see. If
                // sequential x values ever have a jump larger than 10 between them then clear
                // the axis and drop those values off.
                if (previousElement && previousElement.x !== -1 && Math.abs((element - previousElement.x)) > 20) {
                    zeroFound = true;
                    //console.log(seriesName + ": Sequential mismatch!");
                    // When zero is found whe need to clear data and drop off the last bits in the
                    // sample off.
                    indexToChop = index;
                }
                previousElement = element;
            }
            if (!compareMode && (passedMax || zeroFound)) {
                this.currentDataPointLookup[seriesName] = 0;
                this.lastDataPointLookup[seriesName] = null;
                this.allDataPointsLookup[seriesName] = [];
                //No longer clear data explicitly
                //Clear data as following the new ones added.
                //this.clearData(seriesName);
                this.dataPhaseGroups = [];
                if (zeroFound) {
                    console.log(seriesName + " x chopped: " + xValues.splice(0, indexToChop));
                    console.log(seriesName + " values: " + xValues);
                } else if (passedMax) {
                    console.log(seriesName + " x chopped: " + xValues.splice(0, indexToChop + 1));
                    console.log(seriesName + " values: " + xValues);
                }
            }
           
            if (dataIn && dataIn.length > 0) {
                //build x, y structure from given y and current data point.
                if (compareMode)
                {
                    this.currentDataPointLookup[seriesName] = 0;
                    this.lastDataPointLookup[seriesName] = null;
                    this.allDataPointsLookup[seriesName] = [];
                    //this.clearData(seriesName);
                    let startval =  Math.round(scrubberPos- this.props.xaxismax );
                    if (startval < 0) startval =0;
                    for (let index = 0; index < dataIn.length; index++) {
                    // for (let index = startval; index < scrubberPos + this.props.xaxismax; index++) {
                        const element = dataIn[index];
                        
                        if (index < xValues.length) {

                            if (maxX < element)
                            maxX = element;

                            if (xValues[index] >= startval && xValues[index] < scrubberPos )
                          
                          {
                                let dataPointIn = {
                                x: xValues[index] - startval,
                                y: element
                            };
                            dataOut.push(dataPointIn);
                         
                        }
                        // }
    
                            //this.currentDataPointLookup[seriesName] = xValues[index];
    
                          
    
                        }
                    }
    
                }else
                {
                for (let index = 0; index < dataIn.length; index++) {
                    const element = dataIn[index];
                    if (index < xValues.length) {
                        let dataPointIn = {
                            x: xValues[index],
                            y: element
                        };
                        if (maxX < element)
                        maxX = element;

                        this.currentDataPointLookup[seriesName] = xValues[index];

                        dataOut.push(dataPointIn);

                    }
                }
                }
                if (passedMax || zeroFound) {
//                    console.log("SeriesLoaded: " + seriesName);
                }

            }
        } else {
            //Deprecating AutoScroll, always use coordinates dataOut = dataIn;
        }
        if (!compareMode)
            this.clearData(seriesName, maxX);

        if (!this.allDataPointsLookup[seriesName]) {
            this.allDataPointsLookup[seriesName] = [];
        }
        this.allDataPointsLookup[seriesName] = this
            .allDataPointsLookup[seriesName]
            .concat(dataOut);

        //this.currentDataPoints += dataIn.length;
        if (this.lastDataPointLookup[seriesName] && Math.abs((dataOut[0].x - this.lastDataPointLookup[seriesName].x)) < 20) {
            // Add the last data point in again to fill the gaps, missing pixel between
            // slices.

            dataOut.unshift(this.lastDataPointLookup[seriesName]);
        }

        return dataOut;
    }

    addAreaData = (dataIn, seriesColor, seriesName, xValues, compareMode = false, scrubberPos = 0) => {

        let contextholder = this;

        // let dataToDraw = []; for (let index = 0; index < dataIn.length; index++) {
        // //  const element = dataIn[index];   dataToDraw.push({x: xValues[index], y:
        // dataIn[index] }); }


        dataIn = contextholder.prepareData(dataIn, seriesName, xValues, compareMode, scrubberPos);
        if (dataIn.length === 0)
            return;
        if (!seriesColor) {
            seriesColor = this.props.color;
        }

        let startX = dataIn[0]["x"];
        let endX = dataIn[dataIn.length - 1]["x"];
        this
            .dataPhaseGroups
            .push([startX, endX]);
        contextholder
            .svg
            .append("path")
            .datum(dataIn)
            .attr("fill", seriesColor)
            .attr("stroke", seriesColor)
            .attr("stroke-width", "2")
            //Label the filled area path with it's x start and stop.
            .attr("class", seriesName + "-class filled-zone-startx-" + startX + " filled-zone-endx-" + endX)
            .attr("d", d3.area().x(function (d) {
                return contextholder.xaxis(d["x"])
            }).y0(contextholder.yaxis(0)).y1(function (d) {
                return contextholder.yaxis(d["y"])
            }));

        contextholder
            .svglinecanvas
            .append("path")
            .datum(dataIn)
            .attr("fill", "none")
            .attr("stroke", this.props.colorOutline)
            .attr("stroke-width", "2")
            .attr("class", seriesName + "-class line-zone-startx-" + startX + " line-zone-endx-" + endX)
            .attr("d", d3.line().x(function (d) {
                return contextholder.xaxis(d["x"])
            }).y(function (d) {
                return contextholder.yaxis(d["y"])
            }));
        contextholder.lastDataPointLookup[seriesName] = dataIn.pop();

    }

    setAreaColor = (areaData: any, primaryColor: string, secondaryColor: string) => {
        let contextholder = this;
        if (areaData && areaData.length > 0) {
            // let startX = areaData[0]; let endX = areaData[areaData.length-1]; let
            // previousX = 0;

            for (let index = 0; index < areaData.length; index++) {
                let areaVal = areaData[index];
                // for (let indexdata = 0; indexdata <
                // contextholder.allDataPointsLookup["TTFM"].length; indexdata++) {     const
                // element = contextholder.allDataPointsLookup["TTFM"][indexdata];   //  if () }
                let dataInToDraw = [];
                for (let indexdata = areaVal.x; indexdata <= areaVal.xend; indexdata++) {
                    
                    //Now find matching x in contextholder.allDataPointsLookup
                    let matchingItem = null;
                    contextholder
                        .allDataPointsLookup["TTFM"]
                        .forEach(element => {
                            if (indexdata === element.x) {
                                matchingItem = element;
                                return;
                            }
                        });
                    //If an x value is found.
                    if (matchingItem) 
                        dataInToDraw.push({x: matchingItem.x as number, y: matchingItem.y});
                    }
                if (dataInToDraw.length > 0) {
                    // var dataPointStart = {x: areaVal.x as number, y:    }; var dataPointEnd = {x:
                    // areaVal.xend as number, y: 10};

                    contextholder
                        .svg
                        .append("path")
                        .datum(dataInToDraw as any)
                        .attr("fill", (areaVal.phase === 1
                            ? secondaryColor
                            : primaryColor))
                        .attr("stroke", (areaVal.phase === 1
                            ? secondaryColor
                            : primaryColor))
                        .attr("stroke-width", "1.5")
                        //Label the filled area path with it's x start and stop.
                        .attr("class", "TTFM-class")
                        .attr("d", d3.area().x(function (d) {
                            return contextholder.xaxis(d["x"])
                        }).y0(contextholder.yaxis(0)).y1(function (d) {
                            return contextholder.yaxis(d["y"])
                        }));
                }
            }
        }
    }

    addLineData = (dataIn: any, seriesColor: string, seriesName: string, xValues: any[], compareMode = false, scrubberPos = 0, makeDashed = false) => {
        let contextholder = this;
        dataIn = contextholder.prepareData(dataIn, seriesName, xValues, compareMode, scrubberPos);
        if (dataIn.length === 0)
        return;
        if (!seriesColor) {
            seriesColor = this.props.color;
        }
        if (dataIn && dataIn[0])
        {
        let startX = dataIn[0]["x"];
        contextholder
            .svglinecanvas
            .append("path")
            .raise()
            .datum(dataIn)
            .attr("stroke", seriesColor)
            .attr("fill", "none")
            .attr("stroke-width", 2)
            .attr("stroke-dasharray", makeDashed ? "3" : "")
            .attr("class", seriesName + "-class line-zone-startx-" + startX)
            .attr("d", d3.line().x(function (d) {
                return contextholder.xaxis(d["x"])
            }).y(function (d) {
                return contextholder.yaxis(d["y"])
            }));
        contextholder.lastDataPointLookup[seriesName] = dataIn.pop();
        }
        else
        {
            console.log("Blank data in add line oddity: ");
            console.log(dataIn);
        }
    }

    clearData = (seriesName: string, lastX: number) => {

        // let lastXToUse = lastX +100;
        // if (lastXToUse < this.props.xaxismax)
        // {
            let gapbetweenlines = 50;
            if (Number.isInteger(lastX) )
            {
            for (let index = lastX ; index < lastX+ gapbetweenlines/2; index++) {
                
                this
                .svg
                .selectAll("path." + seriesName + "-class.filled-zone-startx-" + index)//seriesName + "-class filled-zone-startx-" + startX
                .remove();
            this
                .svglinecanvas
                .selectAll("path." + seriesName + "-class.line-zone-startx-" + index)
                .remove();
              
            }

            if (lastX+ gapbetweenlines/2 > this.props.xaxismax)
            {
                for (let index = 0 ; index < lastX+ gapbetweenlines - this.props.xaxismax; index++) {
                
                    this
                    .svg
                    .selectAll("path." + seriesName + "-class.filled-zone-startx-" + index)//seriesName + "-class filled-zone-startx-" + startX
                  
                    .remove();
                this
                    .svglinecanvas
                    .selectAll("path." + seriesName + "-class.line-zone-startx-" + index)
                    .remove();
                  
                }
            }

            for (let index = lastX+ gapbetweenlines/2 ; index < lastX+ gapbetweenlines; index++) {
                
                this
                .svg
                .selectAll("path." + seriesName + "-class.filled-zone-startx-" + index)//seriesName + "-class filled-zone-startx-" + startX
                .attr("opacity", 0.1);
            this
                .svglinecanvas
                .selectAll("path." + seriesName + "-class.line-zone-startx-" + index)
                .attr("opacity", 0.1);
              
            }
            for (let index = lastX+ gapbetweenlines ; index < lastX+ gapbetweenlines + + gapbetweenlines/2; index++) {
                
                this
                .svg
                .selectAll("path." + seriesName + "-class.filled-zone-startx-" + index)//seriesName + "-class filled-zone-startx-" + startX
                .attr("opacity", 0.3);
            this
                .svglinecanvas
                .selectAll("path." + seriesName + "-class.line-zone-startx-" + index)
                .attr("opacity", 0.3);
              
            }
           

        }
        else
        {

        }
            
        // }
    }

    getScreenCapture = () : string => { // Set-up the export button
        // d3.select('#saveButton').on('click', function(){ 	var svgString =
        // getSVGString(svg.node()); 	svgString2Image( svgString, 2*width, 2*height,
        // 'png', save ); // passes Blob and filesize String to the callback 	function
        // save( dataBlob, filesize ){ 		saveAs( dataBlob, 'D3 vis exported to PNG.png'
        // ); // FileSaver.js function 	} });

       

    //     let x = 100;
    //     let barHeight = 150;
    //     var bar = this.svg.append("g");
      
    // bar.append("rect")
    // .attr("x", -50)
    //     .attr("width", "100%")
    //     .attr("height", "20%")
    //     .attr("y", "80%");
    
    // bar.append("text")
    //     .attr("x", 0)
    //     .attr("y", "90%")
    //     .attr("stroke", "var(--swan)")
    //     .attr("fill", "var(--swan)")
    //     .text("Hello Sailor");

        var svgString = this.getSVGString(this.svg.node().parentNode as Element);

        // bar.remove();

        return this.svgString2Image(svgString);
        //  return (this.ttfmChartd3 as TTFMGraphD3).getScreenCapture();
    }

    // Below are the functions that handle actual exporting: getSVGString ( svgNode
    // ) and svgString2Image( svgString, width, height, format, callback )
    getSVGString = (svgNode: Element) => {
        svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');

      

        var cssStyleText = this.getCSSStyles();

        this.appendCSS(cssStyleText, svgNode);

        var serializer = new XMLSerializer();
        var svgString = serializer.serializeToString(svgNode);
        svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
        svgString = svgString.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix
        svgString = svgString.replace("xmlns=\"http://www.w3.org/1999/xhtml\"", "");
        return svgString;
    }
    getCSSStyles = () => {

        // Extract CSS Rules
        var extractedCSSText = "";
        for (var i = 0; i < document.styleSheets.length; i++) {
            var s = document.styleSheets[i]as CSSStyleSheet;

            try {
                if (!s.cssRules) 
                    continue;
                }
            catch (e) {
                if (e.name !== 'SecurityError') 
                    throw e; // for Firefox
                continue;
            }

            var cssRules = s.cssRules;
            for (var r = 0; r < cssRules.length; r++) {
                if (contains((cssRules[r]as any).selectorText, ":root")) 
                    extractedCSSText += cssRules[r].cssText;
                }
            }

        return extractedCSSText;

        function contains(str: string, arr: string) {
            return arr.indexOf(str) === -1
                ? false
                : true;
        }

    }

    appendCSS = (cssText: string, element: Element) => {
        var styleElement = document.createElement("style");
        styleElement.setAttribute("type", "text/css");
        styleElement.removeAttribute("xmlns");
        styleElement.innerHTML = cssText;
        var refNode = element.hasChildNodes()
            ? element.children[0]
            : null;
        element.insertBefore(styleElement, refNode);
    }

    svgString2Image = (svgString: string) => {

        var imgsrc = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString))); // Convert SVG string to data URL

        return imgsrc;
    }

    render() {

        return ( <> <div id={this.props.id}></div> </>
        );
    }


}

export default ChartD3;