import React, {RefObject} from "react";
import { DataHelper } from "../Helpers/DataHelper";
import { SuggestedLabelMapping } from "../Models/LabelModel";
import { StudyModel } from "../Models/StudyModel";
import { ErrorWindow } from "../Components/ErrorWindow";
import { InfoBox } from "../Components/InfoBox";
import DataGrid from "../Components/DataGrid";
import { PiTrash } from "react-icons/pi";
import { PopupWindow } from "../Components/PopupWindow";
import { InputItem } from "../Components/InputItem";
import { ConfirmationWindow } from "../Components/ConfirmationWindow";

export class LabelMappingWindow extends React.Component<
    {
        dataClient: DataHelper,
        selectedStudy: StudyModel

        //Properties go heresrc/Settings/.tsx
    },
    {
        //State goes here
        unsavedLabelMappings: SuggestedLabelMapping[],
        unsavedDeleteLabelMappings: SuggestedLabelMapping[],
        labelMappings: SuggestedLabelMapping[],
        isLoading: boolean,
        error: string | null,
        rowData: any,
        popupVisibleTrashCan: boolean,
        popupVisibleStageChages: boolean,
        currentInputValueDict: { [key: string]: string },
        dbLabels:SuggestedLabelMapping[]
    }
> {
    confirmationWindow: RefObject<ConfirmationWindow>;
    errorWindow:  RefObject<ErrorWindow>;
    infoBox: RefObject<InfoBox>

    constructor(props: any) {
        super(props);
        this.errorWindow = React.createRef();
        this.infoBox = React.createRef();
        this.confirmationWindow = React.createRef();
        this.state = {
            unsavedLabelMappings:[],
            unsavedDeleteLabelMappings:[],
            labelMappings: [],
            dbLabels:[],
            currentInputValueDict:{"":""},
            isLoading: false,
            error: null,
            popupVisibleTrashCan: false,
            popupVisibleStageChages:true,
            rowData:""
        }
    }


    getAllMappedLabels = async (): Promise<void> => {
        //this.setState({ isLoading: true, error: null });
        try {
            this.props.dataClient.CallSettingsService(
                "GetAllSuggestedLabelMappings",
                {},
                {studyId: this.props.selectedStudy.id},
                (outputValue: string) => {
                    var mappings = JSON.parse(outputValue) as SuggestedLabelMapping[];
                    var textBoxDict: { [key: string]: string } = {};
                    for (let i = 0; i < mappings.length; i++){
                        mappings[i].timesUsed=mappings[i].associatedNames.length.toString()
                        textBoxDict[mappings[i].predefinedLabel] = "";
                    }

                    this.setState({ 
                        labelMappings: mappings,
                        currentInputValueDict:textBoxDict
                    });

                }
            );
        } catch (error) {
            this.setState({ error: "Failed to fetch label mappings" });
        } finally {
            this.setState({ isLoading: false });
        }
    }


    associatedNamesToString=(labelList:SuggestedLabelMapping[])=>{
        const  suggestionLabelString:string[]=[];

        labelList?.forEach((element) => {
                if (typeof element === 'string') {
                    suggestionLabelString.push(element);
                }
        });
        return suggestionLabelString
    }


    closeLabelMapWindow=(onYes: () => void, onNo: () => void, callback: () => void)=>{
        if(this.confirmationWindow.current && (this.state.unsavedLabelMappings.length>0 || this.state.unsavedDeleteLabelMappings.length>0) ) {
            this.confirmationWindow.current.show(
                ()=>{
                    this.updateSuggestedLabels(()=>{onYes(); callback()})
                } ,
                ()=>{
                    onNo();
                    callback();
                }, 
                ()=>{
                    
                    this.confirmationWindow.current?.hide()
                }  
            )
        }else{
            onNo();
            callback();
            
        }
    }


    //alter label, to make local changes, stage add or deletes for update funtion
    alterLabel = (predefined: string, value: string, remove: boolean, add?: boolean) => {
        this.setState(prevState => {
            let updatedLabelMappings = [...prevState.labelMappings];
            let updatedUnsavedLabelMappings = [...prevState.unsavedLabelMappings];
            let updatedUnsavedDeleteLabelMappings = [...prevState.unsavedDeleteLabelMappings]
    
            if (!remove) {
                // Find the label to update
                const labelIndex = updatedLabelMappings.findIndex(label => label.predefinedLabel === predefined);
                
                if (labelIndex !== -1 && add) {
                    const labelToUpdate = updatedLabelMappings[labelIndex];
                    
                    // Add the new value to associatedNames if it doesn't already exist
                    if (!labelToUpdate.associatedNames.includes(value)) {
                        updatedLabelMappings[labelIndex] = {
                            ...labelToUpdate,
                            timesUsed:(labelToUpdate.associatedNames.length+1).toString(),
                            associatedNames: [...labelToUpdate.associatedNames, value]
                        };
    
                        // Update or add to unsavedLabelMappings
                        const unsavedIndex = updatedUnsavedLabelMappings.findIndex(
                            label => label.predefinedLabel === predefined
                        );
    
                        if (unsavedIndex !== -1) {
                            updatedUnsavedLabelMappings[unsavedIndex] = {
                                ...updatedUnsavedLabelMappings[unsavedIndex],
                                timesUsed:"1",//times used determin wheter to delete or save later?
                                associatedNames: [...updatedUnsavedLabelMappings[unsavedIndex].associatedNames, value]
                            };
                        } else {
                            const newLabel: SuggestedLabelMapping = {
                                predefinedLabel: predefined,
                                associatedNames: [value],
                                timesUsed: "1"
                            };
                            updatedUnsavedLabelMappings.push(newLabel);
                        }
                    }
                }
            } else {//delete from local state
                const labelIndex = updatedLabelMappings.findIndex(label => label.predefinedLabel === predefined);
                
                if (labelIndex !== -1 && remove) {
                    const labelToDelete = updatedLabelMappings[labelIndex];
                    const associatedNames = labelToDelete.associatedNames.filter(name=>name!==value)
                    // Add the new value to associatedNames if it doesn't already exist
                    if (labelToDelete.associatedNames.includes(value)) {
                        updatedLabelMappings[labelIndex] = {
                            ...labelToDelete,
                            timesUsed:(labelToDelete.associatedNames.length-1).toString(),
                            associatedNames: associatedNames
                        };
                    }
                    
                    const unsavedIndex = updatedUnsavedDeleteLabelMappings.findIndex(
                        label => label.predefinedLabel === predefined
                    );

                    if (unsavedIndex !== -1) {
                        const associatedNamesUnsaved= updatedUnsavedDeleteLabelMappings[unsavedIndex].associatedNames.filter(name=>name!==value)
                        updatedUnsavedDeleteLabelMappings[unsavedIndex] = {
                            ...updatedUnsavedDeleteLabelMappings[unsavedIndex],
                            timesUsed:"-1",
                            associatedNames: [...associatedNamesUnsaved, value]
                        };
                    } else {
                        const newLabel: SuggestedLabelMapping = {
                            predefinedLabel: predefined,
                            associatedNames: [value],
                            timesUsed: "1"
                        };
                        updatedUnsavedDeleteLabelMappings.push(newLabel);
                    }
                }
                
            }
            return {
                labelMappings: updatedLabelMappings,
                unsavedLabelMappings: updatedUnsavedLabelMappings,
                unsavedDeleteLabelMappings: updatedUnsavedDeleteLabelMappings
            };
        });
    }


    updateSuggestedLabels = (callback: ()=> void): void => {
        try {
            this.props.dataClient.CallSettingsService(
                "UpdateSuggestedLabelsUsingMap",
                {studyId: this.props.selectedStudy.id, saveLabelModels: this.state.unsavedLabelMappings, deleteLabelModels: this.state.unsavedDeleteLabelMappings},
                {},
                () => {
                    (this.infoBox.current as InfoBox).show("Labels updated");
                    this.setState({
                        unsavedLabelMappings:[],
                        unsavedDeleteLabelMappings:[]
                    });
                    callback();
                }, ()=>{}, "POST"
            );
        } catch (error) {
            this.setState({ error: "Failed to fetch label mappings" });
            this.errorWindow.current?.show(700, "Failed to fetch label mappings")
        } finally {
            this.setState({ isLoading: false });
        }

    }


    handleDeletePredefined(predefinedLabel=this.state.rowData.id,associatedName:string=""): SuggestedLabelMapping[]{
        var mappings: SuggestedLabelMapping[]=[]
        if(associatedName.length===0){
            try {
                this.props.dataClient.CallSettingsService(
                    "DeleteLabelMapping",
                    {},
                    {studyId: this.props.selectedStudy.id, labelMappingId: predefinedLabel},
                    (outputValue: string) => {
                        mappings = JSON.parse(outputValue) as SuggestedLabelMapping[];
                        this.setState({ 
                            labelMappings: mappings 

                        });
                        if (this.state.unsavedLabelMappings.some(mapping => mapping.predefinedLabel === predefinedLabel)) {
                            this.setState(prevState => ({
                                unsavedLabelMappings: prevState.unsavedLabelMappings.filter(mapping => mapping.predefinedLabel !== predefinedLabel)
                            }));
                        }
                        if (this.state.unsavedDeleteLabelMappings.some(mapping => mapping.predefinedLabel === predefinedLabel)) {
                            this.setState(prevState => ({
                                unsavedDeleteLabelMappings: prevState.unsavedDeleteLabelMappings.filter(mapping => mapping.predefinedLabel !== predefinedLabel)
                            }));
                        }
                    }, (status: number, errorMessage: string) => {
                        (this.errorWindow.current as ErrorWindow).show(status, errorMessage);
                    }, "DELETE"
                );
            } catch (error) {
                this.setState({ error: "Failed to fetch label mappings" });
            } finally {
                this.setState({ isLoading: false });
            }
        }
        return mappings
        
    }


    componentDidMount(): void {
        this.getAllMappedLabels()
    }


    render() {
        return (
            <div className="menu">

                <ErrorWindow ref={this.errorWindow}></ErrorWindow>
                <InfoBox ref={this.infoBox} title="Confirmation" type="confirmation"></InfoBox>
                <ConfirmationWindow ref={this.confirmationWindow} title="Save Changes?" message="There are unsaved changes, save before closing?"></ConfirmationWindow>


                {((this.state.popupVisibleTrashCan) ?
                            <PopupWindow title="Are you sure you want to delete this label? This not reverable" closeClicked={() => { this.setState({ popupVisibleTrashCan: false }) }}>
                                <button className="primaryButton" onClick={() => { this.handleDeletePredefined(); this.setState({ popupVisibleTrashCan: false }); }}>Yes</button>
                                <button className="primaryButton" onClick={() => {this.setState({ popupVisibleTrashCan: false })}}>No</button>
                            </PopupWindow> : <></>)}

                <DataGrid usePaging={true} popupWindow={true} columns={[
                                { Name: "predefinedLabel", Caption: "Pre Defined Label" },
                                { Name: "timesUsed", Caption: "Times Used" },
                                { Name: "associatedNames", Caption: "Associated Names" , RenderTemplate: (rowData, row)=>(
                                
                                    <div className="SelectionOptionsHolder" title={row.name}>
                                        
                                        <InputItem type={"addableTextField"} disableButton={this.state.currentInputValueDict[row.id] !== '' ? false : true} value={this.state.currentInputValueDict[row.id]} title="Add Item" name={"addItem"} options={["---Select---"]} onChange={(name, value,shouldAdd)=>{
                                            
                                             if(shouldAdd){
                                                this.alterLabel(row.id, value, false ,shouldAdd)
                                                this.setState(prevState => ({
                                                    currentInputValueDict: {
                                                        ...prevState.currentInputValueDict,
                                                        [row.id]: ''
                                                        
                                                    }
                                                }))
                                            } else {
                                                this.setState(prevState => ({
                                                    currentInputValueDict: {
                                                        ...prevState.currentInputValueDict,
                                                        [row.id]: value
                                                    }
                                                }));
                                            }}}/>
                                        <div className="associatedNamesContainer">
                                        <InputItem  type={"multipleSelect" ||""} title="" name={"suggestedLabel"} chosenOptions={this.associatedNamesToString(row.associatedNames)} deleteOption={(value)=>this.alterLabel(row.id,value,true)}/> 
                                        </div> 
                                    </div>)},
                                {Name: "delete", Caption: "Delete", Editable: true, RenderTemplate: (rowdata, row) => (
                                        <PiTrash className="buttonIcon" onClick={() =>

                                            this.setState({ popupVisibleTrashCan: true, rowData: row})
                                        } />

                                    )
                                }]} rows={this.state.labelMappings as any} onChange={(rowdata, columnChanged, value, index, submitToServer) => {}}></DataGrid>


                                <button disabled={(this.state.unsavedDeleteLabelMappings.length>0||this.state.unsavedLabelMappings.length>0)? false : true} className="primaryButton" style={{ justifySelf: 'center',backgroundColor: (this.state.unsavedLabelMappings.length>0 || this.state.unsavedDeleteLabelMappings.length>0) ? 'orange' : '' }} onClick={()=>{

                                     this.updateSuggestedLabels(()=>{
                                        this.setState({
                                            dbLabels: this.state.labelMappings,
                                            unsavedLabelMappings:[],
                                            unsavedDeleteLabelMappings:[]
                                        })
                                     })

                                }}>Update Suggested Labels</button>
            </div>
        );

    }
}


