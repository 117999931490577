
export class EvalFlowCriteria {
    private criteriaRanges: { [key: string]: CriteriaRange };

    constructor() {
        this.criteriaRanges = {
            "From Centre": {
                upperBound: 4,
                lowerBound: 2,
                smallerThanGood: false
            },
            "Distance Mean": {
                upperBound: 2,
                lowerBound: 1,
                smallerThanGood: true
            },
            "Distance Median": {
                upperBound: 2,
                lowerBound: 1,
                smallerThanGood: true
            },
            "Cluster Consistency": {
                upperBound: 7.5,
                lowerBound: 5,
                smallerThanGood: false
            },
            "Cluster Rating": {
                upperBound: 8,
                lowerBound: 4,
                smallerThanGood: false
            }
        };
    }

    getCriteria(key: string): CriteriaRange | undefined {
        return this.criteriaRanges[key];
    }

    getAllCriteria(): { [key: string]: CriteriaRange } {
        return { ...this.criteriaRanges };
    }
}

interface CriteriaRange {
    upperBound: number;
    lowerBound: number;
    smallerThanGood: boolean;
}