import React , { RefObject } from "react";
import { PieChart, Pie, Legend } from "recharts";
import { DataHelper } from "../Helpers/DataHelper";
import { ChartDataModel } from "../Models/ChartDataModel";
import { LoadingWidget } from "../Components/LoadingWidget";
import { ErrorWindow } from "../Components/ErrorWindow";

export class MeasurementTypesPerStudy extends React.Component<
    {
        dataClient: DataHelper,
        studyId: string
    },
    {
        measurementData: ChartDataModel[],
        isBusy: boolean
    }
> {

    //Data structure: 
    // data = [
    //     { name: "category", value: number, fill: '#color' }
    // ];

    renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }: {
        cx: number;
        cy: number;
        midAngle: number;
        innerRadius: number;
        outerRadius: number;
        percent: number;
        index: number;
    }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="#4A4A4A"
                textAnchor="middle"
                dominantBaseline="middle"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    style = {
        top: 0,
        left: 350,
        lineHeight: "24px"
    };

    errorWindow:  RefObject<ErrorWindow>;
    constructor(props: any) {
        super(props);
        this.errorWindow = React.createRef();
        this.state = {
            measurementData: [],
            isBusy: false
        }
    }

    getData = (studyId: string): void => {
        this.setState({ isBusy: true })
        this.props.dataClient.CallDashboardService("GetMeasurementTypesPerStudy", {}, {studyId: this.props.studyId}, (result: string) => {
            let measurementTypes = JSON.parse(result);
            const colors = ['#3F51B5', '#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57', '#ffc658', '#F58723', '#F54B23', '#D4021B', '#9D0303'];
            for (let index = 0; index < measurementTypes.length; index++) {
                const element = measurementTypes[index];
                element.fill = colors[index % colors.length];
            }
            this.setState({ measurementData: measurementTypes, isBusy: false })
        }, (stautus:number, errorMessage:string)=>{
            (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage)}, "GET", false)}
    
    componentDidMount(): void {}

    render() {

        return (
            <>
            <ErrorWindow ref={this.errorWindow}/>
            <h3>Measurement types</h3>
                <div className="chartHolder">
                
                    <PieChart width={650} height={400}>
                        <Pie
                            dataKey="value1"
                            data={this.state.measurementData}
                            cx={200}
                            cy={200}
                            outerRadius={140}
                            labelLine={false}
                            label={this.renderCustomizedLabel}
                        />
                        <Legend
                            layout="vertical"
                            align="right"
                            height={300}
                            width={250}
                        />
                    </PieChart>
                    <LoadingWidget visible={this.state.isBusy} zIndexLayer={0}></LoadingWidget>
                </div>
            </>
        );
    }
}
