import React, { ReactNode } from "react";
import { PiX, PiQuestion } from "react-icons/pi";

interface ConfirmationWindowProps {
  title: string;
  message: string;
  children?: ReactNode;
}

interface ConfirmationWindowState {
  visible: boolean;
  onYes: () => void;
  onNo: () => void;
  onCancel: () => void;
}

export class ConfirmationWindow extends React.Component<ConfirmationWindowProps, ConfirmationWindowState> {
  constructor(props: ConfirmationWindowProps) {
    super(props);
    this.state = {
      visible: false,
      onYes: () => {},
      onNo: () => {},
      onCancel: () => {},
    };
  }

  show = (onYes: () => void, onNo: () => void, onCancel: () => void) => {
    this.setState({
      visible: true,
      onYes,
      onNo,
      onCancel,
    });
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  render() {

    return (this.state.visible ?
      <div className="confirmationWrapper">
        <div className="confirmationContainer">
          <div className="confirmationHeader">
            <h3 className="confirmationTitle">{this.props.title}</h3>
            <PiX className="confirmationCloseButton" onClick={this.state.onCancel} />
          </div>
          <div className="confirmationBody">
            <PiQuestion className="confirmationIcon" />
            <div className="confirmationText">
              <div className="confirmationMessage">{this.props.message}</div>
              {this.props.children}
            </div>
          </div>
          <div className="confirmationButtons">
            <button className="primaryButton" onClick={() => { this.state.onYes(); this.hide(); }}>
              Yes
            </button>
            <button className="primaryButton" onClick={() => { this.state.onNo(); this.hide(); }}>
              No
            </button>
            <button className="primaryButton" onClick={() => { this.state.onCancel(); this.hide(); }}>
              Cancel
            </button>
          </div>
        </div>
      </div>:<></>
    );
  }
}