//Ignore any TS errors here bcaus the d3 and html references don't play very well
//@ts-nocheck
import * as React from 'react';
import {TileRange} from "../Helpers/SharedClasses"
import * as d3 from 'd3';

class GaugeTile extends React.PureComponent<{context: string, enableColouring: boolean,valueStale: boolean, title: string,value: number,
  subValue: number, subValueUom: string, uom: string,includeSubValue: boolean, defaultColor: string, maxValue: number,
  includeEdit: boolean, goodRange: TileRange, badRange: TileRange, worstRange: TileRange, wideTile: boolean, expanded: boolean, referenceValueStyle: string, 
  tileClicked: Function, tileValueChanged: Function, reverseValues: boolean, goodColor: string, badColor: string, worstColor: string, goodClass: string, badClass: string, worstClass: string, overridename: string, sizeFactor: number }, { isediting: boolean } >
 {

    constructor(props: any) {
        super(props);

      this.state = {
        isediting: false
      }

      }
      componentDidUpdate = () =>
      {
   
    
            this.drawReferenceValuesGauge();
          
      }

      static defaultProps = {
        context: "TTFM",
        title: "ML",
        uom: "mL/min",
        value: 0,
        sizeFactor:1,
        subValue: "",
        status: 0,
        subValueUom: "",
        enableColouring: true,
        defaultColor: "tile-no-data",
        includeEdit: false,
        includeSubValue: false,
        referenceValueStyle: "Bar",
        reverseValues: false,
        worstColor: "var(--BernRed)",
        badColor: "var(--BananaClan)",
        goodColor: "var(--GreenApple)",
        worstClass: "tile-redlight",
        badClass: "tile-yellowlight",
        goodClass: "tile-greenlight",
        goodRange: {
           min: -1,
           max: 0
        },
        badRange: {
          min: 90,
          max: 99
       },
       worstRange: {
        min: 100,
        max: 1000
      },
      wideTile: false,
      maxValue: 10000,
      expanded: true,
      valueStale: false,
      tileValueChanged: ()=>{}

      }

resizeGauge = () =>
{
  this.clearReferenceValuesBar();
  this.gaugeCreated = null;
}

      getLightColorForTile()
      {
        if (this.props.enableColouring)
        {
            if (this.props.value >= this.props.goodRange.min &&  this.props.value <= this.props.goodRange.max )
            {
              return this.props.goodClass;
            }
            else if (this.props.value >= this.props.badRange.min &&  this.props.value <= this.props.badRange.max )
            {
              return this.props.badClass;
            }
            else if (this.props.value >= this.props.worstRange.min &&  this.props.value <= this.props.worstRange.max )
            {
              return this.props.worstClass;
            }

            return this.props.defaultColor;
        }
        else
        {
          return this.props.defaultColor;
        }
      }

      calculateValueOnAxis(value: any, axisMin: any, axisMax: any, axisLength: any, axisOffset: any)
      {
        return axisLength*(value/(axisMax - axisMin)) + axisOffset;
      }

      calculateValueOnAxisEvenParts(value: any, axisMin: any, axisMax: any, axisLength: any, axisOffset: any)
      {
        let valuePercentage = value/(axisMax - axisMin);
        let numberOfParts = 3;
        
        let partsPercentage = 1/numberOfParts;
        for (let index = 0; index < numberOfParts; index++) {
            if (partsPercentage * index <= valuePercentage && partsPercentage * (index +1) <= valuePercentage )
            {
              //return partNumber;
              return [axisLength*(partsPercentage * (index) ) + axisOffset, axisLength*(partsPercentage * (index +1) ) + axisOffset] ;
            }
        }
        return [0, axisLength + axisOffset];
        // if (1 * partsPercentage  )
        // partNumber

        // let eachLength = axisLength/numberOfParts;
        // if (eachLength)
        // return axisLength*(value/(axisMax - axisMin)) + axisOffset;
      }

      calculateZone(value: any, axisMin: any, axisMax: any)
      {
        let valuePercentage = value/(axisMax - axisMin);
        let numberOfParts = 3;

        let partsPercentage = 1/numberOfParts;
        for (let index = 0; index < numberOfParts; index++) {
            if (partsPercentage * index <= valuePercentage && valuePercentage <= partsPercentage * (index +1) )
            {
              //return partNumber;
             return index;
            }
        }
        return 0;
        // if (1 * partsPercentage  )
        // partNumber

        // let eachLength = axisLength/numberOfParts;
        // if (eachLength)
        // return axisLength*(value/(axisMax - axisMin)) + axisOffset;
      }

      calculateZoneOrders = () =>
      {
         let ZoneList = [{
           color: this.props.goodColor,
           min: this.props.goodRange.min,
           max: this.props.goodRange.max,
           yValue: 0,
           heightValue: 0
         }
         ,{
          color: this.props.badColor,
          min: this.props.badRange.min,
          max: this.props.badRange.max,
          yValue: 0,
          heightValue: 0
        },
        {
          color: this.props.worstColor,
          min: this.props.worstRange.min,
          max: this.props.worstRange.max,
          yValue: 0,
          heightValue: 0
        }
        ];
        //If the worst range is zero then don't display it a worst range.
        if ((this.props.worstRange.min === 0 && this.props.worstRange.max === 0))
        {
          ZoneList[2].color = this.props.badColor;
        }

        for (let mainindex = 0; mainindex < ZoneList.length; mainindex++) {
        for (let index = mainindex+1; index < ZoneList.length; index++) {
          const element = ZoneList[mainindex];
          const element1 = ZoneList[index];
          if (element1.max > element.max)
          {
            //If they are out of order then Swap.
            ZoneList[mainindex] = element1;
            ZoneList[index] = element;
          }
        }
      }
if (this.props.reverseValues)
  return ZoneList.reverse();
else
        return ZoneList;
      }


      getZoneBasedOnPosition = (valueIn: any, zoneList: any) =>
      {
   
        let value = parseFloat(valueIn);
        let zoneItIsIn = null;
        for (let index = 0; index < zoneList.length; index++) {
          const element = zoneList[index];
          if (!zoneItIsIn && element.min <= value && value <= element.max )
          {
            zoneItIsIn = element;
          }
        }
      
        if (zoneItIsIn === null){
            //If its below the minimum range then set to the first range.
            if (zoneList[zoneList.length - 1].min > value)
            {
           
              zoneItIsIn = zoneList[zoneList.length - 1];
            }else if (zoneList[0].max < value)
            {
              
              zoneItIsIn = zoneList[0];
            }else
            {
              //This should be impossible!
              
              zoneItIsIn = zoneList[1];
            }
        }

        return zoneItIsIn;
      }

      getZoneIndexBasedOnPosition = (valueIn, zoneList) =>
      {
        let zoneIndex = -1;
        let value = parseFloat(valueIn);

        for (let index = 0; index < zoneList.length; index++) {
          const element = zoneList[index];
          if (zoneIndex  === -1 && element.min <= value && value <= element.max )
          {
            zoneIndex = index;

          }
        }

        if (zoneIndex  === -1){
            //If its below the minimum range then set to the first range.

            let startZone = zoneList.length - 1;
            let endZone = 0;
            if (this.props.reverseValues)
            {
            startZone = 0;
            endZone = zoneList.length - 1;
            }
            if (zoneList[startZone].min > value)
            {
           
              zoneIndex = startZone;
            }else if (zoneList[endZone].max < value)
            {
              
              zoneIndex = endZone;
            }else
            {
              //This should be impossible!
             
              zoneIndex = 1;
            }
        }
        // if (this.props.reverseValues)
        //     return (zoneList.length - zoneIndex);
        // else
            return zoneIndex;
      }



      getPositionBasedOnZone = (valueIn: any, zoneList: any, returnPercent = false) =>
      {
   
        let value = parseFloat(valueIn);
        let zoneItIsIn = null;
        for (let index = 0; index < zoneList.length; index++) {
          const element = zoneList[index];
          if (!zoneItIsIn && element.min <= value && value <= element.max )
          {
            zoneItIsIn = element;
          }
        }
        let percentPos = 0;
        if (zoneItIsIn === null){

          let startZone = zoneList.length - 1;
          let endZone = 0;
          if (this.props.reverseValues)
          {
          startZone = 0;
          endZone = zoneList.length - 1;
          }
            //If its below the minimum range then set to the first range.
            if (zoneList[startZone].min > value)
            {
              percentPos = 0;
              zoneItIsIn = zoneList[startZone];
            }else if (zoneList[endZone].max < value)
            {
              percentPos = 1;
              zoneItIsIn = zoneList[endZone];
            }else
            {
              //This should be impossible!
              percentPos = 0.5;
              zoneItIsIn = zoneList[1];
            }
        }else
        {

        let range = (zoneItIsIn.max - zoneItIsIn.min);
        if (range === 0)
        {
          //If there is no range make the value 100%
          
          range = value;
        }
        if (range === 0)
        {
          percentPos = 0;
        }else
        {
            percentPos = (value - zoneItIsIn.min) /range;
        }
      }
      if (returnPercent)
      {  
         if (this.props.reverseValues)
        return 1-percentPos;
        else
        return percentPos;
      }
      else
      {

      let drawnPos = (1-percentPos) * zoneItIsIn.heightValue + zoneItIsIn.yValue;
      return drawnPos;
      } 
    }

      clearReferenceValuesBar = () =>
      {
        d3
        .selectAll("#gauge-" + this.props.overridename+"-ttfm-gauge-" + this.props.title + "-" + this.props.context + ' .ttfm-gauge-reference-values')
        .select("svg")
        .remove();
      }


      orderValueRanges = () =>
      {
        //  let
      }

     deg2rad = (deg: any) => {
        return deg * Math.PI / 180;
      }
      
      newAngle = (d: any, config: any) => {

        var ratio = this.scaleGauge(d);
        var newAngle = config.minAngle + (ratio * this.rangeGauge);
        return newAngle;
      }
      
      rangeGauge = null;
      scaleGauge = null;
      pointerGauge = null;
      pointerHeadLength = null;
      arcGauge = null;
      tickData = null;
      ticksGauge = null;
      gaugeCreated = null;
      configure = (configuration) => {
    
        
        this.rangeGauge = configuration.maxAngle - configuration.minAngle;
        let r = configuration.size / 2;
        
        this.pointerHeadLength = Math.round(r * configuration.pointerHeadLengthPercent);
    
        // a linear scale that maps domain values to a percent from 0..1
        this.scaleGauge = d3.scaleLinear()
          .range([0,1])
          .domain([configuration.minValue, configuration.maxValue]);
          let ticksOut = [];
          for (let index = this.ZoneList.length-1; index >= 1; index--) {
              const element = this.ZoneList[index];
              if (this.props.reverseValues)
                ticksOut.push({index: index, caption:  element.min});
              else
                ticksOut.push({index: index, caption:  element.max});
          }   
         
          
        this.ticksGauge =  ticksOut;//this.scaleGauge.ticks(configuration.majorTicks-2);
        this.tickData = d3.range(configuration.majorTicks).map(function() {return 1/configuration.majorTicks;});
        let thisHolder = this;
         this.arcGauge = d3.arc()
          .innerRadius(r - configuration.ringWidth - configuration.ringInset)
          .outerRadius(r - configuration.ringInset)
          .startAngle(function(d, i) {
            let dIn = d as any;
            var ratio = dIn * i;
            return thisHolder.deg2rad(configuration.minAngle + (ratio * thisHolder.rangeGauge));
          })
          .endAngle(function(d, i) {
            let dIn = d as any;
            var ratio = dIn * (i+1);
            return thisHolder.deg2rad(configuration.minAngle + (ratio * thisHolder.rangeGauge));
          });
      }

        
      centerTranslation = (r, xOffset) =>{
        let xPos = r + xOffset;
        return 'translate('+xPos +','+ r +')';
      }
      
    //  isRendered = () => {
    //     return (svg !== undefined);
    //   }

      renderGauge = (newValue, config, svg) => {
    
        var that = this;
        let r = config.size / 2;
        var centerTx = this.centerTranslation(r, config.xOffset);
        
        var arcs = svg.append('g')
            .attr('class', 'arc')
            .attr('transform', centerTx);
        
        arcs.selectAll('path')
            .data(this.tickData)
          .enter().append('path')
            .attr('fill', function(d, i) {
              return config.arcColorFn(d * i);
            })
            .attr('d', this.arcGauge);
            let thisHolder = this;
        var lg = svg.append('g')
            .attr('class', 'label')
            .attr('transform', centerTx);
        lg.selectAll('text')
            .data(this.ticksGauge)
          .enter().append('text')
            .attr('transform', function(d) {
              thisHolder.scaleGauge(d);
              var newAngle = 60*(that.ZoneList.length - d.index) + config.minAngle -5;
              return 'rotate(' +newAngle +') translate(0,' +(config.labelInset - r) +')';
            })
            .text(function(d) {
              return d.caption;
            });
    
        var lineData = [ [config.pointerWidth / 2, 0], 
                [0, -this.pointerHeadLength],
                [-(config.pointerWidth / 2), 0],
                [0, config.pointerTailLength],
                [config.pointerWidth / 2, 0] ];
        var pointerLine = d3.line().curve(d3.curveLinear)
        var pg = svg.append('g').data([lineData])
            .attr('class', 'pointer')
            .attr('transform', centerTx);
            
            this.pointerGauge = pg.append('path')
          .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/ )
          .attr('transform', 'rotate(' +config.minAngle +')');
          
          this.update(newValue === undefined ? 0 : newValue, config, svg);
      }

      update = (newValue, config, svg) => {
        // if ( newConfiguration  !== undefined) {
        //   this.configure(newConfiguration);
        // }
        let newAngle = -90;
        if (newValue !== "--")
         {

            let positionInZone = this.getPositionBasedOnZone(newValue, this.ZoneList, true);

            let ZoneIndex = (this.ZoneList.length - this.getZoneIndexBasedOnPosition(newValue, this.ZoneList))-1;
            newAngle = ZoneIndex*60 + (60)*positionInZone + config.minAngle;
         }

        this.pointerGauge.transition()
          .duration(config.transitionMs)
          .ease(d3.easeElastic)
          .attr('transform', 'rotate(' +newAngle +')');
      }


     gauge = (container, configuration, svg) => {
        
     
      };
      ZoneList = null;
      majorTicks = null;
      drawReferenceValuesGauge = () =>
      {

        this.majorTicks = 3;
        this.ZoneList = this.calculateZoneOrders();

        var widthToUse = document.getElementById("gauge-"+this.props.overridename+"-ttfm-gauge-" + this.props.title+ "-" + this.props.context).offsetWidth;
        //var widthToUse = (document.getElementsByClassName("tile-container")[0] as HTMLElement).offsetWidth/5 -60 ;
        var heightToUse = widthToUse/2;
        let gaugeWidth = widthToUse;
        let xOffset = 0;
        if (this.props.sizeFactor > 1)
        {
          if (heightToUse > 40)
          {
            //If the gauge is larger than 180 in height then rather adjust the width.
            heightToUse = 70; 
            xOffset = widthToUse/2 - heightToUse;
            gaugeWidth = heightToUse*2;
          }
        }else
        {
        
        if (heightToUse > 80)
        {
          //If the gauge is larger than 180 in height then rather adjust the width.
          heightToUse = 100; 
          xOffset = widthToUse/2 - heightToUse;
          gaugeWidth = heightToUse*2;
        }
      }
        (document.getElementById("gauge-"+this.props.overridename+"-ttfm-gauge-" + this.props.title+ "-" + this.props.context).getElementsByClassName("gauge-middle")[0] as HTMLElement).style.height = heightToUse + "px";
        let defaultGaugeConfig = {
            size						: gaugeWidth,
            ringInset					: 30,
            ringWidth					: 42,
            xOffset           : xOffset,
            pointerWidth				: 20,
            pointerTailLength			: 4,
            pointerHeadLengthPercent	: 0.70,
            
            minValue					:  this.ZoneList[this.ZoneList.length-1].min,
            maxValue					: this.ZoneList[0].max,
            
            minAngle					: -81,
            maxAngle					: 81,
            
            transitionMs				: 1550,
            
            majorTicks					: this.majorTicks,
           // labelFormat					: d3.format('d.caption'),
            labelInset					: 10,
            
            arcColorFn					:  this.determineArcColor
          };
          
        if (!this.gaugeCreated)
        {
          
            
        this.gaugeCreated = d3.select("#gauge-" + this.props.overridename+ "-ttfm-gauge-" + this.props.title + "-" + this.props.context + ' .ttfm-gauge-reference-values')
        .append('svg')
        .attr('class', 'gauge')
        .attr('height', heightToUse)
        .attr('width', widthToUse);
 
          d3.pie();
          this.configure(defaultGaugeConfig);
          this.renderGauge(this.props.value, defaultGaugeConfig, this.gaugeCreated );
      }else
      {
        this.update(this.props.value === undefined ? 0 : this.props.value, defaultGaugeConfig,this.gaugeCreated);
      }
      }


      determineArcColor = (t) =>
      {
        let zoneIn = Math.round(this.majorTicks * (1-t))-1;
        let ZoneFound = this.ZoneList[zoneIn];
        return ZoneFound.color;
      }


      editValueClicked = (e) =>
      {
       
       this.setState({
         isediting: true,
       // overridevalue: valueIn 
       });
       e.stopPropagation();
      }

      blurValue = () =>
      {
       this.setState({
         isediting: false
       })
      }

    render() {
    let rightHtml: React.ReactFragment;
    if (this.props.includeEdit)
    {
      rightHtml = <div className="gauge-title-right" onClick={this.editValueClicked}><i className="material-icons"
      style={{fontSize: 22, color: "var(--swan)"}}>edit</i></div>;
    }
    if (this.props.includeSubValue)
    {
      rightHtml = <div className="gauge-subvalue BPM-value">{this.props.subValue}<br/><div className="gauge-title-right BPM">{this.props.subValueUom}</div></div>;
    }

    let valueFragment =  <div className={"gauge-value "+(this.props.valueStale?"gauge-value-stale":"")}> {this.props.value > this.props.maxValue ? this.props.maxValue: this.props.value}</div>;
    if (this.state.isediting)
    {
      valueFragment= <div className="gauge-value"> <input className="gauge-value-input" type="text" autoFocus={true} defaultValue={this.props.value} onBlur={this.blurValue} onChange={(e)=>{this.props.tileValueChanged(this.props.title, e.target.value);}} /></div>;
    }
    let referenceValuesTop: React.ReactFragment;

  referenceValuesTop =   <div className={"ttfm-gauge-reference-values ttfm-gauge-reference-values-expanded"}>
  </div>;


        return (
          <div id={"gauge-" + this.props.overridename+"-ttfm-gauge-" + this.props.title+ "-" + this.props.context}  className={ "ttfm-gauge-container " }>
               <div className={ (this.props.wideTile ? " TTFM-gauge-wide" : " TTFM-gauge-basic")} onClick={(e) => { this.props.tileClicked(e);}}>
               <div className="gauge-title">
          <div className="gauge-title">{this.props.title}</div>
          </div>
          <div className="gauge-middle">
          {referenceValuesTop}
          </div>
         <div className="gauge-bottom">
         {valueFragment}
         {rightHtml}
         <div className="gauge-uom"> {this.props.uom}</div>
         </div>
       
        </div>
        </div>
    );
    }


}

export default GaugeTile;