
import React from "react";

export class UploadDocumentation extends React.Component<
    {

    },
    {
    }
> {

    constructor(props: any) {
        super(props);
        this.state = {

        }
    }
    render() {

        return (
            <>
                <div className="dashboardText">
                    <p><strong>1. Select Study:</strong> Select your desired study.</p>
                    <p><strong>2. Initiate Upload:</strong> Click the 'Upload' button in the top right corner.</p>
                    <p><strong>3. Choose Data:</strong> Press 'Upload' and select .dat, .sha, and .xml files for upload.</p>
                    <p><strong>4. Specify Details:</strong> Indicate desired procedures and measurements.</p>
                    <p><strong>5. Additional Data:</strong> To upload more data, click the 'Upload' button and repeat steps 3 and 4.</p>
                    <p><strong>6. Enhance Organization:</strong> Optionally, add an import label and request a confirmation email.</p>
                    <p><strong>7. Confirm Upload:</strong> Review, then click the 'Confirm' button to upload data to the study dashboard.</p>
                    <p><a target="_blank" rel="noopener noreferrer" title="personal data protection policy" href="https://medistim.com/about-us/personal-data-protection-policy/">More detailed guides are available here</a></p>
                    <p><a target="_blank" rel="noopener noreferrer" title="personal data protection policy" href="https://www.medistimtraining.com/caseCloud/index.html">Personal Data Protection Policy</a></p>
                </div>
            </>
        );
    }
}
