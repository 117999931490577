
import React from "react";

export class RatingDocumentation extends React.Component<
    {

    },
    {
    }
> {



    constructor(props: any) {
        super(props);
        this.state = {

        }
    }



    render() {


        return (
            <>
                <div className="ratingContainer">
                    <div className="leftContainer">
                        <h3 className="good">Plus</h3>
                        <div className="leftContent">
                            <div className="newLine">
                                <div>&gt; 80% measurements have labels:</div>
                                <div className="plus">+4 pts</div>
                            </div>
                            <div className="newLine">
                                <div>No missing data in procedure:</div>
                                <div className="plus">+2 pts</div>
                            </div>
                            <div className="newLine">
                                <div>&gt; 80% measurements have protocolstep:</div>
                                <div className="plus">+2 pts</div>
                            </div>
                            <div className="newLine">
                                <div>Imaging is used:</div>
                                <div className="plus">+3 pts</div>
                            </div>
                            <div className="newLine">
                                <div>No. of protocol steps ≥ no. of saves:</div>
                                <div className="plus">+3 pts</div>
                            </div>
                            <div className="newLine">
                                <div>ECG is used:</div>
                                <div className="plus">+2 pts</div>
                            </div>
                            <div className="newLine">
                                <div>Procedure has comment &gt; 5 characters:</div>
                                <div className="plus">+4 pts</div>
                            </div>
                        </div>
                    </div>
                    <div className="rightContainer">
                        <h3 className="bad">Minus</h3>
                        <div className="rightContent">
                            <div className="newLine">
                                <div>&lt; 30% measurements have labels:</div>
                                <div className="minus">-6 pts</div>
                            </div>
                            <div className="newLine">
                                <div>Some missing data in procedure:</div>
                                <div className="minus">-6 pts</div>
                            </div>
                            <div className="newLine">
                                <div>&lt; 80% measurements have protocolstep:</div>
                                <div className="minus">-2 pts</div>
                            </div>
                            <div className="newLine">
                                <div>No imaging:</div>
                                <div className="minus">-3 pts</div>
                            </div>
                            <div className="newLine">
                                <div>No saves:</div>
                                <div className="minus">-6 pts</div>
                            </div>
                            <div className="newLine">
                                <div>No ECG:</div>
                                <div className="minus">-2 pts</div>
                            </div>
                            <div className="newLine">
                                <div>No comment:</div>
                                <div className="minus">-4 pts</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
