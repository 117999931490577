import React, {RefObject} from "react";
import { DataHelper } from "../Helpers/DataHelper";
import { StudyModel } from "../Models/StudyModel";
import { LabelModel, suggestionLabels, UpdateSuggestionLabelWrapper, UpdateSuggestionLabelMappingWrapper, SuggestedLabelMapping } from "../Models/LabelModel";
import DataGrid from "../Components/DataGrid";
import { InfoBox } from "../Components/InfoBox";
import { ErrorWindow } from "../Components/ErrorWindow";
import { PopupWindow } from "../Components/PopupWindow";
import { PiTrash } from "react-icons/pi";
import { InputItem } from "../Components/InputItem";


//make a list of updated label to send the savefuntion
export class AnatomicalLocationsWindow extends React.Component<
    {
        dataClient: DataHelper, selectedStudy: StudyModel,
        openLabelMap: () => void
    },
    {
        labels: LabelModel[],
        popupVisible: boolean,
        rowdata: string,
        unsavedLabelMappings:SuggestedLabelMapping[],
        unsavedDeleteLabelMappings:SuggestedLabelMapping[],
        labelOption:LabelModel[],
        labelOptionId:string[],
        dBLabels:LabelModel[]
    }
> {



    errorWindow:  RefObject<ErrorWindow>;
    infoBox: RefObject<InfoBox>;
    
    constructor(props: any) {
        super(props);
        this.errorWindow = React.createRef();
        this.infoBox = React.createRef();
        this.state = {
            labels: [],
            popupVisible: false,
            rowdata:"",
            unsavedLabelMappings:[],
            unsavedDeleteLabelMappings:[],
            labelOptionId:[],
            labelOption:[],
            dBLabels:[]

        }
    }


    componentDidMount=(): void => {
        if(this.props.selectedStudy.id ==="-1"){
            this.setState({
                labelOptionId:[],
                labelOption:[]
            });

            (this.errorWindow.current as ErrorWindow).show(400, "Please select a study")

        }else{
            this.getData()
        }
          
    }

    getData = (): void => {
        this.props.dataClient.CallSettingsService("GetALLabels", {}, { studyId: this.props.selectedStudy.id }, (resultOut: string) => {
            let data: LabelModel[] = JSON.parse(resultOut);
            let distinctData: LabelModel[] = [];
            //first we find all the distinct labels
            //if the label doesnt match any label name push it
            data.forEach(label => (distinctData.find(distinct => distinct.name === label.name) === undefined ? distinctData.push(label) : <></>));
            let data2: LabelModel[] = JSON.parse(resultOut);
            let distinctData2: LabelModel[] = [];
            data2.forEach(label => (distinctData2.find(distinct => distinct.name === label.name) === undefined ? distinctData2.push(label) : <></>));
            this.setState({//"labels" meaning local and "dblabels" meaning confirmed database labels(after a fetch the local might have more labels thna the db)
                labels: distinctData,
                dBLabels:distinctData2 
            })
        }, (status: number, errorMessage: string) => {
            (this.errorWindow.current as ErrorWindow).show(status, errorMessage)});
        this.props.dataClient.CallSettingsService("GetLabelOptions", {}, {studyid:this.props.selectedStudy.id}, (resultOut:string)=>{
            let labels: suggestionLabels = JSON.parse(resultOut);
            let options:string[]=[];
            if (labels.suggestedLabels)
            {
            labels.suggestedLabels.forEach(element => {
                options.push(element.name)
            });
            }else{
                labels.suggestedLabels =[];
            }
            this.setState({
                labelOptionId:options,
                labelOption:labels.suggestedLabels
            })
        })

    }

   
    updateSuggestedLabelMap=()=>{
        let wrapper=new UpdateSuggestionLabelMappingWrapper("-1", []);
        let updateLabels:SuggestedLabelMapping[]=this.findUnsavedSuggestedMapLabels();

        if(this.props.selectedStudy.id){
            wrapper = new UpdateSuggestionLabelMappingWrapper(this.props.selectedStudy?.id, updateLabels);
        }

        this.props.dataClient.CallSettingsService("SaveSuggestedLabel",wrapper,{},(resultOut: string) => {
            
            let updatedLabels: LabelModel[] = JSON.parse(resultOut);
            let updatedLabels2: LabelModel[] = JSON.parse(resultOut);
            this.setState({
                labels: updatedLabels,
                dBLabels: updatedLabels2
            });
            (this.infoBox.current as InfoBox).show("Suggested label updated")
            
        } , (status: number, errorMessage: string) => {
            (this.errorWindow.current as ErrorWindow).show(status, errorMessage);
        }, "POST");


    }

    getLabelsWithAutoSugg =():void=>{
        this.props.dataClient.CallSettingsService("GetLabelsWSuggestions", {}, { studyId: this.props.selectedStudy.id, sens:0.5 }, (resultOut: string) => {
            let data: LabelModel[] = JSON.parse(resultOut);
            let distinctData: LabelModel[] = [];
            let updatedUnsavedLabelMappings=this.state.unsavedLabelMappings;
            let updatedUnsavedDeleteLabelMappings=this.state.unsavedDeleteLabelMappings;
            this.state.labels.forEach(label=>{
                label.suggestedLabels?.forEach(suggestedLabel => {
                    const unsavedIndex = updatedUnsavedLabelMappings.findIndex(
                        mappingLabel => mappingLabel.predefinedLabel === suggestedLabel.name
                    );
                    if (unsavedIndex !== -1) {
                        updatedUnsavedLabelMappings[unsavedIndex] = {
                            ...updatedUnsavedLabelMappings[unsavedIndex],
                            timesUsed:"1",//times used determin wheter to delete or save later?
                            associatedNames: [...this.state.unsavedLabelMappings[unsavedIndex].associatedNames, label.name]
                        };
                    } else {
                        const newLabel: SuggestedLabelMapping = {
                            predefinedLabel: suggestedLabel.name,
                            associatedNames: [label.name],
                            timesUsed: "1"
                        };
                        updatedUnsavedLabelMappings.push(newLabel);
                    }
                    
                });

            })
            data.forEach(label => {
                if(distinctData.find(distinct => distinct.name === label.name) === undefined){
                    distinctData.push(label);
                }
                label.suggestedLabels?.forEach(suggestedLabel=>{
                    const unsavedIndex = updatedUnsavedLabelMappings.findIndex(
                        mappingLabel => mappingLabel.predefinedLabel === suggestedLabel.name
                    );
                    if (unsavedIndex !== -1) {
                        updatedUnsavedLabelMappings[unsavedIndex] = {
                            ...updatedUnsavedLabelMappings[unsavedIndex],
                            timesUsed:"1",//times used determin wheter to delete or save later?
                            associatedNames: [...this.state.unsavedLabelMappings[unsavedIndex].associatedNames, label.name]
                        };
                    } else {
                        const newLabel: SuggestedLabelMapping = {
                            predefinedLabel: suggestedLabel.name,
                            associatedNames: [label.name],
                            timesUsed: "1"
                        };
                        updatedUnsavedLabelMappings.push(newLabel);
                    }

                })

            })
            this.setState({
                labels: distinctData,
                unsavedDeleteLabelMappings:updatedUnsavedDeleteLabelMappings,
                unsavedLabelMappings:updatedUnsavedLabelMappings
            })
            
        });
    }

    handleDelete = (): void => {
        this.props.dataClient.CallSettingsService("DeleteLabel", {}, { labelid: (this.state.rowdata), studyid: (this.props.selectedStudy.id) }, (resultOut: string) => {
            let updatedlabels: LabelModel[] = JSON.parse(resultOut);
            this.setState({
                labels: updatedlabels
            });
                (this.infoBox.current as InfoBox).show("Label deleted")
        }, (status: number, errorMessage: string) => {
            (this.errorWindow.current as ErrorWindow).show(status, errorMessage);
        }, "DELETE");
    }


    suggestedLabelsToString=(labelList:LabelModel[])=>{
        const  suggestionLabelString:string[]=[];
        labelList.forEach((element) => {
            // Assuming 'element.name' is the property that contains the name as a string
            if (typeof element.name === 'string') {
                suggestionLabelString.push(element.name);
            }
        });

        return suggestionLabelString

    }

    findUnsavedSuggestedLabels=()=>{
        let updatedLabels:LabelModel[]=[];
        for (let i = 0; i < this.state.labels.length; i++) {
            let currentLabelIds = new Set(this.state.labels[i].suggestedLabels?.map(label => label.id));
            let dBLabelIds = new Set(this.state.dBLabels[i].suggestedLabels?.map(label => label.id));
    
            // If sizes of the two sets are not equal, or any ID present in one set is not present in the other, the labels have changed
            if (currentLabelIds.size !== dBLabelIds.size || [...(currentLabelIds as any)].some(id => !dBLabelIds.has(id))) {
                updatedLabels.push(this.state.labels[i]);
            }
        }
        
        return updatedLabels
    }

    findUnsavedSuggestedMapLabels = () => {
        let updatedLabels: SuggestedLabelMapping[] = [];
        let predefinedLabels = this.props.selectedStudy.suggestedLabels;
        

        if (predefinedLabels) {
            predefinedLabels.forEach(predefinedLabel => {
                let newMapping = new SuggestedLabelMapping();
                newMapping.predefinedLabel = predefinedLabel.name;
                newMapping.associatedNames = [];
                newMapping.timesUsed = "0";

                this.state.labels.forEach(label => {
                    // Check if any of the suggested labels match the predefined label
                    const matchingSuggestedLabel = label.suggestedLabels?.find(
                        suggestedLabel => suggestedLabel.name === predefinedLabel.name
                    );

                    if (matchingSuggestedLabel) {
                        newMapping.timesUsed = (parseInt(newMapping.timesUsed) + 1).toString();
                        if (!newMapping.associatedNames.includes(label.name)) {
                            newMapping.associatedNames.push(label.name);
                        }
                    }
                });

                updatedLabels.push(newMapping);
            });
        }

        return updatedLabels;
    }

    saveLabelChanges=()=>{
        let wrapper=new UpdateSuggestionLabelWrapper("-1", []);
        let updateLabels:LabelModel[]=this.findUnsavedSuggestedLabels();

        if(this.props.selectedStudy.id){
            wrapper = new UpdateSuggestionLabelWrapper(this.props.selectedStudy?.id, updateLabels);
        }

        this.props.dataClient.CallSettingsService("SaveSuggestedLabel",wrapper,{},(resultOut: string) => {
            
            let updatedLabels: LabelModel[] = JSON.parse(resultOut);
            let updatedLabels2: LabelModel[] = JSON.parse(resultOut);
            this.setState({
                labels: updatedLabels,
                dBLabels: updatedLabels2
            });
            (this.infoBox.current as InfoBox).show("Suggested label updated")
            
        } , (status: number, errorMessage: string) => {
            (this.errorWindow.current as ErrorWindow).show(status, errorMessage);
        }, "POST");

        this.props.dataClient.CallSettingsService(
            "UpdateSuggestedLabelsUsingMap",
            {studyId: this.props.selectedStudy.id, saveLabelModels: this.state.unsavedLabelMappings, deleteLabelModels: this.state.unsavedDeleteLabelMappings},
            {},
            () => {
                (this.infoBox.current as InfoBox).show("Labels updated");
                this.setState({
                    unsavedLabelMappings:[],
                    unsavedDeleteLabelMappings:[]
                });
            }, (status: number, errorMessage: string) => {
                (this.errorWindow.current as ErrorWindow).show(status, errorMessage);
            }, "POST");


    }

    alterLabel=(row:any, value:any, add:boolean)=>{
        let copyLabels=[...this.state.labels];
        let updatedUnsavedLabelMappings = this.state.unsavedLabelMappings;
        let updatedUnsavedDeleteLabelMappings = this.state.unsavedDeleteLabelMappings;
        let alterLabel=new LabelModel();

        if(add){
            copyLabels.forEach(label => {
                if(label.id===row.id){
                    alterLabel=label
                   //remove "default labels" when something is selected
                    if(alterLabel && alterLabel.suggestedLabels && alterLabel.suggestedLabels.length>0 && !this.state.labelOptionId.includes(alterLabel.suggestedLabels[0].id||"")){
                        alterLabel.suggestedLabels=[];
                    }
                    if(!alterLabel.suggestedLabels?.includes(value)){
                        const unsavedIndex = updatedUnsavedLabelMappings.findIndex(
                            label => label.predefinedLabel === value
                        );
                        alterLabel.suggestedLabels?.push(this.state.labelOption.find(obj => obj.id === value)||new LabelModel());
                        alterLabel.suggestionLabelString= JSON.stringify(alterLabel.suggestedLabels);
                        // Update or add to unsavedLabelMappings
                        

                        
                        if (unsavedIndex !== -1) {
                            updatedUnsavedLabelMappings[unsavedIndex] = {
                                ...updatedUnsavedLabelMappings[unsavedIndex],
                                timesUsed:"1",//times used determin wheter to delete or save later?
                                associatedNames: [...this.state.unsavedLabelMappings[unsavedIndex].associatedNames, row.id]
                            };
                        } else {
                            const newLabel: SuggestedLabelMapping = {
                                predefinedLabel: value,
                                associatedNames: [row.id],
                                timesUsed: "1"
                            };
                            updatedUnsavedLabelMappings.push(newLabel);
                        }
                    }
                }
            })
        }else{
            copyLabels.forEach(label => {
                if(label.name===row){
                    const unsavedIndex = updatedUnsavedDeleteLabelMappings.findIndex(
                        mapLabel => mapLabel.predefinedLabel === value
                    );

                    alterLabel=label;
                    alterLabel.suggestedLabels=label.suggestedLabels?.filter(label => label.name !== value);
                    alterLabel.suggestionLabelString= JSON.stringify(alterLabel.suggestedLabels);


                    if (unsavedIndex !== -1) {
                        const currentAssociatedNames = updatedUnsavedDeleteLabelMappings[unsavedIndex].associatedNames;
                        // Check if value is not in associatedNames
                        if (!currentAssociatedNames.includes(value)) {
                            updatedUnsavedDeleteLabelMappings[unsavedIndex] = {
                                ...updatedUnsavedDeleteLabelMappings[unsavedIndex],
                                timesUsed: "-1",
                                associatedNames: [...currentAssociatedNames, value]
                            };
                        }
                    } else {
                        const newLabel: SuggestedLabelMapping = {
                            predefinedLabel: value,
                            associatedNames: [row],
                            timesUsed: "1"
                        };
                        updatedUnsavedDeleteLabelMappings.push(newLabel);
                    }
                    
                }
            })
        }
        this.setState({
            labels:copyLabels,
            unsavedDeleteLabelMappings:updatedUnsavedDeleteLabelMappings,
            unsavedLabelMappings:updatedUnsavedLabelMappings

        })
    }
    
   

    render() {

        let types = ["Select"].concat(this.props.selectedStudy.labelTypes as string[]);
        let saveButtonText:string = "Save changes";
        let buttonClass:string = "";
        let buttonDisabled:boolean;
        if(this.findUnsavedSuggestedLabels().length>0){
            saveButtonText="Save changes";
            buttonClass="actionButton";
            buttonDisabled=false;
        }
        else{
            saveButtonText="All changes saved";
            buttonClass="NotButton";
            buttonDisabled=true;
        }
        
        
        return (
            <>
            <div className="popupText">
                <p>Please select one of the suggested label for each label row</p>
                <p>Also assign the "Type" value for each label to ensure data organization</p>
                <p>See our label-suggestions on "Generate suggested labels" button and then select the label from the dropdown box under it.</p>
                <p>Save your selections before closing this window.</p>
            </div>
                <div className="ALContainer">
                <ErrorWindow ref={this.errorWindow}></ErrorWindow>
                    <div className="ALHeader">
                        <button className="primaryButton" onClick={()=>{this.props.openLabelMap();}}>Label Map View</button>
                        <button className="primaryButton" onClick={()=>{this.getLabelsWithAutoSugg();}}>Generate suggested labels</button>
                        <button disabled={buttonDisabled} className={buttonClass + " primaryButton"} onClick={() => {this.saveLabelChanges();}} >{saveButtonText}</button>
                    </div>
                    <div className="ALBody">
                        {((this.state.popupVisible) ?
                            <PopupWindow title="Are you sure you want to delete this label?" closeClicked={() => { this.setState({ popupVisible: false }) }}>
                                <button className="primaryButton" onClick={() => { this.handleDelete(); this.setState({ popupVisible: false }); }}>Yes</button>
                                <button className="primaryButton" onClick={() => {this.setState({ popupVisible: false })}}>No</button>
                            </PopupWindow> : <></>)}
                        <div className="usersGridHolder">
                        
                            <DataGrid columns={[
                                { Name: "id", Caption: "ID" },
                                { Name: "name", Caption: "Name", Editable: true },
                                { Name: "description", Caption: "Description", Editable: true },
                                { Name: "type", Type: "dropdown", ValueList: types, ShowValueOncePerAlternate: true, Caption: "Type", Editable: true },
                                { Name: "suggestedLabel", Type:"multipleSelect", Caption:"Suggested labels",RenderTemplate: (rowData, row)=>(
                                
                                <div className="SelectionOptionsHolder" title={row.name}>
                                    
                                    <InputItem type={"multipleSelect" ||""} title="" name={"suggestedLabel"} chosenOptions={this.suggestedLabelsToString(row.suggestedLabels)} deleteOption={(value)=>this.alterLabel(row.id,value,false)}/>
                                    <InputItem type={"dropdown"||""} value={"--Select--"} title="" name={"Add label"} options={["---Select---",...this.state.labelOptionId||[]]} onChange={(name, value)=>this.alterLabel(row, value, true)}/>  
                                
                                </div>)},
                                { Name: "timesUsed", Type: "number", Caption: "Times used" },
                                // { Name: "isAttribute", Type:"boolean",Caption: "Is attribute", Editable: true },
                                // { Name: "componentType", Type:"number", Caption: "Component Type", Editable: true},
                                {Name: "delete", Caption: "Delete", Editable: true, RenderTemplate: (rowdata) => (
                                        <PiTrash className="buttonIcon" onClick={() =>
                                            
                                            this.setState({ popupVisible: true, rowdata: rowdata})
                                        } />

                                    )
                                }]} rows={this.state.labels} onChange={(rowdata, columnChanged, value, index, submitToServer) => {

                                    this.setState({ labels: this.state.labels })


                                    if (submitToServer) {
                                        this.props.dataClient.CallStudyService("UpdateLabel", { studyId: this.props.selectedStudy.id, label: rowdata }, {}, (outputValue: string) => {
                                            
                                            if (outputValue)
                                            {
                                                let toShow = JSON.parse(outputValue);
                                                if (toShow === "DONE")
                                                    (this.infoBox.current as InfoBox).show("Label updated");
                                                else
                                                    (this.errorWindow.current as ErrorWindow).show(700, toShow);
                                            }else
                                          {
                                            (this.errorWindow.current as ErrorWindow).show(700, outputValue);
                                          }
                                        
                                        
                                        }, (stautus: number, errorMessage: string) => { (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage) }, "POST");
                                    }
                                }}></DataGrid>
                                
                        </div>
                    </div>
                    <div className="ALFooter"></div>
                    <InfoBox ref={this.infoBox} title="Confirmation" type="confirmation"></InfoBox>
                    
                </div >
            </>
        );
    }
}
