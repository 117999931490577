import React, {RefObject} from "react";
import DataGrid from "../Components/DataGrid";
import { UserModel } from "../Models/UserModel";
import { DataHelper } from "../Helpers/DataHelper";
import { PiTrash, PiPencilSimple, PiX, PiCheck } from "react-icons/pi";
import { ErrorWindow } from "../Components/ErrorWindow";
import { InputItem } from "../Components/InputItem";
import { StudyModel } from "../Models/StudyModel";
import { userType } from "../Helpers/AccessMap";
import { InfoBox } from "../Components/InfoBox";
import { UserAccessModel } from "../Models/UserAccessModel";

export class UserWindow extends React.Component<
    {
        
        dataClient: DataHelper,
        addUser: () => void,
        allStudies:StudyModel[],
        userAccess:UserAccessModel

        

        //Properties go here
    },
    {
        allUsers:UserModel[],
        editRow:UserModel,
        selectedUser:UserModel,
        editMode:boolean
        //State goes here
    }
> {

    ;

    errorWindow:  RefObject<ErrorWindow>;
    infoBox: RefObject<InfoBox>;
    
    constructor(props: any) {
        super(props);
        this.errorWindow = React.createRef();
        this.infoBox = React.createRef();
        this.state={
            editMode:false,
            allUsers:[],
            editRow:new UserModel(),
            selectedUser: new UserModel()
        }

    }


    componentDidMount(): void {
        this.props.dataClient.CallMetadataService("GetUsers", {}, {}, (resultOut: string)=>
        {
            let allUsers: UserModel[] = JSON.parse(resultOut);
            this.setState({
                allUsers: allUsers
                
            })
        //Put stuff here that you want to run the first time the component is loaded.
    });        
    }

    handleDelete(rowData:string):void{

        this.props.dataClient.CallSettingsService("DeleteUser", {}, {email:rowData}, (resultOut: string)=>{
            let allUsers: UserModel[] = JSON.parse(resultOut);
                this.setState({
                    allUsers: allUsers
                }); (this.infoBox.current  as InfoBox).show("User Deleted Successfully");
            
        }, (stautus:number, errorMessage:string)=>{
            (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage);
          },"DELETE");
}
    refreshUsers():void{
        this.props.dataClient.CallMetadataService("GetUsers", {}, {}, (resultOut: string)=>
            {
                let allUsers: UserModel[] = JSON.parse(resultOut);
                this.setState({
                    allUsers: allUsers
                })
            },(stautus:number, errorMessage:string)=>{
                (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage);
            });
    }
    handleEdit(rowData:any, type:string):void{
        let user=this.state.allUsers.find((user)=>{
            return (user as any)[type]===rowData;
        }) 

        //if(user){(user as any)[type]=(this.state.selectedUser as any)[type]}
        if(user){
            this.setState({
                editRow:this.state.editRow===user?new UserModel():user
            }, ()=>{
                if(this.state.editRow.id === user?.id){
                    this.setState({
                        selectedUser:user})

                    //update user as selected user in database, and fetch the database
                }else{
                    this.setState({
                        selectedUser:new UserModel()
                })
                }
            })
    }
        
    }

    saveUser(){
        this.props.dataClient.CallSettingsService("SaveUser", this.state.selectedUser, {}, (outputValue: string)=>{
  
                            if (outputValue)
                            {
                                let toShow = JSON.parse(outputValue);
            if (toShow === "DONE")
            {
            (this.infoBox.current  as InfoBox).show("User Saved Successfully");
            this.props.dataClient.CallMetadataService("GetUsers", {}, {}, (resultOut: string)=>
                {
                let allUsers: UserModel[] = JSON.parse(resultOut);
                this.setState({
                    allUsers: allUsers
                })
    });
}else
{
    (this.errorWindow.current as ErrorWindow).show(700, toShow);
}
                            }else{
                                (this.errorWindow.current as ErrorWindow).show(700, outputValue);
                            }
        },(stautus:number, errorMessage:string)=>{
            (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage);
          }, "POST");
      
    }

    renderEdit(rowItem:any){
        if(!rowItem.id){return rowItem===this.state.editRow.id;}
        else{return rowItem.id===this.state.editRow.id;}
    }
    isSubset(subset:any[], superset:any[]) {
        if (subset === null || superset === null) {
            return false;
          }
        if(subset.length===0){return false}
        return subset.every(value => superset.includes(value));
      }

    render() {
        let visableUsers=this.state.allUsers.filter((user)=>this.isSubset(user.studies,this.props.userAccess.studies)||user.userHospital===this.props.userAccess.userHospital||this.props.userAccess.userType===userType[0])


        let columns=[
            //{Name: "id", Caption: "Id"},
            {Name: "name", Caption: "Name", GetCellEdit:(row:any, column:any)=>{
                return this.renderEdit(row)
            }},
            {Name: "email", Caption: "E-Mail"},
            {Name: "userType", Caption: "User Type", Type:"dropdown", GetValueList:()=>{
                return userType;
            },GetCellEdit:(row:any, column:any)=>{
                return this.renderEdit(row)
            }},
            {Name: "lastAccessed", Caption: "Last logon", Type: "date"},
            {Name: "userHospital", Caption: "Hospital", GetCellEdit:(row:any, column:any)=>{
                return this.renderEdit(row)
            }},
            {Name: "studies", Caption: "Studies", RenderTemplate: (rowData:any, rowItem:any)=>(
                <div>
                {this.renderEdit(rowItem)?<div><InputItem title="" options={this.props.allStudies.map(study => study.name)} chosenOptions={this.state.selectedUser.studies} type="dropdownMultipleSelect" name="studies" deleteOption={(index , value)=>{
                    let newList = this.state.selectedUser.studies.filter(element => element !== index);
                    this.setState(prevState => ({
                        selectedUser: {
                            ...prevState.selectedUser,
                            studies: newList
                        }
                    }));
                }} onChange={(input, study, chosenOption)=>{
                    this.setState(prevState=>({
                        selectedUser:{
                            ...prevState.selectedUser,
                            studies:[study,...chosenOption]
                        }
                    }))
                }}/></div>:
                <div title={rowData}>{rowData === null ? 0 : rowData.length}</div>}
                </div>
            )}
            ];
            if (this.props.userAccess.userType===userType[0]) {
                columns.push({Name: "email", Caption:"Delete/Edit", RenderTemplate: (rowData:any, rowItem:any)=>(
                    <div>
                    {this.renderEdit(rowItem)?<div>
                        <PiCheck className="buttonIcon" onClick={()=> {
                        this.saveUser();
                        this.setState({
                            editRow:new UserModel()
                        })}
                        }/>
                        <PiX className="buttonIcon" onClick={()=> 
                        this.setState({
                            editRow:new UserModel()
                        })}/>
                    </div>
    
    
                    :<div><PiTrash className="buttonIcon" onClick={()=> 
                        this.handleDelete(rowData) }/>
                    <PiPencilSimple className="buttonIcon" onClick={()=>{
                            this.handleEdit(rowData, "email");
                        }}/></div>}
                    </div>
                )})
              }
     
        return (
            <>
            <ErrorWindow ref={this.errorWindow}/>
            <InfoBox ref={this.infoBox} title="Confirmation" type="confirmation"></InfoBox> 
            <div className="userWindowWrapper">
                <div className="userWindowBody">
                    <div className="usersGridHolder">
                        <DataGrid columns={columns}

                            rows={visableUsers}
                            onChange={(rowData, columnChanged, value, index, submitToServer)=>{

                                this.forceUpdate();
                            }}
                        ></DataGrid>

                    </div>
                </div>
                <div className="userWindowFooter">
                    <button className="primaryButton" onClick={() => { this.props.addUser(); }}>
                    Add User</button>
                </div>
            </div>
            </>
        );
    }
}

