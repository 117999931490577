import React from "react";
import { PreviewMeasurement } from "../Models/PreviewMeasurement";
import { StudyModel } from "../Models/StudyModel";
import { DataHelper } from "../Helpers/DataHelper";
import { LargeTTFMCurve } from "./LargeTTFMCurve";
import { PopupWindow } from "./PopupWindow";
import { LabelModel } from "../Models/LabelModel";
import SubwayMap from "../Models/Trigger/SubwayMap";
import { Graft } from "../Models/Trigger/TriggerClasses";
import { ClusterEvalModel } from "../Models/ClusterEvalModel";
import { AICurveEvalButton } from "./AICurveEvalButton";


export class LargePreviewWindow extends React.Component<
    {
        rowData: PreviewMeasurement,
        studySelected: StudyModel,
        dataHelper: DataHelper,
        previewAvailable: boolean,
        context: string, 
        procedureId: string,
        closeClicked: ()=>void,
        onEvaluationResult: (result: ClusterEvalModel) => void,
        cachedEvalResutls:ClusterEvalModel

    },
    {
      evaluationResult: ClusterEvalModel
      evalResultDict: ClusterEvalModel
    }
> {

    constructor(props: any) {
        super(props);
        this.state = {
          evaluationResult:new ClusterEvalModel(),
          evalResultDict: this.props.cachedEvalResutls
        }
    }

    static defaultProps = {
        //Set any properties that are optional when creating the component.
        context: "",
        previewAvailable: true
      }

    
    componentDidMount(): void {
        //Put stuff here that you want to run the first time the component is loaded.
    }


    stringToColor(str: string): string {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        const hue = (hash*19) % 360;
        return `hsl(${hue}, 80%, 50%)`; // Here, we set the saturation to 100% and lightness to 75%
      }

      onEvalResult = (result: ClusterEvalModel) => {
        this.props.onEvaluationResult(result)
        
        this.setState(prevState => ({
          evalResultDict: {
            ...prevState.evalResultDict,
            [this.props.procedureId]: result
          }
        }))
      }

    
    render() {
        let fullAnatomicalLocation = this.props.rowData.anatomicalLocations?.join("-") as string;
        if (!fullAnatomicalLocation)
          fullAnatomicalLocation = this.props.rowData.vessels?.join("-") as string;
        
        let suggestedLabels: LabelModel[] = [];
          
        if (this.props.rowData.labels) {
             //Remove label duplicates
           let newLabelList = [];
           let labelsAdded = [];
        for (let index = 0; index < this.props.rowData.labels.length; index++) {
          const element = this.props.rowData.labels[index];
          if (labelsAdded.indexOf(element.id) === -1)
          {
            labelsAdded.push(element.id);
            newLabelList.push(element);
          }
        
        }
        newLabelList.forEach((label: LabelModel) => {
                label.suggestedLabels?.forEach((labelChild: LabelModel) => {
                    suggestedLabels.push(labelChild);
                });
         
          });
        }
        
        // let uniqueLabels = Array.from(new Set(suggestedLabels));
        let labelsStyled = <></>;
        if (suggestedLabels) {
            labelsStyled =
            <div className="tagsWrapper">
              {suggestedLabels.map((label, index) => (
                
                    label.name ?
                    <div key={index} className={"labelInDataGrid"} style={{backgroundColor: this.stringToColor(label.name)}}>
                    {label.name}
                  </div>
                  :<></>

                
               
              ))}
            </div>
        
        }

        let graftsIn: Graft[] = Graft.GenerateGrafts([suggestedLabels as LabelModel[]]);

        
        return (
            
            
            <>
                <PopupWindow title={fullAnatomicalLocation} closeClicked={()=>{
                    this.props.closeClicked();
                }}>
                    <div className="previewHolder">
                    <div className="previewDataHolder">
                 
                    {
                 ( this.props.rowData.measurementType === "Flow" ?
            
                <LargeTTFMCurve meanCutOff1={this.props.studySelected.lowerReferenceValue as number} meanCutOff2={this.props.studySelected.upperReferenceValue as number} parentGroup={this.props.context} height={560} width={880} studySelected={this.props.studySelected} procedureId={this.props.procedureId} dataReceiver={this.props.dataHelper} measurementPreview={this.props.rowData}></LargeTTFMCurve>
             : (
           this.props.rowData.measurementType === "Ecg"
            ?
              <LargeTTFMCurve meanCutOff1={this.props.studySelected.lowerReferenceValue as number} color1="#0196C2" color2="#C0E8F2" color3="#2A6BFF" meanCutOff2={this.props.studySelected.upperReferenceValue as number} parentGroup={this.props.context} height={560} width={880}  yAxisVisible={true} procedureId={this.props.procedureId} xAxisVisible={true} usefilling={false} subType="Ecg" studySelected={this.props.studySelected} dataReceiver={this.props.dataHelper} measurementPreview={this.props.rowData}></LargeTTFMCurve>
            :
            (this.props.rowData.measurementType === "Imaging"
            ?
            <div className="imagingFullPreview">
          
                <img alt="Imaging preview"  src={this.props.dataHelper.hostAddress + "MiscData/GetImagingPreview?studyId=" + this.props.studySelected.id + "&measurementId=" +  this.props.rowData.id+ "&procedureId=" +  this.props.procedureId+ "&tokenId=" +  this.props.dataHelper.miscToken} loading="lazy"/></div>
            : (
              this.props.rowData.measurementType === "PWDopplers"
               ?
                 <LargeTTFMCurve meanCutOff1={this.props.studySelected.lowerReferenceValue as number} meanCutOff2={this.props.studySelected.upperReferenceValue as number} parentGroup={this.props.context} height={560} width={880}  color1="#4A90E2" yAxisVisible={true} procedureId={this.props.procedureId} xAxisVisible={true} usefilling={false} subType="PWDopplers" studySelected={this.props.studySelected} dataReceiver={this.props.dataHelper} measurementPreview={this.props.rowData}></LargeTTFMCurve>
               :<></>)
                 )))
                    }
                       </div>
                       <div className="previewWorklistHolder">
                        {
                            graftsIn.length > 0 ?
                            <SubwayMap CurrentItem={graftsIn[0]} Context={""} customGraftText={""} index={0} commentToggled={function (e: boolean): void {
                                throw new Error("Function not implemented.");
                            } } measurementSelectionOpen={false} measurementSelectionSelected={function (e: boolean): void {
                                throw new Error("Function not implemented.");
                            } } tileColorOn={true} ></SubwayMap>
                            :
                            labelsStyled
                        }
                    
                       </div>
                       {(this.props.rowData.measurementType==="Flow" && this.props.studySelected.curveEvalEnabled )? (
                       <AICurveEvalButton evaluationResult={this.props.cachedEvalResutls?? new ClusterEvalModel()} dataHelper={this.props.dataHelper} rowData={this.props.rowData} onEvaluationResult={this.onEvalResult} studySelected={this.props.studySelected} procedureId={this.props.procedureId}></AICurveEvalButton>):<></>}
                    </div>
                </PopupWindow>
            </>
        );
    }
}


