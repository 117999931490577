import React from "react";

export class LoadingWidget extends React.Component<
    {
        visible: boolean,
        zIndexLayer: number
    },
    {
        //State goes here
    }
> {

    ;

    constructor(props: any) {
        super(props);
        this.state = {
            //Initialize ALL state variables here
        }
    }

    static defaultProps = {
        //Set any properties that are optional when creating the component.
      }

    
    componentDidMount(): void {
        //Put stuff here that you want to run the first time the component is loaded.
    }

    render() {


        return (
            <>
            {this.props.visible ?

            <div className="loadingHolder" style={ {zIndex: this.props.zIndexLayer}}>
                <img alt="Loading..." className="loadingImage" src="/Loading_orange.gif"></img>
                </div>
                : <></>}
            </>
        );
    }
}
