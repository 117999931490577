import React, { RefObject } from "react";
import { StudyModel } from "../Models/StudyModel";

import { InfoBox } from "../Components/InfoBox";
import { InputItem } from "../Components/InputItem";
import { DataHelper } from "../Helpers/DataHelper";
import { ErrorWindow } from "../Components/ErrorWindow";
import MultiFileBrowser from "../Components/MultiFileBrowser";
import { PiPlus } from "react-icons/pi";
import { PiTrash } from "react-icons/pi";
import { LabelModel } from "../Models/LabelModel";

export class AddStudyWindow extends React.Component<
    { studies: StudyModel[], selectedStudy: StudyModel, dataClient: DataHelper, idOptions: string[]},
    {

        changeStudy: StudyModel,
        workflowProtocolSteps: string[]


    }
> {
    fileBrowserMulti: RefObject<MultiFileBrowser>;
    infoBox: RefObject<InfoBox>;
    errorWindow: RefObject<ErrorWindow>;
    constructor(props: any) {
        super(props);
        this.fileBrowserMulti = React.createRef();
        this.infoBox = React.createRef();
        this.errorWindow = React.createRef();
        this.state = {
            changeStudy: {
                index: props.selectedStudy.index,
                id: props.selectedStudy.id,
                idFieldForStudy: props.selectedStudy.idFieldForStudy,
                allowPersonalData: props.selectedStudy.allowPersonalData,
                name: props.selectedStudy.name,
                description: props.selectedStudy.description,
                startdate: props.selectedStudy.startdate,
                expectedProcedures: props.selectedStudy.expectedProcedures,
                expectedHospitals: props.selectedStudy.expectedHospitals,
                protocolSteps: props.selectedStudy.protocolSteps,
                workflowFilename: props.selectedStudy.workflowFilename,
                lowerReferenceValue: props.selectedStudy.lowerReferenceValue,
                upperReferenceValue: props.selectedStudy.upperReferenceValue,
                labelTypes: props.selectedStudy.labelTypes,
                suggestedLabels: props.selectedStudy.suggestedLabels,
                exportEnabled: props.selectedStudy.exportEnabled,
                curveEvalEnabled: props.selectedStudy.curveEvalEnabled,
                lastUpdated: props.selectedStudy.lastUpdated,
                procedures: props.selectedStudy.procedures,
                measurements: props.selectedStudy.measurements,
                flowNum: props.selectedStudy.flowNum,
                ecgNum: props.selectedStudy.ecgNum,
                imagingNum: props.selectedStudy.imagingNum,
                noLabelNum: props.selectedStudy.noLabelNum
            },
            workflowProtocolSteps: []


        };

    }
/*
    onInputItemchanged = (name: string, value: any) => {
        let today = new Date();
        this.setState(
            {
                changeStudy: {
                    index: this.state.changeStudy.index,
                    name: name === "name" ? value : this.state.changeStudy.name,
                    id: this.state.changeStudy.id,
                    idFieldForStudy: name === "idFieldForStudy" ? value: this.state.changeStudy.idFieldForStudy,
                    allowPersonalData: name === "allowPersonalData",
                    description: name === "description" ? value : this.state.changeStudy.description,
                    startdate: name === "startdate" ? value : this.state.changeStudy.startdate,
                    expectedProcedures: name === "expectedProcedures" ? value : this.state.changeStudy.expectedProcedures,
                    expectedHospitals: name === "expectedHospitals"? value : this.state.changeStudy.expectedHospitals,
                    protocolSteps: this.state.changeStudy.protocolSteps,
                    workflowFilename: this.state.changeStudy.workflowFilename,
                    lowerReferenceValue: name === "lowerValue" ? value: this.state.changeStudy.lowerReferenceValue,
                    upperReferenceValue: name === "upperValue" ? value: this.state.changeStudy.upperReferenceValue,
                    labelTypes: this.state.changeStudy.labelTypes,
                    suggestedLabels: this.state.changeStudy.suggestedLabels,
                    exportEnabled: name === "exportEnabled" ? value : this.state.changeStudy.exportEnabled,
                    lastUpdated: today,
                    procedures: this.state.changeStudy.procedures,
                    measurements: this.state.changeStudy.measurements,
                    flowNum: this.state.changeStudy.flowNum,
                    ecgNum: this.state.changeStudy.ecgNum,
                    imagingNum: this.state.changeStudy.imagingNum,
                    noLabelNum: this.state.changeStudy.noLabelNum
                }
            })
    }
            */

    onInputItemchanged = (name: string, value: any) => {
        if(name ==="allowPersonalData" && value ===false){
            this.setState(prevState => ({
                changeStudy: {
                  ...prevState.changeStudy,
                  encryptionEnabled: false
                }
              }));
        }
       
        this.setState(prevState=>(
            {
                changeStudy: {
                    ...prevState.changeStudy,
                    [name]: value
                }
            }));
    }

    onDropdownChanged = (name: string, value: string) => {
        let today = new Date();

        if (value === "Add New Study") {
            this.setState({
                changeStudy: {
                    index: 0,
                    id: "-1",
                    idFieldForStudy:this.props.idOptions[0],
                    allowPersonalData: true,
                    name: "",
                    description: "",
                    startdate: today,
                    expectedProcedures: 0,
                    expectedHospitals: 0,
                    protocolSteps: [],
                    workflowFilename: "",
                    lowerReferenceValue: 0,
                    upperReferenceValue: 0,
                    labelTypes:[],
                    suggestedLabels:[],
                    exportEnabled: false,
                    curveEvalEnabled:false,
                    procedures: 0,
                    measurements: 0,
                    flowNum:  0,
                    imagingNum: 0,
                    ecgNum: 0,
                    noLabelNum:  0,
                    lastUpdated:  today
                }
            })

        }
        else {
            let study = this.props.studies.find(study => study.name === value);
            this.setState({ changeStudy: study as StudyModel } )
        
        }

    }
    onIdFieldDropdownChanged = (name: string, value: string) => {
        this.setState(prevState => ({
            changeStudy: {
                ...prevState.changeStudy,
                idFieldForStudy: value
            }
        }));
    }
    onUploadConfirmed = async (dataOut: string) => {
        if (dataOut) {
            let workflowModelParsed = JSON.parse(dataOut);
            let filename = workflowModelParsed.filename;
            this.setState({
                changeStudy: {
                    index: this.state.changeStudy.index,
                    name: this.state.changeStudy.name,
                    id: this.state.changeStudy.id,
                    idFieldForStudy: this.state.changeStudy.idFieldForStudy,
                    allowPersonalData: this.state.changeStudy.allowPersonalData,
                    description: this.state.changeStudy.description,
                    startdate: this.state.changeStudy.startdate,
                    expectedProcedures: this.state.changeStudy.expectedProcedures,
                    expectedHospitals: this.state.changeStudy.expectedHospitals,
                    protocolSteps: workflowModelParsed.protocolSteps,
                    workflowFilename: filename,
                    lowerReferenceValue: this.state.changeStudy.lowerReferenceValue,
                    upperReferenceValue: this.state.changeStudy.upperReferenceValue,
                    labelTypes:this.state.changeStudy.labelTypes,
                    suggestedLabels: this.state.changeStudy.suggestedLabels,
                    exportEnabled: this.state.changeStudy.exportEnabled,
                    curveEvalEnabled: this.state.changeStudy.curveEvalEnabled,
                    lastUpdated: this.state.changeStudy.lastUpdated,
                    procedures: this.state.changeStudy.procedures,
                    measurements: this.state.changeStudy.measurements,
                    flowNum: this.state.changeStudy.flowNum,
                    ecgNum: this.state.changeStudy.ecgNum,
                    imagingNum: this.state.changeStudy.imagingNum,
                    noLabelNum: this.state.changeStudy.noLabelNum
                },
                
            })
        }
    }


    render() {

        let protocolform =
            <div className="protocolform">
                <div className="protocolformHeader">
                    <h2 className="protocolformTitle">Protocol</h2>
                    <button className="addOrRemoveButton" onClick={() => {
                                    let newStudy1 = this.state.changeStudy;
                                    if (!newStudy1.protocolSteps) { newStudy1.protocolSteps = [] }
                                    newStudy1.protocolSteps?.push(""); 
                                    this.setState({ changeStudy: newStudy1 });
                                }}><PiPlus className="buttonIcon"/></button>
                </div>
                <div className="protocolformBody">
                    {
                        this.state.changeStudy.protocolSteps?.map((value, index: number) => {
                            return <div className="newStep">
                                    <InputItem type="text" title={"Step " + (index + 1)} name={"step" + (index + 1)} value={value} onChange={(name, value1) => {
                                    let newStudy1 = this.state.changeStudy;
                                    (newStudy1.protocolSteps as string[])[index] = value1;
                                    this.setState({ changeStudy: newStudy1 });
                                    }}></InputItem>

                                    <button className="menuButton" onClick={() => {
                                    let newStudy1 = this.state.changeStudy;
                                    if (!newStudy1.protocolSteps) { newStudy1.protocolSteps = [] }
                                    (newStudy1.protocolSteps as string[]).splice(index,1);  
                                    this.setState({ changeStudy: newStudy1 });
                                    }}><PiTrash className="buttonIcon"/></button>
                                </div>
                        })
                    }
                </div>
            </div>
        let addLabelTypes =
        <div className="protocolform">
            <div className="protocolformHeader">
                <h2 className="protocolformTitle">Label Types</h2>
                <button className="addOrRemoveButton" onClick={() => {
                                let newStudy1 = this.state.changeStudy;
                                if (!newStudy1.labelTypes) { newStudy1.labelTypes = [] }
                                newStudy1.labelTypes?.push(""); 
                                this.setState({ changeStudy: newStudy1 });
                            }}><PiPlus className="buttonIcon"/></button>
            </div>
            <div className="protocolformBody">
                {
                    this.state.changeStudy.labelTypes?.map((value, index: number) => {
                        return <div className="newStep">
                                <InputItem type="text" title={"Type " + (index + 1)} name={"type" + (index + 1)} value={value} onChange={(name, value1) => {
                                let newStudy1 = this.state.changeStudy;
                                (newStudy1.labelTypes as string[])[index] = value1;
                                this.setState({ changeStudy: newStudy1 });
                                }}></InputItem>

                                <button className="menuButton" onClick={() => {
                                let newStudy1 = this.state.changeStudy;
                                if (!newStudy1.labelTypes) { newStudy1.labelTypes = [] }
                                (newStudy1.labelTypes as string[]).splice(index,1);  
                                this.setState({ changeStudy: newStudy1 });
                                }}><PiTrash className="buttonIcon"/></button>
                            </div>
                    })
                }
            </div>
        </div>
        
        
        let suggestedlabels =
        <div className="protocolform">
            <div className="protocolformHeader">
                <h2 className="protocolformTitle">Suggested Labels</h2>
                <button className="addOrRemoveButton" onClick={() => {
                                let newStudy1 = this.state.changeStudy;
                                if (!newStudy1.suggestedLabels) { newStudy1.suggestedLabels = [] }
                                newStudy1.suggestedLabels?.push({index:0, name:"", description:"", type:""}); 
                                this.setState({ changeStudy: newStudy1 });
                            }}><PiPlus className="buttonIcon"/></button>
            </div>
            <div className="protocolformBody">
               {this.state.changeStudy.suggestedLabels && this.state.changeStudy.suggestedLabels.length>0?<div className="newStepHeader">
                                    <div className="labelgridName">Name</div>
                                    <div className="labelgridDesc">Description</div>
                                    <div className="labelgridType">Type</div>
              </div> :<></>} 
            
                {this.state.changeStudy.suggestedLabels?.map((value, index: number) => {
                        let labelTypeoptions: string[]= ["--Select--"];
                        this.state.changeStudy.labelTypes?.forEach(type => labelTypeoptions.push(type));
                        
                        return <div className="newStep"> 
                                <div className="newStepContainer">
                                <div className="newStepInputs">
                                    <div className="labelName">
                                        
                                        <InputItem type="text" title={"Label " + (index + 1)} name={"labelName" + (index + 1)} value={value.name} onChange={(name, value1) => {
                                        let newStudy1 = this.state.changeStudy;
                                        (newStudy1.suggestedLabels as LabelModel[])[index].name = value1;
                                        (newStudy1.suggestedLabels as LabelModel[])[index].id = value1;
                                        this.setState({ changeStudy: newStudy1 });
                                        }}></InputItem>
                                    </div>
                                    <div className="labeldesc">
                                        <InputItem type="text" title={""} name={"labelDescription" + (index + 1)} value={value.description} onChange={(name, value1) => {
                                        let newStudy1 = this.state.changeStudy;
                                        (newStudy1.suggestedLabels as LabelModel[])[index].description = value1;
                                        this.setState({ changeStudy: newStudy1 });
                                        }}></InputItem>
                                    </div>  
                                    
                                    <div className="labelType">
                                        
                                        <InputItem type="dropdown" title={""} name={"labelType" + (index + 1)} value={value.type? value.type: labelTypeoptions[0]} options={labelTypeoptions} onChange={(name, value1) => {
                                        let newStudy1 = this.state.changeStudy;
                                        (newStudy1.suggestedLabels as LabelModel[])[index].type = value1;
                                        this.setState({ changeStudy: newStudy1 });
                                        }}></InputItem>
                                    </div> 
                                    
                                    <button className="menuButton" onClick={() => {
                                    let newStudy1 = this.state.changeStudy;
                                    if (!newStudy1.suggestedLabels) { newStudy1.suggestedLabels = [] }
                                    (newStudy1.suggestedLabels as LabelModel[]).splice(index,1);  
                                    this.setState({ changeStudy: newStudy1 });
                                    }}><PiTrash className="buttonIcon"/></button>
                                </div>
                            </div>
                            </div>
                    })
                }
            </div>
        </div>

        let studyoptions: string[]= this.props.studies.map((study) => (study.name));
        studyoptions.splice(0,0,"Add New Study");
        

        return (
            <>

                <div className="addStudyContainer">
                    <div className="addStudyHeader">
                        
                        <div className="studyDropdownContainer">
                            <InputItem type="dropdown" title={"Edit or add a study"} name="studiesDropdown" onChange={this.onDropdownChanged} value={(this.props.selectedStudy.name ?this.props.selectedStudy.name: studyoptions.at(0))} options={studyoptions}></InputItem>
                        </div>
                    </div>
                    <div className="addStudyBody">
                        <div className="leftContainer">
                            <h2 className="studyformHeader">Study Information</h2>
                            <div className="inputItems">
                                <InputItem type="text" title={"Name"} name={"name"} onChange={this.onInputItemchanged} value={this.state.changeStudy.name}></InputItem>
                                <InputItem type="date" title={"Startdate"} name={"startdate"} onChange={this.onInputItemchanged} value={this.state.changeStudy.startdate}></InputItem>
                                <InputItem type="number" title={"Expected Procedures"} name={"expectedProcedures"} onChange={this.onInputItemchanged} value={this.state.changeStudy.expectedProcedures}></InputItem>
                                <InputItem type="number" title={"Expected Hospitals"} name={"expectedHospitals"} onChange={this.onInputItemchanged} value={this.state.changeStudy.expectedHospitals}></InputItem>
                                <InputItem type="longtext" title={"Description"} name={"description"} onChange={this.onInputItemchanged} value={this.state.changeStudy.description}></InputItem>
                                <InputItem type="dropdown" title={"Select ID Column"} name={"idFieldForStudy"} onChange={this.onInputItemchanged} value={this.state.changeStudy.idFieldForStudy || this.props.idOptions[0]} options={this.props.idOptions} key={`id-dropdown-${this.state.changeStudy.id}`}></InputItem>
                                <InputItem type="checkbox" title={"Allow Personal Data"} name={"allowPersonalData"} onChange={this.onInputItemchanged} value={this.state.changeStudy.allowPersonalData}></InputItem>
                                {this.state.changeStudy.allowPersonalData &&
                                <InputItem type="checkbox" title={"Encryption Enabled"} name={"encryptionEnabled"} onChange={this.onInputItemchanged} value={this.state.changeStudy.encryptionEnabled}></InputItem>
                                }
                                <InputItem type="checkbox" title={"Export Enabled"} name={"exportEnabled"} onChange={this.onInputItemchanged} value={this.state.changeStudy.exportEnabled}></InputItem>
                                <InputItem type="checkbox" title={"AI Curve Evaluation Enabled"} name={"curveEvalEnabled"} onChange={this.onInputItemchanged} value={this.state.changeStudy.curveEvalEnabled}></InputItem>
                                <div className="referenceValues"> 
                                <div className="referenceValuesTitle">Reference Values</div>
                                <InputItem type="number" title={"Lower Value"} name={"lowerReferenceValue"} onChange={this.onInputItemchanged} value={this.state.changeStudy.lowerReferenceValue}></InputItem>
                                <InputItem type="number" title={"Upper Value"} name={"upperReferenceValue"} onChange={this.onInputItemchanged} value={this.state.changeStudy.upperReferenceValue}></InputItem>
                                </div>
                            </div>
                        </div>
                        <div className="rightContainer">
                            <div className="workflowContainer">
                                <div className="workflowHeader">
                                    <h2>Workflow</h2>
                                </div>    
                                <div className="addWorkflow">
                                    <span>{(this.state.changeStudy.workflowFilename ? this.state.changeStudy.workflowFilename: "Add workflow") }</span>
                                    <button className="addOrRemoveButton" onClick={() => {
                                        (this.fileBrowserMulti.current as MultiFileBrowser).openDialog();
                                    }} ><PiPlus className="buttonIcon"/></button>

                                </div>
                            </div>
                            {protocolform}
                            {addLabelTypes}
                            {suggestedlabels}
                        </div>



                    </div>
                    <div className="addStudyFooter">
                        <button className="primaryButton" onClick={() => {
                            this.props.dataClient.CallSettingsService("SaveNewStudy", this.state.changeStudy, {}, (outputValue: string) => 
                            {   
                                if (outputValue)
                                {
                                    let toShow = JSON.parse(outputValue);
                                    if (toShow === "DONE")
                                        this.state.changeStudy.id === "-1" ? (this.infoBox.current as InfoBox).show("Study successfully added") : (this.infoBox.current as InfoBox).show("Study updated") 
                                    else
                                        (this.errorWindow.current as ErrorWindow).show(700, toShow);
                                }else
                              {
                                (this.errorWindow.current as ErrorWindow).show(700, outputValue);
                              }

                            },  (stautus:number, errorMessage:string)=>{ (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage)},"POST");

                        }}>{this.state.changeStudy.id === "-1" ? "Create" : "Save"}</button>
                    </div>
                    <InfoBox ref={this.infoBox} title="Confirmation" type="confirmation"></InfoBox>
                    <MultiFileBrowser ref={this.fileBrowserMulti} singleFileInput={true} performUpload={true} methodName={"ProcessWorkflowFile"} controllerName={"Settings"} dataTransfer={this.props.dataClient} progressEvent={() => { }} onUploadComplete={this.onUploadConfirmed} onUploadFailed={(status: number, errorText: string, statusText: string) =>{ 
                    if (this.errorWindow.current as ErrorWindow)
                        (this.errorWindow.current as ErrorWindow).show(status, errorText, statusText, "File upload failed")
                }}></MultiFileBrowser>
                    <ErrorWindow ref={this.errorWindow}></ErrorWindow>
                </div>

            </>
        );
    }
}
