import React from 'react';
import * as d3 from 'd3';



class DragBar extends React.Component<{ width: number, height: number, name: string, sizeMultiplier: number, min: number, max: number, value: number, valueWidth: number, onChange: Function, xAxisLabelsFormat: (value: any) => void}> {

    constructor(props: any) {
        super(props);
        this.state = {
            
        };
      }


      componentDidUpdate(){
          
          this.drawBar();
      }

    
      


      
      static defaultProps = {
            xAxisLabelsFormat: null,
            valueWidth: 10,
            sizeMultiplier: 1
      }

      barCreated: any;
      dragStartPos: any = {};
      drawBar = () =>
      {
        let widthToUse = this.props.width;
        if (widthToUse === -1)
        {
            widthToUse = document.getElementById(this.props.name)?.offsetWidth as number;
        }
        d3.select('#' + this.props.name + '.drag-bar-container')
        .select('svg').remove();
            let margin = {right: 50, left: 50};
            let width = widthToUse- margin.left - margin.right;
            let height = this.props.height;
            this.barCreated = d3.select('#' + this.props.name + '.drag-bar-container')
            .append('svg')
            .attr('class', 'drag-bar ')
            .attr('height', height)
            .attr('width', widthToUse);

            var x = d3.scaleLinear()
            .domain([this.props.min, this.props.max])
            .range([0, width])
            .clamp(true);

            var slider = this.barCreated.append("g")
            .attr("class", "slider")
            .attr("transform", "translate(" + margin.left+ "," + height / 2 + ")");

            slider.append("line")
            .attr("class", "track")
            .attr("x1", x.range()[0])
            .attr("x2", x.range()[1])
        .select(function() { 
            //@ts-ignore
            return this.parentNode.appendChild(this.cloneNode(true)); })
            .attr("class", "track-inset")
        .select(function() { 
              //@ts-ignore
            return this.parentNode.appendChild(this.cloneNode(true)); })
            .attr("class", "track-overlay")
            .call(d3.drag()
                .on("start.interrupt", function() { slider.interrupt(); })
                .on("start", (e) => { 
                    console.log("Drag start: - " + e.x + ", " + e.y);
                    this.dragStartPos.x = e.x;
                    this.dragStartPos.y = e.y;
                   // console.log("Drag start: " + " - " + e.x + ", " + e.y);
                    let xvalue = x.invert(e.x);    
                    handle.attr("cx", x(xvalue)); 
                    
                      //@ts-ignore
                    handle.attr("cx", x(xvalue)); 
                      //@ts-ignore
                    if (handle2)
                    {  //@ts-ignore
                        handle2.attr("cx", x(xvalue- this.props.valueWidth)); 
                          //@ts-ignore
                        handleJoint.attr("x1", x(xvalue- this.props.valueWidth)).attr("x2", x(xvalue)); 
                    }else
                    {
                          //@ts-ignore
                        handleJoint.attr("x1", x(0)).attr("x2", x(xvalue))
                    }
                      //@ts-ignore
                    this.props.onChange(xvalue );
                })
                .on("drag", (e)=> {
                
                    if(e.sourceEvent.offsetX + e.dx !== this.dragStartPos.x ||
                        e.sourceEvent.offsetY + e.dy !== this.dragStartPos.y) {
                     // console.log("Drag start: " + " - " + e.x + ", " + e.y);
                    let xvalue = x.invert(e.sourceEvent.offsetX + e.dx);    
                    handle.attr("cx", x(xvalue)); 
                    
                      //@ts-ignore
                    handle.attr("cx", x(xvalue)); 
                      //@ts-ignore
                    if (handle2)
                    {  //@ts-ignore
                        handle2.attr("cx", x(xvalue- this.props.valueWidth)); 
                          //@ts-ignore
                        handleJoint.attr("x1", x(xvalue- this.props.valueWidth)).attr("x2", x(xvalue)); 
                    }else
                    {
                          //@ts-ignore
                        handleJoint.attr("x1", x(0)).attr("x2", x(xvalue))
                    }
                      //@ts-ignore
                    this.props.onChange(xvalue );
                    
                    }
                }
                ).on("end", (e)=> {
                     
                }));

                slider.insert("g", ".track-overlay")
                .attr("class", "ticks")
                .attr("transform", "translate(0," + 18 + ")")
              .selectAll("text")
              .data(x.ticks(10))
              .enter().append("text")
                .attr("x", x)
                .attr("text-anchor", "middle")
                .text(this.props.xAxisLabelsFormat)
                .attr("class", "axis-default");

                let startBarX = this.props.value - this.props.valueWidth;
            
                let addFirstHandle = true;
                if (this.props.valueWidth === -1)
                {
                    addFirstHandle = false;
                    startBarX = 0;
                }

               var handleJoint = slider.insert("line", ".track-overlay")
                .attr("class", "handle-joint")
                .attr("x1", x(startBarX))
                .attr("x2", x(this.props.value));
                  //@ts-ignore
                var handle2 = null;
            var handle = slider.insert("circle", ".track-overlay")
          
                .attr("class", "handle")
                .attr("r", 9*this.props.sizeMultiplier)
                .attr("cx", x(this.props.value) );
                if (addFirstHandle)
                {
                    handle2 = slider.insert("circle", ".track-overlay")
                    .attr("class", "handle")
                    .attr("r", 9*this.props.sizeMultiplier)
                    .attr("cx", x(startBarX) );
                }
      }


    render() {

        return (
            <>
         
    <div id={this.props.name} className={"drag-bar-container "+ (this.props.sizeMultiplier === 2 ? " chunky-dragbar": "")}>


   
        </div>
        </>
        );
    }


}

export default DragBar;