import * as React from 'react';
import { Graft, TargetDetails, Conduit, IndicatorState, Tabs } from "./TriggerClasses";
import ConduitVisualize from './ConduitVisualize';
import { VascularGraft,AVAccessType } from './VascularClasses';


class SubwayMap extends React.Component < {
    CurrentItem : Graft,
    Context : string,
    customGraftText : string,
    index: number, 
    commentsOpen: boolean,
    commentToggled: (e: boolean) => void,
    measurementSelectionOpen: boolean,
    measurementSelectionSelected: (e: boolean) => void,
    tileColorOn: boolean,
}, {
    frequentUsedOpen: boolean,

},
any > {

    static defaultProps = {
        commentsOpen: false
      }

    constructor(props : any) {
        super(props);
        this.state = {
            frequentUsedOpen: false
        };
    }

    rowContainer: React.ReactElement[] = [];
    columnContainerAll: React.ReactElement[][] = [];
    indentCount: number = 0;
    indentCountMax: number = 0;
    measuredGraftId: string = "";
 
    manuallyAddAVAccessBottom = ()=>
    {
        let columnContainer = [];
        
        for (let index = 0; index < 2; index++) {

            let showBottom = false;
            if ((this.props.CurrentItem as VascularGraft) && (this.props.CurrentItem as VascularGraft).AVConfig)
            {
            if ((this.props.CurrentItem as VascularGraft).AVConfig && ((this.props.CurrentItem as VascularGraft).AVConfig as AVAccessType).indexOf("ENDA") === 0 && index === 0 )
                {
                    showBottom = true;
                }
                if ((this.props.CurrentItem as VascularGraft).AVConfig && ((this.props.CurrentItem as VascularGraft).AVConfig as AVAccessType).indexOf("ENDV") > 0 && index === 1 )
                {
                    showBottom = true;
                }
            //But indentation should only include ConduitVisualize from the direct parent
                columnContainer.push(<div key={"col"+index} className={"visual-graft-column av-access-item "}>
                    <ConduitVisualize leftConduit={((index === 0 ? null : Conduit.SVG)) as string} rightConduit={((index === 0 ? Conduit.LITA : null)) as string} topConduit={(index === 0 ? Conduit.LITA : Conduit.SVG)} bottomConduit={(showBottom ? (index === 0 ? Conduit.LITA : Conduit.SVG): null) as string}></ConduitVisualize>
                </div>);     
            }  
        }
        this.rowContainer.push(
            <div  key={"row" } className="visual-graft-row">
                {columnContainer}
            </div>);
            // if (columnContainer.length > this.indentCountMax)
            // {
            //     this.indentCountMax = columnContainer.length;
            // }
        this.columnContainerAll.push(columnContainer);
    }

    recurseBuildGraft = (childGraft: Graft, columnContainer: React.ReactElement[], parentIsSource = false, parentConduit: Conduit | undefined, parentIndent: number = 0, yChildren: number = 0, latestYConduit: Conduit | undefined, leftmost: boolean = false ) => {
       
      
       let isTopLevel = childGraft.Type === "Source"  || childGraft.Type === "Carotid" || childGraft.Type === "NewGraft";
       let sequentialChildren = 0;
      
       if (childGraft.Targets)
       {
            for (let index = 0; index < childGraft.Targets.length; index++) {
                const element = childGraft.Targets[index];
                if (element.Sequential)
                {
                    sequentialChildren++;
                }else
                {
                    yChildren++;
                    latestYConduit = element.Conduit as Conduit;
                }
            }
       }
       let hasBeenSavedClass: string = "";

    //    let savedInstances = this.context.getTTFMSavedInstance(childGraft.Id);
    //    if (this.props.Context === "Imaging")
    //    {
    //     savedInstances = this.context.getImagingSavedInstance(childGraft.Id);
    //    }
        // let savedInstances = this.context.getAllSavedInstances(childGraft.Id);
        // if (savedInstances && savedInstances.length > 0 )
        // {
        //     hasBeenSavedClass = "saved ";
        //     savedItemCount = savedInstances.length;
        // }
        // let beingMeasured = childGraft.Id === this.measuredGraftId;
         let indication: IndicatorState = IndicatorState.none;
        // if (beingMeasured && hasBeenSavedClass)
        //     indication = IndicatorState.measuringsaved;
        // else if (beingMeasured)
        //     indication = IndicatorState.measuring;
        // else if (hasBeenSavedClass)
        //     indication = IndicatorState.saved;

        let buttonText = childGraft.Title ? childGraft.Title : childGraft.Name;
        let isSourceClass = (isTopLevel  ? "is-source " : "");
        if (childGraft.Type === "NewGraft")
            isSourceClass = "is-blank-item ";
        if (childGraft.Type === "AVVein")
        {
            isSourceClass = "av-access-item ";
        }
        if (childGraft.Type === "Source" && TargetDetails.getTargetDetail(childGraft.Conduit as Conduit).isSource)
        {
            
            buttonText = buttonText + (childGraft.inSitu ? " In situ" : " Free");
        }
        if (childGraft.Type === "Source" && !childGraft.inSitu)
        {
            buttonText = "AORTA";
        }
        if (childGraft.Type === "NewGraft")
        {
            buttonText = "Location Undefined";
        }
        
       let itemToAdd =  <div key={childGraft.Id} className={"visual-graft-column " + isSourceClass }>
       <ConduitVisualize 
       leftConduit={(isTopLevel || ((childGraft.Type === "AVVein"|| childGraft.Type === "AVAccessGraft")) ? null : (childGraft.Type === "PeripheralGraft" && leftmost ? Conduit.LITA : parentConduit))as string } 
       rightConduit={(isTopLevel || sequentialChildren===0 || childGraft.Type === "AVVein"|| childGraft.Type === "AVAccessGraft"? (childGraft.Type === "PeripheralGraft" ? Conduit.LITA : null) : parentConduit) as string}
       bottomConduit={(isTopLevel || (yChildren > 0 && parentIndent > 0) || (childGraft.Type === "AVVein" || childGraft.Type === "AVAccessGraft")  ? parentConduit : null) as string}
       bottomIndicator={(yChildren > 0 && parentIndent > 0) ? 0 : indication}
       leftIndicator={indication}
       topIndicator={indication}
       rightIndicator={sequentialChildren>0 || childGraft.Type === "AVVein"|| childGraft.Type === "AVAccessGraft"? IndicatorState.none: indication }
       mode={Tabs.TTFM}
       ></ConduitVisualize>
       <button className={"visual-graft-button " +isSourceClass + hasBeenSavedClass +  (childGraft.Id === this.measuredGraftId ? "selected " : " ") } >
       {buttonText}
    
    {/* { ((SavedInstance.getAllCurrentAnnotations(savedInstances, false, this.props.Context).length > 0) && this.props.commentsOpen ) 
    ? <div className="graft-annotation">{Graft.countAnnotations(childGraft, this.props.Context, false, this.props.Context === "TTFM" 
    ? this.context.getTTFMSavedInstance : this.context.getImagingSavedInstance )}
    </div>
    : null} */}
       </button> </div>;

        if ((childGraft.Type === "PeripheralGraft" || childGraft.Type === "AVVein" || childGraft.Type === "AVAccessGraft") && !columnContainer )
        {
            columnContainer = [];
            this.rowContainer.push(
                <div  key={"row" + childGraft.Id} className="visual-graft-row">
                    {columnContainer}
                </div>);
                if (columnContainer.length > this.indentCountMax)
                {
                    this.indentCountMax = columnContainer.length;
                }
            this.columnContainerAll.push(columnContainer);
        }
       if (isTopLevel )
        {
            columnContainer = [];
            if (columnContainer)
                columnContainer.push(itemToAdd);
            //Add a column with the Source button 
            this.rowContainer.push(
                <div key={"row" + childGraft.Id} className="visual-graft-row">
                    {columnContainer}
                </div>);
                this.columnContainerAll.push(columnContainer);
                columnContainer = [];
                //If it has no sequential but does have a Y then add a ConduitVisualize
                if (yChildren > 0 && sequentialChildren === 0)
                    columnContainer.push(<div className="visual-graft-column is-source"><ConduitVisualize topConduit={parentConduit} bottomConduit={parentConduit}></ConduitVisualize></div>);
              //Add a column as an indent from the source
              if (childGraft.Type !== "NewGraft")
                this.rowContainer.push(
                    <div  key={"row2" + childGraft.Id} className="visual-graft-row">
                        {columnContainer}
                    </div>);
                    if (columnContainer.length > this.indentCountMax)
                    {
                        this.indentCountMax = columnContainer.length;
                    }
                this.columnContainerAll.push(columnContainer);
                
        }
        else if (!childGraft.Sequential || isTopLevel )
        {
            columnContainer = [];
            //First Indent based on parent.
            for (let index = 0; index < this.indentCount; index++) {
                //But indentation should only include ConduitVisualize from the direct parent
                if (index >= parentIndent-1)
                {
                    columnContainer.push(<div key={"col"+index} className={"visual-graft-column" + (index===0?" is-source":"")}><ConduitVisualize topConduit={parentConduit} rightConduit={parentConduit}></ConduitVisualize></div>);
                }else
                {
                    columnContainer.push(<div key={"col"+index} className={"visual-graft-column" + (index===0?" is-source":"")}></div>);
                }
                 
                
                    
            }
          
            
            columnContainer.push(itemToAdd);
            
           
            //If it's not sequential add a new row
            this.rowContainer.push(
                <div  key={"row" + childGraft.Id} className="visual-graft-row">
                    {columnContainer}
                </div>);
                if (columnContainer.length > this.indentCountMax)
                {
                    this.indentCountMax = columnContainer.length;
                }
            this.columnContainerAll.push(columnContainer);
                
        }
        else
        {
            if (parentIsSource)
            {

                let hasyChild: boolean = (yChildren > 0 ) ;
                //If the parent is the source and this is the first sequential.
                columnContainer.push(<div className="visual-graft-column is-source"><ConduitVisualize topConduit={parentConduit} bottomConduit={(hasyChild ? latestYConduit : null) as string} rightConduit={parentConduit} ></ConduitVisualize> </div>);
           
            }
                
            //If it is sequential add a new column
            if (columnContainer)
            {
                // if (!leftmost)
                // {
                //     if (sequentialChildren === 0)
                //         columnContainer.push(<div className="visual-graft-column is-source"><ConduitVisualize leftConduit={parentConduit} topConduit={null} bottomConduit={null} rightConduit={null} ></ConduitVisualize> </div>);
                // }
                columnContainer.push(itemToAdd);
                if (childGraft.Type === "PeripheralGraft")
                {
                    if (sequentialChildren === 0)
                        columnContainer.push(<div className="visual-graft-column"><ConduitVisualize leftConduit={Conduit.LITA} ></ConduitVisualize> 
                         </div>);
                }
                if (childGraft.Type === "AVVein")
                {
                    // let conduitChild = parentConduit;
                    // if (childGraft.Targets && childGraft.Targets.length > 0)
                    // {
                    //     conduitChild = childGraft.Targets[0].Conduit;
                    // }

                    // columnContainer.push(<div className="visual-graft-column"><ConduitVisualize leftConduit={parentConduit} topConduit={null} bottomConduit={null} rightConduit={conduitChild} ></ConduitVisualize> </div>);
                    
                }
                if (columnContainer.length > this.indentCountMax)
                {
                    this.indentCountMax = columnContainer.length;
                }
            }
        }
        if (childGraft.Targets)
        {
        for (let index = 0; index < childGraft.Targets.length; index++) {
            
            const element = childGraft.Targets[index];
            //If it's sequential then add a new column and a 
            if (element.Sequential)
            {
                this.recurseBuildGraft(element, columnContainer,  isTopLevel && index === 0, element.Conduit ? element.Conduit : parentConduit, 0, yChildren, latestYConduit ? latestYConduit : parentConduit);
            }
            
        }

        for (let index = 0; index < childGraft.Targets.length; index++) {
            
            const element = childGraft.Targets[index];
            //If it's sequential then add a new column and a 
            if (!element.Sequential)
            { 
                this.indentCount++;
                this.recurseBuildGraft(element, [], false, element.Conduit, this.indentCount, 0, undefined, false);
            }
            
        }
    }
    }


    render() {
        //TODO: Could workout which one to highlight.
        this.measuredGraftId = "";
         this.indentCount = 0;
         this.indentCountMax = 0;
         this.rowContainer = [];
         this.columnContainerAll = [];

         let conduitInput = this.props.CurrentItem.Conduit;
         if (this.props.CurrentItem.Type === "PeripheralGraft")
         {
            conduitInput = (this.props.CurrentItem as VascularGraft).Material as any;
         }

        //  let mainSelected = this.context.GraftMeasured ? false : true;
         //let savedItems = (this.props.Context === "Imaging" ? this.context.getImagingSavedInstance(this.context.GraftMeasured.Id) : this.context.getTTFMSavedInstance(this.context.GraftMeasured.Id));
        //  let savedItems = this.context.getAllSavedInstances(this.context.GraftMeasured.Id) ;
        //  let savedItemsActual = (this.props.Context === "Imaging" ? this.context.getImagingSavedInstance(this.props.CurrentItem.Id) : this.context.getTTFMSavedInstance(this.props.CurrentItem.Id));
        //  if (this.props.CurrentItem.Selected)
        //  {
        //     savedItemsActual = savedItems;
        //  }
        //  let savedItem = savedItems && savedItems.length > 0 ? savedItems[this.context.SelectedSaveIndex] : null;

        this.recurseBuildGraft(this.props.CurrentItem, [], false, conduitInput, 0, 0, undefined, true);
         if (this.props.CurrentItem.Type === "AVVein" || this.props.CurrentItem.Type === "AVAccessGraft")
            this.manuallyAddAVAccessBottom();
          //Foreach genuine new target added, we need to make sure call parent columns have the same numer.
          for (let index = 0; index < this.columnContainerAll.length; index++) {
            while (this.columnContainerAll[index].length < this.indentCountMax)
                this.columnContainerAll[index].push(<div className="visual-graft-column"></div>);
        }
        // if (this.props.CurrentItem.Type !== "PreGraft") {
        //     editButton =  <div className="worklist-edit-container"  onClick={this.editGraftClicked}><i className="material-icons worklist-icon-edit">create</i></div>;
        // }
        // commentButton = <div className={"worklist-comment-container "+ (this.props.commentsOpen ? "button-active" : "")  + 
        // (!savedItemsActual || savedItemsActual.length === 0 ? "disabledwl" : "")}
        //   onClick={this.commentGraftClicked}>
        //       <i className={"material-icons worklist-icon-edit " } >comment</i>
        // { (Graft.countAnnotations(this.props.CurrentItem, this.props.Context, true, this.props.Context === "TTFM" ?
        //  this.context.getTTFMSavedInstance 
        //  : this.context.getImagingSavedInstance ) > 0)
        //   ? <div className="graft-annotation">
        //       {Graft.countAnnotations(this.props.CurrentItem, this.props.Context,true, this.props.Context === "TTFM" 
        //       ? this.context.getTTFMSavedInstance 
        //       : this.context.getImagingSavedInstance )}
        //       </div>
        //       : null}
        //       </div>;
        // if (this.props.commentsOpen)
        // {
        //     commentButton = null;
        //     editButton = null;
        // }

        // let previewUrl;
        // let savedCarousel;

    //     if (savedItems && savedItems.length > 0)
    // {
    //     //previewUrl = this.props.savedItems[this.props.savedItems.length-1].pngUrl;
    //     previewUrl = savedItems.reverse().map(((item, index)=>
    //       (
    //         <div className={(index === this.context.SelectedSaveIndex ? "option-container-selected" : "option-container") + " micro " + (item.type === "Flow"  ? "flow" : "imaging")} onClick={()=> {
    //             // this.selectedSaveInCommentSelected(index);
    //             }}>
    //                   <div className="description-container">
    //       <span className="selection-time">{this.context.getCurrentDateTime(item.timeSaved)}</span> 
    //       {/* <span className="selection-graft-name"><WorklistText customGraftText={this.props.customGraftText} CurrentItem={this.props.CurrentGraft} ignoreUnderline={true}></WorklistText></span> */}
    //   </div>
    //         <div className={"thumbnail-container thumbnail-container-transparent " + (item.type === "Flow"  ? "flow" : "imaging")}>
    //         <img src={item.pngUrl}></img>
    //       </div>
    //       {SavedInstance.countAnnotations(item, this.props.Context) > 0 ? <div className="graft-annotation">{SavedInstance.countAnnotations(item, this.props.Context)}</div> : null}
    //       {
    //           item.type === "Flow" 
    //           ?
    //           <div className="thumbnail-values-container">
    //      <MiniTrafficLightTile id={"MeanFlowMiniTile"} key={"MeanFlowMiniTile"}
    //                         value={item.MF}
    //                         title="MF"
    //                         uom="ml/min"
    //                         {...this.context.tileLimits.meanFlow}
    //                         enableColouring={this.props.tileColorOn}
    //                         subTileMode={true} ></MiniTrafficLightTile>
    
    //                 <MiniTrafficLightTile  id={"PulsalityIndexMiniTile"}  key={"PulsalityIndexMiniTile"}
    //                         value={item.PI}
    //                         title="PI"
    //                         uom=""
    //                         maxValue={99}
    //                         {...this.context.tileLimits.pulsalityIndex}
    //                         enableColouring={this.props.tileColorOn} 
    //                         subTileMode={true}></MiniTrafficLightTile>
    //       </div>
    //       : null
    //     }
    //   </div>
    //   )));


       


return ( <>
    <div 
    id={this.props.CurrentItem.Id}
        className={"visual-row-container " + (this.props.CurrentItem.Selected ? "selected" : "")}>
       <div className={"plain-container " + (this.props.CurrentItem.Type === "Target" ? "other-cardiac-target" : "")}> 
       {/* { (SavedInstance.getAllCurrentAnnotations(this.context.getTTFMSavedInstance(this.props.CurrentItem.Id), true, this.props.Context).length > 0) ? <div className="main-graft-annotation graft-annotation">{SavedInstance.getAllCurrentAnnotations(this.context.getTTFMSavedInstance(this.props.CurrentItem.Id), true, this.props.Context).length}</div>: null} */}
        {this.rowContainer}
        </div>
       
    </div> 
    </>     
    )
  }
}

export default SubwayMap;