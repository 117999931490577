import React from "react";

export class Progressbar extends React.Component<
  {value: number, max: number, title:string, showValues: boolean},
  {
    showValue: boolean
  }
> {

  render() {
    let valueWidth = this.props.value/this.props.max * 100;
    if (Number.isNaN(valueWidth))
      valueWidth = 0;
    return (
      <>
        <div className="progressbarWrapper large-value">
            <div className="progressbarValue"style={{width: valueWidth}}>
                <div>{this.props.showValues? this.props.value:<></>}</div>
            </div> 

            <div className="progressbarContainer">
                <div className="progressbarHolder">
                    <div className="progressbar" style={{width:100}}>
                        <div className="progressbarValueWidth" style={{width: valueWidth}}></div>
                    </div>
                </div>

                <div className="maxValue">{this.props.showValues? this.props.max:<></>}</div>

            </div>

            
        </div>
        <div className="progressbarTitle small-label">{this.props.title}</div>
        
      </>
    );
  }}
