import React, { RefObject }  from "react";
import { ErrorWindow } from "../Components/ErrorWindow";
import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ErrorBar,
    Legend
} from "recharts";
import { DataHelper } from "../Helpers/DataHelper";
import { ChartDataModel } from "../Models/ChartDataModel";
import { LoadingWidget } from "../Components/LoadingWidget";
import { StudyModel } from "../Models/StudyModel";
import { InputItem } from "../Components/InputItem";

export class FlowValues extends React.Component<
{
    dataClient: DataHelper,
    studyId: string,
    studySelected: StudyModel
},
{
    flowData: ChartDataModel[],
    isBusy: boolean,
    labelType: string,
    additionalFilter:string
}
> {

    //Data structure: 
    // data = [ 
    //     {
    //         "x": category,
    //         "y": number,
    //         "errorY": [minNumber, maxNumber]
    //     }];
    errorWindow:  RefObject<ErrorWindow>;
    constructor(props: any) {
        super(props);
        this.errorWindow = React.createRef();
        if (props.studySelected.labelTypes?.at(0)){
        this.state = {
            flowData: [],
            isBusy: false,
            labelType: props.studySelected.labelTypes?.at(0) as string,
            additionalFilter: "All"
        }}
        else{
            this.state = {
                flowData: [],
                isBusy: false,
                labelType: "",
                additionalFilter: "All"
            }
        }
    }

    getData = (studyId: string, labelType?: string, additionalFilter?: string): void => {
        this.setState({ isBusy: true })
        this.props.dataClient.CallDashboardService("GetFlowValues", {}, {studyId: studyId, labelType: labelType ? labelType : (this.state.labelType ? this.state.labelType : ""), additionalFilter: additionalFilter ? additionalFilter : this.state.additionalFilter}, (result: string) => {
            let flowValues = JSON.parse(result);
            // if (callback)
            //     callback(flowValues);

            this.setState({ labelType: labelType ? labelType : this.state.labelType, flowData: flowValues, isBusy: false })
        }, (stautus:number, errorMessage:string)=>{
            // if (callback)
            // callback([1,2,3]);
            (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage)}, "GET", false);

    }

    
    componentDidMount(): void {}

    onDropdownChange = (name:string, value: string): void => {
        if (name === "labelType")
        {
            this.getData(this.props.studySelected.id as string, value as string)
        }else
        if (name === "additionalFilter")
        {
            this.getData(this.props.studySelected.id as string, "",  value as string)
        }

    }

    render() {

        let options = [...this.props.studySelected.labelTypes as string[]];
        let avgMeanTestDiv = <></>;
        if (this.state.flowData && this.state.flowData.length > 0 && this.state.flowData[this.state.flowData.length-1])
        {
            avgMeanTestDiv  = <div id="averagemeanflowhiddenttestdiv" style={{display: "none"}}>{this.state.flowData[this.state.flowData.length-1].value1}</div>
        }

        return (
            <>
            <ErrorWindow ref={this.errorWindow}/>
            <h3>Average mean flow</h3>
            {avgMeanTestDiv}
                <div className="chartDropdown">
                    <InputItem title={"Select label type"} type={"dropdown"} name={"labelType"} onChange={this.onDropdownChange} value={this.state.labelType?this.state.labelType:options[0]} options={options}></InputItem>
                    <InputItem title={"Filter"} type={"dropdown"} name={"additionalFilter"} onChange={this.onDropdownChange} value={this.state.additionalFilter} options={["All", "Qualified"]}></InputItem>
                </div>
                <div className="chartHolder">
                    <ScatterChart
                        width={900}
                        height={300}
                        margin={{ top: 20, right: 20, bottom: 0, left: 20 }}
                    >
                        <CartesianGrid />
                        <XAxis dataKey="name" type="category" name="labels" />
                        <YAxis dataKey="value1" type="number" name="avgmeanflow" unit=" mL/min" />
                        <Tooltip />
                        <Scatter name="Average mean flow" dataKey={"value1"} data={this.state.flowData} fill="#83a6ed">
                            <ErrorBar dataKey="errorY" width={4} strokeWidth={2} stroke="#82ca9d" direction="y" />
                        </Scatter>
                        <Legend />
                    </ScatterChart>
                    <LoadingWidget visible={this.state.isBusy} zIndexLayer={0}></LoadingWidget>
                </div>
            </>
        );
    }
}
