import * as React from 'react';
import {TileRange} from "../Helpers/SharedClasses"
import * as d3 from 'd3';

class TrafficLightTile extends React.PureComponent<{context: string, enableColouring: boolean,valueStale: boolean, title: string,value: number | string,
  subValue: number, subValueUom: string, uom: string,includeSubValue: boolean, defaultColor: string, maxValue: number,
  includeEdit: boolean, goodRange: TileRange, badRange: TileRange, worstRange: TileRange, wideTile: boolean, expanded: boolean, referenceValueStyle: string, tileClicked: Function, tileValueChanged: Function }, { isediting: boolean } >
 {

    constructor(props: any) {
        super(props);

      this.state = {
        isediting: false
      }

      }
      componentDidUpdate = () =>
      {
        this.clearReferenceValuesBar();
        if (this.props.expanded )
        {
          
            
            this.drawReferenceValuesBar();
          
        }
      
      }

      static defaultProps = {
        context: "TTFM",
        title: "ML",
        uom: "mL/min",
        value: 0,
        subValue: "",
        status: 0,
        subValueUom: "",
        enableColouring: false,
        defaultColor: "tile-no-data",
        includeEdit: false,
        includeSubValue: false,
        referenceValueStyle: "",
        goodRange: {
           min: -1,
           max: 0
        },
        badRange: {
          min: 90,
          max: 99
       },
       worstRange: {
        min: 100,
        max: 1000
      },
      wideTile: false,
      maxValue: 10000,
      expanded: true,
      valueStale: false,
      tileValueChanged: ()=>{}

      }

      getLightColorForTile()
      {
        if (this.props.enableColouring)
        {
            if (this.props.value as number  >= this.props.goodRange.min &&  this.props.value as number  <= this.props.goodRange.max )
            {
              return "tile-greenlight";
            }
            else if (this.props.value as number  >= this.props.badRange.min &&  this.props.value as number  <= this.props.badRange.max )
            {
              return "tile-yellowlight";
            }
            else if (this.props.value as number  >= this.props.worstRange.min &&  this.props.value as number  <= this.props.worstRange.max )
            {
              return "tile-redlight";
            }
            else if (this.props.goodRange.min < this.props.badRange.min)
            {
                if (this.props.value as number  < this.props.goodRange.min)
                {
                  return "tile-greenlight";
                }
                else if (this.props.value as number  > this.props.badRange.max && (this.props.badRange.max > this.props.worstRange.max))
                {
                  return "tile-yellowlight";
                }
                else if (this.props.value as number  > this.props.worstRange.max)
                {
                  return "tile-redlight";
                }
            }
            else if (this.props.goodRange.max > this.props.badRange.max)
            {
                if (this.props.value as number  > this.props.goodRange.max)
                {
                  return "tile-greenlight";
                }
                else if (this.props.value as number  < this.props.badRange.min &&(this.props.badRange.min < this.props.worstRange.min))
                {
                  return "tile-yellowlight";
                }
                else if (this.props.value as number  < this.props.worstRange.min)
                {
                  return "tile-redlight";
                }
            }
            
            return this.props.defaultColor;
        }
        else
        {
          return this.props.defaultColor;
        }
      }

      calculateValueOnAxis(value: number, axisMin: number, axisMax: number, axisLength: number, axisOffset: number)
      {
        return axisLength*(value/(axisMax - axisMin)) + axisOffset;
      }

      calculateValueOnAxisEvenParts(value: number, axisMin: number, axisMax: number, axisLength: number, axisOffset: number)
      {
        let valuePercentage = value/(axisMax - axisMin);
        let numberOfParts = 3;
      
        let partsPercentage = 1/numberOfParts;
        for (let index = 0; index < numberOfParts; index++) {
            if (partsPercentage * index <= valuePercentage && partsPercentage * (index +1) <= valuePercentage )
            {
              //return partNumber;
              return [axisLength*(partsPercentage * (index) ) + axisOffset, axisLength*(partsPercentage * (index +1) ) + axisOffset] ;
            }
        }
        return [0, axisLength + axisOffset];
        // if (1 * partsPercentage  )
        // partNumber

        // let eachLength = axisLength/numberOfParts;
        // if (eachLength)
        // return axisLength*(value/(axisMax - axisMin)) + axisOffset;
      }

      calculateZone(value: any, axisMin: any, axisMax: any)
      {
        let valuePercentage = value/(axisMax - axisMin);
        let numberOfParts = 3;
       
        let partsPercentage = 1/numberOfParts;
        for (let index = 0; index < numberOfParts; index++) {
            if (partsPercentage * index <= valuePercentage && valuePercentage <= partsPercentage * (index +1) )
            {
              //return partNumber;
             return index;
            }
        }
        return 0;
        // if (1 * partsPercentage  )
        // partNumber

        // let eachLength = axisLength/numberOfParts;
        // if (eachLength)
        // return axisLength*(value/(axisMax - axisMin)) + axisOffset;
      }

      calculateZoneOrders = () =>
      {
         let ZoneList = [{
           color: "var(--GreenApple)",
           min: this.props.goodRange.min,
           max: this.props.goodRange.max,
           yValue: 0,
           heightValue: 0
         }
         ,{
          color: "var(--BananaClan)",
          min: this.props.badRange.min,
          max: this.props.badRange.max,
          yValue: 0,
          heightValue: 0
        },
        {
          color: "var(--BernRed)",
          min: this.props.worstRange.min,
          max: this.props.worstRange.max,
          yValue: 0,
          heightValue: 0
        }
        ];
        //If the worst range is zero then don't display it a worst range.
        if ((this.props.worstRange.min === 0 && this.props.worstRange.max === 0))
        {
          ZoneList[2].color = "var(--BananaClan)";
        }

        for (let mainindex = 0; mainindex < ZoneList.length; mainindex++) {
        for (let index = mainindex+1; index < ZoneList.length; index++) {
          const element = ZoneList[mainindex];
          const element1 = ZoneList[index];
          if (element1.max > element.max)
          {
            //If they are out of order then Swap.
            ZoneList[mainindex] = element1;
            ZoneList[index] = element;
          }
        }
      }

        return ZoneList;
      }

      getPositionBasedOnZone = (valueIn: any, zoneList: any) =>
      {
     
        let value = parseFloat(valueIn);
        let zoneItIsIn = null;
        for (let index = 0; index < zoneList.length; index++) {
          const element = zoneList[index];
          if (!zoneItIsIn && element.min <= value && value <= element.max )
          {
            zoneItIsIn = element;
          }
        }
        let percentPos = 0;
        if (zoneItIsIn === null){
            //If its below the minimum range then set to the first range.
            if (zoneList[zoneList.length - 1].min > value)
            {
              percentPos = 0;
              zoneItIsIn = zoneList[zoneList.length - 1];
            }else if (zoneList[0].max < value)
            {
              percentPos = 1;
              zoneItIsIn = zoneList[0];
            }else
            {
              //This should be impossible!
              percentPos = 0.5;
              zoneItIsIn = zoneList[1];
            }
        }else
        {

        let range = (zoneItIsIn.max - zoneItIsIn.min);
        if (range === 0)
        {
          //If there is no range make the value 100%
          
          range = value;
        }
        if (range === 0)
        {
          percentPos = 0;
        }else
        {
            percentPos = (value - zoneItIsIn.min) /range;
        }
      }
        let drawnPos = (1-percentPos) * zoneItIsIn.heightValue + zoneItIsIn.yValue;
return drawnPos;
      }

      clearReferenceValuesBar = () =>
      {
        d3
        .selectAll("#ttfm-tile-" + this.props.title+ "-" + this.props.context+ ' .ttfm-tile-reference-values')
        .select("svg")
        .remove();
      }




      
    
      widthToUse = -1;
      drawReferenceValuesBar = () =>
      {
        if (this.widthToUse === -1)
          this.widthToUse = document.getElementById("ttfm-tile-" + this.props.title+ "-" + this.props.context)?.offsetWidth as number;
        if (this.props.referenceValueStyle === "Stacked")
          this.widthToUse = 150;
      
        var heightToUse = 200;
        var svg = d3.select("#ttfm-tile-" + this.props.title+ "-" + this.props.context + ' .ttfm-tile-reference-values')
        .append('svg')
        .attr('height', heightToUse)
        .attr('width', this.widthToUse);

        var blockHeight = heightToUse - 20;
        var blockWidth = 30;

        var blockX = this.widthToUse/2;
        var blockY = 10;


//First calculate the entire range
  var rangeMax = this.props.goodRange.max;
  if (rangeMax < this.props.badRange.max)
    rangeMax = this.props.badRange.max;
  if (rangeMax < this.props.worstRange.max)
    rangeMax = this.props.worstRange.max;

    var rangeMin = this.props.goodRange.min;
    if (rangeMin > this.props.badRange.min)
      rangeMin = this.props.badRange.min;
    if (rangeMin > this.props.worstRange.min)
      rangeMin = this.props.worstRange.min;

      let cornerRadius = 6;

//Tile reference block with no values.
svg.append('rect')
.attr('class', 'bg-rect')
.attr('fill', 'var(--snapper)')
.attr('height', blockHeight)
.attr('width', blockWidth)
.attr("rx", cornerRadius)
.attr("ry", cornerRadius)
.attr('x', blockX)
.attr('y', blockY);



       let ZonesSorted = this.calculateZoneOrders();

       var scale = d3.scaleLinear()
       .domain([0, 99])
       .range([blockY, blockHeight]);
      //     // Add scales to axis
           var x_axis = d3.axisLeft(scale)
           .tickValues([33, 66])
           .tickSize(0)
           .tickFormat((n: any) => { 
             if ((n/33) < ZonesSorted.length && ZonesSorted[(n/33)].max !== 0)
             return ZonesSorted[(n/33)].max.toString(); 
             else
             return "";
            });
      
          //Append group and insert axis
          svg.append("g")

          .attr('class', 'tile-axis-label')
          .attr("transform", "translate("+blockX+","+blockY+")")
             .call(x_axis)
             .call(g => g.select(".domain").remove());
      

      //  var goodZone = this.calculateZone(this.props.goodRange.min, rangeMin, rangeMax);
      //  var badZone = this.calculateZone(this.props.goodRange.max, rangeMin, rangeMax);
      //  var worstZone = this.calculateZone(this.props.badRange.min, rangeMin, rangeMax);
      //  var badZoneMax = this.calculateZone(this.props.badRange.max, rangeMin, rangeMax);
      //  var worstZoneMin = this.calculateZone(this.props.worstRange.min, rangeMin, rangeMax);
      //   var worstZoneMax = this.calculateZone(this.props.worstRange.max, rangeMin, rangeMax);
//Draw the zones sorted



for (let index = 0; index < ZonesSorted.length; index++) {

  const element = ZonesSorted[index];
  //Increment the index to start a bit later because the bottom range is missing
  
  ZonesSorted[index].yValue = blockY + index*blockHeight*(1/ZonesSorted.length);
  ZonesSorted[index].heightValue = blockHeight*(1/ZonesSorted.length);
 
  //Round the top zones top corners and the bottom zones bottom corners.
  let topCornersRadius = index === 0;
  let bottomCornersRadius = index === ZonesSorted.length -1;
 
  svg.append("path")
    .attr("d", this.rounded_rect(blockX, ZonesSorted[index].yValue, blockWidth, ZonesSorted[index].heightValue,cornerRadius, topCornersRadius,topCornersRadius,  bottomCornersRadius, bottomCornersRadius ))
		.attr('class', 'bg-rect')
		.attr('fill', element.color);
		// .attr('height', ZonesSorted[index].heightValue)
    // .attr('width', blockWidth)
    // .attr('x', blockX)
    // .attr('y',ZonesSorted[index].yValue);
   
}

//Now calculate the good range
// var goodY = this.calculateValueOnAxisEvenParts(this.props.goodRange.min, rangeMin, rangeMax, blockHeight, blockY);
// var goodHeight = this.calculateValueOnAxisEvenParts(this.props.goodRange.max, rangeMin, rangeMax, blockHeight, blockY);

// //Tile good block with good values range.
// svg.append('rect')
// 		.attr('class', 'bg-rect')

// 		.attr('fill', 'var(--GreenApple)')
// 		.attr('height', goodHeight[0])
// 		.attr('width', blockWidth)
//     .attr('x', blockX)
//     .attr('y', goodHeight[1]);

//     //Now calculate the bad range

// var badHeight = this.calculateValueOnAxisEvenParts(this.props.badRange.max, rangeMin, rangeMax, blockHeight, blockY);

// //Tile good block with bad values range.
// svg.append('rect')
// 		.attr('class', 'bg-rect')

// 		.attr('fill', 'var(--BananaClan)')
// 		.attr('height', badHeight[0])
// 		.attr('width', blockWidth)
//     .attr('x', blockX)
//     .attr('y', badHeight[1]);

//     //Now calculate the worst range
// var worstY = this.calculateValueOnAxisEvenParts(this.props.worstRange.min, rangeMin, rangeMax, blockHeight, blockY);
// var worstHeight = this.calculateValueOnAxisEvenParts(this.props.worstRange.max, rangeMin, rangeMax, blockHeight, blockY);

// //Tile good block with worst values range.
// svg.append('rect')
// 		.attr('class', 'bg-rect')

// 		.attr('fill', 'var(--CoralRed)')
// 		.attr('height', worstHeight[0])
// 		.attr('width', blockWidth)
//     .attr('x', blockX)
//     .attr('y', badHeight[1]);

     let colourSet = this.getLightColorForTile();
     let valueColor = "var(--Raven)";
     if (colourSet === "tile-redlight")
        valueColor= "var(--swan)";
    //Finally draw the actual value line
    var valuePosition = this.getPositionBasedOnZone(this.props.value, ZonesSorted);
svg.append('rect')
.attr('class', 'bg-rect')

.attr('fill', valueColor)
.attr('height', 3)
.attr('width', blockWidth)
.attr('x', blockX)
.attr('y', valuePosition);
      }


      editValueClicked = (e: any) =>
      {
       // let valueIn = this.props.value;
       
       this.setState({
         isediting: true,
       // overridevalue: valueIn 
       });
       e.stopPropagation();
      }

      blurValue = () =>
      {
       this.setState({
         isediting: false
       })
      }

//       x: x-coordinate
// y: y-coordinate
// w: width
// h: height
// r: corner radius
// tl: top_left rounded?
// tr: top_right rounded?
// bl: bottom_left rounded?
// br: bottom_right rounded?
     rounded_rect(x: any, y: any, w: any, h: any, r: any, tl?: any, tr?: any, bl?: any, br?: any) {
        var retval;
        retval  = "M" + (x + r) + "," + y;
        retval += "h" + (w - 2*r);
        if (tr) { retval += "a" + r + "," + r + " 0 0 1 " + r + "," + r; }
        else { retval += "h" + r; retval += "v" + r; }
        retval += "v" + (h - 2*r);
        if (br) { retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + r; }
        else { retval += "v" + r; retval += "h" + -r; }
        retval += "h" + (2*r - w);
        if (bl) { retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + -r; }
        else { retval += "h" + -r; retval += "v" + -r; }
        retval += "v" + (2*r - h);
        if (tl) { retval += "a" + r + "," + r + " 0 0 1 " + r + "," + -r; }
        else { retval += "v" + -r; retval += "h" + r; }
        retval += "z";
        return retval;
    }

    render() {
    let rightHtml: React.ReactElement = <></>;
    // if (this.props.includeEdit)
    // {
    //   rightHtml = <div className="tile-title-right" onClick={this.editValueClicked}><i className="material-icons"
    //   style={{fontSize: 22, color: "var(--swan)"}}>edit</i></div>;
    // }
    if (this.props.includeSubValue)
    {
      rightHtml = <div className="tile-title-right BPM-value">{this.props.subValue}<br/><div className="tile-title-right BPM">{this.props.subValueUom}</div></div>;
    }

    let valueFragment =  <div className={"tile-value "+(this.props.valueStale?"tile-value-stale":"")}> {this.props.value as number > this.props.maxValue ? this.props.maxValue: this.props.value}</div>;
    
    if (this.props.includeEdit)
    {
      valueFragment =  <div className={"tile-value tile-value-container-no-edit "+(this.props.valueStale?"tile-value-stale":"") +(this.getLightColorForTile() === this.props.defaultColor ?" tile-value-container-no-edit-no-color":"")} onClick={this.editValueClicked}> {this.props.value as number > this.props.maxValue ? this.props.maxValue: this.props.value}</div>;
    
    }
    if (this.state.isediting)
    {
      valueFragment= <div className="tile-value"> <input className="tile-value-input" type="number" autoFocus={true} defaultValue={this.props.value} onBlur={this.blurValue} onChange={(e)=>{this.props.tileValueChanged(this.props.title, e.target.value);}} /></div>;
    }
    //This defines reference values at the bottom of the tile if required
//     let referenceValuesBottom: React.ReactFragment;

// referenceValuesBottom =   <div className={"ttfm-tile-reference-values "+(this.props.expanded ? "ttfm-tile-reference-values-expanded" : "") }>
// </div>;

        return (
        <div id={"ttfm-tile-" + this.props.title+ "-" + this.props.context} className={ "ttfm-tile-container " + (this.props.expanded ? "ttfm-tile-container-expanded " : " ") + (this.props.referenceValueStyle === "Stacked" && this.props.expanded ? "ttfm-tile-container-stacked" : "") }>
  
          <div className={this.getLightColorForTile() + (this.props.wideTile ? " TTFM-tile-wide" : " TTFM-tile-basic")} onClick={(e) => { this.props.tileClicked(e);}}>
            <div className="tile-title">{this.props.title}
              {rightHtml}
            </div>
          <div className="tile-content">
            {valueFragment}
            <span className="tile-uom"> {this.props.uom}</span>
          </div>
          </div>
        </div>
    );
    }


}

export default TrafficLightTile;