import { DataGridRow } from "../Helpers/SharedClasses";

export class LabelModel extends DataGridRow
{
    
    name: string = "";
    description: string = "";
    id?: string = "";
    type: string = "";
    isAttribute?: string = "";
    componentType?: string = "";

    suggestedLabels?: LabelModel[]=[];
    suggestionLabelString?:string="";
    timesUsed?: string= "";
    
}

export class suggestionLabels{
    suggestedLabels: LabelModel[]=[];
}

export class UpdateSuggestionLabelWrapper
{
    studyId:string = "";
    labelModels:LabelModel[] = [];
    deleteLabelModels: LabelModel[]=[];

    constructor(id:string, labels:LabelModel[], delLabel?:LabelModel[]){
        this.studyId=id;
        this.labelModels=labels
    }
}



/*NEW LABEL SYSTEM MODELS*/


export class SuggestedLabelMapping
{
    predefinedLabel:string = "";

    associatedNames: string[]=[];
    timesUsed: string= "";

}



export class UpdateSuggestionLabelMappingWrapper
{
    studyId:string = "";
    saveLabelModels:SuggestedLabelMapping[] = [];


    constructor(studyId:string, saveLabels:SuggestedLabelMapping[]){
        this.studyId=studyId;
        this.saveLabelModels=saveLabels;
    }
}