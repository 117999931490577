import React, {RefObject} from "react";
import DataGrid from "../Components/DataGrid";
import { DataHelper } from "../Helpers/DataHelper";
import { ErrorWindow } from "../Components/ErrorWindow";
import { InfoBox } from "../Components/InfoBox";
import { CurrentLogonModel } from "../Models/CurrentLogonModel";

export class CurrentLogons extends React.Component<
    {
        
        dataClient: DataHelper,
    

        

        //Properties go here
    },
    {
        usageHistoryLog:CurrentLogonModel[],
       
        //State goes here
    }
> {

    ;

    errorWindow:  RefObject<ErrorWindow>;
    infoBox: RefObject<InfoBox>;
    
    constructor(props: any) {
        super(props);
        this.errorWindow = React.createRef();
        this.infoBox = React.createRef();
        this.state={
            
            usageHistoryLog:[],
            
        }

    }


    componentDidMount(): void {
        this.refreshUsers();
    //     this.props.dataClient.CallMetadataService("GetUsers", {}, {}, (resultOut: string)=>
    //     {
    //         let allUsers: UserModel[] = JSON.parse(resultOut);
    //         this.setState({
    //             allUsers: allUsers
                
    //         })
    //     //Put stuff here that you want to run the first time the component is loaded.
    // });        
    }


    refreshUsers():void{
        this.props.dataClient.CallMetadataService("GetCurrentLogons", {}, {}, (resultOut: string)=>
            {
                let usageHistoryLog = JSON.parse(resultOut);
                this.setState({
                    usageHistoryLog: usageHistoryLog
                })
            },(stautus:number, errorMessage:string)=>{
                (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage);
            });
    }
   

   
    render() {
       
        let columns=[
            //{Name: "id", Caption: "Id"},
           
            {Name: "logonDate", Caption: "Logon date", Type: "date"},
            {Name: "logonDate", Caption: "Logon time", Type: "time"},
            {Name: "ipAddress", Caption: "Ip Address"},
            {Name: "userAgent", Caption: "User Agent"},
            {Name: "current", Caption: "Current user", Type: "boolean"},
            {Name: "flagged", Caption: "Unexpected", Type: "boolean"}
            ];
          
        return (
            <>
            <ErrorWindow ref={this.errorWindow}/>
            <InfoBox ref={this.infoBox} title="Confirmation" type="confirmation"></InfoBox> 
            <div className="userWindowWrapper">
                <div className="userWindowBody">
                    <div className="usersGridHolder">
                        <DataGrid showSummary={false} columns={columns} 

                            rows={this.state.usageHistoryLog}
                            
                        ></DataGrid>

                    </div>
                </div>
                
            </div>
            </>
        );
    }
}

