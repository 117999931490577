
import { useState } from 'react';
import './App.css';
import { DataHelper } from './Helpers/DataHelper';
import { AppFrame } from './Main/AppFrame';
import { AuthPage } from './Main/AuthPage';


function App(props: any) {




  const [state, setState] = useState({
    autheduser: false,
    user_id: "",
    authKey: "",
  });

  let hostaddress = process.env.REACT_APP_APIADDRESS as string ;
  console.log("REACT_APP_APIADDRESS pre: " + hostaddress);
  console.log("NODE_ENV: " + process.env.NODE_ENV);
  let useAuth = process.env.REACT_APP_USEAUTH;
  let nodeEnv = process.env.NODE_ENV;
  let redirectUrl = process.env.REACT_APP_AUTH_REDIRECTURI as string;
  if (nodeEnv === 'production')
  {
    useAuth = "AUTH";
    if (!hostaddress)
      hostaddress = "https://medistimstudiesservice.azurewebsites.net/";
    console.log("Build mode: " + nodeEnv);
    console.log("REACT_APP_USEAUTH mode: " + useAuth);
  }
  console.log("REACT_APP_APIADDRESS: " + hostaddress);
  // const search = window.location.search;
  // const params = new URLSearchParams(search);
  // const systemSerialNumber: string = params.get('SerialNumber') as string;
  let dataClient = new DataHelper(hostaddress);
  
  //const inputRef = React.useRef<AppFrame>(null);
  //If unauthed and the User id is blank then 
  if (!state.autheduser && state.authKey !== "UNAUTHED")        
  {
  //console.log("Calling GetUserDetails");
  dataClient.GetUserDetails((userDetails: any) =>
  
      {
       // console.log("Calling GetUserDetails Out: " + JSON.stringify(userDetails));
        if (userDetails.autherror)
        {
        

          if (useAuth !== "AUTH") {
            //console.log("Test mode, ignoring auth");
            setState(
              {
                //Swap comments to test Auth
                // autheduser: false,
                // user_id: "",
                // authKey: "UNAUTHED"
                autheduser: true,
                //user_id: "mathias.tyseng@medistim.com",
                //user_id: "pernille.temmerud@medistim.com",
                user_id: (props && props.username) ? props.username : "robert.hurry@medistim.com",
                //user_id: "jenny.temmerud@medistim.com",
                //user_id: "upload@medistim.com",
                authKey: "Authed"
              }
            );
        } else {
          console.log("Production mode, auth enabled");
          setState(
            {
              //Swap comments to test Auth
              autheduser: false,
              user_id: "",
              authKey: "UNAUTHED",
             
              // autheduser: true,
              // user_id: "robhurry@medistim.com",
              // authKey: "Authed"
            }
          );
        }
        
        
          
        }
        else{

    
        console.log("User logged in: " + userDetails.user_id);
        
        setState(
          {
            autheduser: true,
            user_id: userDetails.user_id,
            //TODO ENCRIPTO: Should this be access_token?
            authKey: userDetails.id_token
          }
        );

        //inputRef.current?.refreshAll();
      }

          //Otherwise there is no auth so don't do anything
          // callback({
          //   user_id: "robhurry@gmail.com",
          //   access_token: "blablafishpaste"
          // });
      });
    }

let authError = (e: string)=>
{
  setState(
    {
      autheduser: false, 
      user_id: "",
      authKey: "UNAUTHED"
    }
  );
}




  return (
    <div className="App"> 
    {
      state.autheduser ?
      <>
      <AppFrame unauthorizedCallback={authError} serviceAddress={hostaddress} user_id ={state.user_id} authKey={state.authKey}></AppFrame>
      <div className="signedin-label">{ state.user_id !== "robert.hurry@medistim.com" ? "Signed in as: " + state.user_id : ""}</div>
      </>
      :
     <AuthPage redirectUrl={redirectUrl}></AuthPage>
    }
      
    </div>
  );
}

export default App;
