import { PiX, PiInfo, PiCheckCircle } from "react-icons/pi";

import React from "react";
/* 
How to use infoBox:

    1. Add this line to return in render:
          <InfoBox ref={this.infoBox} title="(type of info)" type="(type of info)"></InfoBox>

      (type of info) could be "confirmation" or "info"
      the title is what is shown in the header. 
      the type is to get the right icon.
      
    2. Add this line to where/when you want to show the infobow
          (this.infoBox as InfoBox).show("(message here)");
      (message here) is what is shown in the body of the infobox
          
 */
export class InfoBox extends React.Component<
  { title: string, type: string },
  {
    visible: boolean,
    message: string
  }
> {


  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      message: ""
    }
  }
  onButtonPress = () => {
    this.setState({ visible: false })
  }
  show = (content: string, autoHide: boolean = true ) => {
    this.setState({ visible: true, message: content })
    if (autoHide) {
      setTimeout(() => { this.setState({ visible: false }) }, 3000)
    }

  }


  render() {
    if (this.state.visible) {
      return (
        <>
          <div className="infoBoxContainer">
            <div className="infoBoxHeader">
              <div className="infoBoxHeaderTitle">{this.props.title}</div>
              <button className="closeButton" onClick={() => { this.setState({ visible: false }) }}>
              <PiX className="buttonIcon"/>
              </button>
            </div>
            <div className="infoBoxBody">
              <div className="typeIcon"><div>
                {this.props.type === "info" ?
                  <PiInfo/>

                  : this.props.type === "confirmation" ?
                  <PiCheckCircle/>
                    : <></>
                }
              </div></div>
              <div className="bodyContent">{this.state.message}</div>
            </div>
          </div>


        </>
      );
    }
    else {
      return (<></>);
    }

  }
}