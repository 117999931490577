import React from "react";
import { DropdownButton } from "../Components/DropdownButton";
import { StudyModel } from "../Models/StudyModel";
import { AppMode } from "../Helpers/SharedClasses";
import { PiGearSixFill } from "react-icons/pi";
import { StudySummaryModel } from "../Models/StudySummaryModel";
import { Progressbar } from "../Components/Progressbar";
import { checkUserAccess } from "../Helpers/AccessMap";
import { UserAccessModel } from "../Models/UserAccessModel";
import { StarPreview } from "../Components/StarPreview"
import { PopupWindow } from "../Components/PopupWindow";
import { PiInfo } from "react-icons/pi";
import { RatingDocumentation } from "../Helpers/RatingDocumentation";

export class Toolbar extends React.Component<
  { starRating: number, loadingProcedures: boolean, appmodeStack: AppMode[], userAccess: UserAccessModel, studySummary: StudySummaryModel, allStudies: StudyModel[], studySelected: StudyModel, 
    addAppToStack: (mode: AppMode) => void, removeAppFromStack: (mode:AppMode) => void, toggleFilter: () => void,toggleEdit:()=>void, exportData:()=>void,
    onRefreshSelected: () => void, setAppMode: (mode: AppMode) => void, onstudySelected: (studyValue: StudyModel, studyIndex: number) => void },
  {
    popupVisible: boolean,
    filterPressed: boolean,
    editPressed: boolean
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { popupVisible: false, filterPressed: false, editPressed: false }
  }

  filterPressed = ()=>{
    let current = this.state.filterPressed;
    current = !current;
    this.setState({filterPressed: current});
  }
  editPressed = ()=>{
    let current = this.state.editPressed;
    current = !current;
    this.setState({editPressed: current});
  }

  render() {
    // let filterClassName = "";
    let editClassName = "";
    // if (this.state.filterPressed){
    //   filterClassName = "primaryButton Pressed";
    // }
    // else{
    //   filterClassName = "primaryButton";
    // }
    if (this.state.editPressed){
      editClassName = "primaryButton Pressed";
    }
    else{
      editClassName = "primaryButton";
    }
    
    
    return (
      <>

        <div className="superHeader dashboardHeader">  
          {((this.state.popupVisible) ?
            <div className="ratingPopup">
                  <PopupWindow title={"How rating works"} closeClicked={() => this.setState({ popupVisible: false })}> 
                    <RatingDocumentation />
                  </PopupWindow>
                  </div>: <></>)}
          
          <span className="dashboardLogo" onClick={() => {
            this.props.onRefreshSelected();
          }}><img src="/data2.svg" alt="Case Cloud" /></span>
          <span className="superName" onClick={() => {
            this.props.onRefreshSelected();
          }}>
            <span className="medLogo"><img src="/logoWhite.svg" alt="Meaningful text" /></span>
            <span className="medMiniText">case cloud</span>
          </span>
          <span className="superMiddle">

            <span className="TopTile bigTile">
              <span className="TopTileWrapp">
                <span className="large-value">Study: </span>
                <DropdownButton dropdownValue={this.props.studySelected.name} >
                  {
                    this.props.allStudies.map((item: StudyModel, index: number) => {
                      return <div key={"study" + item.id} onClick={() => {
                        this.props.onstudySelected(item, index);

                      }} >{item.name} - {item.description}</div>
                    })
                  }
                </DropdownButton>
              </span>
            </span>




            <span className="TopTile systemsTile">
          
              {
                checkUserAccess(this.props.userAccess.userType, AppMode.SitesSummary)
                ?
                <>
                <span className="TopTileWrapp">
                <span className="large-value">{this.props.studySummary.sites}</span>
                <span className="small-label">Sites</span>
              </span>
                <span className="TopTileRightCol">
                <span className="large-value">{this.props.studySummary.saves}</span>
                <span className="small-label">Saves</span>
              </span>
              </>
              :
              <></>
              }
           
              <span className="TopTileRightCol">
                <span className="large-value" >{this.props.studySummary.procedures}</span>
                <span className="small-label">Procedures</span>
              </span>

              <span className="TopTileRightCol">
                <div className="starRating large-value" title={this.props.starRating.toString()}>
                <StarPreview value={this.props.starRating}></StarPreview>
                </div>
                <div className="starRatingTitle small-label">
                  <span className="small-label">Rating</span>
                  <PiInfo className="buttonIcon" onClick={() => this.setState({ popupVisible: true })}></PiInfo>
                </div>

              </span>
              {
                checkUserAccess(this.props.userAccess.userType, AppMode.SitesSummary)
                ?
              <span className="MainProgressbar TopTileRightCol">
                {/* max = {this.props.expectedProceduresCount} value={this.props.f} */}
                {/**/}
                <Progressbar max={this.props.studySelected.expectedProcedures as number} value={this.props.studySummary.procedures} title="Procedures" showValues={true}></Progressbar>
              </span>
:<></>}

            </span>
            


          </span>


          <div className="tabsAndButtons">

              <div className="buttonContainer middleButtons">

                { this.props.studySelected.exportEnabled && (this.props.appmodeStack.includes(AppMode.Search) && this.props.studySelected.id !== "-1" && checkUserAccess(this.props.userAccess.userType, AppMode.Export)) && (
                    <button className={"primaryButton"} onClick={() => { this.props.exportData(); }}>Export</button>
                  )} 

                  {checkUserAccess(this.props.userAccess.userType, AppMode.Edit) && this.props.appmodeStack.includes(AppMode.Search) &&(
                    <button className={editClassName} onClick={()=>{ this.props.toggleEdit(); this.editPressed()}}>Edit</button>
                  )}

                {this.props.studySelected.id !== "-1" && (checkUserAccess(this.props.userAccess.userType, AppMode.Upload) && (
                    <button className={(this.props.appmodeStack.includes(AppMode.Upload))?"primaryButton Pressed":"primaryButton"} onClick={() => { this.props.addAppToStack(AppMode.Upload); }}>Upload</button>
                  ))}
                
              </div>

              <div className="buttonContainer Tabish">

              <button className={(this.props.appmodeStack.includes(AppMode.Home))?"primaryButton Pressed":"primaryButton"}
                      onClick={() => {
                        this.setState({filterPressed: false});
                        this.props.onRefreshSelected();
                        }}>Dashboard</button>
                
                  {this.props.studySelected.id !== "-1" && checkUserAccess(this.props.userAccess.userType, AppMode.Search) && (
                    
                    this.props.loadingProcedures
                    ?
                    <div className="primaryButton animatingButton">Loading...</div>
                    :
                    <button className={(this.props.appmodeStack.includes(AppMode.Search))?"primaryButton Pressed":"primaryButton"}
                            onClick={() => { 
                              this.props.addAppToStack(AppMode.Search)}}>Search</button>
                  )}

                  {/* {this.props.studySelected.id !== "-1" && checkUserAccess(this.props.userAccess.userType, AppMode.Export) && (
                    <button className={(this.props.appmodeStack.includes(AppMode.Export))?"primaryButton Pressed":"primaryButton"} onClick={() => { this.props.addAppToStack(AppMode.Export); }}>Export</button>
                  )} */}

                  {/* {(this.props.appmodeStack.includes(AppMode.Search) || this.props.appmodeStack.includes(AppMode.Export)) &&(
                    
                    <button className={filterClassName + " filter"} onClick={()=>{ this.props.toggleFilter(); this.filterPressed()}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <path id="filter-icon" data-name="filter-icon" d="M11.485,25.713V20.426H17c.552,0,1-.314,1-.808a1.033,1.033,0,0,0-1-.988H10.983L2,7H30L20.516,19.528V32ZM0,4V0H32V4Z"/>
                      </svg>
                    </button> 
                  )}    */}

              </div>





          </div>

          

            {checkUserAccess(this.props.userAccess.userType, AppMode.Settings) &&(
            <div className="buttonContainer buttonSettings">
              <button className={(this.props.appmodeStack.includes(AppMode.Settings))?"primaryButton Pressed":"primaryButton"} onClick={() => { this.props.addAppToStack(AppMode.Settings); }}><PiGearSixFill /></button>
            </div>
          )}


        </div>
      </>
    );
  }
}
