import React, { RefObject } from "react";
import { Toolbar } from "./Toolbar";
import { DataHelper } from "../Helpers/DataHelper";
import { StudyModel } from "../Models/StudyModel";
import { AppMode } from "../Helpers/SharedClasses";
import { PopupWindow } from "../Components/PopupWindow";
import { Slideout } from "../Components/Slideout";
import { UploadWindow } from "../Upload/UploadWindow";
import { SearchWindow } from "../Search/SearchWindow";
import { SettingsWindow } from "../Settings/SettingsWindow";
import { UserWindow } from "../Settings/UserWindow";
import { AddStudyWindow } from "../Settings/AddStudyWindow";
import { ErrorWindow } from "../Components/ErrorWindow";
import { AddUser } from "../Settings/AddUser";
import { checkUserAccess, userType } from "../Helpers/AccessMap"
import { StudySummaryModel } from "../Models/StudySummaryModel";
import { HomeWindow } from "../Home/HomeWindow";
import { SelectedHomeWindow } from "../Home/SelectedHomeWindow";
import { AnatomicalLocationsWindow } from "../Settings/AnatomicalLocationsWindow";
import { UserAccessModel } from "../Models/UserAccessModel";
import { StudiesUserInfoWrapper } from "../Models/userInfoStudiesWrapper";
import { LoadingWidget } from "../Components/LoadingWidget";
import { PreviewProcedureModel } from "../Models/PreviewProcedureModel";
import { ReportingWindow } from "../Report/ReportingWindow";
import { DashboardControlWindow } from "../Settings/DashboardControlWindow";
import { UploadHomeWindow } from "../Home/UploadHomeWindow";
import { TermsConditions } from "./TermsConditions";
import { UsageHistory } from "../Settings/UsageHistory";
import { StudyUsageHistory } from "../Settings/StudyUsageHistory";
import { ImportHistory } from "../Settings/ImportHistory";
import { CurrentLogons } from "../Settings/CurrentLogons";
import { LogOnHistoryWindow } from "../Settings/LogOnHistoryWindow";
import { LabelMappingWindow } from "../Settings/LabelMappingWindow";

export class Main extends React.Component<
  {
    serviceAddress: string,
    authKey: string,
    user_id: string,
    testingStudyId: string,
    unauthorizedCallback: (e: string) => void
  },
  {
    userAccess: UserAccessModel,
    confirmedConditions: boolean,
    allStudies: StudyModel[],
    studySelected: StudyModel,
    studySummary: StudySummaryModel,
    appModeStack: AppMode[],
    reportingProcedure: PreviewProcedureModel | undefined,
    isBusy: boolean,
    toggleFilter:boolean,
    editMode:boolean,
    unstagedChangesPopup:boolean,
    starRating:number,
    procedures: PreviewProcedureModel[]
  }
> {

  dataClient: DataHelper;
  errorWindow: RefObject<ErrorWindow>;
  labelMapRef: RefObject<LabelMappingWindow>;
  searchWindow: RefObject<SearchWindow>;
  anatomicalLocationWindow: RefObject<AnatomicalLocationsWindow>;
  // exportWindow: RefObject<SearchWindow>;
  selectedHome: RefObject<SelectedHomeWindow>;
  userWindow: RefObject<UserWindow>;
  constructor(props: any) {
    super(props);
    this.labelMapRef = React.createRef();
    this.userWindow = React.createRef();
    this.searchWindow = React.createRef();
    this.errorWindow = React.createRef();
    this.anatomicalLocationWindow = React.createRef();
    // this.exportWindow = React.createRef();
    this.selectedHome = React.createRef();
    this.dataClient = new DataHelper(this.props.serviceAddress, this.props.authKey, this.props.user_id, this.props.unauthorizedCallback, this.setBusy);
    
    let defaultStudy = "-1";
    if (props.testingStudyId)
    {
      defaultStudy = props.testingStudyId;
    }

    this.state = {
      toggleFilter:true,
      confirmedConditions: false,
      unstagedChangesPopup: false,
      editMode: false,
      allStudies: [],
      starRating: 0,
      procedures: [],
      userAccess:{
        userPrivileges:[],
        studies:[],
        userType:"",
        userHospital:"",
        logonCount: 0
      },
      studySelected: {
        index: 0,
        id: defaultStudy,
        name: "",
        description: "",
        startdate: undefined,
        expectedProcedures: undefined,
        expectedHospitals: undefined,
        protocolSteps: [],
        workflowFilename: "",
        labelTypes: [],
        lowerReferenceValue: undefined,
        upperReferenceValue: undefined

      },
      
      appModeStack: [ props.testingStudyId ? AppMode.SelectedHome : AppMode.Home],
      studySummary: {
        sites: 0,
        procedures: 0,
        saves: 0,
        consistency: 0,
        index: 0
      },
      isBusy: false,
      reportingProcedure: undefined
    }
  }
  
  componentDidMount(): void {
    this.dataClient.CallSettingsService("GetStudiesUserInfo",{},{},(resultOut:string)=>
    {

      let userInfo:StudiesUserInfoWrapper = JSON.parse(resultOut);
      if (userInfo.miscToken)
      {
        this.dataClient.miscToken = userInfo.miscToken;
      }
      if (this.props.testingStudyId)
      {
        //this.addAppToStack(AppMode.SelectedHome);
        this.onstudySelected(this.state.studySelected, 0);
        

      }else
      {
      this.selectHomescreen(userInfo.userInfo.userType)
      }
      


        if (userInfo.userInfo.studies && userInfo.userInfo.studies.length===1){
          const selectedStudy = userInfo.studies.find(
            (study) => study.name === (userInfo.userInfo.studies as any)[0]
          );
          
          const onlyStudy = selectedStudy || new StudyModel();
          this.onstudySelected(onlyStudy, 0)
          // this.setState({ studySelected: onlyStudy,
          //   userAccess:userInfo.userInfo,
          //   allStudies:userInfo.studies });

          // if (this.selectedHome as SelectedHomeWindow)
          // {
          //   (this.selectedHome as SelectedHomeWindow).getMainData(onlyStudy);
          // }
        // }else
        // {
          this.setState({
            userAccess:userInfo.userInfo,
            allStudies:userInfo.studies
          });
        }else
        {

         
            this.setState({
              userAccess:userInfo.userInfo,
              allStudies:userInfo.studies
            });
          

         
        }
            
    },(stautus:number, errorMessage:string)=>{
      (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage);
    });  
    this.dataClient.attemptSaveUsage();
    
  }

  //add unique usertypespecific homescreens here
  selectHomescreen=(ourUserType:any):void=>{
    if(ourUserType===userType[3]){
      this.addAppToStack(AppMode.UploadHome)
    }
    else{this.addAppToStack(AppMode.SelectedHome)}
  }

setBusy = (isBusyIn: boolean): void  =>
{
  this.setState({
    isBusy: isBusyIn
  })

}
  setAppMode = (mode: AppMode): void  =>
  {
    this.state.appModeStack.push(mode)

  }
  
  onstudySelected = (studyValue: StudyModel, index: number): void  =>
  {
    this.dataClient.CallMetadataService("GetStudySummary", {}, {studyId: studyValue.id}, (resultOut: string)=>
    {
        let studySummary1: StudySummaryModel = JSON.parse(resultOut);
        this.setState({
          studySelected: studyValue, studySummary: studySummary1
        }, ()=>
        {
          
          if (this.selectedHome.current)
          {
            
            (this.selectedHome.current).getMainData(studyValue);
          }
        });
        if (studyValue.id)
          this.refreshProcedures(studyValue.id as string, studyValue.name as string);
    });

  
    
    // if (this.selectedHome as SelectedHomeWindow)
    //     {
    //       (this.selectedHome as SelectedHomeWindow).getMainData(studyValue);
    //     }
    
  }
  loadingProcedures = false;
  refreshProcedures = (studyId: string, studyName: string) => 
  {
    this.loadingProcedures = true;
    this.dataClient.CallMetadataService("GetProcedures", {}, {studyId: studyId, studyName: studyName}, (resultOut: string)=>
    {
      let proceduresOut: PreviewProcedureModel[]  = [];
      let rating = 0;
      proceduresOut = JSON.parse(resultOut);
      proceduresOut.forEach(procedure => rating += (procedure.starRating? procedure.starRating: 0));
      rating = (rating/proceduresOut.length);
      this.loadingProcedures = false;
      this.setState({starRating: rating, procedures: proceduresOut});
    //   if (this.searchWindow.current as SearchWindow)
    // {
    //   (this.searchWindow.current as SearchWindow).loadProcedures(proceduresOut);
    // }
    });
  }

  
  onRefreshSelected=():void=>{
    let homescreen = [AppMode.Home];
    if(this.state.userAccess.userType===userType[3]){
      homescreen = [AppMode.UploadHome];
    }
    else{
      homescreen = [AppMode.SelectedHome];
    }

    this.setState({
      appModeStack:homescreen,
      editMode: false

    }, ()=>
    {
      if (this.selectedHome.current)
          {
            (this.selectedHome.current).getMainData(this.state.studySelected);
          }
    });
  }


  //adds an app layer, eiter 
  addAppToStack=(mode:AppMode, z:number=-1, dontSetState: boolean = false):AppMode[]=>{
    let stackList=this.state.appModeStack
   
    if(!stackList.includes(mode) ){
      if (stackList.includes(AppMode.Home) && (mode === AppMode.Export || mode===AppMode.Search)){
        let index = stackList.indexOf(AppMode.Home);
        stackList.splice(index,1)
      }
      if (stackList.includes(AppMode.SelectedHome) && (mode === AppMode.Export || mode===AppMode.Search)){
        let index = stackList.indexOf(AppMode.SelectedHome);
        stackList.splice(index,1)
      }
      if (stackList.includes(AppMode.UploadHome) && (mode === AppMode.Export || mode===AppMode.Search)){
        let index = stackList.indexOf(AppMode.UploadHome);
        stackList.splice(index,1)
      }
      if (stackList.includes(AppMode.Search)&& mode===AppMode.Export){
        let index = stackList.indexOf(AppMode.Search);
        stackList.splice(index,1)
      }
      if (stackList.includes(AppMode.Export) && mode===AppMode.Search){
        let index = stackList.indexOf(AppMode.Export);
        stackList.splice(index,1)
      }
      stackList.splice(z, 0, mode)
      if (!dontSetState)
        this.setState({appModeStack:stackList})
    }

    return stackList;
  }

  toggleFilter=():void=>{
    this.setState({toggleFilter:!(this.state.toggleFilter)});
    
  }
  toggleEdit=():void=>{
    this.setState({editMode:!(this.state.editMode)})
  }
  exportData=():void=>{
      if (this.searchWindow && this.searchWindow.current)
      {
          this.searchWindow.current.exportData();
      }
    
  }
  
    //removes an app layer, either by type or index, returns what was removed
  removeAppFromStack=(mode:AppMode=AppMode.Default,z:number=0):AppMode=>{
    let stackList=this.state.appModeStack;
    let removed=AppMode.Default;
    if(mode===AppMode.Default){
      try{removed=stackList[z]}
      catch{removed=stackList[0]}
      stackList.splice(z,1)
      
    }else{
      stackList=stackList.filter(item => item !== mode);
      removed=mode;
    }
    this.setState({appModeStack:stackList})
    return removed
  }

  checkAppModeAccess=(mode:AppMode):boolean=>{
    if (this.state.userAccess.userType)
          {if (this.state.appModeStack.includes(mode) && checkUserAccess(this.state.userAccess.userType, mode))
            {
              return true;
            } }
    return false;
  }

  

  showSearchSubwindow= (appMode: AppMode, dataIn: any) =>{

       
      if (appMode === AppMode.Report)
      {
        let stackList = this.addAppToStack(appMode, -1, true);
        this.setState({
          reportingProcedure: dataIn,
          appModeStack:stackList
        });
      }else
      {
        this.addAppToStack(appMode, -1);
      }

  }



  render() {

    let infoContent = "3. Press 'Upload' and select .dat, .sha, and .xml files for upload.\n4. Indicate desired procedures and measurements.\n5. To upload more data, click the 'Upload' button and repeat steps 3 and 4.\n6. Optionally, add an import label and request a confirmation email.\n7. Review, then click the 'Confirm' button to upload data to the study dashboard."
    
    return (
      <>
      <Toolbar starRating={this.state.starRating} loadingProcedures={this.loadingProcedures} toggleEdit = {this.toggleEdit} appmodeStack={this.state.appModeStack} userAccess={this.state.userAccess} studySummary={this.state.studySummary} allStudies={this.state.allStudies} toggleFilter={this.toggleFilter} addAppToStack={this.addAppToStack} removeAppFromStack={this.removeAppFromStack} onRefreshSelected={this.onRefreshSelected}  setAppMode={this.setAppMode} onstudySelected={this.onstudySelected} studySelected={this.state.studySelected} exportData={this.exportData}></Toolbar>
       <div className="mainContainer">
       
       {/* <AddStudyWindow studies={this.state.allStudies} selectedStudy={this.state.studySelected} dataClient={this.dataClient}></AddStudyWindow> */}
        <ErrorWindow ref={this.errorWindow}/>
        

        </div>
        {!this.state.confirmedConditions && this.state.userAccess && (
          <PopupWindow title="Terms of use" closeClicked={()=>{}} hasClose={false}><TermsConditions confirmClicked={(accepted: boolean)=>{
              this.setState({
                confirmedConditions: true
               });
            }}></TermsConditions></PopupWindow>
        )}

        {this.checkAppModeAccess(AppMode.SelectedHome) &&(
            (this.state.studySelected.name=== ""?
          <HomeWindow dataClient={this.dataClient}></HomeWindow>
          :<SelectedHomeWindow ref={this.selectedHome} selectedStudy={this.state.studySelected} dataClient={this.dataClient}></SelectedHomeWindow>
          ) 
            )}

        {/* {this.checkAppModeAccess(AppMode.SelectedHome) &&(
            
            <SelectedHomeWindow ref={this.selectedHome} selectedStudy={this.state.studySelected} dataClient={this.dataClient}></SelectedHomeWindow>
           
            )} */}

        {this.checkAppModeAccess(AppMode.UploadHome) &&(
            
            <UploadHomeWindow dataClient={this.dataClient} uploadButtonClicked={()=>{
              this.addAppToStack(AppMode.Upload);
            }}></UploadHomeWindow>
           
            )}

      
      
        {this.state.appModeStack.includes(AppMode.Upload) &&(
          
          <PopupWindow title="Case Upload" info={infoContent} closeClicked={()=>
          {
            this.removeAppFromStack(AppMode.Upload);
          }}>
            <UploadWindow dataHelper={this.dataClient} user={this.state.userAccess} studySelected={this.state.studySelected} closeClicked={()=>
          {
            this.removeAppFromStack(AppMode.Upload);
          }} refreshStudyRequested={()=>{
            if (this.state.studySelected && this.state.studySelected.id)
              this.onstudySelected(this.state.studySelected, 0);
          }}></UploadWindow>
          </PopupWindow>
          )}

          {this.checkAppModeAccess(AppMode.Search) &&(            
             <SearchWindow ref={this.searchWindow} procedureItemUpdated={(procId: string, columnChanged:string, value:string) =>{
              
              
              let procsIn = this.state.procedures;
              for (let index = 0; index < procsIn.length; index++) {
                const element = procsIn[index];
                if (element.id === procId)
                {
                  (element as any)[columnChanged] = value;
                }
                
              }
              this.setState({procedures: procsIn});

             }} toggleFilter={this.state.toggleFilter} dataHelper={this.dataClient} studySelected={this.state.studySelected} showOtherWindow={this.showSearchSubwindow} procedures={this.state.procedures} editMode={this.state.editMode} exportMode={true} /> 
          )}
        
          {/* {this.checkAppModeAccess(AppMode.Export) &&(
            <SearchWindow ref={this.exportWindow} toggleFilter={this.state.toggleFilter} dataHelper={this.dataClient} studySelected={this.state.studySelected} showOtherWindow={this.showSearchSubwindow} procedures={this.state.procedures} editMode={this.state.editMode} exportMode={true} /> 
            // <ExportWindow toggleFilter={this.state.toggleFilter} dataHelper={this.dataClient} studySelected={this.state.studySelected} procedures={this.state.procedures} />
          )} */}


          {this.checkAppModeAccess(AppMode.Settings) &&(
            <Slideout title="Settings" closeClicked={()=>{
              this.removeAppFromStack(AppMode.Settings)
          }}>
              <SettingsWindow dataClient={this.dataClient} userAccess={this.state.userAccess} addAppToStack={this.addAppToStack}/>
            </Slideout>)}

          {this.checkAppModeAccess(AppMode.User) &&(
            <PopupWindow title="User" closeClicked={()=>{
              this.removeAppFromStack(AppMode.User)
            }}>
              <UserWindow userAccess={this.state.userAccess} allStudies={this.state.allStudies} ref={"userWindow"} dataClient={this.dataClient} addUser={()=>{
              this.addAppToStack(AppMode.AddUser);}}/>
            </PopupWindow>
          )}


          {this.checkAppModeAccess(AppMode.AddUser) &&(
            <PopupWindow title="Add User" closeClicked={()=>{
              this.removeAppFromStack(AppMode.AddUser);

              if(this.checkAppModeAccess(AppMode.User)){
                if ((this.userWindow.current as UserWindow))
                  (this.userWindow.current as UserWindow).refreshUsers();}
            }}>
              <AddUser userAccess={this.state.userAccess}availbleStudies={this.state.userAccess.studies} dataClient={this.dataClient} cancelUser={()=>{
              this.removeAppFromStack(AppMode.AddUser);
              (this.userWindow.current as UserWindow).refreshUsers();
           }}/>
            </PopupWindow>
          )}

          {this.checkAppModeAccess(AppMode.Study) &&(
            <PopupWindow title="Study" closeClicked={()=>{
              this.removeAppFromStack(AppMode.Study);
              
            }}>
              <AddStudyWindow studies={this.state.allStudies} selectedStudy={this.state.studySelected} dataClient={this.dataClient} idOptions={["None","comments","patientId", "createdDate"]}/>
            </PopupWindow>
          )}
          
           {this.state.appModeStack.includes(AppMode.Labels) &&(
            <PopupWindow title="Labels" closeClicked={()=>{
              this.removeAppFromStack(AppMode.Labels);
            }}>
            <AnatomicalLocationsWindow ref={this.anatomicalLocationWindow} selectedStudy={this.state.studySelected} dataClient={this.dataClient} openLabelMap={()=>{
              this.addAppToStack(AppMode.LabelMap)
            }}/>
            </PopupWindow>
          )}
           {this.state.appModeStack.includes(AppMode.Report) &&(
            <PopupWindow title="Report" closeClicked={()=>{
              this.removeAppFromStack(AppMode.Report);
            }}>
            <ReportingWindow  procedure={this.state.reportingProcedure} studySelected={this.state.studySelected} dataHelper={this.dataClient}/>
            </PopupWindow>
          )}
          <LoadingWidget zIndexLayer={10000} visible={this.state.isBusy}></LoadingWidget>

          {this.state.appModeStack.includes(AppMode.DashboardControl) &&(
            <PopupWindow title="Data maintenance" closeClicked={()=>{
              this.removeAppFromStack(AppMode.DashboardControl);
            }}>
            <DashboardControlWindow allStudies={this.state.allStudies} onstudySelected={this.onstudySelected} studySelectedDefault={this.state.studySelected} dataClient={this.dataClient}></DashboardControlWindow>
            </PopupWindow>
          )}
          
          {this.state.appModeStack.includes(AppMode.UsageHistory) &&(
            <PopupWindow title="Logon history" closeClicked={()=>{
              this.removeAppFromStack(AppMode.UsageHistory);
            }}>
            <UsageHistory dataClient={this.dataClient}></UsageHistory>
            </PopupWindow>
          )}
           {this.state.appModeStack.includes(AppMode.StudyUsageHistory) &&(
            <PopupWindow title="Study usage history" closeClicked={()=>{
              this.removeAppFromStack(AppMode.StudyUsageHistory);
            }}>
            <StudyUsageHistory dataClient={this.dataClient}></StudyUsageHistory>
            </PopupWindow>
          )}
          {this.state.appModeStack.includes(AppMode.UserHistorySummary) &&(
            <PopupWindow title="Logon summary for all users" closeClicked={()=>{
              this.removeAppFromStack(AppMode.UserHistorySummary);
            }}>
            <LogOnHistoryWindow dataClient={this.dataClient}></LogOnHistoryWindow>
            </PopupWindow>
          )}
          {this.state.appModeStack.includes(AppMode.LabelMap) &&(
            <PopupWindow title="Label Map" closeClicked={()=>{
              this.labelMapRef.current?.closeLabelMapWindow(()=>{this.removeAppFromStack(AppMode.LabelMap);},()=>{this.removeAppFromStack(AppMode.LabelMap)}, ()=>{this.anatomicalLocationWindow.current?.getData()});
              
            }}>
            <LabelMappingWindow ref={this.labelMapRef} dataClient={this.dataClient} selectedStudy={this.state.studySelected}></LabelMappingWindow>
            </PopupWindow>
          )}
            {this.state.appModeStack.includes(AppMode.CurrentLogons) &&(
            <PopupWindow title="Current logons" closeClicked={()=>{
              this.removeAppFromStack(AppMode.CurrentLogons);
            }}>
            <CurrentLogons dataClient={this.dataClient}></CurrentLogons>
            </PopupWindow>
          )}
          {this.state.appModeStack.includes(AppMode.ImportHistory) &&(
            <PopupWindow title="Upload history" closeClicked={()=>{
              this.removeAppFromStack(AppMode.ImportHistory);
            }}>
            <ImportHistory dataClient={this.dataClient} allStudies={this.state.allStudies}></ImportHistory>
            </PopupWindow>
          )}
      </>
    );
  }
}

